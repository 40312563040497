import React from "react";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import signUpImage from "../../images/2.png";
import { toast } from "react-toastify";
import "../../css/signup.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import Tooltip from "./Tooltip";

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);


  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const handleMouseDownPassword = (e) => {
    // Prevent the input from losing focus when clicking the eye icon
    e.preventDefault();
  };

  const handleMouseDownConfirmPassword = (e) => {
    // Prevent the input from losing focus when clicking the eye icon
    e.preventDefault();
  };

  useEffect(() => {
    axios
      .get("/get_companies")
      .then((response) => setCompanies(response.data))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    localStorage.removeItem('registrationCompleted');
  }, []);
  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    return hasUpperCase && hasNumber;
  };


  const handleChangeForEmail = (event) => {
    if (
      typeof event.target.value !== "undefined" &&
      event.target.value !== ""
    ) {
      var pattern = new RegExp(
        /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(event.target.value)) {
        event.target.setCustomValidity("Please enter a valid email address.");
      } else if (
        event.target.value.includes("gmail") ||
        event.target.value.includes("yahoo") ||
        event.target.value.includes("outlook") ||
        event.target.value.includes("live") ||
        event.target.value.includes("icloud") ||
        event.target.value.includes("ymail") ||
        event.target.value.includes("hotmail") ||
        event.target.value.includes("email")
      ) {
        event.target.setCustomValidity(
          "Email address from this domain not allowed"
        );
      } else {
        event.target.setCustomValidity("");
        setEmail(event.target.value);
      }
    }
  };

  const handleSubmit = (event) => 
  {
    event.preventDefault();

    setPasswordValid(validatePassword(password));
    if (validatePassword(password)) {
      setLoading(true);
      setPasswordValid(true);
      let objToSend = {
        name: firstName + ' ' + lastName,
        fname: firstName,
        lname: lastName,
        email: email,
        country: country,
        phone: phone,
        company: company,
        designation: designation,
        password: password,
        confirmPass: confirmPass,
      };
      console.log(objToSend);
      objToSend = JSON.stringify(objToSend);
      axios
        .post("/create_user", objToSend, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          setLoading(false);
          console.log(response.status);
          if (response.status === 200) {
            navigate("/admin/sign-up/about-me", {
              state: { responseData: response.data },
            });
          } else if (response.status === 202) {
            toast.error("Email Already Exists");
            //setError({ ...error, email: "Email Already Exists!" });
            //document.getElementById('email').setCustomValidity("Email Already Exists!");
          } else if (response.status === 203) {
            toast.error("Passwords do not match");
            setError("Passwords do not match");
            console.log(error);
            //document.getElementById('password').setCustomValidity("Passwords do not match");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    else {
      setPasswordValid(false);
      //toast.error("Invalid password. Ensure it contains at least one uppercase letter and a number.");
    }
    // document.getElementById('password').setCustomValidity("");


    // console.log(name, email, company, phone, country, designation, password)
  };
  const handleCompanySelect = (event) => {
    setCompany(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div
      style={{
        padding: "1em 0",
        height: "110vh",
        backgroundColor: "#ffffff",
      }}
    >
      {currentUser ? (
        <Navigate to="/admin/dashboard" replace={true} />
      ) : (
        <div className="loginCard">
          <div className="card_body">
            <Row>
              <Col xs={12} md={5}>
                <div style={{ padding: "0.5rem 1.5em" }}>
                  <h5 className="form_tagline mb-[0.8em] create_account">
                    Create Account
                  </h5>
                  <form onSubmit={handleSubmit}>
                    {/* <div className="form-group">
                      <label htmlFor="name" className="signUpLabels">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Enter Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="firstName" className="signUpLabels">
                        First Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="firstName"
                        placeholder="Enter First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="lastName" className="signUpLabels">
                        Last Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="lastName"
                        placeholder="Enter Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email" className="signUpLabels">
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Email"
                        onChange={handleChangeForEmail}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company" className="signUpLabels">
                        Company <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-select country_select"
                        id="company"
                        defaultValue=""
                        onChange={handleCompanySelect}
                        required
                      >
                        <option value="" hidden>
                          Select Company
                        </option>
                        {companies.map((company) => (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="designation" className="signUpLabels">
                        Designation
                      </label>
                      <input
                        type="text"
                        name="designation"
                        className="form-control"
                        id="designation"
                        placeholder="Enter Designation"
                        onChange={(e) => setDesignation(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="country" className="signUpLabels">
                        Country <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="country"
                        className="form-select country_select"
                        id="country"
                        defaultValue=""
                        onChange={(e) => setCountry(e.target.value)}
                        required
                      >
                        <option value="" hidden>
                          Select Country
                        </option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Aland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BQ">
                          Bonaire, Sint Eustatius and Saba
                        </option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">
                          Congo, Democratic Republic of the Congo
                        </option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Cote D'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curacao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="VA">
                          Holy See (Vatican City State)
                        </option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">
                          Lao People's Democratic Republic
                        </option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libyan Arab Jamahiriya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK">
                          Macedonia, the Former Yugoslav Republic of
                        </option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">
                          Micronesia, Federated States of
                        </option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Reunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthelemy</option>
                        <option value="SH">Saint Helena</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="CS">Serbia and Montenegro</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.s.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone" className="signUpLabels">
                        Phone <span style={{ color: "red" }}>*</span>
                      </label>
                      <PhoneInput
                        defaultCountry={country}
                        placeholder="Enter phone number (without country code)"
                        value={phone}
                        onChange={setPhone}
                      />
                      {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="password" className="signUpLabels">
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        className={`form-control`}
                        id="password"
                        placeholder="Enter Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="signUpLabels">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        name="confirmpassword"
                        className="form-control"
                        id="confirmpassword"
                        placeholder="Enter Confirm Password"
                        onChange={(e) => {
                          setConfirmPass(e.target.value);
                        }}
                        required
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="password" className="signUpLabels">
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="password-input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className={`form-control`}
                          id="password"
                          placeholder="Enter Password"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          title={"Password must contain at least one uppercase letter and one number."}
                        />
                        <span
                          className="password-toggle-icon"
                          onClick={togglePasswordVisibility}
                          onMouseDown={handleMouseDownPassword}
                        >
                          👁️
                        </span>
                      </div>

                      <div className="password-validation-message">
                        {passwordValid ? "" : "Password must contain at least one uppercase letter and one number."}
                      </div>

                    </div>
                    <div className="form-group">
                      <label htmlFor="confirmpassword" className="signUpLabels">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="password-input-container">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmpassword"
                          className="form-control"
                          id="confirmpassword"
                          placeholder="Enter Confirm Password"
                          onChange={(e) => {
                            setConfirmPass(e.target.value);
                          }}
                          required
                        />
                        <span
                          className="password-toggle-icon"
                          onClick={toggleConfirmPasswordVisibility}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          👁️
                        </span>
                      </div>
                    </div>
                    <br />
                    <div className="signUp_btn_container justify-content-between align-items-center text-center">
                      <a
                        href="/admin/login"
                        className="text-[11px] already_registered"
                        style={{ textDecoration: "none" }}
                      >
                        <span className="text-gray-500">
                          Already Have An Account?
                        </span>{" "}
                        Login Here
                      </a>
                      <button
                        type="submit"
                        disabled = {loading}
                        className="btn custom_btn signUp_button"
                        style={{ width: "120px" }}
                      >
                        <div className="d-flex justify-content-center gap-2 align-items-center">
                          Sign Up
                          {loading && (
                            <div className="spinner-border text-info" style = {{ width: '18px', height: '18px' }} role="status"></div>
                          )}
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={7} className="d-none d-md-block">
                <div className="background-signup">
                  <div className="imageText">
                    Subscribe to Inv-X <br />
                    Premium <br />
                    and Save More.
                  </div>
                  <img loading="lazy"  src={signUpImage} className="bg-image-signup-1" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
