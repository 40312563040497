import React from 'react';
import Header from '../header/Header';
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import '../../css/style.css';
import '../../css/FAQ.css';

const FrequentlyAskedQuestions = (props) => {
    // Mock data for FAQs, you can fetch this data from an API or any other source
    const faqData = [
        {
            question: "How many user roles are available on the platform?",
            answer: "There are two user roles: buyers and sellers."
        },
        {
            question: "Can a user hold both buyer and seller roles simultaneously?",
            answer: "Yes, a user can be both a buyer and a seller."
        },
        {
            question: "What are the types of products that a seller can list?",
            answer: "Sellers can list two types of products: items with a fixed price and lots made up of multiple items."
        },
        {
            question: "What's the difference between open and closed bidding for lots?",
            answer: "In open bidding, there's no deadline and sellers can accept a bid at any time. In closed bidding, there's a set deadline and sellers can only view all the bids after the deadline."
        },
        {
            question: "When can sellers view bids in a closed bidding system?",
            answer: "Sellers can view all the bids for a closed bidding only after the bidding deadline has passed."
        },
        {
            question: "What steps do sellers need to follow to list an item?",
            answer: "Sellers first create and list an item, and then they need to publish it."
        },
        {
            question: "Are all listed items immediately visible to buyers?",
            answer: "No, only items that have been published by the seller are visible in the search to buyers."
        }
    ];

    const [filteredFaqs, setFilteredFaqs] = useState(faqData);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = event => {
        const query = event.target.value;
        setSearchTerm(query);

        const filtered = faqData.filter(faq =>
            faq?.question?.toLowerCase()?.includes(query?.toLowerCase()) ||
            faq?.answer?.toLowerCase()?.includes(query?.toLowerCase())
        );
        setFilteredFaqs(filtered);
    };

    return (
        <div className='faq_page' style={{ height: '100vh' }}>
            <Header logOut={props.logOut} />
            <br />
            <div style={{ backgroundColor: '#F8F8F8' }}>
                <div
                    className='container faq_searchBar_con'
                    style={{
                        background: '#F8F8F8',
                        position: 'relative',
                        zIndex: '5',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <h4 className="pageName_heading">FAQs</h4>
                    <input
                        type="text"
                        placeholder="Search FAQs..."
                        value={searchTerm}
                        onChange={handleSearch}
                        style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            marginBottom: '20px'
                        }}
                    />
                </div>

                <div className='container pb-5'>
                    {filteredFaqs.length === 0 ? (
                        <div className="no-faq-matches">No FAQ matches found.</div>
                    ) : (
                        <Row className="faqContainer">
                            {filteredFaqs.map((faq, index) => (
                                <div key={index} className="faqItem">
                                    <div className="faqQuestion">{faq.question}</div>
                                    <div className="faqAnswer">{faq.answer}</div>
                                </div>
                            ))}
                        </Row>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FrequentlyAskedQuestions;
