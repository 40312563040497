// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
}
  
.tooltip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: white;
    background: black;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 0 !important;
    white-space: nowrap;
    opacity: 1 !important;
}

.tooltip.right {
    left: calc(100% + 30px);
    top: 50%;
    width: 250px;
    transform: translateX(0) translateY(-50%);
}

.bulk-import-tooltip .tooltip {
    z-index: 1 !important;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 1em;
}

.bulk-import-tooltip .tooltip ul.MuiList-root span {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/css/tooltip.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;IACT,2BAA2B;IAC3B,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,uBAAuB;IACvB,cAAc;IACd,qBAAqB;IACrB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,QAAQ;IACR,YAAY;IACZ,yCAAyC;AAC7C;;AAEA;IACI,qBAAqB;IACrB,uBAAkB;IAAlB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".tooltip-wrapper {\n    display: inline-block;\n    position: relative;\n    cursor: pointer;\n}\n  \n.tooltip {\n    position: absolute;\n    border-radius: 4px;\n    left: 50%;\n    transform: translateX(-50%);\n    padding: 6px;\n    color: white;\n    background: black;\n    font-size: 14px;\n    font-family: sans-serif;\n    line-height: 1;\n    z-index: 0 !important;\n    white-space: nowrap;\n    opacity: 1 !important;\n}\n\n.tooltip.right {\n    left: calc(100% + 30px);\n    top: 50%;\n    width: 250px;\n    transform: translateX(0) translateY(-50%);\n}\n\n.bulk-import-tooltip .tooltip {\n    z-index: 1 !important;\n    width: fit-content;\n    padding: 0 1em;\n}\n\n.bulk-import-tooltip .tooltip ul.MuiList-root span {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
