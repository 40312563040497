import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';

const ItemPieChart = ({ startDate, endDate }) => {
  const [pieData, setPieData] = useState([]);

  // Fetch data
  useEffect(() => {
    const fetchDataForItemPieChart = async () => {
      const url = `/dashboard/itemstats?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        setPieData(response.data.itemStats);
      } catch (error) {
        console.log('Error fetching data for item pie chart: ', error);
      }
    };
    fetchDataForItemPieChart();
  }, [startDate, endDate]);

  // Render chart
  useEffect(() => {
    if (pieData.length === 0) {
      return;
    }

    const canvas = document.getElementById('itemPieCanvas');
    const ctx = canvas.getContext('2d');
    const labelMap = {
      'publishedItems': 'Published Items',
      'unpublishedItems': 'Unpublished Items',
      // Add more mappings as needed
    };
    
    const labels = Object.keys(pieData).map(key => labelMap[key] || key);
  
    const data = Object.values(pieData);

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: ['#C8A2C8', '#40E0D0'],
          },
        ],
      },
    };

    const chartInstance = new Chart(ctx, config);

    // Destroy chart instance to avoid memory leaks when component unmounts or data changes
    return () => {
      chartInstance.destroy();
    };
  }, [pieData]);

  return <canvas id="itemPieCanvas" width="50" height="50" />;
};

export default ItemPieChart;
