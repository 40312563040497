import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';

const BidTypePieChart = ({ startDate, endDate }) => {
  const [bidData, setBidData] = useState([]);

  useEffect(() => {
    const fetchBidTypeData = async () => {
      const url = `/dashboard/bidtypecount?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        setBidData([data.OpenBiddingCount, data.CloseBiddingCount]);
      } catch (error) {
        console.log("Error fetching data for bid type pie chart: ", error);
      }
    };
    fetchBidTypeData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (bidData.length === 0) {
      return;
    }

    const canvas = document.getElementById('bidTypePieCanvas');
    const ctx = canvas.getContext('2d');

    const labels = ['Open Bidding', 'Close Bidding'];
    const data = {
      labels,
      datasets: [{
        data: bidData,
        backgroundColor: ['#C8A2C8', '#40E0D0'],
      }],
    };

    const config = {
      type: 'pie',
      data,
    };

    const chartInstance = new Chart(ctx, config);

    return () => {
      chartInstance.destroy();
    };
  }, [bidData]);

  return (
    <canvas id="bidTypePieCanvas" width="100" height="100"></canvas>
  );
};

export default BidTypePieChart;
