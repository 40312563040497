import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';

const CompanyPieChart = ({ startDate, endDate }) => {
  const [pieData, setPieData] = useState([]);

  // Fetch data
  useEffect(() => {
    const fetchDataForLotPieChart = async () => {
      const url = `/dashboard/company-sales-count?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        console.log(response.data);
        setPieData(response.data);
      } catch (error) {
        console.log('Error fetching data for lot pie chart: ', error);
      }
    };
    fetchDataForLotPieChart();
  }, [startDate, endDate]);

  // Render chart
  useEffect(() => {
    if (pieData.length === 0) {
      return;
    }

    const canvas = document.getElementById('companyPieCanvas');
    const ctx = canvas.getContext('2d');
    const labels = Object.keys(pieData);
    const data = Object.values(pieData);

    const config = {
        type: 'pie',
        data: {
          labels,
          datasets: [
            {
              data,
              // Using hex color codes for a light purple (lavender) and a turquoise shade
              backgroundColor: ['#C8A2C8', '#40E0D0'],
            },
          ],
        },
      };
      

    const chartInstance = new Chart(ctx, config);

    // Destroy chart instance to avoid memory leaks when component unmounts or data changes
    return () => {
      chartInstance.destroy();
    };
  }, [pieData]);

  return <canvas id="companyPieCanvas" width="200" height="200" />;
};

export default CompanyPieChart;
