import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Alert, Dropdown, Modal } from 'react-bootstrap';
import { useNavigate, Navigate } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import LocationPicker from "./LocationPicker.js";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "./Tooltip";
import { Button } from "react-bootstrap";
import "../../css/style.css";
import "../../css/inventory.css";
import { Category } from "@mui/icons-material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";


const CreateItem = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const [alertError, setAlertError] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(null);

  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(require("../../images/no-image.jpg"));
  const [images, setImages] = useState([]);
  const [limit, setLimit] = useState(null);
  const [sizeAllowed, setSizeAllowed] = useState(0);

  const [item, setItem] = useState({});
  const [files, setFiles] = useState([]);
  const [docName, setDocName] = useState([]);
  const [types, setTypes] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [initialPosition, setInitialPosition] = useState(null);
  const [showModal, setShowModal] = useState(false);

  


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setInitialPosition([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, []);

  const handleLocationSelect = (selectedLocation) => {
    console.log("Selected location:", selectedLocation);
    setLocation(selectedLocation);
    setShowModal(false); // Update the state with the selected location
    console.log(selectedLocation);
  };

  const timeOut = () => {
    const timeId = setTimeout(() => {
      setAlertSuccess(null);
      setAlertError(null);
      navigate("/admin/item-listing/inventory-list", { state: item });
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  };
  const imageInput = useRef();
  const [formFields, setFormFields] = useState([
    {
      label: "Size",
      name: "size",
      placeholder: "Enter Size",
      type: "number",
      step: "any",
    },
    {
      label: "Physical State",
      name: "physical-state",
      placeholder: "Enter Physical State",
      type: "text",
    },
    {
      label: "Grade Standard",
      name: "grade-standard",
      placeholder: "Enter Grade Standard",
      type: "text",
    },
    {
      label: "Material Number",
      name: "material-number",
      placeholder: "Enter Material Number",
      type: "number",
    },
    {
      label: "Expiry Date",
      name: "expiry-date",
      placeholder: "Enter Expiry Date",
      type: "date",
    },
   
    {
      label: `Quantity`,
      name: "quantity",
      placeholder: "Enter Quantity",
      type: "number",
      step: "any",
    },
    {
      label: "Minimum Buying Quantity",
      name: "min-buy-quantity",
      placeholder: "Enter Minimum Buying Quantity",
      type: "number",
      step: "any",
    },

  ]);
  const [newLabels, setNewLabels] = useState([]);
  const [counter, setCounter] = useState(0);
  const [newLabel, setNewLabel] = useState("");
  // const [published, setPublished] = useState(true);
  const [values, setValues] = useState([]);

  const [industryFilters, setIndustryFilters] = useState([]);
  const [mapKey, setMapKey] = useState(Math.random());


  const IndustryDropdown = ({ industryFilters, setIndustryFilters }) => {
    const industries = [
      "Aerospace",
      "Agriculture",
      "Computer",
      "Construction",
      "Education",
      "Electronics",
      "Energy",
      "Entertainment",
      "Food",
      "Health Care",
      "Hospitality",
      "Manufacturing",
      "Mining",
      "Music",
      "News Media",
      "Pharmaceutical",
      "Telecommunication",
      "Transport",
      "World Wide Web"
    ];
    




    
    const handleIndustryChange = (industry) => {
      if (industryFilters.includes(industry)) {
        setIndustryFilters(prev => prev.filter(item => item !== industry));
      } else {
        setIndustryFilters(prev => [...prev, industry]);
      }
    };

    const selectAllIndustries = () => {
      if (industryFilters.length === industries.length) {
        setIndustryFilters([]);
      } else {
        setIndustryFilters(industries);
      }
    };

    return (
      <div className="row">
        <div className="col-md-3">
          <label>Target Industry <span>*</span></label>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <Dropdown>
              <Dropdown.Toggle
                variant="light" // White background
                style={{ fontSize: '0.8rem', color: 'black' }} // Smaller size and black text
              >
                Target Industry
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropDown_wrapper">
                <div className="p-3 d-flex flex-column" style={{ height: '18rem', overflowY: 'scroll', gap: '5px' }}>
                  <div className="d-flex" style={{ gap: '0 8px' }}>
                    <input type="checkbox" name="selectAll" style={{ backgroundColor: 'white' }} checked={industryFilters.length === industries.length} onChange={selectAllIndustries} onClick={(e) => e.stopPropagation()} />
                    <label htmlFor="selectAll">Select All</label>
                  </div>

                  {industries.map((industry) =>
                    <div key={industry} className="d-flex align-items-center" style={{ gap: '0 10px' }}>
                      <input
                        type="checkbox"
                        name={industry}
                        value={industry}
                        onChange={() => handleIndustryChange(industry)}
                        checked={industryFilters.includes(industry)}
                        style={{ backgroundColor: 'white' }} // Apply white background
                        onClick={(e) => e.stopPropagation()}
                      />

                      <label style={{ fontSize: '12px' }}>{industry}</label>
                    </div>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  };






  const handleLabelInput = (e) => {
    setNewLabel(e.target.value);
  };

  const addFormField = () => {
    setFormFields([
      ...formFields,
      {
        label: newLabel,
        name: `new-field${counter}`,
        placeholder: "Enter New Field",
        type: "text",
      },
    ]);
    setCounter(counter + 1);
    setNewLabels([...newLabels, newLabel]);
    setNewLabel("");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    axios
      .get("/get_categories")
      .then((response) => {
        console.log(response.data);
        const valuesArr = response.data.map(
          (category) => category.categoryName
        );
        setValues(valuesArr.slice(1));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/get_documentTypes")
      .then((response) => {
        console.log(response.data);
        const newArr = response.data.map((type) => type.documentType);
        setTypes(newArr);
      })
      .catch((error) => {
        console.log(error);
      });
      axios.get(`/getImageLimit?userId=${currentUser.id}`)
      .then((response) => {
        console.log(response.data);
        setLimit(response.data.imageLimit);
      })
      .catch((error) => {
        console.log(error);
      });
      axios.get(`/file_size_allowed?user_id=${currentUser.id}`)
        .then((response) => {
            console.log(response.data);
            setSizeAllowed(response.data.file_size_allowed);
        })
        .catch((error) => {
            console.log(error);
        });
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };
  const handleImageChange = (e) => 
  {
    const newImageFiles = Array.from(e.target.files);
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    const allFilesValid = Array.from(newImageFiles).every(file => allowedExtensions.test(file.name));

    if (!allFilesValid) 
    {
      toast.error('Invalid file type. Please upload images only.');
      // You can also clear the input field here if needed
      return;
    }

    // Check if adding new images exceeds the limit
    if (images.length + newImageFiles.length > limit) {
        // Optionally, alert the user or handle this case differently
        toast.error("Cannot add more images. Limit reached.");
        return; // Prevent adding new images
    }

    // If within the limit, add the new images
    setImages((prevImages) => [...prevImages, ...newImageFiles]);
    console.log(newImageFiles[0]?.name, 'newImageFiles?.name');
};

const handleFileChange = (e) => {
  const maxSizeInMB = sizeAllowed; // Size limit, for example, 5 MB
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes
  const docfiles = Array.from(e.target.files);

  docfiles.forEach(file => {
      if (file.size <= maxSizeInBytes) {
          setFiles((prevFiles) => [...prevFiles, file]);
          setDocName([...docName, 'technical datasheet']);
      } else {
          // Show toast notification
          toast.error(`No file above ${maxSizeInMB} MB accepted for this subscription level`);
      }
  });
};
  const handleDeleteFile = (fileIndex) => {
    // Deleting from files array
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));

    // Deleting the associated name from docName array
    setDocName((prevDocNames) => prevDocNames.filter((_, index) => index !== fileIndex));
  };

  const handleFileName = (index, e) => {
    setDocName((prevDocNames) => {
      const newDocNames = [...prevDocNames];
      newDocNames[index] = e.target.value;
      return newDocNames;
    });
    console.log(docName);
  };

  const handleInputChange = (event) => {
    let tempItem = { ...item };

    if (event.target.name === "name") tempItem["Name"] = event.target.value;
    if (event.target.name === "address") tempItem["address"] = event.target.value;
    else if (event.target.name === "price")
      tempItem["Price"] = event.target.value;
    // else if (event.target.name === "industry")
    //   tempItem["Industry"] = event.target.value;
    else if (event.target.name === "category")
      tempItem["Category"] = event.target.value;
    // else if (event.target.name === "bid-choice")
    //   tempItem["BidChoice"] = event.target.value;
    else if (event.target.name === "packaging")
      tempItem["Packaging"] = event.target.value;
    else if (event.target.name === "description")
      tempItem["Description"] = event.target.value;
    else if (event.target.name === "size")
      tempItem["Size"] = event.target.value;
    else if (event.target.name === "physical-state")
      tempItem["PhysicalState"] = event.target.value;
    else if (event.target.name === "grade-standard")
      tempItem["GradeStandard"] = event.target.value;
    else if (event.target.name === "material-number")
      tempItem["MaterialNumber"] = event.target.value;
    else if (event.target.name === "expiry-date") {
      const selectedDate = new Date(event.target.value);
      setFormattedDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
      tempItem["ExpiryDate"] = event.target.value;
    } else if (event.target.name === "quantity")
      tempItem["Quantity"] = event.target.value;
    else if (event.target.name === "uom") tempItem["UoM"] = event.target.value;
    else if (event.target.name === "min-buy-quantity") tempItem["MinQuan"] = event.target.value;
    for (let x = 0; x < counter; x++) {
      if (event.target.name === `new-field${x}`) {
        tempItem[newLabels[x]] = event.target.value;
      }
    }
    const updatedFormFields = [...formFields];

    setFormFields(updatedFormFields);

    console.log(tempItem);
    setItem(tempItem);
    console.log(item);
  };

  const handleCancel = () => {
    navigate("/admin/item-listing/inventory-list");
  };

  const handleDone = async (event) => {
    event.preventDefault();
    console.log(item);
    if (docName.includes('')) {
      toast.error('Please select a file name for each file');
      return;
    }
    if (!industryFilters.length) {
      toast.error('Please select at least one industry.');
      return;
    }
    setIsLoading(true);


    const formData = new FormData();
    formData.append("itemName", item.Name);
    console.log(formData);
    // formData.append("itemIndustry", item.Industry);
    formData.append("itemCategory", item.Category);
    formData.append("address", item.address);
    // formData.append('itemBid', item.BidChoice);
    formData.append("itemPackaging", item.Packaging);
    formData.append("itemDesc", item.Description);
    formData.append("itemSize", item.Size);
    formData.append("itemState", item.PhysicalState);
    formData.append("itemGrade", item.GradeStandard);
    formData.append("itemMaterialNumber", item.MaterialNumber);
    formData.append("itemExpiry", item.ExpiryDate);
    formData.append("itemQuantity", item.Quantity);
    formData.append("itemUom", item.UoM);
    formData.append("itemPrice", item.Price);
    formData.append("itemMinQuantity", item.MinQuan);
    formData.append("sellerId", currentUser.id);
    formData.append("lat", location.lat);
    formData.append("lng", location.lng);
    formData.append("itemIndustries", JSON.stringify(industryFilters));
 
    console.log(formData);
    images.forEach((image, index) => {
      formData.append(`image_${index}`, image);
    });
    files.forEach((doc, index) => {
      formData.append(`doc_${index}`, doc);
    });
    let x = 0;
    formFields.forEach((field) => {
      if (field.name !== "min-buy-quantity" && field.name != 'quantity') {
        formData.append(field.label, item[field.label]);
      }

    });
    docName.forEach((name, index) => {
      formData.append(`docName_${index}`, name);
    });
    //formData.append('itemFile', file);
    console.log(formData);

    try {
      const response = await axios.post(
        "/create_item",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setIsLoading(false);
      setAlertSuccess("Item Created Successfully.");
      timeOut()
    } catch (error) {
      console.log("Error occurred:", error);

      // Checking if the error response is in the expected format
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
        setIsLoading(false);
      } else {
        toast.error("An unexpected error occurred.");
        setIsLoading(false);
      }
    }
  };

 
  const handleDeleteImage = (imageIndex) => {
    setImages((prevImages) => prevImages.filter((_, index) => index !== imageIndex));
  };
  const handleRemoveField = (fieldIndex) => {
    setFormFields(prevFields => prevFields.filter((_, index) => index !== fieldIndex));
    // Also remove from newLabels if needed.
    setNewLabels(prevLabels => prevLabels.filter((_, index) => index !== fieldIndex));
  };



  return (
    <div className="create_items">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                  }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>
              <div
                className={`inventory_list_content create_item ${collapseSidebar ? "open_content" : "pinched_content"
                  }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}

                <div
                  className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                    }`}
                >
                  <div className="content_panel">
                    {alertError && <Alert variant="danger">{alertError}</Alert>}
                    {alertSuccess && <Alert variant="success">{alertSuccess}</Alert>}
                    <h4 className="pageName_heading">{localStorage.getItem('logoUrl') && (
  <img
    src={localStorage.getItem('logoUrl')}
    alt="Company Logo"
    style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }}
  />
)}
Create Item</h4>
                    <form onSubmit={handleDone}>
                      <div className="createItem d-md-flex">
                        {/* <div className='upload_image'>
                          <input type="file" accept="image/*" style={{ display: 'none' }} ref={imageInput} onChange={handleImageChange} />
                          <div className='text-center d-lg-flex align-items-center' style={{gap:'10px'}}>
                            <img loading="lazy"  src={image} alt="" onClick={() => { imageInput.current.click(); }} /><span>Item Name</span>
                          </div>

                        </div> */}
                        <div
                          className="upload_image"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >

                          {/* <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            multiple
                          /> */}
                          <label>
                            Upload Image <span style={{ color: "red" }}>*</span>
                            <input
                              className="ml-5"
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              multiple
                              required
                            />
                          </label>
                          {images &&
                            images.length > 0 &&
                            images.map((image, index) => (
                              <div key={index} style={{ position: 'relative', flex: "0 0 25%", padding: "10px" }}>
                                <img loading="lazy" 
                                  src={URL.createObjectURL(image)}
                                  alt=""
                                  style={{ maxWidth: "100%", height: "auto" }}
                                />
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "50%",
                                    padding: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDeleteImage(index)}
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="12"
                                  height="12"
                                  fill="#000000"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z" />
                                  </svg>
                                </span>
                                <span>Image {index + 1}</span>
                              </div>
                            ))
                          }

                        </div>

                        <div className="buttons_group">
                          <button
                            type="button"
                            className="cancel"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="done">
                            {isLoading ? (
                              <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status" style={{ height: '20px', width: '20px' }}></div>
                              </div>
                            ) : (
                              <>
                                <AiOutlineCheckCircle />
                                {isLoading ? (
                                  <div
                                    className="spinner-border text-info"
                                    role="status"
                                    style={{
                                      zIndex: '2',
                                      width: '20px',
                                      height: '20px',
                                      textAlign: 'center',
                                    }}
                                  ></div>
                                ) : (
                                  'Create'
                                )}

                              </>
                            )}
                          </button>

                        </div>
                      </div>
                      <div className="secondaryContainer">
                        <span>Core Information</span>
                        <br />
                        <span>
                          <small>
                            <RiErrorWarningLine
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                marginTop: "-3px",
                              }}
                            />
                            Specify the primary description for your item.
                          </small>
                        </span>
                      </div>

                      <div className="createItemForm">
                        <div className="row" style={{ marginTop: "0" }}>
                          <div className="col-md-3">
                            <label>
                              Item Name <span>*</span>
                            </label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter Item Name"
                                onChange={handleInputChange}
                                required
                                maxLength="1024"
                              />
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Input the name of your item, can not be more than 1024 characters.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-3">
                            <label>
                              Target Industry <span>*</span>
                            </label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <select
                                name="industry"
                                className="form-select"
                                defaultValue=""
                                onChange={handleInputChange}
                                required
                              >
                                <option value="" hidden>
                                  Select Target Industry
                                </option>
                                <option value="aerospace">Aerospace</option>
                                <option value="agriculture">Agriculture</option>
                                <option value="computer">Computer</option>
                                <option value="construction">
                                  Construction
                                </option>
                                <option value="education">Education</option>
                                <option value="electronics">Electronics</option>
                                <option value="energy">Energy</option>
                                <option value="entertainment">
                                  Entertainment
                                </option>
                                <option value="food">Food</option>
                                <option value="healthcare">Health Care</option>
                                <option value="hospitality">Hospitality</option>
                                <option value="manufacturing">
                                  Manufacturing
                                </option>
                                <option value="mining">Mining</option>
                                <option value="music">Music</option>
                                <option value="news-media">News Media</option>
                                <option value="pharmaceutical">
                                  Pharmaceutical
                                </option>
                                <option value="telecommunication">
                                  Telecommunication
                                </option>
                                <option value="transport">Transport</option>
                                <option value="world-wide-web">
                                  World Wide Web
                                </option>
                              </select>
                            </div>
                          </div>
                        </div> */}


                        <IndustryDropdown industryFilters={industryFilters} setIndustryFilters={setIndustryFilters} />


                        <div className="row">
                          <div className="col-md-3">
                            <label>
                              Category <span>*</span>
                            </label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <select
                                name="category"
                                className="form-select"
                                defaultValue=""
                                onChange={handleInputChange}
                                required
                              >
                                <option value="" disabled hidden>
                                  Enter category
                                </option>
                                {values.map((value, index) => {
                                  return (
                                    <option value={value} key={index}>
                                      {value}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* <input type="text" name="category" className="form-control" placeholder="Enter Category" onChange={handleInputChange} required /> */}
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Enter the best known keyword which can be used to describe your item.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-3">
                            <label>Bid Choice <span>*</span></label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <select name="bid-choice" className="form-select" defaultValue="" onChange={handleInputChange} required>
                                <option value="" hidden>Select Bid Choice</option>
                                <option value="Fixed Price">Fixed Price</option>
                                <option value="Open Bidding">Open Bidding</option>
                                <option value="Close Bidding">Close Bidding</option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-md-3">
                            <label>Packaging <span>*</span></label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                name="packaging"
                                className="form-control"
                                placeholder="Enter Packaging"
                                onChange={handleInputChange}
                                required
                                maxLength="1024"
                              />
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="How is your item packed with respect to its state e.g., drums for liquid etc.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label>Documents <span>*</span></label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="file"
                                name="documents"
                                className="form-control"
                                placeholder="Choose file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                required
                                multiple
                              />
                              {files &&
                                files.length > 0 &&
                                files.map((file, index) => (
                                  <div
                                    key={index}
                                    style={{ flex: "0 0 25%", padding: "10px" }}
                                  >
                                    <span>{file.name}</span>
                                    <span>
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="mr-5"
                                        onClick={() => handleDeleteFile(index)}
                                      />
                                    </span>
                                    <select
                                      className="form-select"
                                      type="text"
                                      placeholder="Enter file name"
                                      onChange={(e) => handleFileName(index, e)}
                                      required
                                    >
                                      {types.map((option, optionIndex) => (
                                        <option
                                          key={optionIndex}
                                          value={option}
                                        >
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Please upload any relevant documents or files related to this item. Only enter PDFs.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label>Unit of Measurement <span>*</span></label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                name="uom"
                                placeholder="Enter UoM"
                                className="form-control"
                                required
                                onChange={handleInputChange}
                                maxLength="1024"
                              />

                            </div>
                          </div>
                        </div>
                        <div className="row">
  <div className="col-md-3">
    <label>Price (USD, exclusive of taxes) <span>*</span></label> 
  </div>
  <div className="col-11 col-md-5">
    <div className="form-group">
      <input
        type="number"
        name="price"
        className="form-control"
        placeholder="Enter Price"
        onChange={handleInputChange}
        min={1}
        step="0.01"
        required
      />
    </div>
  </div>
</div>
<div className="row" >
                          <div className="col-md-3">
                            <label>
                              Address <span>*</span>
                            </label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                name="address"
                                className="form-control"
                                placeholder="Enter Item Address"
                                onChange={handleInputChange}
                                required
                                maxLength="1024"
                              />
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Input the address of your item, can not be more than 1024 characters.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>
<div className="row">
<div className="col-md-3">
    <label>Location <span>*</span></label>
  </div>
  <div className="col-md-5">
        <div className="form-group">
          <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>
            Select Location
          </button>
          {location && (
            <div>
              <strong>Selected Location:</strong> {location.lat}, {location.lng}
            </div>
          )}
        </div>
      </div>
  <div className="col-1 col-md-2 tooltip_container">
    <Tooltip content="Select the location for your item on the map.">
      <BsInfoCircle />
    </Tooltip>
  </div>
  </div>
  {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Select Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LocationPicker 
              onLocationSelect={handleLocationSelect} 
              initialPosition={initialPosition} 
              key={mapKey}
            />
          </Modal.Body>
        </Modal>
      )}

                        <div className="row">
                          <div className="col-md-3">
                            <label>Description <span>*</span></label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <textarea
                                rows="4"
                                name="description"
                                className="form-control"
                                style={{ resize: "none" }}
                                placeholder="Enter Description"
                                onChange={handleInputChange}
                                required
                                maxLength="1024"
                              />
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Input the description for your item, can not be more than 1024 characters.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-3">
                            <label>Published</label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                            <div>
      <label>
        <input
          type="radio"
          name="published"
          value="true"
          checked={published === true}
          onChange={handlePublishedChange}
        />
        Published
      </label>
      <label>
        <input
          type="radio"
          name="published"
          value="false"
          checked={published === false}
          onChange={handlePublishedChange}
        />
        Unpublished
      </label>
    </div>
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="secondaryContainer">
                        <span>Extended Information</span>
                        <br />
                        <span>
                          <small>
                            <RiErrorWarningLine
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                marginTop: "-3px",
                              }}
                            />
                            Specify other additional details for your item.
                          </small>
                        </span>
                      </div>

                      <div className="createItemForm">
                        {formFields.map((field, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-3">
                              <label>
                                {field.label}
                                {field.name === 'quantity' || field.name === 'min-buy-quantity' ? <span>*</span> : null}
                              </label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group" style={{ display: "flex", alignItems: "center" }}>
                                {field.type === "date" ? (
                                  <input
                                    type={field.type}
                                    step={field.step}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    value={formattedDate}
                                    onChange={handleInputChange}
                                    style={{
                                      border: "1px solid #ced4da",
                                      borderRadius: "0.25rem",
                                      padding: "0.375rem 0.75rem",
                                      backgroundColor: "#ffffff",
                                      color: "#000000",
                                    }}
                                    min={new Date().toISOString().split('T')[0]}
                                  // required
                                  />
                                ) : (
                                  <input
                                    type={field.type}
                                    step={field.step}
                                    name={field.name}
                                    className="form-control"
                                    placeholder={field.placeholder}
                                    onChange={handleInputChange}
                                    required={field.name === 'quantity' || field.name === 'min-buy-quantity'}
                                    min={(field.name === 'quantity' || field.name === 'min-buy-quantity' || field.name === "size") ? 1 : undefined}
                                  />
                                )}
                                {index >= 7 && (
                                  <span style={{ marginLeft: '10px' }}>
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="mr-5"
                                      onClick={() => handleRemoveField(index)}
                                    />
                                  </span>
                                )}

                                {
                                  field.name === "grade-standard" && (
                                    <div className="col-1 col-md-2 ml-5 tooltip_container">
                                      <Tooltip content="Enter the best known keyword which can be used to describe grade of your item.">
                                        <BsInfoCircle />
                                      </Tooltip>
                                    </div>
                                  )
                                }
                                {
                                  field.name === "material-number" && (
                                    <div className="col-1 col-md-2 ml-5 tooltip_container">
                                      <Tooltip content="Enter material number of your item.">
                                        <BsInfoCircle />
                                      </Tooltip>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        ))}



                        <div className="row">
                          <div className="col-md-3">
                            <label>Enter New Label:</label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                name="new-label"
                                className="form-control"
                                placeholder="Enter Label"
                                value={newLabel}
                                onChange={handleLabelInput}
                              />
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="You can upload any relevant field or information about your item.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-5 offset-md-3">
                            <Button
                              className="add-field-button"
                              onClick={addFormField}
                              disabled={newLabel === ""}
                            >
                              Add Label
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

        </>
      )}
    </div>
  );
};

export default CreateItem;