import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import SearchHeader from "../header/SearchHeader";
import axios from "axios";
import Tooltip from "../admin/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import Footer from "../footer/Footer";
import { useNavigate, Navigate } from "react-router-dom";
import ProductRow from "./ProductRow";
import { BsCart4, BsCheckCircle } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../css/style.css";

const NegotiateCart = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [products, setProducts] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [favourites, setFavourites] = useState([]);
  const [lots, setLots] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElIndex, setAnchorElIndex] = useState(0);
  const [productIdSelected, setProductIdSelected] = useState(null);
  const [seller, setSeller] = useState(null);
  const [typeSelected, setTypeSelected] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedLots, setSelectedLots] = useState([]);
  const options = ["Chat with seller", "Remove from wishlist"];
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {

    const fetchWishlist = async () => {
      setProducts(null)
      try {
        const response = await axios.get("/get_wishlist", {
          params: {
            userId: currentUser.id, // Replace this with the user ID you want to fetch the wishlist for
          },
        });

        if (response.status === 200) {
          console.log("WISHLIST", response.data)
          const computeTooltip = (status, allowed, bidChoice) => {
            

            if (allowed === 0) {
              if ((status === "In progress" || status === "Accepted")) {
                return "Bid already made";
              }
              else{
                return "Another bid has been accepted"
              }
            } else if (allowed === 1) {
              if (bidChoice === "Close Bidding") {
                return "Bid already made";
              }
              if (bidChoice === "Open Bidding") {
                return "Bid already made"; // Assuming you want the same message for open bids
              }
            }
            if (bidChoice === "Fixed Price") {
              return "Item with a fixed price can be added directly to the cart";
            }
            if (status === "No bid submitted" && bidChoice !== "Fixed Price") {
              return "Make the bid";
            }

            

            return "";
          };


          const tempProducts = response.data.items.map((item) => ({
            itemId: item.itemId,
            wishlistitemId: item.wishlistItemId,
            image: item.itemImage,
            name: item.itemName,
            industry: item.itemIndustry, // Replace this with the appropriate data from the response if available
            size: item.itemSize,
            uom: item.itemUoM,
            bidChoice: item.itemBid,
            price: item.initialPrice,
            newPrice: item.wishlistPrice,
            quantity: item.quantity,
            status: item.status,
            sellerId: item.sellerId,
            allowed: item.allowed,
            itemMinQuantity: item.itemMinQuantity,
            itemPublishedQuantity: item.itemPublishedQuantity,
            tooltipMessage: computeTooltip(item.status, item.allowed, item.itemBid)

          }));
          const tempLots = response.data.lots.map((lot) => ({
            lotId: lot.lotId,
            wishlistitemId: lot.wishlistItemId,
            image: lot.lotImage,
            name: lot.lotName,
            bidChoice: lot.bidType,
            price: lot.initialPrice,
            newPrice: lot.wishlistPrice,
            quantity: lot.quantity,
            status: lot.status,
            sellerId: lot.sellerId,
            allowed: lot.allowed,
            tooltipMessage: computeTooltip(lot.status, lot.allowed, lot.itemBid)
          }));

          var sum = 0;
          tempProducts?.forEach(function (product) {
            sum += product.price * product.quantity;
          });
          tempLots.forEach(function (product) {
            sum += product.price * product.quantity;
          });

          setProducts(tempProducts);
          setLots(tempLots);
          setTotalPrice(sum);
        } else if (response.status === 202) {
          setProducts([]);
          console.log("Wishlist is empty");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchWishlist();
  }, []);

  const handleMenuClick = (event, index, pId, sId, type) => {
    setAnchorEl(event.currentTarget);
    setAnchorElIndex(index);
    setProductIdSelected(pId);
    setSeller(sId);
    setTypeSelected(type);
  };

  const handleMenuClose = async (selectedOption, productId, sellerId, type) => {
    setAnchorEl(null);
    console.log(selectedOption);
    console.log("TYPE", type)
    console.log("ID", productId);
    if (selectedOption === "Chat with seller") {
      try {
        console.log("BEFORE REQ");
        const response = await axios.post("/create_chat", {
          senderUserId: currentUser.id,
          receiverUserId: seller,
          itemId: productIdSelected,
          type: typeSelected
        });

        const chatId = response.data.chatId;
        if (chatId) {
          navigate("/admin/chat-support", { state: chatId });
        }
        console.log("Chat ID:", chatId);
      } catch (error) {
        console.error("Error creating chat:", error);
      }
    } else if (selectedOption === "Remove from wishlist") {
      handleDelete(productIdSelected, typeSelected);
    }
  };
  const handleDelete = (productId, type) => {
    console.log("ID", productId);
    if (type === 'product') {
      axios
        .post("/delete_from_wishlist", {
          userId: currentUser.id,
          itemId: productId,
        })
        .then((response) => {
          console.log(response.data);
          axios
            .get(`/get_wishlist?userId=${currentUser.id}`)
            .then((response) => {
              console.log(response.data);
              if (response.status === 200) {
                const tempProducts = response.data.items.map((item) => ({
                  itemId: item.itemId,
                  wishlistitemId: item.wishlistItemId,
                  image: item.itemImage,
                  name: item.itemName,
                  industry: item.itemIndustry,
                  size: item.itemSize,
                  uom: item.itemUoM,
                  bidChoice: item.itemBid,
                  price: item.initialPrice,
                  newPrice: item.wishlistPrice,
                  quantity: item.quantity,
                  status: item.status,
                  sellerId: item.sellerId,
                  allowed: item.allowed,
                  itemMinQuantity: item.itemMinQuantity,
                  itemPublishedQuantity: item.itemPublishedQuantity,
                }));
                const tempLots = response.data.lots.map((lot) => ({
                  lotId: lot.lotId,
                  wishlistitemId: lot.wishlistItemId,
                  image: lot.lotImage,
                  name: lot.lotName,
                  bidChoice: lot.bidType,
                  price: lot.initialPrice,
                  newPrice: lot.wishlistPrice,
                  quantity: lot.quantity,
                  status: lot.status,
                  sellerId: lot.sellerId,
                  allowed: lot.allowed
                }));

                var sum = 0;
                tempProducts?.forEach(function (product) {
                  sum += product.price * product.quantity;
                });
                tempLots.forEach(function (product) {
                  sum += product.price * product.quantity;
                });

                setProducts(tempProducts);
                setLots(tempLots);
                setTotalPrice(sum);
              } else if (response.status === 202) {
                setProducts([]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
    else {
      axios
        .post("/deleteLot_from_wishlist", {
          userId: currentUser.id,
          lotId: productId,
        })
        .then((response) => {
          console.log(response.data);
          axios
            .get(`/get_wishlist?userId=${currentUser.id}`)
            .then((response) => {
              console.log(response.data);
              if (response.status === 200) {
                const tempProducts = response.data.items.map((item) => ({
                  itemId: item.itemId,
                  wishlistitemId: item.wishlistItemId,
                  image: item.itemImage,
                  name: item.itemName,
                  industry: item.itemIndustry,
                  size: item.itemSize,
                  uom: item.itemUoM,
                  bidChoice: item.itemBid,
                  price: item.initialPrice,
                  newPrice: item.wishlistPrice,
                  quantity: item.quantity,
                  status: item.status,
                  sellerId: item.sellerId,
                  allowed: item.allowed,
                  itemMinQuantity: item.itemMinQuantity,
                  itemPublishedQuantity: item.itemPublishedQuantity,
                }));
                const tempLots = response.data.lots.map((lot) => ({
                  lotId: lot.lotId,
                  wishlistitemId: lot.wishlistItemId,
                  image: lot.lotImage,
                  name: lot.lotName,
                  bidChoice: lot.bidType,
                  price: lot.initialPrice,
                  newPrice: lot.wishlistPrice,
                  quantity: lot.quantity,
                  status: lot.status,
                  sellerId: lot.sellerId,
                  allowed: lot.allowed
                }));

                var sum = 0;
                tempProducts?.forEach(function (product) {
                  sum += product.price * product.quantity;
                });
                tempLots.forEach(function (product) {
                  sum += product.price * product.quantity;
                });

                setProducts(tempProducts);
                setLots(tempLots);
                setTotalPrice(sum);
              } else if (response.status === 202) {
                setProducts([]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  };

  const handleChange = (event, index, type) => {
    if (type === 'product') {
      let tempProducts = [...products];
      tempProducts[index].newPrice = event.target.value;
      setProducts(tempProducts);
    }
    else if (type === 'lot') {
      let tempProducts = [...lots];
      tempProducts[index].newPrice = event.target.value;
      setLots(tempProducts);
    }

  };

  const handleEnter = (index, type) => {
    if (type === "product") {
      if (parseInt(products[index].newPrice) < parseInt(products[index].price)) {
        toast.error("Enter more than the minimum amount");
      }
      else if (parseInt(products[index].newPrice) === 0) {
        toast.error("Bid amount should be more than 0");
      }


      else {
        let tempProducts = [...products];
        tempProducts[index].status = "In progress";

        setSelectedProducts(tempProducts);
        const data = {
          userId: currentUser.id,
          itemId: products[index].itemId,
          wishlistItemId: products[index].wishlistitemId,
          price: products[index].newPrice,
        };
        console.log(data);

        axios
          .post("/make_bid", data)
          .then((response) => {
            setShowModal(true);
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }


    }
    if (type === "lot") {
      // let tempProducts = [...products];
      // tempProducts[index].status = "In progress";

      // setSelectedProducts(tempProducts);
      if (parseInt(lots[index].newPrice) < parseInt(lots[index].price)) {
        toast.error("Enter more than the minimum amount");
      }
      else if (parseInt(lots[index].newPrice) === 0) {
        toast.error("Bid amount should be more than 0");
      }



      else {
        let tempProducts = [...lots];
        tempProducts[index].status = "In progress";
        setLots(tempProducts);
        console.log(parseInt(lots[index].newPrice), parseInt(tempProducts[index].price))
        const data = {
          userId: currentUser.id,
          lotId: lots[index].lotId,
          wishlistItemId: lots[index].wishlistitemId,
          price: lots[index].newPrice,

        };
        console.log(data);

        axios
          .post("/make_bidLot", data)
          .then((response) => {
            if (response.status === 202) {
              console.log("HELLO");
              toast.error("Deadline for bidding has passed");
            }
            else {
              setShowModal(true);
            }

            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });


      }

    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleButtonClick = async (selectedButton) => {
    if (selectedButton === "Continue Shopping") navigate("/");
    else if (selectedButton === "Add to Cart") {
      console.log(selectedProducts);
      let successCount = 0;

      const allSelectedItems = [...selectedProducts, ...selectedLots];

      for (const product of allSelectedItems) {
        try {
          console.log("product");
          let requestData = {
            userId: currentUser.id,
            price: parseInt(product.newPrice),
            quantity: product.quantity,
          };
          if ('lotId' in product) {
            requestData.lotId = product.lotId;
            requestData.type = 'lot'
          } else {
            requestData.itemId = product.itemId;
            requestData.type = 'product'
          }

          const response = await axios.post(
            "/add_to_cart",
            requestData
          );

          if (response.status === 203) {
            toast.error(
              `${product.name}'s quantity exceeds the available quantity`
            );
          } else if (response.status === 200) {
            successCount++;
          }
          else if (response.status === 210)
          {
            toast.error(
              response.data
            );
          }
        } catch (error) {
          console.error(
            `Error adding product ${product.itemId || product.lotId} to cart:`,
            error
          );
        }
      }

      console.log(successCount);
      console.log(allSelectedItems.length);
      if (successCount === allSelectedItems.length) {
        navigate("/cart");
      }
    }
  };



  const addToWishList = (productName) => {
    let tempFavourites = [...favourites];
    const index = tempFavourites.indexOf(productName);

    if (index === -1) {
      tempFavourites.push(productName);
      toast.success(productName + " has been added to your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    } else {
      tempFavourites.splice(index, 1);
      toast.success(productName + " has been removed from your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    }

    setFavourites(tempFavourites);
  };
  const incrementQuanity = (itemId) => {
    // Find the index of the product with the matching itemId in the products array
    const index = products?.findIndex((product) => product.itemId === itemId);

    // If a product with the matching itemId is found, update its quantity by 1
    if (index !== -1) {
      if (products[index].quantity + 1 > products[index].itemPublishedQuantity){
        console.log("INSIDEEE", products[index].quantity, products[index].itemPublishedQuantity)
        toast.error("Quantity can not be less than minimum buying quantity")
        return;
      }
      axios
        .post("/update_wishlist_item_quantity", {
          itemId: itemId,
          userId: currentUser.id,
          quantity: products[index].quantity + 1,
        })
        .then((response) => {
          if (response.status === 200) {
            const updatedProduct = {
              ...products[index],
              quantity: products[index].quantity + 1,
            };
            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
          } else if (response.status === 202) {
            toast.error("Quantity exceeds the available quantity");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Define the decrementQuanity function to update the quantity of the product with the given itemId
  const decrementQuanity = (itemId) => {
    // Find the index of the product with the matching itemId in the products array
    const index = products?.findIndex((product) => product.itemId === itemId);

    // If a product with the matching itemId is found and its quantity is greater than 0, update its quantity by -1
    if (index !== -1 && products[index].quantity > 0) {
      console.log("PRODUCT", products[index])
      console.log("INSIDEEE", products[index].quantity, products[index].itemMinQuantity)
      if (products[index].quantity -1 < products[index].itemMinQuantity){
        console.log("INSIDEEE", products[index].quantity, products[index].itemMinQuantity)
        toast.error("Quantity can not be less than minimum buying quantity")
        return;
      }

      axios
        .post("/update_wishlist_item_quantity", {
          itemId: itemId,
          userId: currentUser.id,
          quantity: products[index].quantity - 1,
        })
        .then((response) => {
          if (response.status === 200) {
            const updatedProduct = {
              ...products[index],
              quantity: products[index].quantity - 1,
            };
            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
          }

          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    
  };

  const handleClick = (selected) => {
    navigate("/product-detail", { state: selected });
  };

  const handleCheckboxChange = (event, index, type) => {
    if (type === 'items') {
      let tempSelectedProducts = [...selectedProducts];

      if (event.target.checked) {
        tempSelectedProducts?.push(products[index]);
        setSelectedProducts(tempSelectedProducts);
      } else {
        tempSelectedProducts?.splice(index, 1);
        setSelectedProducts(tempSelectedProducts);
      }
    }
    else {
      let tempSelectedProducts = [...selectedLots];

      if (event.target.checked) {
        tempSelectedProducts?.push(lots[index]);
        setSelectedLots(tempSelectedProducts);
      } else {
        tempSelectedProducts?.splice(index, 1);
        setSelectedLots(tempSelectedProducts);
      }
    }

  };

  return (
    <>
      {!currentUser ? (
        <Navigate
          to="/admin/login"
          state={{ previousPath: window.location.pathname }}
          replace={true}
        />
      ) : (
        <>
          {/* <SearchHeader wishListCount={favourites.length} /> */}
          <Header logOut={props.logOut} />

          <ToastContainer
          // position="bottom-right"
          // autoClose={5000}
          // hideProgressBar={false}
          // newestOnTop={false}
          // closeOnClick
          // rtl={false}
          // pauseOnFocusLoss
          // pauseOnHover
          />

          <div className="container main-container app_container">
            <h4 className="pageName_heading">Add Your Bids</h4>
            <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
              <ul className="page-hierarchy">
                <li>
                  <a href="/cart">Your Cart</a>
                </li>
                <li>
                  <a href="/negotiate-cart">Negotiate Payment</a>
                </li>
              </ul>
            </div>
            <br />
            <br />
            <hr style={{ width: "100%" }} />
            <br />

            {(!products || products?.length === 0) && (!lots || lots.length === 0) ? (
              <>
                {!products ? (
                  <>
                    <div
                      className="d-flex justify-content-center"
                    >
                      <br />
                      <div className="spinner-border text-info" role="status"></div>
                    </div>

                  </>
                ) : (<>
                  <BsCart4
                    style={{ margin: "auto auto 10px auto", fontSize: "50px" }}
                  />
                  <h6 style={{ textAlign: "center", fontWeight: "400" }}>
                    Your wishlist is currently empty.
                  </h6>
                  <div
                    className="d-md-flex justify-content-end"
                    style={{ marginTop: "1em", columnGap: "15px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-primary w-[200px]"
                      onClick={() => handleButtonClick("Continue Shopping")}
                    >
                      CONTINUE SHOPPING
                    </button>
                  </div>
                </>)}

              </>
            ) : (
              <>
                <div className="overflow-x-auto w-full">
                  <table
                    className="table w-full border-collapse border border-slate-200 cart-table"
                    style={{ textAlign: "center" }}
                  >
                    <thead style={{ backgroundColor: "#F1F1F1" }}>
                      <tr>
                        <th className="border border-slate-200"></th>
                        <th className="border border-slate-200">
                          Product Name
                        </th>
                        <th className="border border-slate-200">Bid Choice</th>
                        <th className="border border-slate-200">List Price</th>
                        <th className="border border-slate-200">My Price</th>
                        <th className="border border-slate-200">Quantity</th>
                        <th className="border border-slate-200">Total</th>
                        <th className="border border-slate-200">Status</th>
                        <th className="border border-slate-200"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {products &&
                        products?.length > 0 &&
                        products?.map((product, index) => (
                          <tr key={index}>
                            <td className="border border-slate-200">
                              <label>
                                <input
                                  type="checkbox"
                                  className="checkbox cart-checkbox"
                                  onChange={(event) =>
                                    handleCheckboxChange(event, index, 'items')
                                  }
                                  disabled={
                                    product.status !== "Accepted" &&
                                    product.bidChoice !== "Fixed Price"
                                  }
                                />
                              </label>
                            </td>
                            <td
                              className="border border-slate-200"
                              style={{ textAlign: "left" }}
                            >
                              <div className="flex items-center space-x-3">
                                <div className="avatar rounded-none">
                                  <div className="mask w-18 h-16">
                                    <img loading="lazy" 
                                      src={`${product.image}`}
                                      alt=""
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                </div>
                                <div className="text-gray-600">
                                  <div className="font-bold text-[13px]">
                                    {product.name}
                                  </div>
                                  {/* <div className="font-bold text-[12px]">
                                    Industry: {product.industry}
                                  </div>
                                  <div className="font-bold text-[13px]">
                                    Size: {product.size}
                                  </div> */}
                                </div>
                              </div>
                            </td>
                            <td className="border border-slate-200">
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {product.bidChoice}
                                <Tooltip content={product.tooltipMessage}>
                                  <BsInfoCircle style={{ marginLeft: '5px' }} />
                                </Tooltip>
                              </div>
                            </td>

                            <td className="border border-slate-200">
                              {product.price} {currentUser.currency}
                            </td>

                            <td className="border border-slate-200">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    position: "relative",
                                  }}
                                >
                                  {currentUser.currency}
                                </span>
                                <div>
                                  <input
                                    type="number"
                                    className="priceInput"
                                    placeholder="Your Price"
                                    value={product.newPrice ? product.newPrice : ""}
                                    onChange={(event) => handleChange(event, index, 'lot')}
                                    disabled={
                                      product.bidChoice === "Fixed Price"
                                    }
                                    title={product.bidChoice === "Fixed Price" ? "Input is disabled because the bid choice is Fixed Price." : ""}
                                    min={product.newPrice ? product.newPrice : ""}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-dark"
                                  style={{
                                    height: "35px",
                                    minHeight: "35px",
                                    borderRadius: "0",
                                    fontSize: "14px",
                                    marginLeft: "10px",
                                  }}
                                  disabled={
                                    product.bidChoice === "Fixed Price"
                                  }
                                  onClick={() => handleEnter(index, "product")}
                                  title={product.bidChoice === "Fixed Price" ? "Button is disabled because the bid choice is Fixed Price." : ""}
                                >
                                  Enter
                                </button>

                              </div>
                            </td>
                            <td className="border border-slate-200">
                              <span
                                className="bg-slate-700 text-white"
                                style={{
                                  padding: "3.5px 8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => decrementQuanity(product.itemId)}
                                // disabled = {product.quantity - 1 < product.itemMinQuantity}
                              >
                                <span style={{ marginTop: "-3px" }}>-</span>
                              </span>
                              <input
                                type="number"
                                value={product.quantity}
                                readOnly
                                disabled
                                className="w-[90px] pl-10 h-7 border border-slate-300"
                                style={{ backgroundColor: "white" }}
                              />
                              <span
                                className="bg-slate-700 text-white"
                                style={{
                                  padding: "3.5px 8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => incrementQuanity(product.itemId)}
                                // disabled = {product.quantity + 1 > product.itemPublishedQuantity}
                              >
                                <span style={{ marginTop: "-3px" }}>+</span>
                              </span>
                            </td>
                            <td className="border border-slate-200">
                              {(product.price * product.quantity).toFixed(2)} {currentUser.currency}
                            </td>
                            <td className="border border-slate-200">
                              {product.status}
                            </td>
                            <td className="border border-slate-200">
                              {product.status !== "Accepted" ? (
                                <>
                                  <IconButton
                                    aria-label="more"
                                    id="more-button"
                                    aria-controls={open ? "menu" : undefined}
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) =>
                                      handleMenuClick(event, index, product.itemId, product.sellerId, 'product')
                                    }
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="menu"
                                    MenuListProps={{
                                      "aria-labelledby": "more-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem
                                      key="Chat with seller"
                                      onClick={() =>
                                        handleMenuClose(
                                          "Chat with seller",
                                          product.itemId,
                                          product.sellerId
                                        )
                                      }
                                    >
                                      Chat with seller
                                    </MenuItem>
                                    <MenuItem
                                      key="Remove from wishlist"
                                      onClick={() =>
                                        handleMenuClose(
                                          "Remove from wishlist",
                                          product.itemId,
                                          product.sellerId,
                                          'product'
                                        )
                                      }
                                    >
                                      Remove from wishlist
                                    </MenuItem>
                                  </Menu>
                                </>
                              ) : (
                                <svg
                                  style={{ margin: "auto", cursor: "pointer" }}
                                  onClick={() => handleDelete(product.itemId, 'product')}
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="IconChangeColor"
                                >
                                  <path
                                    d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074z"
                                    fill="blue"
                                    id="mainIconPathAttribute"
                                  ></path>
                                </svg>
                              )}
                            </td>
                          </tr>
                        ))}
                      {lots &&
                        lots.length > 0 &&
                        lots.map((product, index) => (
                          <tr key={index}>
                            <td className="border border-slate-200">
                              <label>
                                <input
                                  type="checkbox"
                                  className="checkbox cart-checkbox"
                                  onChange={(event) =>
                                    handleCheckboxChange(event, index, 'lots')
                                  }
                                  disabled={
                                    product.status !== "Accepted" &&
                                    product.bidChoice !== "Fixed Price"
                                  }
                                />
                              </label>
                            </td>
                            <td
                              className="border border-slate-200"
                              style={{ textAlign: "left" }}
                            >
                              <div className="flex items-center space-x-3">
                                <div className="avatar rounded-none">
                                  <div className="mask w-18 h-16">
                                    <img loading="lazy" 
                                      src={`${product.image}`}
                                      alt=""
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                </div>
                                <div className="text-gray-600">
                                  <div className="font-bold text-[13px]">
                                    {product.name}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="border border-slate-200">
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {product.bidChoice}
                                <Tooltip content={product.tooltipMessage}>
                                  <BsInfoCircle style={{ marginLeft: '5px' }} />
                                </Tooltip>
                              </div>
                            </td>

                            <td className="border border-slate-200">
                              -
                            </td>
                            <td className="border border-slate-200">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    position: "relative",
                                  }}
                                >
                                  {currentUser.currency}
                                </span>
                                <div>
                                  <input
                                    type="number"
                                    className="priceInput"
                                    placeholder="Your Price"
                                    value={product.newPrice ? product.newPrice : ""}
                                    onChange={(event) => handleChange(event, index, 'lot')}
                                    disabled={
                                      (product.status === "In progress" ||
                                        product.status === "Accepted") && (product.allowed === 0)
                                    }
                                    min={product.newPrice ? product.newPrice : ""}
                                    title={(product.status === "In progress" ||
                                      product.status === "Accepted") && (product.allowed === 0) ? "Can not bid again" : ""}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-dark"
                                  style={{
                                    height: "35px",
                                    minHeight: "35px",
                                    borderRadius: "0",
                                    fontSize: "14px",
                                    marginLeft: "10px",
                                  }}
                                  disabled={
                                    (!product.newPrice ||
                                      product.status === "In progress" ||
                                      product.bidChoice === "Fixed Price" || product.status === "Rejected" ||
                                      product.status === "Accepted") &&
                                    (product.allowed === 0)
                                  }
                                  onClick={() => handleEnter(index, "lot")}
                                >
                                  Enter
                                </button>
                              </div>

                            </td>
                            <td className="border border-slate-200">
                              {product.quantity}
                            </td>
                            {
                              !product.price && (
                                <td className="border border-slate-200">
                                  Enter Any Bid
                                </td>
                              )
                            }
                            {
                              product.price && (
                                <td className="border border-slate-200">
                                  {product.price} {currentUser.currency}
                                </td>
                              )
                            }


                            <td className="border border-slate-200">
                              {product.status}
                            </td>
                            <td className="border border-slate-200">
                              {product.status !== "Accepted" ? (
                                <>
                                  <IconButton
                                    aria-label="more"
                                    id="more-button"
                                    aria-controls={open ? "menu" : undefined}
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) =>
                                      handleMenuClick(event, index, product.lotId,
                                        product.sellerId,
                                        'lot')
                                    }
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="menu"
                                    MenuListProps={{
                                      "aria-labelledby": "more-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem
                                      key="Chat with seller"
                                      onClick={() =>
                                        handleMenuClose(
                                          "Chat with seller",
                                          product.itemId,
                                          product.sellerId
                                        )
                                      }
                                    >
                                      Chat with seller
                                    </MenuItem>
                                    <MenuItem
                                      key="Remove from wishlist"
                                      onClick={() =>
                                        handleMenuClose(
                                          "Remove from wishlist",
                                          product.lotId,
                                          product.sellerId,
                                          'lot'
                                        )
                                      }
                                    >
                                      Remove from wishlist
                                    </MenuItem>
                                  </Menu>
                                </>
                              ) : (
                                <svg
                                  style={{ margin: "auto", cursor: "pointer" }}
                                  onClick={() => handleDelete(product.lotId, 'lot')}
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="IconChangeColor"
                                >
                                  <path
                                    d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074z"
                                    fill="blue"
                                    id="mainIconPathAttribute"
                                  ></path>
                                </svg>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    {/* <tfoot style={{ backgroundColor: "#F1F1F1" }}>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th className="border">Total</th>
                        <th>${totalPrice}</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </tfoot> */}
                  </table>
                </div>
                <div className="cart_buttons_group">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-[210px]"
                    onClick={() => handleButtonClick("Continue Shopping")}
                  >
                    CONTINUE SHOPPING
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary w-[210px]"
                    onClick={() => handleButtonClick("Add to Cart")}
                  >
                    ADD TO CART
                  </button>
                </div>
              </>
            )}

            <hr className="relatedProductsLine" />
            <div className="d_sm_center">
              <h4 className="related_products_heading text-gray-600 font-bold text-[25px] inline-block float-left mt-[13px]">
                RELATED
              </h4>
            </div>
            {/* <div className = "w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
                            <div className = "flex flex-row space-x-2">
                                <div className = "bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                                    <svg xmlns = "http://www.w3.org/2000/svg" width = "16" height = "16" fill = "currentColor" className = "bi bi-arrow-left" viewBox = "0 0 16 16"> <path fillRule = "evenodd" d = "M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /> </svg>
                                </div>
                                <div className = "bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                                    <svg style = {{ color: 'rgb(78, 77, 70)' }} xmlns = "http://www.w3.org/2000/svg" width = "16" height = "16" fillRule = "currentColor" className = "bi bi-arrow-right" viewBox = "0 0 16 16"> <path fillRule = "evenodd" d = "M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill = "#4e4d46"></path> </svg>
                                </div>
                            </div>
                        </div> */}
            <br className="d_sm_none" />
            <br className="d_sm_none" />
            <br />
            <ProductRow />
            {/* <div className="row related_products">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("Raw Materials")}
                  >
                    <img loading="lazy" 
                      src={require("../../images/image002.jpg")}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("ISO Propyl Alcohol") > -1
                              ? "900"
                              : ""
                              }`,
                          }}
                          onClick={() => addToWishList("ISO Propyl Alcohol")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("Packaging Materials")}
                  >
                    <img loading="lazy" 
                      src={require("../../images/image001.png")}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Wood</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("Wood") > -1
                              ? "900"
                              : ""
                              }`,
                          }}
                          onClick={() => addToWishList("Wood")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("")}
                  >
                    <img loading="lazy" 
                      src="https://placeimg.com/400/225/arch"
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Sample Product 1</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("Sample Product 1") > -1
                              ? "900"
                              : ""
                              }`,
                          }}
                          onClick={() => addToWishList("Sample Product 1")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("")}
                  >
                    <img loading="lazy" 
                      src="https://placeimg.com/400/225/arch"
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Sample Product 2</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("Sample Product 2") > -1
                              ? "900"
                              : ""
                              }`,
                          }}
                          onClick={() => addToWishList("Sample Product 2")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
            </div> */}

            <Modal centered show={showModal} onHide={handleCloseModal}>
              <Modal.Header
                closeButton={handleCloseModal}
                style={{ border: "none" }}
              ></Modal.Header>
              <Modal.Body className="confirmationMessage">
                <BsCheckCircle className="checkIcon" />
                <br />
                <span>
                  Bid submitted for review.
                  <br />
                  {/* <a
                    href="/admin/sales-list"
                    style={{ textDecoration: "none" }}
                  >
                    View Status
                  </a> */}
                </span>
                {/* <h3>Item Number: 12131123</h3> */}
                <br />
              </Modal.Body>
            </Modal>

            <Footer />
          </div>
        </>
      )
      }
    </>
  );
};

export default NegotiateCart;
