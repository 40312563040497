import React from "react";
import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Card, Row, Col, Button, Form, Tabs, Tab } from "react-bootstrap";
import "react-bootstrap";
import { useNavigate } from "react-router-dom";
import signUpImage from "../../images/2.png";
import { toast } from "react-toastify";
import "../../css/signup.css";
import "../../css/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";


const EditCompany = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();
    const location = useLocation();
    const companyId = location.state.ID;
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [url, setUrl] = useState("");
    const [error, setError] = useState();
    const [key, setKey] = useState('buyer');
    const [tableData, setTableData] = useState([{ min: '', max: '', userType: '', commission: '' }]);
    const [buyerData, setBuyerData] = useState([{ min: '', max: '', commission: '' }]);
    const [sellerData, setSellerData] = useState([{ min: '', max: '', commission: '' }]);
    

    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );


    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    

    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };
    useEffect(() => {
        axios.get(`/get_company/${companyId}`) // Replace companyId with the actual id
            .then((response) => {
                const data = response.data;
                console.log(response.data);
                setName(data.companyData.name);
                setPhone(data.companyData.phone);
                setUrl(data.companyData.url);
                setAddress(data.companyData.address);
                setBuyerData(data.commissionData.buyer);
                setSellerData(data.commissionData.seller);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
                toast.error("Error fetching data.");
            });
    }, []);
    

    function handleChange(e, field, index) {
        if (key === 'buyer') {
            const newBuyerData = [...buyerData];
            newBuyerData[index][field] = e.target.value;
            setBuyerData(newBuyerData);
        } else {
            const newSellerData = [...sellerData];
            newSellerData[index][field] = e.target.value;
            setSellerData(newSellerData);
        }
    }

    function addRow() {
        if (key === 'buyer') {
            setBuyerData([...buyerData, { min: '', max: '', commission: '' }]);
        } else {
            setSellerData([...sellerData, { min: '', max: '', commission: '' }]);
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
    
        let objToSend = {
            name: name,
            country: country,
            phone: phone,
            address: address,
            url: url,
            buyerData: buyerData,
            sellerData: sellerData,
        };
    
        console.log(objToSend);
        objToSend = JSON.stringify(objToSend);
        
        axios
            .put(`/edit_company/${companyId}`, objToSend, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                console.log(response.status);
                if (response.status === 200) {
                    toast.success(response?.data?.message);
                    navigate("/admin/admin-section/company-details");
                } else {
                    toast.error(response?.data?.error);
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error("An error occurred. Please try again.");
            });
    };
    
    return (
        <div
        >
            {/* {currentUser ? (
        <Navigate to="/admin/dashboard" replace={true} />
      ) : ( */}
            <div className="admin_panel">
                <div className="d-lg-flex">
                    <div
                        className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                            }`}
                    >
                        <Sidebar
                            collapsed={collapseSidebar}
                            collapsedDropdown={collapseDropdown}
                        />
                    </div>
                    <div
                        className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                            }`}
                    >
                        {!matches && (
                            <Header
                                getCollapseSidebar={getCollapseSidebar}
                                getCollapseDropdown={getCollapseDropdown}
                                logOut={props.logOut}
                            />
                        )}
                        <form onSubmit={handleSubmit} className="content_panel">
                            <Row className="customCard">
                                <Col xs={12} md={5}>
                                    <div>
                                        <h5 className="form_tagline mb-[0.8em] create_account">
                                            Edit Company
                                        </h5>
                                        <div className="form-group">
                                            <label htmlFor="name" className="signUpLabels">
                                                Name <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                placeholder="Enter Name"
                                                value = {name}
                                                style={{ height: '30px' }}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>                                       
                                         
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="phone" className="signUpLabels">
                                                Phone <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <PhoneInput
                                                defaultCountry={country}
                                                placeholder="Enter phone number (without country code)"
                                                value={phone}
                                                onChange={setPhone}
                                            />
                                            {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                    <label htmlFor="url" className="signUpLabels">
                        URL  <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                        type="text"
                        name="url"
                        className="form-control"
                        id="url"
                        placeholder="Enter URL"
                        value= {url}
                        style={{ height: '30px' }}
                        onChange={(e) => setUrl(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group" style={{ marginTop: "0.5em" }}>
                    <label htmlFor="address" className="signUpLabels">
                        Address  <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                        type="text"
                        name="address"
                        className="form-control"
                        id="address"
                        placeholder="Enter Address"
                        value = {address}
                        style={{ height: '30px' }}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </div>
                                        <br />

                                    </div>
                                </Col>
                                <Col className="d-flex flex-column justify-content-between">
                                    <div>
                            
                            <h5 className="form_tagline mb-[0.8em] create_account">
                                            Add Commission Slabs
                            </h5>
                            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="buyer" title="Buyer">
                    {buyerData
                        .map((data, index) => (
                            <div key={index} className="d-flex align-items-end" style={{ gap: '10px' }}>
                                                            {/* Your existing form fields... */}
                                                            <div className="d-flex justify-content-between align-items-end" style={{ gap: '10px' }}>
                                                                <div>
                                                                    <Form.Label>Min Value</Form.Label>
                                                                    <Form.Control value={data.min} onChange={e => handleChange(e, 'min', index)} />
                                                                </div>
                                                                <div>
                                                                    <Form.Label>Max Value</Form.Label>
                                                                    <Form.Control value={data.max} onChange={e => handleChange(e, 'max', index)} />
                                                                </div>
                                                                <div>
                                                                    <Form.Label>Commission %</Form.Label>
                                                                    <Form.Control value={data.commission} onChange={e => handleChange(e, 'commission', index)} />
                                                                </div>
                                                            </div>
                                                            {index === buyerData.length - 1 &&
                                                                <Button onClick={addRow} style={{ minHeight: '30px', height: '30px' }} className="py-0 btn-outline-primary">+</Button>
                                                            }
                                                        </div>
                        ))}
                </Tab>
                <Tab eventKey="seller" title="Seller">
                    {sellerData
                        .map((data, index) => (
                            <div key={index} className="d-flex align-items-end" style={{ gap: '10px' }}>
                                                            {/* Your existing form fields... */}
                                                            <div className="d-flex justify-content-between align-items-end" style={{ gap: '10px' }}>
                                                                <div>
                                                                    <Form.Label>Min Value</Form.Label>
                                                                    <Form.Control value={data.min} onChange={e => handleChange(e, 'min', index)} />
                                                                </div>
                                                                <div>
                                                                    <Form.Label>Max Value</Form.Label>
                                                                    <Form.Control value={data.max} onChange={e => handleChange(e, 'max', index)} />
                                                                </div>
                                                                <div>
                                                                    <Form.Label>Commission %</Form.Label>
                                                                    <Form.Control value={data.commission} onChange={e => handleChange(e, 'commission', index)} />
                                                                </div>
                                                            </div>
                                                            {index === sellerData.length - 1 &&
                                                                <Button onClick={addRow} style={{ minHeight: '30px', height: '30px' }} className="py-0 btn-outline-primary">+</Button>
                                                            }
                                                        </div>
                        ))}
                </Tab>
            </Tabs>
            </div>
                                    <button className="btn btn-primary py-0" style={{ marginTop: '15px', marginLeft: 'auto', minHeight: '30px', height: '30px' }}>Submit</button>
                                </Col>
                            </Row>
                        </form>
                        
                    </div>
                    
                    
        
                </div>
            </div>
            {/* )} */}
        </div>
    );
};

export default EditCompany;
