import React, { useState } from 'react'
import '../../css/sidePanel.css'
import Logo from '../../images/Logo.png';

const SidePanel = ({ overlayHandler }) => {
    const [open, setOpen] = useState(false);

    const toggleSidebar = () => {
        setOpen(!open)
        overlayHandler();
    }
    return (
        <div style={{ width: '25.33%' }}>
            <div id="mySidebar" className={`sidebar ${open && 'openSideBar'}`}>
                <a href="javascript:void(0)" className="closebtn" onClick={toggleSidebar}>×</a>
                <a href="/" style={{ display: 'flex', alignItems: 'center' }}>
                    <img loading="lazy"  src={Logo} alt="Inv-X" className="sidebar_logo" />
                </a>
                <a href="/faqs">FAQs</a>
                <a href="/request-demo">Request a Demo</a>
                <a href="/pricing">Pricing</a>
                {/* <a href="#/" onClick={(event) => { event.preventDefault() }}>Knowledge Hub</a> */}
                <ul className="menu menu-horizontal p-0 space-x-4 m-0">
                    <li tabIndex="0" className="categories_menu_item">
                        <a href="#/" onClick={(event) => { event.preventDefault() }}>
                            Categories
                            <svg className="fill-current text-black rounded-lg bg-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                        </a>
                        <ul className="p-2 bg-slate-50 text-black page-list more-links" style={{ zIndex: '2' }}>
                            <li><a href="/search-product/Raw%20Materials">Raw Materials</a></li>
                            <li><a href="/search-product/Packaging%20Materials">Packaging Materials</a></li>
                            <li><a href="/search-product/Semi%20Finished%20Products">Semi Finished Products</a></li>
                            <li><a href="/search-product/Finished%20Products">Finished Products</a></li>
                            <li><a href="/search-product/Machines%20&amp;%20Tools">Machines &amp; Tools</a></li>
                            <li><a href="/search-product/Machine%20Spare%20Parts">Machine Spare Parts</a></li>
                            <li><a href="/search-product/Scrap%20Material">Scrap Material</a></li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className='main'>
                <button className="openbtn" onClick={toggleSidebar}>☰</button>
            </div>
        </div>
    )
}

export default SidePanel