import { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import Header from '../header/Header';
import axios from "axios";
import { Row, Col } from 'react-bootstrap';
import '../../css/style.css';
import '../../css/FAQ.css';
import CountDownTimer from '../helperComponents/CountDownTimer';

const SubscriptionProfile = (props) => {
  const [pubKey, setPubKey] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    // const [plantoSend, setPlantoSend] = useState("");
    const location = useLocation();
    // const [showModal, setShowModal] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const [showModalPlan, setShowModalPlan] = useState(false);
    const [showModalPlanDown, setShowModalPlanDown] = useState(false);
    const [checkoutResponse, setCheckoutResponse] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const navigate = useNavigate();
    
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);
    const hasApiBeenCalledRef = useRef(false);
    // const subscriptionDetails = {
    //     plan: 'Standard',
    //     startDate: new Date(),
    //     expirationDate: new Date('2023-12-31T23:59:59'),
    // };
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [error, setError] = useState('');

    const session_id = queryParams.get("session_id");
    const plan_made = queryParams.get("plan");

    useEffect(() => {
      console.log("useEffect triggered", { session_id, plan_made });
      async function fetchCheckoutSessionDetails() {
        if (hasApiBeenCalledRef.current) {
          return;
        }
        console.log("INSIDE FUNCTION", { session_id, plan_made });
        try {
          const res = await fetch("/retrieve-checkout-session-details", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ session_id: session_id }),
          });
          const data = await res.json();
          
          setCheckoutResponse(data?.response);
          
          if (data?.response?.payment_status === "paid" && !isUpdatingSubscription) {
            setIsUpdatingSubscription(true);
            hasApiBeenCalledRef.current = true;
            setShowModal(true);
            try {
              await axios.post('/update_subscription', {
                userId: currentUser.id,
                plan: plan_made
              });
              
            } catch (error) {
              console.error('Error updating subscription:', error);
            }
            finally{
              setIsUpdatingSubscription(false);
              fetchSubscriptionDetails2();

            }
    
            // setShowModal(true);
          } 
          
        } catch (error) {
          console.error("Error fetching API data:", error);
        }
      }
    
      if (session_id !== null && session_id !== "" && plan_made !== null && plan_made !== "") {
        fetchCheckoutSessionDetails();
      }
    }, []);

    const handleClosePlan = () => setShowModalPlan(false);
    const handleShowPlan = () => setShowModalPlan(true);
    const handleClosePlanDown = () => setShowModalPlanDown(false);
    const handleShowPlanDown = () => setShowModalPlanDown(true);
    useEffect(() => {
      const fetchSubscriptionDetails = async () => {
        try {
          const userId = currentUser.id
          const response = await axios.get(`/get_subscription_details`, { params: { userId } });
          setSubscriptionDetails(response.data);
          // if (response.data.plan === 'Free'){
          //   setPlantoSend('Standard')
          // }
          // if (response.data.plan === 'Standard'){
          //   setPlantoSend('Advanced')
          // }
          // if (response.data.plan === 'Advanced'){
          //   setPlantoSend('Enterprise')
          // }
        } catch (err) {
          setError(err.response?.data?.error || 'An error occurred');
        }
      };
  
      if (currentUser) {
        fetchSubscriptionDetails();
      }
    }, []); 

    const fetchSubscriptionDetails2 = async () => {
        try {
          const userId = currentUser.id
          const response = await axios.get(`/get_subscription_details`, { params: { userId } });
          setSubscriptionDetails(response.data);
          // if (response.data.plan === 'Free'){
          //   setPlantoSend('Standard')
          // }
          // if (response.data.plan === 'Standard'){
          //   setPlantoSend('Advanced')
          // }
          // if (response.data.plan === 'Advanced'){
          //   setPlantoSend('Enterprise')
          // }
        } catch (err) {
          setError(err.response?.data?.error || 'An error occurred');
        }
      };
    const cancelSubscription = async () => {
      try {
        const response = await axios.post('/cancel_subscription', {
          email: currentUser.email
        });
        console.log(response.data);
        setShowModal2(true);
        // Handle success (e.g., show a confirmation message)
      } catch (error) {
        console.error('Error during subscription cancellation:', error);
        // Handle error (e.g., show an error message)
      }
    };
    
    
    
    useEffect(() => {
        fetch("/config")
          .then((result) => result.json())
          .then((data) => {
            setPubKey(data?.publicKey);
          });
      }, []);

      async function redirectToSubscriptionPayment(plantoSend) {
        try {
          setShowModalPlan(false);
          setShowModalPlanDown(false);
      
          loadStripe(pubKey).then((stripe) => {
            // Fetch the Stripe Checkout session ID from your backend
            fetch("/upgrade-subscription", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: currentUser.email, 
                plan: plantoSend
              }),
            })
            .then((response) => response.json())
            .then((data) => {
              // Redirect to the Stripe Checkout page with the session ID
              stripe.redirectToCheckout({
                sessionId: data.sessionId,
              }).then((result) => {
                if (result.error) {
                  // Log the error to the console and display a message to the user
                  console.error("Error redirecting to Stripe Checkout:", result.error);
                  toast.error("Error redirecting to Stripe Checkout.");
                }
              });
            })
            .catch((error) => {
              console.error("Error fetching Checkout session ID:", error);
              toast.error("An error occurred while fetching Checkout session ID.");
            });
          }).catch((error) => {
            console.error("Error loading Stripe:", error);
            toast.error("An error occurred while loading Stripe.");
          });
        } catch (error) {
          // Handle any errors that occur during the API request
          if (error.response) {
            // The server responded with a status code that falls out of the range of 2xx
            toast.error(error.response.data.message);
          } else {
            // The request was made but no response was received or an error occurred in setting up the request
            toast.error("An error occurred: " + error.message);
          }
        }
      }
      const handleCloseModal = () => {
        setShowModal(false);
        // navigate("/");
      };
      const handleCloseModal2 = () => {
        setShowModal2(false);
        // navigate("/");
      };
      const planHierarchy = ['Free', 'Standard', 'Advanced', 'Enterprise'];

function renderUpgradeButton(plan, currentPlan) {
    const currentIndex = planHierarchy.indexOf(currentPlan);
    const buttonIndex = planHierarchy.indexOf(plan);

    const isUpgrade = buttonIndex > currentIndex;

    return (
        <Button 
            variant="secondary" 
            className="m-2" 
            onClick={() => redirectToSubscriptionPayment(plan)}
            disabled={!isUpgrade}
        >
            {plan}
        </Button>
    );
}

function renderDowngradeButton(plan, currentPlan) {
    const currentIndex = planHierarchy.indexOf(currentPlan);
    const buttonIndex = planHierarchy.indexOf(plan);

    const isDowngrade = buttonIndex < currentIndex;

    return (
        <Button 
            variant="secondary" 
            className="m-2" 
            onClick={() => redirectToSubscriptionPayment(plan)}
            disabled={!isDowngrade}
        >
            {plan}
        </Button>
    );
}


  

    return (
        <div className='faq_page' style={{ height: '100vh' }}>
            <Header logOut={props.logOut} />
            <br />
            <div className="container mt-5">
      <div className="row">
        <div className="col-lg-6">
          <div className="card subscription_cards">
            <div className="card-body">
              <h2 className="card-title">Your Subscription Details</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Plan:</td>
                    <td>{subscriptionDetails?.plan}</td>
                  </tr>
                  <tr>
                    <td>Start Date:</td>
                    <td>{new Date(subscriptionDetails?.startDate).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <td>Renewal Date:</td>
                    <td>{new Date(subscriptionDetails?.expirationDate).toLocaleDateString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card subscription_cards">
            <div className="card-body">
              <h2 className="card-title">Time Remaining</h2>
              {subscriptionDetails &&
              <CountDownTimer subscriptionDetails={subscriptionDetails} />
}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
    
    {subscriptionDetails?.plan !== 'Enterprise' && (
        <div className="col-md-2">
            <button className="btn btn-primary custom_btn_subscribe btn-block" onClick={() => setShowModalPlan(true)}>Upgrade Subscription</button>
        </div>
    )}

   
    {subscriptionDetails?.plan !== 'Free' && subscriptionDetails?.plan !== 'Standard' && (
        <div className="col-md-2">
            <button className="btn btn-secondary custom_btn_subscribe btn-block" onClick={() => setShowModalPlanDown(true)}>Downgrade Subscription</button>
        </div>
    )}

    <div className="col-md-2">
        <button className="btn btn-danger custom_btn_subscribe btn-block" disabled={subscriptionDetails?.plan === 'Free'} onClick={() => cancelSubscription()}>Cancel Subscription</button>
    </div>
</div>


<Modal show={showModalPlan} onHide={handleClosePlan}>
    <Modal.Header closeButton>
        <Modal.Title>Choose Your Plan</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="text-center">
            {renderUpgradeButton('Standard', subscriptionDetails?.plan)}
            {renderUpgradeButton('Advanced', subscriptionDetails?.plan)}
            {renderUpgradeButton('Enterprise', subscriptionDetails?.plan)}
        </div>
    </Modal.Body>
</Modal>

<Modal show={showModalPlanDown} onHide={handleClosePlanDown}>
    <Modal.Header closeButton>
        <Modal.Title>Choose Your Plan</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="text-center">
            {renderDowngradeButton('Standard', subscriptionDetails?.plan)}
            {renderDowngradeButton('Advanced', subscriptionDetails?.plan)}
            {renderDowngradeButton('Enterprise', subscriptionDetails?.plan)}
        </div>
    </Modal.Body>
</Modal>

    </div>
    {(session_id !== null) | (session_id !== "") && (
              <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header
                  closeButton={handleCloseModal}
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="confirmationMessage">
                  <BsCheckCircle className="checkIcon" />
                  <br />
                  <span>
                    Your subscription has been upgraded to {plan_made}.
                    <br />
                    <small style={{ color: "#F75F5C", fontWeight: "600" }}>
                      You can use the features of your new plan now.
                    </small>
                  </span>
                  
                  <br />
                </Modal.Body>
              </Modal>
            )}
            {(session_id !== null) | (session_id !== "") && (
              <Modal centered show={showModal2} onHide={handleCloseModal2}>
                <Modal.Header
                  closeButton={handleCloseModal2}
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="confirmationMessage">
  <BsXCircle className="checkIcon" style={{ color: 'red' }} />
  <br />
  <span>
    Your subscription has been cancelled.
    <br />
  </span>
  <h3>Your subscription will revert back to free at the end of your billing cycle</h3>
  <br />
</Modal.Body>

              </Modal>
            )}
        </div>
        
    );
}

export default SubscriptionProfile;
