// index.js
import { combineReducers } from 'redux';
import userRole from './userRole';
import { notificationsReducer } from './notificationsReducer';
import { userSessionReducer } from './userSession';

export default combineReducers({
  userRole,
  userSession: userSessionReducer,
  notifications: notificationsReducer
});
