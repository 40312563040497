import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SearchHeader = (props) => {
  const [cartItemCount, setCartItemCount] = useState(0);
  const [cartPriceTotal, setCartPriceTotal] = useState(0);
  const [wishListItemCount, setWishListItemCount] = useState(0);
  const [wishListTotal, setWishListTotal] = useState(0);
  const [wishlistLotCount, setWishLisLottCount] = useState(0);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        if (currentUser) {
          let tempProducts = [];
          let products = [];
          let lots = [];

          axios
            .post("/get_cart", { userId: currentUser.id })
            .then((response) => {
              console.log(response.data);
              if (response.status === 200) {
                products = response.data.cart_items;
                lots = response.data.lot_items;
                console.log(Date.parse(response.data.cart_items[0].itemUpdated));
                var sum = 0;

                products.forEach(function (product) {
                  sum += product.price * product.quantity;
                });
                lots.forEach(function (product) {
                  sum += product.price * product.quantity;
                });


                setCartPriceTotal(sum);
                setCartItemCount(products.length + lots.length);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          const response = await axios.get("/get_wishlist", {
            params: {
              userId: currentUser.id, // Replace this with the user ID you want to fetch the wishlist for
            },
          });
          if (response.status === 200) {
            let tempProducts = response.data.items.map((item) => ({
              itemId: item.itemId,
              wishlistitemId: item.wishlistItemId,
              image: item.itemImage,
              name: item.itemName,
              industry: item.itemIndustry, // Replace this with the appropriate data from the response if available
              size: item.itemSize,
              uom: item.itemUoM, // Replace this with the appropriate data from the response if available
              bidChoice: item.itemBid,
              price: item.initialPrice,
              newPrice: item.wishlistPrice,
              quantity: item.quantity,
              status: item.status,
              sellerId: item.sellerId,
            }));
            const tempLots = response.data.lots.map((lot) => ({
              lotId: lot.lotId,
              wishlistitemId: lot.wishlistItemId,
              image: lot.lotImage,
              name: lot.lotName,
              bidChoice: lot.bidType,
              price: lot.initialPrice,
              newPrice: lot.wishlistPrice,
              quantity: lot.quantity,
              status: lot.status,
              sellerId: lot.sellerId,
            }));

            var sum = 0;
            tempProducts.forEach(function (product) {
              sum += product.price * product.quantity;
            });
            tempLots.forEach(function (product) {
              sum += product.price * product.quantity;
            });
            setWishListTotal(tempProducts.length + tempLots.length);
            setWishListItemCount(tempProducts.length);
            setWishLisLottCount(tempLots.length)
          }
        }
      }
    }
    fetchData();

  }, [props]);

  const viewCart = () => {
    navigate("/cart");
  };

  const viewWishlist = () => {
    navigate("/negotiate-cart");
  };

  return (
    <div className="container-fluid search_header">
      <Row className="navbar justify-content-center">
        <Col className="col-5 col-md-3 d-flex justify-content-center border-yellow-300 divide-x" style={{gap:'0 10px'}}>
            <a
              href="#/"
              onClick={(event) => {
                event.preventDefault();
              }}
              className="d-flex align-items-center btn btn-ghost text-[12px]"
              style={{
                color: "rgb(16, 109, 246)",
                fontSize: "12px",
                fontWeight: "500",
                paddingL:'0'
              }}
            >
              Language
            </a>
            <a
              href="#/"
              onClick={(event) => {
                event.preventDefault();
              }}
              className="d-flex align-items-center btn btn-ghost text-[12px]"
              style={{
                color: "rgb(16, 109, 246)",
                fontSize: "12px",
                fontWeight: "500",
                paddingL:'0'
              }}
            >
              USD 
            </a>
        </Col>
        <Col className="col-5  d-none d-md-block h-100 header-padding">
          <div style={{ width: "260px", height: "30px", position: "relative" }}>
            <input
              type="text"
              placeholder="Search"
              className="input text-black"
              style={{ width: "100%", height: "100%", paddingLeft: "35px" }}
            />
            <AiOutlineSearch className="search" />
          </div>
        </Col>
        <Col className="col-4 col-md-2">
          {/* <div>
          <button className = "btn btn-ghost w-[90px]" style = {{ paddingLeft: '30px', border: 'none' }}>
            <svg xmlns = "http://www.w3.org/2000/svg" className = "h-5 w-5" fill = "none" viewBox = "0 0 24 24" stroke = "currentColor"><path strokeLinecap = "round" strokeLinejoin = "round" strokeWidth = "2" d = "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" style = {{ color: 'gray' }} /></svg>
          </button>
        </div> */}
          <div className="dropdown dropdown-end">
            <label tabIndex="0" style={{ cursor: "pointer" }}>
              <div
                className="indicator rating hover:bg-slate-100 hover:rounded-lg"
                style={{
                  paddingLeft: "15px",
                  paddingRight: "25px",
                  paddingTop: "5px",
                }}
              >
                <div className="position-relative d-flex">
                  <input
                    type="radio"
                    name="rating-3"
                    className="h-[50px] whishlist_icon bg-slate-200"
                  />
                  <div className="notification_count">{wishListTotal}</div>
                  <a
                    href="#/"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    className="text-[14px] mt-[15px] ml-2"
                    style={{
                      color: "gray",
                      fontSize: "12px",
                      fontWeight: "500",
                      textDecoration: "none",
                    }}
                  >
                    WISHLIST
                  </a>
                </div>
              </div>
            </label>
            <div
              tabIndex="0"
              className="wishList_card mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow"
              style={{ marginTop: "-2px" }}
            >
              <div className="card-body">
                <span className="font-bold text-lg">
                  {wishListItemCount} Items

                </span>
                <span className="font-bold text-lg">
                  {wishlistLotCount} Lots
                </span>
                {/* <span className="text-info">Total: ${wishListTotal}</span> */}
                <div className="card-actions">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={viewWishlist}
                  >
                    View Wishlist
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col className="col-3 col-md-2">
          <div className="dropdown dropdown-end">
            <label tabIndex="0" style={{ cursor: "pointer" }}>
              <div
                className="hover:bg-slate-100 hover:rounded-lg"
                style={{ padding: "14px 25px 14px 7px" }}
              >
                <div className="d-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="gray"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 3h2l.4 2M7 13h10l4-32H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  <a
                    href="#/"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    className="mt-1"
                    style={{
                      color: "gray",
                      fontSize: "12px",
                      fontWeight: "500",
                      textDecoration: "none",
                    }}
                  >
                    Cart
                  </a>
                  <div className="notification_count">{cartItemCount}</div>
                  {/* <a href = "#/" onClick = {(event) => { event.preventDefault() }} className = "text-[14px] mt-[15px] ml-2" style = {{ color: 'gray', fontSize: '12px', fontWeight: '500', textDecoration: 'none' }}>Cart</a> */}
                </div>
              </div>
              {/* <div className = "indicator">
                  <div className = "cartCount">{cartItemCount}</div>
                  <svg xmlns = "http://www.w3.org/2000/svg" className = "h-5 w-5" fill = "none" viewBox = "0 0 24 24" stroke = "gray"><path strokeLinecap = "round" strokeLinejoin = "round" strokeWidth = "2" d = "M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" /></svg><a href = "#/" onClick = {(event) => { event.preventDefault() }} className = "mt-1" style = {{ color: 'gray', fontSize: '12px', fontWeight: '500', textDecoration: 'none' }}>Cart</a>
                </div> */}
            </label>
            <div
              tabIndex="0"
              className="cart_card mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow"
            >
              <div className="card-body">
                <span className="font-bold text-lg">{cartItemCount} Items</span>
                <span className="text-info">Total: ${cartPriceTotal}</span>
                <div className="card-actions">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={viewCart}
                  >
                    View Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SearchHeader;
