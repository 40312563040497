// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

  }
  .bid_modal .btn_custom_secondary{
    background-color: #334155 !important;
  }
  .custom-modal-button {
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 2px solid #0d70a5;
    color: #0d70a5;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: white;
  }
  
  .custom-modal-button:hover {
    color: white;
    background-color: #0d70a5;
  }
  .quantity-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .quantity-button {
    padding: 0.25rem 0.5rem;
  }
  @media screen and (max-width: 768px) {
    .custom-modal-footer {
      flex-direction: column;
      gap: 5px 0;
      margin-top: 0  !important;
  
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;;EAElB;EACA;IACE,oCAAoC;EACtC;EACA;IACE,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,iCAAiC;IACjC,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,yBAAyB;EAC3B;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;EACV;;EAEA;IACE,uBAAuB;EACzB;EACA;IACE;MACE,sBAAsB;MACtB,UAAU;MACV,yBAAyB;;IAE3B;EACF","sourcesContent":[".custom-modal-footer {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 1rem;\n\n  }\n  .bid_modal .btn_custom_secondary{\n    background-color: #334155 !important;\n  }\n  .custom-modal-button {\n    margin-right: 1rem;\n    padding: 0.5rem 1rem;\n    border-radius: 5px;\n    border: 2px solid #0d70a5;\n    color: #0d70a5;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    background-color: white;\n  }\n  \n  .custom-modal-button:hover {\n    color: white;\n    background-color: #0d70a5;\n  }\n  .quantity-input-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n  }\n  \n  .quantity-button {\n    padding: 0.25rem 0.5rem;\n  }\n  @media screen and (max-width: 768px) {\n    .custom-modal-footer {\n      flex-direction: column;\n      gap: 5px 0;\n      margin-top: 0  !important;\n  \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
