// reducers.js
import { SET_NOTIFICATIONS } from "../actions/notificationsAction";

const initialState = {
  notifications: [],
};

export function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
}
