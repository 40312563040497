import React, { useEffect, useRef, useState } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import RouterLink from './components/routes/RouterLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { setRedirected } from '../src/actions/userSession'
import { UserInputProvider } from './components/checkout/UserInputContext';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const hasRedirectedDueToExpiration = useSelector(state => state.userSession.hasRedirectedDueToExpiration);
  const dispatch = useDispatch();

  const logoutTimeoutRef = useRef(null);// Store reference to the logout timeout
  useEffect(() => {
    const currentTime = new Date().getTime();
    const loginTime = localStorage.getItem("loginTime"); // assuming loginTime was stored as a timestamp
    const expirationDuration = parseInt(localStorage.getItem('expirationDuration') || '0');
    if (loginTime && currentTime - loginTime > expirationDuration && !hasRedirectedDueToExpiration) {
      // The session has expired. Logout the user.
      localStorage.clear();
      localStorage.setItem("IsLoggedOut", true);
      dispatch(setRedirected(true)); // Mark as redirected
      navigate("/admin/login");
    }

  }, [hasRedirectedDueToExpiration, dispatch, navigate]);

  useEffect(() => {
    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack || location.pathname === "/") {
        CapacitorApp.exitApp();
      } else {
        navigate(-1);
      }
    });

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [location, navigate]);

  return (
    <div>
      <ToastContainer />
      <UserInputProvider>  
        <RouterLink/>
      </UserInputProvider>
    </div>
  );
}

export default App;
