import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import { Col, Modal, Row, Button } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdCancel, MdOutlineDone } from "react-icons/md";
import { ToastContainer, Toast } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { ImSearch } from "react-icons/im";
import { BsFunnel } from "react-icons/bs";
import "../../css/requests.css";
import "../../css/inventory.css";
import "../../css/style.css";

const PurchaseRequests = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [bid, setBid] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [maximum, setMaximum] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const logoUrl = localStorage.getItem('logoUrl');
  const [bidDetails, setBidDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState("Items");
  const [searchColumn, setSearchColumn] = useState(null);
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  const conditionalRowStyles = [
    {
      when: (row) => row.Status === "Accepted",
      style: {
        cursor: "pointer",
      },
    },
    {
      when: row => maximum.includes(row.purchaserequestId),
      style: {
        backgroundColor: '#f9eecd', // lighter color between green and yellow
      },
    }
  ];
  const defaultColumn = {
    cell: (row) => (
      <div style={{ textAlign: typeof row.cell === "number" ? "right" : "left" }}>{row.cell}</div>
    ),
  };
  useEffect(() => {
    setIsLoading(true)
    if (selectedTab === "Items") {
      let tempColumns = [];
      //let tempColumnsSeller = [];
      let tempRows = [];

      tempColumns = [
        {
          name: "",
          selector: (row) => (
            <div
              className="avatar active:scale-[1.01] inline-block align-middle"
              style={{ padding: "0.5em 0" }}
            >
              <div className="w-5 rounded-full">
                <img loading="lazy" 
                  src={`${row.itemImage}`}
                  alt=""
                />
              </div>
            </div>
          ),
          style: { justifyContent: "center" },
        },
     
        {
          name: "Item No.",
          selector: (row) => row.itemId,
          cell: (row) => <div style={{ textAlign: "right" }}>{row.itemId}</div>,
        },
        {
          name: "Item Name",
          selector: (row) => row.itemName,
        },
      
        {
          name: "Item Value",
          selector: (row) => row.itemPrice,
        },
        {
          name: "Quantity",
          selector: (row) => row.itemQuantity,
        },
        {
          name: "Buyer",
          selector: (row) => row.Buyer,
        },
        {
          name: "Company",
          selector: (row) => row.Company,
        }
      ];



      axios
        .get("/get_purchaserequest", {
          params: {
            userId: currentUser.id,
          },
        })
        .then((response) => {
          console.log(response.data);
          tempRows = response.data;
          const isSeller = tempRows.some(
            (row) => row.sellerId === currentUser.id
          );
          setColumns(tempColumns);
          setIsLoading(false)
          setRows(tempRows);
          console.log("TEMP", tempRows);
          setFilteredRows(tempRows);
        })
        .catch((error) => {
          setIsLoading(false)
          console.error(error); // Handle any errors that may occur
        });
    } else if (selectedTab === "Lots") {
      let tempColumns = [];
      //let tempColumnsSeller = [];
      let tempRows = [];
      let highlightedIds = [];

      tempColumns = [
        {
          name: "",
          selector: (row) => (
            <div
        
              style={{ padding: "0.5em 0" }}
            >

              <div className="w-12 rounded-full">
                <img loading="lazy" 
                  src={`${row.lotImage}`}
                  alt=""
                  style={{ width: "50%", height: "50%" }} // Set image size to 50%
                />
              </div>
            </div>
          ),
          style: { justifyContent: "center" },
        },
      
        {
          name: "Lot No.",
          selector: (row) => row.lotId,
          'right': true
        },
        {
          name: "Lot Name",
          selector: (row) => row.lotName,
        },
     
        {
          name: "Lot Value",
          selector: (row) => `${row.lotPrice.toLocaleString()} ${currentUser.currency}`,
          'right': true
        },
  
        {
          name: "Bid Choice",
          selector: (row) => row.itemBid,
        },
        {
          name: "Buyer",
          selector: (row) => row.Buyer,

        },

        {
          name: "Bid",
          minWidth: 300,
          selector: (row) => {
            return (
              <div
                style={{ display: "flex", alignItems: "left" }}>
                <div>
                  <span>{currentUser.currency}</span>
                  <input
                    type="number"
                    step="any"
                    className="bidInput"
                    min="0"
                    placeholder="Your Bid"
                    //defaultValue={row.newBid}
                    value={row.newBid}
                    onChange={(event) => handleChange(event, row)}
                    disabled={
                      (row.Status === "In progress" &&
                        row.itemBid === "Close Bidding") ||
                      row.Status === "Accepted" ||
                      row.Status === "Rejected"
                    }
                    style={{
                      width: '65px',
                      borderRadius: `${(row.Status === "In progress" &&
                        row.itemBid === "Close Bidding") ||
                        row.Status === "Accepted" ||
                        row.Status === "Rejected"
                        ? "5px"
                        : "5px 0 0 5px"
                        }`,
                      textAlign: "right",
                      backgroundColor: "white"
                    }}
                  />
                </div>

                {/* {row.Status === "In progress" &&
                  row.itemBid !== "Close Bidding" &&
                  row.Status !== "Accepted" &&
                  row.Status !== "Rejected" && (
                    <div>
                      <button
                        type="button"
                        style={{
                          background: "#0D70A5",
                          padding: "0.55em 0.3em",
                          borderRadius: "0 5px 5px 0",
                        }}
                        disabled={row.counteredBySeller === 1}
                        onClick={() => handleEnter(row)}
                      >
                        <MdOutlineDone
                          style={{ color: "white", fontSize: "15px" }}
                        />
                      </button>
                    </div>
                  )} */}
                <div>
                  <button
                    type="button"
                    disabled={
                      !(row.Status === "In progress" &&
                        row.itemBid !== "Close Bidding" &&
                        row.Status !== "Accepted" &&
                        row.Status !== "Rejected") || row.counteredBySeller === 1
                    }
                    onClick={() => handleEnter(row)}
                    style={{
                      background: (row.Status === "In progress" &&
                        row.itemBid !== "Close Bidding" &&
                        row.Status !== "Accepted" &&
                        row.Status !== "Rejected" &&
                        row.counteredBySeller !== 1) ? "#0D70A5" : "#B0B0B0", // Gray color when disabled
                      padding: "0.55em 0.3em",
                      borderRadius: "0 5px 5px 0",
                      cursor: (row.Status === "In progress" &&
                        row.itemBid !== "Close Bidding" &&
                        row.Status !== "Accepted" &&
                        row.Status !== "Rejected" &&
                        row.counteredBySeller !== 1) ? "pointer" : "not-allowed" // Displaying a not-allowed cursor for disabled state
                    }}
                  >
                    <MdOutlineDone
                      style={{ color: "white", fontSize: "15px" }}
                    />
                  </button>
                </div>

              </div>
            );
          },
        },
        {
          name: "Status",
          selector: (row) => row.Status,
          cell: (row) => {
            if (row.Status === "In progress") {
              return (
                <div
                  style={{
                    backgroundColor: `${row.Status === "In progress"
                      ? "#55CE63"
                      : row.Status === "Accepted"
                        ? "#009EFB"
                        : "#F62D51"
                      }`,
                  }}
                  onClick={() => handleRowClicked(row)}
                >
                  {row.Status}
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    backgroundColor: `${row.Status === "In progress"
                      ? "#55CE63"
                      : row.Status === "Accepted"
                        ? "#009EFB"
                        : "#F62D51"
                      }`,
                  }}
                  onClick={() => handleRowClicked(row)}
                >
                  {row.Status} Bid
                </div>
              );
            }
          },
        },
        {
          name: "Actions",
          cell: (row) => {
            return (
              <div
                style={{
                  display: "flex",
                  columnGap: "10px",
                  alignItems: "center",
                }}
              >
                <button
                  type="button"
                  onClick={() => handleAccept(row)}
                  disabled={
                    row.Status === "Accepted" ||
                    row.Status === "Rejected" ||
                    row.itemBid === "Fixed Price" ||
                    row.counteredBySeller === 1
                  }
                  title="Accept"
                >
                  <BsFillCheckCircleFill
                    style={{
                      color: `${row.Status === "Accepted" ||
                        row.Status === "Rejected" ||
                        row.itemBid === "Fixed Price" ||
                        row.counteredBySeller === 1
                        ? "#CCC"
                        : "green"
                        }`,
                      fontSize: "21px",
                    }}
                  />
                </button>
                {row.itemBid !== "Close Bidding" && (
                  <button
                    type="button"
                    onClick={() => handleReject(row)}
                    disabled={
                      row.Status === "Rejected" ||
                      row.Status === "Accepted" ||
                      row.itemBid === "Fixed Price" ||
                      row.counteredBySeller === 1
                    }
                    title="Reject"
                  >
                    <MdCancel
                      style={{
                        color: `${row.Status === "Rejected" ||
                          row.Status === "Accepted" ||
                          row.itemBid === "Fixed Price" ||
                          row.counteredBySeller === 1
                          ? "#CCC"
                          : "red"
                          }`,
                        fontSize: "25px",
                      }}
                    />
                  </button>
                )}
              </div>
            );
          },
          style: { justifyContent: "center" },
        }

      ];


      axios
        .get("/get_purchaserequest_lot", {
          params: {
            userId: currentUser.id,
          },
        })
        .then((response) => {
          console.log(response.data);
          tempRows = response.data;
          const highestBidIds = {};
          response.data.forEach((row) => {
            const currentBid = parseFloat(row.newBid);
            const currentLotId = row.lotId;

            if (
              highestBidIds[currentLotId] === undefined ||
              currentBid > highestBidIds[currentLotId].bid
            ) {
              highestBidIds[currentLotId] = {
                id: row.purchaserequestId,
                bid: currentBid,
              };
            }
          });
          console.log(highestBidIds);


          // Step 2: Create an array of highlightedIds for rows that should be highlighted
          highlightedIds = Object.values(highestBidIds).map(
            (item) => item.id
          );
          setMaximum(highlightedIds);
          console.log(maximum);
          const isSeller = tempRows.some(
            (row) => row.sellerId === currentUser.id
          );
          setColumns(tempColumns);
          setIsLoading(false)
          setRows(tempRows);
          console.log("TEMP", tempRows);
          setFilteredRows(tempRows);
        })
        .catch((error) => {
          setIsLoading(false)
          console.error(error); // Handle any errors that may occur
        });
    }

    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTab]);

  useEffect(() => {
    axios.post('/setNotificationAsSeen', {
      userId: currentUser.id,
      url: window.location.pathname
    })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error('Error updating notification', error);
      });
  }, []);



  /*const filters = {
        "SerialNumber": { value: null, matchMode: FilterMatchMode.EQUALS },
        "RequestBy": { value: null, matchMode: FilterMatchMode.IN },
        "OrderValue": { value: null, matchMode: FilterMatchMode.EQUALS },
        "OrderQuantity": { value: null, matchMode: FilterMatchMode.EQUALS },
        "OrderStatus": { value: null, matchMode: FilterMatchMode.EQUALS },
    }*/

  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };



  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };

  const handleChange = (event, selectedRow) => {
    console.log("INSIDE change");
    if (selectedTab === "Items") {
      setFilteredRows((prevRows) => {
        let tempRows = [...prevRows];
        const index = tempRows.findIndex(
          (tempRow) => tempRow.itemIndex === selectedRow.itemIndex
        );

        if (index > -1) {
          tempRows[index] = { ...tempRows[index], Bid: event.target.value };
          return tempRows;
        }

        return prevRows;
      });

      setRows(filteredRows);

    }
    else if (selectedTab === "Lots") {
      setFilteredRows((prevRows) => {
        let tempRows = [...prevRows];
        const index = tempRows.findIndex(
          (tempRow) => tempRow.itemIndex === selectedRow.itemIndex
        );

        if (index > -1) {
          tempRows[index] = { ...tempRows[index], newBid: event.target.value };
          return tempRows;
        }

        return prevRows;
      });

      setRows(filteredRows);

    }

  };

  const handleEnter = async (selectedRow) => {
    console.log(selectedRow.Bid);
    let response;
    if (selectedRow.newBid < 0) {
      toast.error('Counter bid can not be less than 0');
      return;
    }

    try {
      if (selectedTab === 'Lots') {
        console.log(parseInt(selectedRow.newBid))
        console.log(parseInt(selectedRow.lotPrice))
        if (parseInt(selectedRow.newBid) < parseInt(selectedRow.lotPrice)) {
          toast.error('Counter bid can not be less than minimum lot value');
          return;
        }
        response = await axios.post(
          "/make_counterofferSeller",
          {
            userId: currentUser.id,
            purchaserequestId: selectedRow.purchaserequestId,
            price: selectedRow.newBid,
          }
        );

      }
      else if (selectedTab === 'Items') {
        response = await axios.post(
          "/make_counterofferSeller",
          {
            userId: currentUser.id,
            purchaserequestId: selectedRow.purchaserequestId,
            price: selectedRow.Bid,
          }
        );
      }


      if (response.status === 200) {
        console.log("Counter offer made successfully:", response.data);

        if (selectedTab === "Items") {
          setBid({
            itemNumber: selectedRow.ItemNumber,
            itemName : selectedRow.itemName,
            bidValue: selectedRow.Bid,
          });
          setShowToast(true);
          axios
            .get("/get_purchaserequest", {
              params: {
                userId: currentUser.id,
              },
            })
            .then((response) => {
              console.log(response.data);
              const tempRows = response.data;
              const isSeller = tempRows.some(
                (row) => row.sellerId === currentUser.id
              );

              setRows(tempRows);
              console.log("TEMP", tempRows);
              setFilteredRows(tempRows);
            })
            .catch((error) => {
              console.error(error); // Handle any errors that may occur
            });
        } else if (selectedTab === "Lots") {
          setBid({
            itemNumber: selectedRow.lotId,
            itemName: selectedRow.lotName,
            bidValue: selectedRow.newBid,
          });
          setShowToast(true);
          axios
            .get("/get_purchaserequest_lot", {
              params: {
                userId: currentUser.id,
              },
            })
            .then((response) => {
              console.log(response.data);
              const tempRows = response.data;
              const isSeller = tempRows.some(
                (row) => row.sellerId === currentUser.id
              );

              setRows(tempRows);
              console.log("TEMP", tempRows);
              setFilteredRows(tempRows);
            })
            .catch((error) => {
              console.error(error); // Handle any errors that may occur
            });
        }
      } else {
        console.error(
          "Error making counter offer:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error making counter offer:", error);
    }
  };
  const handleAccept = async (selectedRow) => {
    console.log(selectedRow.Bid);
    try {
      const response = await axios.post(
        "/accept_request",
        {
          purchaserequestId: selectedRow.purchaserequestId,
          userType: 'seller'
        }
      );

      if (response.status === 200) {
        setBidDetails(selectedRow);
        console.log("Counter offer made successfully:", response.data);
        if (selectedTab === "Items") {
          axios
            .get("/get_purchaserequest", {
              params: {
                userId: currentUser.id,
              },
            })
            .then((response) => {
              console.log(response.data);
              const tempRows = response.data;
              const isSeller = tempRows.some(
                (row) => row.sellerId === currentUser.id
              );

              setRows(tempRows);
              console.log("TEMP", tempRows);
              setFilteredRows(tempRows);
            })
            .catch((error) => {
              console.error(error); // Handle any errors that may occur
            });
          setShowStatusModal(true);
        } else if (selectedTab === "Lots") {
          axios
            .get("/get_purchaserequest_lot", {
              params: {
                userId: currentUser.id,
              },
            })
            .then((response) => {
              console.log(response.data);
              const tempRows = response.data;
              const isSeller = tempRows.some(
                (row) => row.sellerId === currentUser.id
              );

              setRows(tempRows);
              console.log("TEMP", tempRows);
              setFilteredRows(tempRows);
            })
            .catch((error) => {
              console.error(error); // Handle any errors that may occur
            });
          setShowStatusModal(true);
        }
      } else {
        console.error(
          "Error making counter offer:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error making counter offer:", error);
    }
  };

  const handleReject = async (selectedRow) => {
    console.log(selectedRow.Bid);
    try {
      const response = await axios.post(
        "/reject_request",
        {
          purchaserequestId: selectedRow.purchaserequestId,
          userType: 'seller'
        }
      );

      if (response.status === 200) {
        console.log("Counter offer made successfully:", response.data);
        if (selectedTab === "Items") {
          axios
            .get("/get_purchaserequest", {
              params: {
                userId: currentUser.id,
              },
            })
            .then((response) => {
              console.log(response.data);
              const tempRows = response.data;
              const isSeller = tempRows.some(
                (row) => row.sellerId === currentUser.id
              );

              setRows(tempRows);
              console.log("TEMP", tempRows);
              setFilteredRows(tempRows);
            })
            .catch((error) => {
              console.error(error); // Handle any errors that may occur
            });
        } else if (selectedTab === "Lots") {
          axios
            .get("/get_purchaserequest_lot", {
              params: {
                userId: currentUser.id,
              },
            })
            .then((response) => {
              console.log(response.data);
              const tempRows = response.data;
              const isSeller = tempRows.some(
                (row) => row.sellerId === currentUser.id
              );

              setRows(tempRows);
              console.log("TEMP", tempRows);
              setFilteredRows(tempRows);
            })
            .catch((error) => {
              console.error(error); // Handle any errors that may occur
            });
        }

        //setShowStatusModal(true);
      } else {
        console.error(
          "Error making counter offer:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error making counter offer:", error);
    }
  };

  const handleRowClicked = (row) => {
    if (row.Status === "Accepted") {
      setSelectedRow(row);
      setShowDetailModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowDetailModal(false);
    setShowStatusModal(false);
  };

  const handleDropdownClick = (event, search) => {
    event.preventDefault();
    setSearchColumn(search);
  };

  const handleInputChange = (event) => {
    let userInput = event.target.value;
    userInput = userInput.trim();

    if (userInput === "") {
      setFilteredRows(rows);
    } else {
      if (searchColumn) {
        if (searchColumn === "Bid Choice") {
          const result = rows.filter((search) =>
            search?.itemBid?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
          setFilteredRows(result);
        } else if (searchColumn === "Status") {
          const result = rows.filter((search) =>
            search?.Status?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
          setFilteredRows(result);
        }
      } else {
        let result;
        if (selectedTab === 'Lots') {
          result = rows.filter((search) =>
            search?.lotName?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
        }
        else if (selectedTab === 'Items') {
          result = rows.filter((search) =>
            search?.itemName?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
        }


        setFilteredRows(result);
      }
    }
  };

  return (
    <div className="purchase_request_page">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                  }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>

              <div
                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                  }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}

                <ToastContainer className="messageToast">
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                  >
                    <Toast.Header>
                      <strong className="me-auto">Message</strong>
                      <small>1 sec ago</small>
                    </Toast.Header>
                    <Toast.Body>
                      New bid entered for <b>{bid.itemName}</b>:{" "}
                      {bid.bidValue}
                    </Toast.Body>
                  </Toast>
                </ToastContainer>

                <div
                  className={`w-100 content_panel ${collapseSidebar ? "slide-in" : "slide-out"
                    }`}
                >
                  <div style={{ height: "100vh" }}>
                    <div className="purchase_request d-flex justify-content-between">
                       <h4 className="pageName_heading">
    {logoUrl && <img src={logoUrl} alt="Company Logo" style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }} />}My Selling List</h4>

                      <div className="btn-group align-items-center justify-content-center" role="group">
                        <button
                          type="button"
                          className={`btn button_flex_0 custom_btn ${selectedTab === 'Items' ? 'btn-primary' : 'btn-light'}`}
                          onClick={() => setSelectedTab('Items')}
                        >
                          Items
                        </button>
                        <button
                          type="button"
                          className={`btn button_flex_0 custom_btn ${selectedTab === 'Lots' ? 'btn-primary' : 'btn-light'}`}
                          onClick={() => setSelectedTab('Lots')}
                        >
                          Lots
                        </button>
                      </div>

                      <div className="position-relative d-flex">

                        <input
                          type="text"
                          placeholder={`Search ${searchColumn ? searchColumn : "Item Name"}`}
                          className="input py-[1.5em] px-[3em]"
                          onChange={(event) => handleInputChange(event)}
                        />
                        <button
                          className="request-filter dropdown-toggle"
                          type="button"
                          id="filterButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <BsFunnel
                            style={{ color: "black", fontSize: "18px" }}
                          />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="filterButton"
                          style={{ zIndex: "1", fontSize: "14px" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Item Name")
                              }
                            >
                              Filter By Item Name
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Bid Choice")
                              }
                            >
                              Filter By Bid Choice
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Status")
                              }
                            >
                              Filter By Status
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="table-responsive custom_table_container customCard">
                      {
                        isLoading ? (
                          <div className="d-flex justify-content-center">
                            <br />
                            <div className="spinner-border text-info" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        ) : (
                          
                            
                            <DataTable
                              columns={columns}
                              data={filteredRows}
                              pagination={true}
                              conditionalRowStyles={conditionalRowStyles}
                              onRowClicked={(row) => handleRowClicked(row)}
                              rowHeight={40}
                              defaultColumn={defaultColumn}
                            />
                            
                          

                        )
                      }
                    </div>
                    {/* <div className="table-responsive">
                      <div
                        className={`purchase-requests ${props.userRole.access === "seller"
                          ? "purchase-requests-seller"
                          : props.userRole.access === "admin"
                            ? "purchase-requests-admin"
                            : "purchase-requests-buyer"
                          }`}
                      >
                        <DataTable
                          columns={columns}
                          data={filteredRows}
                          pagination={true}
                          conditionalRowStyles={conditionalRowStyles}
                          onRowClicked={(row) => handleRowClicked(row)}

                        />
                      </div>
                    </div> */}

                    {/* <CustomDataTable rows = {rows} columns = {columns} filters = {filters} handleShowModal = {handleShowDetailModal} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal centered show={showDetailModal} onHide={handleCloseModal}>
            <Modal.Header closeButton={handleCloseModal}>
              <Modal.Title style={{ color: "#0d70a5" }}>DETAILS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  REQUEST DATE
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  24 - JUL - 2022
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  ITEM NUMBER
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  {selectedRow.ItemNumber}
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  ITEM NAME
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  {selectedRow.ItemName}
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  VENDOR
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  {selectedRow.Vendor}
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  BID PLACED
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  ${selectedRow.Bid}
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  ORDER ADD ONS
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  INSPECTION, INSURANCE
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  TOTAL ITEMS
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  1
                </Col>
              </Row>
              <Row style={{ marginBottom: "0.5em" }}>
                <Col xs={4} style={{ color: "#9F9F9F" }}>
                  STATUS
                </Col>
                <Col xs={8} style={{ color: "#707070" }}>
                  Order In Delivery
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal centered show={showStatusModal} onHide={handleCloseModal}>
            <Modal.Body>
              <h1 className="text-slate-600 text-[18px] text-center p-[1em]">
                {selectedTab === 'Lots'
                  ? `Bid Accepted for ${bidDetails.lotName} for ${bidDetails.newBid} ${currentUser.currency}`
                  : `Bid Accepted for ${bidDetails.itemName} for ${bidDetails.Bid} ${currentUser.currency}`}
              </h1>

            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={handleCloseModal}
                style={{ margin: "auto", width: "100px", color: "white" }}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps)(PurchaseRequests);
