import React, { useEffect, useState } from 'react';
import { ArcElement } from 'chart.js';
import Chart from 'chart.js/auto';
import axios from 'axios';
import { Category } from '@mui/icons-material';

const CategoryPieChart = ({ startDate, endDate }) => {
  
  const [pieData, setPieData] = useState([]);

  // Fetch data
  useEffect(() => {
    const fetchDataForPieChart = async () => {
      const url = `/dashboard/topitemcategories?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
        setPieData(response.data.topCategories);
      } catch (error) {
        console.log("Error fetching data for pie chart: ", error);
      }
    };
    fetchDataForPieChart();
  }, [startDate, endDate]);

  // Render chart
  useEffect(() => {
    if (pieData.length === 0) {
      return;
    }

    const canvas = document.getElementById('myPieCanvas');
    const ctx = canvas.getContext('2d');
    const labels = pieData.map(item => item.itemCategory);
    const data = pieData.map(item => item.totalQuantitySold);

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [{
          data,
          backgroundColor: [
            '#C8A2C8', // Light Purple (Lavender)
            '#40E0D0', // Turquoise (Teal)
            '#FFA500', // Orange
            '#7494EA', // Soft Blue
            '#90EE90', // Light Green
            '#FFFACD', // Lemon Chiffon (Warm Yellow)
            '#800080', // Dark Purple (Purple)
            
          ],
        }]
      },
      options: {
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 5,
              padding: 5,
              generateLabels: chart => {
                // Custom logic to format legend labels
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map((label, i) => {
                    return {
                      text: label,
                      fillStyle: data.datasets[0].backgroundColor[i],
                      // Add more properties if needed
                    };
                  });
                }
                return [];
              },
            },
          },
        },
      },
    };
    

    const chartInstance = new Chart(ctx, config);

    // Destroy chart instance to avoid memory leaks when component unmounts or data changes
    return () => {
      chartInstance.destroy();
    };
  }, [pieData]);

  return (
    <canvas id="myPieCanvas" width="50" height="50" style={{ width: '50px', height: '50px' }}></canvas>

  );
};

export default CategoryPieChart;