import React from 'react';
import Modal from 'react-modal';

import '../../css/modal.css';

const customStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '500px',
    // maxHeight: '160px',
    height:'fit-content',
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '10px',
    outline: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '2px solid #0d70a5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center', // Add this line to center the text
  },
};

const AddToWishlistPic = ({
  showPrompt,
  showQuantityInput,
  handlePromptResponse,
  handleQuantityChange,
  handleSaveQuantity,
  quantityPic,
  minQuantity,
  maxQuantity
}) => {
    const incrementQuantity = () => {
        console.log("INCREMENT")
        handleQuantityChange({ target: { value: Number(quantityPic) + 1 } });
      };
    
      const decrementQuantity = () => {
        if (quantityPic > 1) {
          handleQuantityChange({ target: { value: Number(quantityPic) - 1 } });
        }
      };
  return (
    <div>
      <Modal
        isOpen={showPrompt}
        onRequestClose={() => handlePromptResponse(false)}
        style={customStyles}
      >
        <p>Do you wish to specify quantity? (Minimum Buying Quantity: {minQuantity})</p>
        <div className="custom-modal-footer">
          <button className="btn custom_btn btn-outline-primary" onClick={() => handlePromptResponse(1)}>Yes</button>
          <button className="btn custom_btn btn-outline-primary" onClick={() => handlePromptResponse(0)}>No, Add Minimum Quantity</button>
          <button className="btn custom_btn btn-outline-primary" onClick={() => handlePromptResponse(3)}>Cancel</button>
        </div>
      </Modal>
      <Modal
        isOpen={showQuantityInput}
        onRequestClose={() => handleSaveQuantity()}
        style={customStyles}
      >
        <p>Enter quantity:</p>
        <div className="quantity-input-wrapper">
          <button className="custom-modal-button quantity-button" style={{ marginRight: '0rem' }} disabled={quantityPic <= minQuantity} onClick={decrementQuantity}>-</button>
          <p>{quantityPic}</p>
          <button className="custom-modal-button quantity-button" disabled={quantityPic >= maxQuantity} onClick={incrementQuantity}>+</button>
        </div>
        <div className="custom-modal-footer">
          <button className="btn custom_btn btn-outline-primary" onClick={handleSaveQuantity}>Add to wishlist</button>
          <button className="btn custom_btn btn-outline-primary" onClick={() => handlePromptResponse(3)}>Cancel</button>
        </div>
      </Modal>
    </div>
  );
};

export default AddToWishlistPic;
