import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import SearchHeader from "../header/SearchHeader";
import { useLocation } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import Footer from "../footer/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserInput } from './UserInputContext';
import "../../css/style.css";

const CheckOut = (props) => {
  const location = useLocation();
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const id = currentUser.id;
  //const vat = localStorage.getItem("vat");
  const [favourites, setFavourites] = useState([]);
  const vat = location.state?.total_vat || 0;
  // const [userInput, setUserInput] = useState({});
  const { userInput, setUserInput } = useUserInput();
  const [errors, setErrors] = useState({});

  const [products, setProducts] = useState([]);
  const [cProducts, setCProducts] = useState([]);
  const [gProducts, setGProducts] = useState([]);
  const navigate = useNavigate();
  const [subTotal, setSubTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [buyerCommission, setBuyerCommission] = useState(0);
  const [VAT, setVAT] = useState(0);
  const [apiData, setApiData] = useState({
    products: [],
    subTotal: 0,
    VAT: 0,
    buyerCommission: 0,
    finalTotal: 0,
    gProducts: [],
    cProducts: []
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/get-usercompanydetails/${currentUser.id}`);
        const { userData, companyData } = response.data;
        
        setUserInput({
          email_phone: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          address: companyData.address,
          city: companyData.district,
          country: companyData.country,
          province: companyData.region
        });
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await axios.post(
          "/cartbySeller",
          { userId: currentUser.id }
        );

        const responseData = response.data;
        console.log("RESPONSE", response.data);
        if (responseData) {
          let allProducts = [];
          let total = 0;
          let vatTotal = 0;
          let commissionTotal = 0;

          responseData.subcarts.forEach((subcart) => {
            allProducts = allProducts.concat(subcart.products);
            console.log(allProducts);

            total += parseFloat(subcart.subcart_total);
            vatTotal += subcart.subcart_vat;
            commissionTotal += subcart.subcart_commission;
          });

          //setProducts(allProducts);
          setSubTotal(total);
          setVAT(vatTotal);
          setBuyerCommission(commissionTotal);
          const fullTotal = total + vatTotal + commissionTotal;
          setFinalTotal(fullTotal);
          const groupedProducts = groupProductsBySellerId(
            responseData.subcarts
          );
          const groupedProducts2 = groupProductsByCompanyId(
            responseData.subcarts
          );
          setGProducts(groupedProducts);
          setCProducts(groupedProducts2);
          console.log("COMPANY WISE GROUPED", groupedProducts2);
          console.log("GROUPED", groupedProducts);
          setApiData({
            products: allProducts,
            subTotal: total,
            VAT: vatTotal,
            buyerCommission: commissionTotal,
            finalTotal: fullTotal,
            gProducts: groupedProducts,
            cProducts: groupedProducts2
          });

        }
      } catch (error) {
        console.log("Error fetching cart data:", error);
      }
    };

    fetchCartData();
  }, [currentUser.id]);

  const groupProductsBySellerId = (subcarts) => {
    const groupedProducts = subcarts.map((subcart) => {
      const products = subcart.products;
      const cartData = {
        seller_id: products[0].sellerId,
        sellerName: products[0].sellerName,
        company_id: products[0].company_id,
        company: products[0].company,
        subcart_commission: subcart.subcart_commission,
        subcart_total: subcart.subcart_total,
        subcart_vat: subcart.subcart_vat,
        products,
      };
      return cartData;
    });

    return groupedProducts;
  };

  const groupProductsByCompanyId = (subcarts) => {
    const groupedProducts = subcarts.map((subcart) => {
      const products = subcart.products;
      const cartData = {
        company_id: products[0].company_id,
        company: products[0].company,
        subcart_commission: subcart.subcart_commission,
        subcart_total: subcart.subcart_total,
        subcart_vat: subcart.subcart_vat,
        products,
      };
      return cartData;
    });

    return groupedProducts;
  };

  async function sendPurchaseRequests(products) {
    const apiUrl = "/make_purchaseorder";

    for (const product of products) {
      const companyId = product.company_id;
      const sellerId = product.seller_id;
      const items = product.products.map((p) => ({
        itemId: p.id,
        itemPrice: parseFloat(p.price),
        itemQuantity: p.quantity,
        totalPrice: parseFloat(p.price) * p.quantity,
        type: p.type
      }));
      console.log(items)

      const requestData = {
        sellerId: sellerId,
        companyId: companyId,
        buyerId: id, // Replace with the actual buyerId
        subtotal: product.subcart_total,
        shipping: 0, // Replace with the actual shipping value
        VAT: product.subcart_vat,
        commission: product.subcart_commission,
        total:
          parseFloat(product.subcart_total) +
          product.subcart_vat +
          product.subcart_commission,
        items: items,
      };
      console.log(requestData)

      try {
        const response = await axios.post(apiUrl, requestData);
        console.log(
          `Purchase request for company ${companyId} sent successfully`,
          response.data
        );
      } catch (error) {
        console.error(
          `Error sending purchase request for company ${companyId}`,
          error.response.data
        );
      }
    }
  }
  async function sendInvoiceRequests(products) {
    try {
      const invoiceResponse = await axios.post(
        "/make_invoice",
        {
          buyer: id,
          subtotal: subTotal,
          VAT: VAT,
          commission: buyerCommission,
          total: finalTotal,
        }
      );

      const invoiceId = invoiceResponse.data.invoiceId;

      for (const company of products) {
        const companyId = company.company_id;
        const companySubTotal = company.subcart_total;
        const companyVAT = company.subcart_vat;
        const companyCommission = company.subcart_commission;
        const companyTotal =
          parseFloat(companySubTotal) +
          parseFloat(companyVAT) +
          parseFloat(companyCommission);

        const items = company.products.map((product) => {
          return {
            itemId: product.id,
            itemPrice: product.price,
            itemQuantity: product.quantity,
            type: product.type,
            totalPrice:
              parseFloat(product.price) * parseFloat(product.quantity),
          };
        });

        try {
          const companyInvoiceResponse = await axios.post(
            "/make_companywiseinvoice",
            {
              invoiceId: invoiceId,
              companyId: companyId,
              buyerId: id,
              subtotal: companySubTotal,
              shipping: 0,
              VAT: companyVAT,
              commission: companyCommission,
              total: companyTotal,
              items: items,
            }
          );

          console.log(
            `Company-wise invoice for company ${companyId} created successfully`,
            companyInvoiceResponse.data
          );
        } catch (error) {
          console.error(
            `Error while creating company-wise invoice for company ${companyId}`,
            error.response.data
          );
        }
      }
    } catch (error) {
      console.error("Error while creating invoice:", error.response.data);
    }
  }


  const handleChange = (event) => {
    setUserInput(prevState => ({...prevState, [event.target.name]: event.target.value}));
    ValidateForm();
  };

  const handleCheckboxChange = (event) => {
    console.log(event.target.value);
  };

  function handleClick(selected) {
    navigate("/product-detail/123", { state: selected });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (ValidateForm()) {
      navigate("/delivery-method", { state: { userInput, apiData } });
    }
  };


  const addToWishList = (productName) => {
    let tempFavourites = [...favourites];
    const index = tempFavourites.indexOf(productName);

    if (index === -1) {
      tempFavourites.push(productName);
      toast.success(productName + " has been added to your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    } else {
      tempFavourites.splice(index, 1);
      toast.success(productName + " has been removed from your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    }

    setFavourites(tempFavourites);
  };

  const ValidateForm = () => {
    let input = userInput;
    let errors = {};
    let isValid = true;

    if (!input["email_phone"]) {
      isValid = false;
      errors["email_phone"] = "*Please enter your email or phone number.";
    }

    if (
      typeof input["email_phone"] !== "undefined" &&
      input["email_phone"] !== ""
    ) {
      var emailPattern = new RegExp(
        /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      var phonePattern = new RegExp(/^[0-9]{11}$/);

      if (
        !emailPattern.test(input["email_phone"]) &&
        !phonePattern.test(input["email_phone"])
      ) {
        isValid = false;
        errors["email_phone"] = "*Please enter a valid email or phone number.";
      }
    }

    if (!input["firstName"]) {
      isValid = false;
      errors["firstName"] = "*Please enter your first name.";
    }

    if (!input["lastName"]) {
      isValid = false;
      errors["lastName"] = "*Please enter your last name.";
    }

    if (!input["address"]) {
      isValid = false;
      errors["address"] = "*Please enter your address.";
    }

    if (!input["city"]) {
      isValid = false;
      errors["city"] = "*Please enter your city.";
    }

    if (!input["country"]) {
      isValid = false;
      errors["country"] = "*Please select your country.";
    }

    if (!input["province"]) {
      isValid = false;
      errors["province"] = "*Please enter your province.";
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <>
      {!currentUser ? (
        <Navigate
          to="/admin/login"
          state={{ previousPath: window.location.pathname }}
          replace={true}
        />
      ) : (
        <>
          <SearchHeader wishListCount={favourites.length} />
          <Header logOut={props.logOut} />

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />

          <div className="container main-container app_container">
            <div className='d_sm_center'>
              <h4 className="pageName_heading">CHECK OUT</h4>
            </div>
            <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
              <ul className="page-hierarchy">
                <li>
                  <a href="/cart">Your Cart</a>
                </li>
                <li>
                  <a href="/checkout">Check Out</a>
                </li>
              </ul>
            </div>
            <br />
            <hr style={{ width: "100%" }} />
            <form onSubmit={handleSubmit}>
              <div className="row payment_product_details">
                <div className="col-12 col-lg-6">
                  <label className="label" style={{ paddingLeft: "0" }}>
                    <span className="font-bold text-[15px]">
                      CONTACT INFORMATION
                    </span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      name="email_phone"
                      placeholder="Email or Phone Number"
                      onChange={(event) => handleChange(event)}
                      className="input form-control"
                      value = {userInput.email_phone}
                    />
                    <div className="text-danger">
                      <small>{errors.email_phone}</small>
                    </div>
                  </div>
                  <div
                    className="text-gray-600 checkbox-container"
                    style={{ color: "#0d6efd" }}
                  >
                    <input
                      type="checkbox"
                      className="checkbox checkbox-sm mt-3 custom_checkbox"
                      onChange={(event) => handleCheckboxChange(event)}
                      name="get-updates"
                    />
                    <label htmlFor="get-update">Keep up to date on news</label>
                  </div>
                  <br />
                  <label className="label" style={{ paddingLeft: "0" }}>
                    <span className="font-bold text-[15px]">
                      SHIPPING DETAILS
                    </span>
                    <button
                      className="btn btn-sm"
                      onClick={() =>
                        console.log("Use existing information clicked")
                      }
                      style={{ borderColor: "blue", color: "gray" }}
                    >
                      Existing Information
                    </button>
                  </label>

                  <div className="row">
                    <div className="col-6" style={{ paddingRight: "0.4em" }}>
                      <div className="form-group">
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          onChange={(event) => handleChange(event)}
                          value = {userInput.firstName}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.firstName}</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-6" style={{ paddingLeft: "0.4em" }}>
                      <div className="form-group">
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={(event) => handleChange(event)}
                          value = {userInput.lastName}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.lastName}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "0.8em" }}>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="address"
                          placeholder="Address"
                          onChange={(event) => handleChange(event)}
                          value = {userInput.address}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.address}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "0.8em" }}>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="city"
                          placeholder="City"
                          onChange={(event) => handleChange(event)}
                          className="input form-control"
                          value = {userInput.city}
                        />
                        <div className="text-danger">
                          <small>{errors.city}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "0.8em" }}>
                    <div className="col-6" style={{ paddingRight: "0.4em" }}>
                      <div className="form-group">
                        <select
                          className="form-select"
                          name="country"
                          defaultValue=""
                          onChange={(event) => handleChange(event)}
                          value = {userInput.country}
                          style={{ fontSize: "13px" }}
                        >
                          <option value="" hidden>
                            Country
                          </option>
                          <option value="AF">Afghanistan</option>
                          <option value="AX">Aland Islands</option>
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <option value="AS">American Samoa</option>
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <option value="AI">Anguilla</option>
                          <option value="AQ">Antarctica</option>
                          <option value="AG">Antigua and Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">Bolivia</option>
                          <option value="BQ">
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value="BA">Bosnia and Herzegovina</option>
                          <option value="BW">Botswana</option>
                          <option value="BV">Bouvet Island</option>
                          <option value="BR">Brazil</option>
                          <option value="IO">
                            British Indian Ocean Territory
                          </option>
                          <option value="BN">Brunei Darussalam</option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">Central African Republic</option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <option value="CX">Christmas Island</option>
                          <option value="CC">Cocos (Keeling) Islands</option>
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CG">Congo</option>
                          <option value="CD">
                            Congo, Democratic Republic of the Congo
                          </option>
                          <option value="CK">Cook Islands</option>
                          <option value="CR">Costa Rica</option>
                          <option value="CI">Cote D'Ivoire</option>
                          <option value="HR">Croatia</option>
                          <option value="CU">Cuba</option>
                          <option value="CW">Curacao</option>
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">Dominican Republic</option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">Equatorial Guinea</option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <option value="FK">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FR">France</option>
                          <option value="GF">French Guiana</option>
                          <option value="PF">French Polynesia</option>
                          <option value="TF">
                            French Southern Territories
                          </option>
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <option value="GG">Guernsey</option>
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-Bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <option value="HM">
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value="VA">
                            Holy See (Vatican City State)
                          </option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IR">Iran, Islamic Republic of</option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <option value="IM">Isle of Man</option>
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <option value="JE">Jersey</option>
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option value="KP">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="KR">Korea, Republic of</option>
                          <option value="XK">Kosovo</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">
                            Lao People's Democratic Republic
                          </option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libyan Arab Jamahiriya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option value="MK">
                            Macedonia, the Former Yugoslav Republic of
                          </option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">Marshall Islands</option>
                          <option value="MQ">Martinique</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">
                            Micronesia, Federated States of
                          </option>
                          <option value="MD">Moldova, Republic of</option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="AN">Netherlands Antilles</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NZ">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <option value="NU">Niue</option>
                          <option value="NF">Norfolk Island</option>
                          <option value="MP">Northern Mariana Islands</option>
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="PA">Panama</option>
                          <option value="PG">Papua New Guinea</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="RE">Reunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">Russian Federation</option>
                          <option value="RW">Rwanda</option>
                          <option value="BL">Saint Barthelemy</option>
                          <option value="SH">Saint Helena</option>
                          <option value="KN">Saint Kitts and Nevis</option>
                          <option value="LC">Saint Lucia</option>
                          <option value="MF">Saint Martin</option>
                          <option value="PM">Saint Pierre and Miquelon</option>
                          <option value="VC">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <option value="ST">Sao Tome and Principe</option>
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="CS">Serbia and Montenegro</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <option value="SX">Sint Maarten</option>
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <option value="SB">Solomon Islands</option>
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <option value="GS">
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value="SS">South Sudan</option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SD">Sudan</option>
                          <option value="SR">Suriname</option>
                          <option value="SJ">Svalbard and Jan Mayen</option>
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="SY">Syrian Arab Republic</option>
                          <option value="TW">Taiwan, Province of China</option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">
                            Tanzania, United Republic of
                          </option>
                          <option value="TH">Thailand</option>
                          <option value="TL">Timor-Leste</option>
                          <option value="TG">Togo</option>
                          <option value="TK">Tokelau</option>
                          <option value="TO">Tonga</option>
                          <option value="TT">Trinidad and Tobago</option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <option value="TC">Turks and Caicos Islands</option>
                          <option value="TV">Tuvalu</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">United Arab Emirates</option>
                          <option value="GB">United Kingdom</option>
                          <option value="US">United States</option>
                          <option value="UM">
                            United States Minor Outlying Islands
                          </option>
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">Venezuela</option>
                          <option value="VN">Viet Nam</option>
                          <option value="VG">Virgin Islands, British</option>
                          <option value="VI">Virgin Islands, U.s.</option>
                          <option value="WF">Wallis and Futuna</option>
                          <option value="EH">Western Sahara</option>
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                        </select>
                        <div className="text-danger">
                          <small>{errors.country}</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-6" style={{ paddingLeft: "0.4em" }}>
                      <div className="form-group">
                        <input
                          type="text"
                          name="province"
                          placeholder="Province"
                          onChange={(event) => handleChange(event)}
                          value = {userInput.province}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.province}</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="text-gray-600 checkbox-container"
                    style={{ color: "#0d6efd" }}
                  >
                    <input
                      type="checkbox"
                      className="checkbox checkbox-sm mt-3 custom_checkbox"
                      name="save-information"
                    />
                    <label htmlFor="save-information">Save Information</label>
                  </div>
                  <div className="row justify-content-between cart_buttons" style={{ marginTop: '1em' }}>
                    <div className="col-md-5" >
                      <button
                        type="button"
                        className="btn custom_btn btn-outline-primary"
                        onClick={() => navigate(-1)}
                        // onClick={()=>{sendInvoiceRequests(gProducts)}}
                        // onClick={() => {
                        //   sendPurchaseRequests(gProducts);
                        // }}
                        style={{ width: "100%", fontSize: "13px" }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-5" >
                      <button
                        type="submit"
                        className="btn custom_btn btn-primary"
                        style={{ width: "100%", fontSize: "13px" }}
                      // onClick={()=>{sendInvoiceRequests(gProducts)}}
                      // onClick={() => {
                      //   sendPurchaseRequests(gProducts);
                      // }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-lg-6 products_container"
                  style={{ backgroundColor: "#F1F1F1" }}
                >
                  <div
                    className="scrollable-container"
                    style={{
                      maxHeight: "500px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="row">
                      {gProducts.map((companyProducts, companyIndex) => (
                        <div className="col-12" key={companyIndex}>
                          <div className="card my-3">
                            <div className="card-body">
                              <h4>{companyProducts.company}({companyProducts.sellerName})</h4>
                              {companyProducts.products.map(
                                (product, index) => (
                                  <div
                                    className="product-container d-flex justify-content-between align-items-center"
                                    key={index}
                                  >
                                    <div className="d-flex">
                                      <div style={{ position: "relative" }}>
                                        <img loading="lazy" 
                                          src={`${product.image}`}
                                          alt=""
                                          className="h-[60px] w-[60px]"
                                        />
                                        <div
                                          className="quantity"
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                          }}
                                        >
                                          {product.quantity}
                                        </div>
                                      </div>
                                      <div className="ml-3">
                                        <h1 className="text-[13px] font-bold">
                                          {product.name}
                                        </h1>
                                        {/* <h1 className="text-[13px] font-bold">
                                          Industry: {product.itemIndustry}
                                        </h1> */}
                                        <h1 className="text-[13px] font-bold">
                                          Price: {product.price} {currentUser.currency}
                                        </h1>
                                      </div>
                                    </div>
                                    <div>
                                      <h1
                                        className="text-[13px] font-bold"
                                        style={{ color: "#0d6efd" }}
                                      >

                                        {(
                                          product.price * product.quantity
                                        ).toFixed(2)} {currentUser.currency}
                                      </h1>
                                    </div>
                                  </div>
                                )
                              )}
                              <div className="text-right">
                                <h1 className="text-[13px] font-bold">
                                  Sub Total: {companyProducts.subcart_total} {currentUser.currency}
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Shipping: EXW
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Insurance: TBC
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Inspection: TBC
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  VAT: {companyProducts.subcart_vat.toFixed(2)} {currentUser.currency}
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Commission:
                                  {companyProducts.subcart_commission.toFixed(
                                    2
                                  )} {currentUser.currency}
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Total:
                                  {(parseFloat(
                                    companyProducts.subcart_commission.toFixed(
                                      2
                                    )
                                  ) +
                                    parseFloat(
                                      companyProducts.subcart_vat.toFixed(2)
                                    ) +
                                    parseFloat(companyProducts.subcart_total)).toFixed(2)} {currentUser.currency}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <hr />
                    <h1 className="text-[13px] font-bold">
                      Sub Total
                      <span
                        className="text-gray-500"
                        style={{ float: "right" }}
                      >
                        {subTotal} {currentUser.currency}
                      </span>
                    </h1>
                    <h1 className="text-[13px] font-bold">
                      Shipping
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        EXW
                      </span>
                    </h1>
                    <h1 className="text-[13px] font-bold">
                      Insurance
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        TBC
                      </span>
                    </h1>
                    <h1 className="text-[13px] font-bold">
                      Inspection
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        TBC
                      </span>
                    </h1>
                    <h1 className="text-[13px] font-bold">
                      VAT
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        {VAT.toFixed(2)} {currentUser.currency}
                      </span>
                    </h1>
                    <h1 className="text-[13px] font-bold">
                      Commission
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        {buyerCommission.toFixed(2)} {currentUser.currency}
                      </span>
                    </h1>
                    <br />
                    <h1 className="text-[14px] font-bold">
                      TOTAL
                      <span
                        className="ml-[122px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        {finalTotal.toFixed(2)} {currentUser.currency}
                      </span>
                    </h1>
                  </div>
                </div>

                {/* <div className="col-12 col-lg-6 products_container" style={{ backgroundColor: '#F1F1F1' }}>
                  <div className="product-container">
                    <div style={{ position: 'relative' }}>
                      <img loading="lazy"  src={require("../../images/image001.png")} alt="" className="h-[95px] w-[95px]" />
                      <div className="quantity">1</div>
                    </div>
                    <span>
                      <h1 className="text-[13px] font-bold">Wood</h1>
                      <h1 className="text-[13px] font-bold">Color : Packaging Materials</h1>
                      <h1 className="text-[13px] font-bold">Size : 1000mm x 1200mm x 145mm</h1>
                      <br />
                      <h1 className="text-[13px] font-bold" style={{ color: '#0d6efd' }}>$2000.00</h1>
                    </span>
                  </div>

                  <div className="product-container">
                    <div style={{ position: 'relative' }}>
                      <img loading="lazy"  src={require("../../images/image002.jpg")} alt="" className="h-[95px] w-[95px]" />
                      <div className="quantity">1</div>
                    </div>
                    <span>
                      <h1 className="text-[13px] font-bold">ISO Propyl Alcohol</h1>
                      <h1 className="text-[13px] font-bold">Industry : Raw Materials</h1>
                      <h1 className="text-[13px] font-bold">Size : 200L Steel Drum</h1>
                      <br />
                      <h1 className="text-[13px] font-bold" style={{ color: '#0d6efd' }}>$2340.00</h1>
                    </span>
                  </div>

                  <div>
                    <hr />
                    <h1 className="text-[13px] font-bold">Sub Total
                      <span className="text-gray-500" style={{ float: 'right' }}>$4340.00</span>
                    </h1>
                    <h1 className="text-[13px] font-bold">Shipping
                      <span className="ml-[106px] text-gray-500" style={{ float: 'right' }}>$200.00</span>
                    </h1>
                    <br />
                    <h1 className="text-[14px] font-bold">TOTAL
                      <span className="ml-[122px] text-gray-500" style={{ float: 'right' }}>$4540.00</span>
                    </h1>
                  </div>
                </div> */}
              </div>
            </form>
            <br />
            <hr />
            <div className='d_sm_center'>
              <h4 className="related_products_heading text-gray-600 font-bold text-[25px] inline-block float-left mt-[13px]">
                RELATED
              </h4>
            </div>
            {/* <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
              <div className="flex flex-row space-x-2">
                <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /> </svg>
                </div>
                <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                  <svg style={{ color: 'rgb(78, 77, 70)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill="#4e4d46"></path> </svg>
                </div>
              </div>
            </div> */}
            <br />
            <br />
            <br />
            <br />
            <div className="row related_products">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("Raw Materials")}
                  >
                    <img loading="lazy" 
                      src={require("../../images/image002.jpg")}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("ISO Propyl Alcohol") > -1
                              ? "900"
                              : "#E2E8F0"
                              }`,
                          }}
                          onClick={() => addToWishList("ISO Propyl Alcohol")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("Packaging Materials")}
                  >
                    <img loading="lazy" 
                      src={require("../../images/image001.png")}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Wood</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("Wood") > -1
                              ? "900"
                              : "#E2E8F0"
                              }`,
                          }}
                          onClick={() => addToWishList("Wood")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("")}
                  >
                    <img loading="lazy" 
                      src="https://placeimg.com/400/225/arch"
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Sample Product 1</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("Sample Product 1") > -1
                              ? "900"
                              : "#E2E8F0"
                              }`,
                          }}
                          onClick={() => addToWishList("Sample Product 1")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("")}
                  >
                    <img loading="lazy" 
                      src="https://placeimg.com/400/225/arch"
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Sample Product 2</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${favourites.indexOf("Sample Product 2") > -1
                              ? "900"
                              : "#E2E8F0"
                              }`,
                          }}
                          onClick={() => addToWishList("Sample Product 2")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default CheckOut;
