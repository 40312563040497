import React, { useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import { FaRegEye, FaRegEyeSlash  } from 'react-icons/fa';
import loginImage from '../../images/1.png';
import Logo from '../../images/Logo.png';
import axios from 'axios';
import '../../css/login.css'

const ResetPassword = () => 
{
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    /* FORM VARIABLES */
    const [isLoading, setIsLoading] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(null);
    const [alertError, setAlertError] = useState(null);
    const [formData, setFormData] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const confirmPasswordInputRef = useRef(null);

    const handleChange = (event) => 
    {
        const { name, value } = event.target;
        const userData = { ...formData };

        if (typeof value !== "undefined" && value !== "") 
        {
            if (name === "password") 
            {
                const passwordPattern = new RegExp(/^(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[0-9])(?=.*[a-z]).{8,}$/);

                if (passwordPattern.test(value)) 
                {
                    if (userData["confirmPassword"] === value) 
                    {
                        confirmPasswordInputRef?.current?.setCustomValidity("");
                    } 
                    else 
                    {
                        confirmPasswordInputRef?.current?.setCustomValidity("Passwords do not match.");
                    }

                    event.target.setCustomValidity("");
                    userData[name] = value;
                } 
                else 
                {
                    event.target.setCustomValidity("Password must be at least 8 characters long, include one upper case, one lower case, one digit and one special character.");
                }
            } 
            else if (name === "confirmPassword") 
            {
                if (userData["password"] === value) 
                {
                    event.target.setCustomValidity("");
                } 
                else 
                {
                    event.target.setCustomValidity("Passwords do not match.");
                }

                userData[name] = value;
            }
        }

        setFormData(userData);
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();
        setIsLoading(true);
        setAlertSuccess(null);
        setAlertError(null);

        await axios.post("/reset_password", {
            password: formData?.password,
            token: token
        })
        .then(response => 
        {
            setIsLoading(false);
            const responseData = response?.data || null;
            
            if (response.status === 200)
            {
                setAlertSuccess("Password reset successfully!");
                setTimeout(() =>
                {
                    navigate("/admin/login");
                }, 2000);
            }
            else
            {
                console.log("Reset Password Api: ", responseData);
                setAlertError(responseData?.data);
            }
        })
        .catch(error => 
        {
            console.log("Reset Password Api: ", error);
            setIsLoading(false);
            setAlertError("Something went wrong. Please try again later.");
        });
    }

    return (
        <>
            {currentUser?.id ? (
                <Navigate to = {state?.path || "/"} replace = {true} />
            ) : (          
                <div className = "login_page">
                    <div className = "loginCard loginPageCard">
                        <div className = "card_body">
                            <Row className = "m-auto">
                                <Col xs = {12} md = {5} className = "m-auto !pr-0">
                                    <div className = "login-form-container">                                        
                                        <a href = "/">
                                            <img 
                                                loading = "lazy" 
                                                src = {Logo} 
                                                alt = "Inv-X"  
                                                className = "border-logo" 
                                            />
                                        </a>

                                        <form onSubmit = {handleSubmit}>
                                            <div className = "form-group">
                                                <label htmlFor = "password">Password</label>

                                                <div className = "flex flex-row relative items-center justify-between gap-2">
                                                    <input type = {showPassword ? "text" : "password"} id = "password" name = "password" className = "form-control" placeholder = "Enter Password" onChange = {handleChange} required />
                                                    {showPassword ? (
                                                        <FaRegEyeSlash title = "Hide Password" className = "absolute cursor-pointer right-[10px]" onClick = {() => setShowPassword(!showPassword)} />
                                                    ) : (
                                                        <FaRegEye title = "Show Password" className = "absolute cursor-pointer right-[10px]" onClick = {() => setShowPassword(!showPassword)} />
                                                    )}
                                                </div>
                                            </div>

                                            <div className = "form-group">
                                                <label htmlFor = "confirmPassword">Confirm Password</label>

                                                <div className = "flex flex-row relative items-center justify-between gap-2">
                                                    <input ref = {confirmPasswordInputRef} type = {showPassword ? "text" : "password"} id = "confirmPassword" name = "confirmPassword" className = "form-control" placeholder = "Enter Confirm Password" onChange = {handleChange} required />
                                                    {showPassword ? (
                                                        <FaRegEyeSlash title = "Hide Password" className = "absolute cursor-pointer right-[10px]" onClick = {() => setShowPassword(!showPassword)} />
                                                    ) : (
                                                        <FaRegEye title = "Show Password" className = "absolute cursor-pointer right-[10px]" onClick = {() => setShowPassword(!showPassword)} />
                                                    )}
                                                </div>
                                            </div>

                                            <a href = "/admin/login" className = "text-gray-500 text-[13px] login-link text-decoration-none">
                                                Login
                                            </a>
                                            <br />

                                            {alertSuccess ? (
                                                <Alert variant = "success" className = "text-[13px]">{alertSuccess}</Alert>
                                            ) : alertError && (
                                                <Alert variant = "danger" className = "text-[13px]">{alertError}</Alert>
                                            )}

                                            <div className = "login">
                                                <button type = "submit" className = "custom_btn login_button btn m-auto">
                                                    {isLoading ? (
                                                        <div className = "d-flex justify-content-center">
                                                            <br />
                                                            <div className = "spinner-border text-info" role = "status"></div>
                                                        </div>
                                                    ) : 
                                                        'Submit'
                                                    }
                                                </button>
                                            </div>
                                        </form>                                        
                                    </div>
                                </Col>

                                <Col md = {7} className = "d-none d-md-block !pr-0">
                                    <div className = "background-login">
                                        <div className = "imageText">
                                            Fast. Reliable. <br />
                                            Tracking Service.
                                        </div>
                                        
                                        <img 
                                            loading = "lazy" 
                                            className = "bg-image-login img-fluid"
                                            src = {loginImage} 
                                            alt = "" 
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ResetPassword;