import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { styled } from '@mui/system';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import "../../css/style.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdCancel, MdOutlineDone } from "react-icons/md";
import "../../css/muiDataTable.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUp from "../helperComponents/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
//import { BsPersonFillCheck, BsPersonFillSlash } from "react-icons/bs";
const RfqRequests = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState("");

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const logoUrl = localStorage.getItem('logoUrl');
  const [showTxModal, setShowTxModal] = useState(false);
  const [txModalType, setTxModalType] = useState();
  const [txTitle, setTxTitle] = useState("");
  const [txMsg, setTxMsg] = useState("");
  const [txData, setTxData] = useState();
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  const styles = {
    container: {
      height: "500px",
      widht: "100px" // set the height of the container
    },
  };

  useEffect(() => {
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  const timeOut = () => {
    const timeId = setTimeout(() => {
      setShowTxModal(false)
    }, 2000)
    return () => {
      clearTimeout(timeId)
    }
  }
  useEffect(() => {
    const getDeadlineReasonsData = async () => {
      setIsLoading(true);
      await axios({
        method: "get",
        url: "/get_rfqs",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response, "channel Pc");

          const dynamicColumns = response?.data?.columns.filter(columnName => columnName !== 'adminResponded').map((columnName) => {
            const columnValue = response?.data?.rowData[0][columnName]; // Assuming rowData is an array of objects
            const isInteger = Number.isInteger(columnValue);

            return {
              field: columnName,
              headerName: columnName,
              width: isInteger ? 75 : 150,
              type: isInteger ? 'number' : 'string', // Assign 'number' type for integer values and 'string' type for others
            };
          });

          const actionsColumn = {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 200, // Updated minWidth to make the column narrower
            flex: 1,
            renderCell: (params) => {
              const isDisabled = params?.row?.adminResponded === 1;

              return (
                <div className="d-flex gap-3 m-auto">
                  <button
                    className="custom_btn outline_disabled_button btn btn-outline-success"
                    onClick={() => handleApprove(params.row)}
                    disabled={isDisabled}
                    style={{ width: '60px' }} // Updated width of the button
                  >
                    <BsFillCheckCircleFill
                      style={{
                        color: `${params.row.adminResponded === 1
                            ? "#CCC"
                            : "green"
                          }`,
                        fontSize: "15px",
                      }}
                    />
                  </button>
                  <button
                    className="custom_btn outline_disabled_button btn btn-outline-danger"
                    onClick={() => handleReject(params.row)}
                    disabled={isDisabled}
                    style={{ width: '60px' }} // Updated width of the button
                  >
                    <MdCancel
                      style={{
                        color: `${params.row.adminResponded === 1
                            ? "#CCC"
                            : "red"
                          }`,
                        fontSize: "15px",
                      }}
                    />
                  </button>
                </div>
              );
            },
          };

          // Separate integer columns and string columns based on their values
          const integerColumns = dynamicColumns.filter((column) => column.type === 'number');
          const stringColumns = dynamicColumns.filter((column) => column.type === 'string');

          // Combine integer columns, string columns, and the actions column
          const combinedColumns = [...integerColumns, ...stringColumns, actionsColumn];

          setColumns(combinedColumns);
          setRows(response?.data?.rowData);
          console.log("columns", columns)
          console.log("ROWS", rows)
          setIsLoading(false);
        })

        .catch((error) => {
          setAlertError("something went wrong");
          setIsLoading(false);
          console.log("Get Filters Api: ", error);
        });
    };

    getDeadlineReasonsData();

  }, [])






  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };
  const handleApprove = async (row) => {
    setIsLoading(true);
    await axios({
      method: "post",
      url: "/approve_eRFQ",
      data: {
        'rfqId': row?.ID,
      }
    })
      .then((response) => {
        console.log(response, "channel Pc");
        const dynamicColumns = response?.data?.columns
          .filter(columnName => columnName !== 'adminResponded')
          .map((columnName) => {
            const columnValue = response?.data?.rowData[0][columnName];
            const isInteger = Number.isInteger(columnValue);
            return { field: columnName, headerName: columnName, width: isInteger ? 75 : 150 };
          });

        const actionsColumn = {
          field: 'actions',
          headerName: 'Actions',
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            const isDisabled = params?.row?.adminResponded === 1;

            return (
              <div className="d-flex gap-3 m-auto">
                <button
                  className="custom_btn outline_disabled_button btn btn-outline-success"
                  onClick={() => handleApprove(params.row)}
                  disabled={isDisabled}
                  style={{ width: '80px' }}
                >
                  <BsFillCheckCircleFill
                    style={{
                      color: `${params.row.adminResponded === 1
                          ? "#CCC"
                          : "green"
                        }`,
                      fontSize: "15px",
                    }}
                  />
                </button>
                <button
                  className="custom_btn outline_disabled_button btn btn-outline-danger"
                  onClick={() => handleReject(params.row)}
                  disabled={isDisabled}
                  style={{ width: '80px' }}
                >
                  <MdCancel
                    style={{
                      color: `${params.row.adminResponded === 1
                          ? "#CCC"
                          : "red"
                        }`,
                      fontSize: "15px",
                    }}
                  />
                </button>
              </div>
            );
          },
        };


        setColumns([...dynamicColumns, actionsColumn]);
        setRows(response?.data?.rowData);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        setAlertError("Process Failed!");
        setTxModalType(3);
        setTxMsg("Process Failed");
      });
  }

  const handleReject = async (row) => {
    setIsLoading(true);
    await axios({
      method: "post",
      url: "/reject_eRFQ",
      data: {
        'rfqId': row?.ID,
      }
    })
      .then((response) => {
        console.log(response, "channel Pc");
        const dynamicColumns = response?.data?.columns
          .filter(columnName => columnName !== 'adminResponded')
          .map((columnName) => {
            const columnValue = response?.data?.rowData[0][columnName];
            const isInteger = Number.isInteger(columnValue);
            return { field: columnName, headerName: columnName, width: isInteger ? 75 : 150 };
          });

        const actionsColumn = {
          field: 'actions',
          headerName: 'Actions',
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            const isDisabled = params?.row?.adminResponded === 1;

            return (
              <div className="d-flex gap-3 m-auto">
                <button
                  className="custom_btn outline_disabled_button btn btn-outline-success"
                  onClick={() => handleApprove(params.row)}
                  disabled={isDisabled}
                  style={{ width: '80px' }}
                >
                  <BsFillCheckCircleFill
                    style={{
                      color: `${params.row.adminResponded === 1
                          ? "#CCC"
                          : "green"
                        }`,
                      fontSize: "15px",
                    }}
                  />
                </button>
                <button
                  className="custom_btn outline_disabled_button btn btn-outline-danger"
                  onClick={() => handleReject(params.row)}
                  disabled={isDisabled}
                  style={{ width: '80px' }}
                >
                  <MdCancel
                    style={{
                      color: `${params.row.adminResponded === 1
                          ? "#CCC"
                          : "red"
                        }`,
                      fontSize: "15px",
                    }}
                  />
                </button>
              </div>
            );
          },
        };

        setColumns([...dynamicColumns, actionsColumn]);
        setRows(response?.data?.rowData);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        setAlertError("Process Failed!");
        setTxModalType(3);
        setTxMsg("Process Failed");
      });
  }








  const filteredRows = searchQuery
    ? rows?.filter((row) =>
      Object.values(row).some((value) =>
        String(value)?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )
    )
    : rows;


  return (
    <div className="create_items">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                  }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>
              <div
                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                  }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}

                <div
                  className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                    }`}
                >
                  <div className="content_panel container-fluid row_gap">
                    <h3 className="pageName_heading">e-RFQ Requests</h3>
                    <div className="row justify-content-between">
                      <div className="col-md-5">
                        <input
                          type="text"
                          placeholder="Search"
                          className="input py-[1.5em] px-[3em]"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>

                    </div>
                    <div style={styles.container} className="customCard">
                      <GridContextProvider>
                        <DataGrid
                          rows={filteredRows ? filteredRows : []} columns={columns}
                          getRowId={(row) => row['ID']}
                          loading={isLoading}
                          rowHeight={40}
                          // rowsPerPageOptions={[5]}
                          pagination
                          density="compact"
                          disableExtendRowFullWidth={true}
                          disableSelectionOnClick={false}
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                          }
                        />
                      </GridContextProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RfqRequests;
