import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { styled } from '@mui/system';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import "../../css/style.css";
import "../../css/muiDataTable.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUp from "../helperComponents/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import CategoryPieChart from "./CategoryPieChart";
import CustomDownloader from "./CustomDownloader";
//import { BsPersonFillCheck, BsPersonFillSlash } from "react-icons/bs";
const CategoryReport = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();
    const [order, setOrder] = useState("top");

    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState("All Categories");
    const [values, setValues] = useState([]);
    const [alertError, setAlertError] = useState("");
    const [startDate, setStartDate] = useState(null);  // Initialize with your date logic
    const [endDate, setEndDate] = useState(null);

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );
    const styles = {
        container: {
            height: "500px",
            widht: "100px" // set the height of the container
        },
    };

    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    useEffect(() => {
        axios
            .get("/get_categories")
            .then((response) => {
                const valuesArr = response.data.map(
                    (category) => category.categoryName
                );
                if (!valuesArr.includes("All Categories")) {
                    valuesArr.unshift("All Categories");  // Adding "All Categories" at the start
                }
                setValues(valuesArr);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            let url;
            if (order === 'top') {
                url = `/dashboard/topsellingitemsbycategory?&category=${category}&startDate=${startDate}&endDate=${endDate}`;
            }
            else {
                url = `/dashboard/leastsellingitemsbycategory?&category=${category}&startDate=${startDate}&endDate=${endDate}`;
            }



            try {
                const response = await axios.get(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                });

                const apiRows = response.data.SellingItems;
                setRows(apiRows);

                if (apiRows.length > 0) {
                    const exampleRow = apiRows[0];

                    // Define your desired column order here
                    const columnOrder = ['ID', 'Item Name']; // Add other keys in desired order

                    // Create columns based on the defined order
                    const dynamicColumns = columnOrder.map((key) => ({
                        field: key,
                        headerName: key,
                        width: key === 'ID' ? 100 : 180,  // Custom width for 'ID', default 180 for others
                        type: typeof exampleRow[key] === 'number' ? 'number' : 'string',
                        align: typeof exampleRow[key] === 'number' ? 'right' : 'left'
                    }));

                    // Optionally, check for any keys in exampleRow not in columnOrder and add them
                    Object.keys(exampleRow).forEach((key) => {
                        if (!columnOrder.includes(key)) {
                            dynamicColumns.push({
                                field: key,
                                headerName: key,
                                width: key === 'ID' ? 90 : 180, // Default width for additional columns
                                type: typeof exampleRow[key] === 'number' ? 'number' : 'string',
                                align: typeof exampleRow[key] === 'number' ? 'right' : 'left'
                            });
                        }
                    });

                    setColumns(dynamicColumns);
                }


            } catch (error) {
                console.log("Error fetching data: ", error);
                // Handle error appropriately here
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate, category, order]);


    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };






    return (
        <div className="create_items">
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header
                            getCollapseSidebar={getCollapseSidebar}
                            getCollapseDropdown={getCollapseDropdown}
                            logOut={props.logOut}
                        />
                    )}

                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}

                                <div
                                    className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                                        }`}
                                >
                                    <div className="content_panel container-fluid row_gap">
                                        <div className="d-flex justify-content-between align-items-center custom_section">
                                            <h3 className="pageName_heading m-0">Sales by Category Report</h3>

                                        </div>


                                        <div className="d-sm-flex justify-content-center">

                                            <div style={{ flexBasis: '50%' }}>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="input-label">Start Date</label>
                                                        <input type="date" className="input" style={{ height: '2em' }} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="input-label">End Date</label>
                                                        <input type="date" className="input" style={{ height: '2em' }} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="input-label">Select Category</label>
                                                        <select className="styled-dropdown" onChange={(e) => setCategory(e.target.value)}>
                                                            {values.map((category, index) => (
                                                                <option key={index} value={category}>{category}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="input-label">Order by</label>
                                                        <select className="styled-dropdown" onChange={(e) => setOrder(e.target.value)}>
                                                            <option value="top">Top to Least</option>
                                                            <option value="least">Least to Top</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ flexBasis: '50%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <CategoryPieChart startDate={startDate} endDate={endDate} />
                                            </div>
                                        </div>







                                        <div style={styles.container} className="customCard">
                                            <GridContextProvider>
                                                <DataGrid
                                                    rows={rows ? rows : []} columns={columns}
                                                    getRowId={(row) => row['ID']}
                                                    loading={isLoading}
                                                    rowHeight={40}
                                                    // rowsPerPageOptions={[5]}
                                                    pagination
                                                    density="compact"
                                                    disableExtendRowFullWidth={true}
                                                    disableSelectionOnClick={false}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                                                    }
                                                />
                                            </GridContextProvider>
                                        </div>
                                        {rows && rows.length > 0 && (
                
                <CustomDownloader
                    rows={rows}
                    title={'Category Report'}
                />
                
        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CategoryReport;
