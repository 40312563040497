import React from "react";
import { useState, useEffect } from "react";
import Header from "../header/Header";
import SearchHeader from "../header/SearchHeader";
import Footer from "../footer/Footer";
import { Modal } from "react-bootstrap";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useUserInput } from '../checkout/UserInputContext';
import axios from "axios";
import "../../css/style.css";

import { loadStripe } from "@stripe/stripe-js";
// const stripePromise = loadStripe(
//   "pk_test_51Nu7BzFUSASGeF5HXfYSjWNsRc7NPfPl4uOxbsGYAKhNtSYYRdRqRrBcI5lRfLLxLkOE7D6hCYvZ0pcJnc14tcr200aCFEaLWz"
// );
// end stripe

const PaymentByCreditCard = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [favourites, setFavourites] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const { userInput, setUserInput } = useUserInput();
  const [allProducts, setAllProducts] = useState([]);
  const navigate = useNavigate();
  // const location = useLocation();
  // const apiData = location.state;

  const id = currentUser?.id;
  const [fetchedInvoiceId, setFetchedInvoiceId] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [buyerCommission, setBuyerCommission] = useState(0);
  const [VAT, setVAT] = useState(0);
  const [apiData, setApiData] = useState({
    products: [],
    subTotal: 0,
    VAT: 0,
    buyerCommission: 0,
    finalTotal: 0,
    gProducts: [],
    cProducts: [],
  });

  // ============================== stripe
  // const [clientSecret, setClientSecret] = useState("");
  const [pubKey, setPubKey] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the value of session_id from the query parameters
  const session_id = queryParams.get("session_id");
  // states
  const [checkoutResponse, setCheckoutResponse] = useState(null);

  // get pub key
  useEffect(() => {
    fetch("/config")
      .then((result) => result.json())
      .then((data) => {
        setPubKey(data?.publicKey);
      });
  }, []);

 

useEffect(() => {
  async function fetchCheckoutSessionDetails() {
    try {
      const res = await fetch("/retrieve-checkout-session-details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: session_id }),
      });
      const data = await res.json();
      
      setCheckoutResponse(data?.response);
      
      if (data?.response?.payment_status === "paid") {
        setShowModal(true);
        try {
          const response = await axios.post('/update-payment-status', {
            order_id: data.response.metadata.order_id,
            session_id: session_id
          });
          if (response.data.status === 'success') {
            console.log('Payment status updated successfully.');
            try {
              axios.post('/clear_cart',{"userId":currentUser.id}).then(response => {
                console.log('Response from server:', response.data);
              }).catch(error => {
                console.log('Axios error:', error);
              });
              
              console.log('Quantity revert API called.');
            } catch (error) {
              console.log('Error reverting quantity:', error);
            }
          }
        } catch (error) {
          console.log('Error updating payment status:', error);
        }
      } else {
        console.log('Entering else block, attempting to revert quantity.');
      
        try {
          axios.post('/quantity_revert', allProducts).then(response => {
            setShowModal2(true);
            console.log('Response from server:', response.data);
          }).catch(error => {
            console.log('Axios error:', error);
          });
          
          console.log('Quantity revert API called.');
        } catch (error) {
          console.log('Error reverting quantity:', error);
        }
        
      }
      
      
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  }

  if (session_id !== null && session_id !== "" && allProducts.length > 0) {
    fetchCheckoutSessionDetails();
  }
}, [session_id,allProducts]);


  async function redirectToPayment() {
    try {
      const lockResponse = await axios.post('/quantity_lock', allProducts);
      // Explicitly check for success is not required here, because a failure would go to the catch block
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
        return;
      } else {
        toast.error("An error occurred: " + error.message);
        return;
      }
    }
    // create invoice and purchase order first
    
    await sendPurchaseRequests(apiData?.gProducts);
    const fetch_invoice_id = await sendInvoiceRequests(apiData?.cProducts);
    // Get Checkout Session ID
    loadStripe(pubKey)
      .then((stripe) => {
        console.log("PRICE", apiData?.finalTotal)
        // Fetch the Stripe Checkout session ID from your backend
        fetch("/create-checkout-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            t_price: apiData?.finalTotal,
            email: currentUser?.email,
            order_id: fetch_invoice_id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // Redirect to the Stripe Checkout page with the session ID
            stripe
              .redirectToCheckout({
                sessionId: data.sessionId,
              })
              .then((result) => {
                if (result.error) {
                  console.error(
                    "Error redirecting to Stripe Checkout:",
                    result.error
                  );
                }
              });
          })
          .catch((error) => {
            console.error("Error fetching Checkout session ID:", error);
          });
      })
      .catch((error) => {
        console.error("Error loading Stripe:", error);
      });
  }
  // ============================== end stripe

  // fetch cart data
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await axios.post("/cartbySeller", {
          userId: currentUser.id,
        });

        const responseData = response.data;
        if (responseData) {
          let allProducts = [];
          let total = 0;
          let vatTotal = 0;
          let commissionTotal = 0;

          responseData.subcarts.forEach((subcart) => {
            allProducts = allProducts.concat(subcart.products);

            total += parseFloat(subcart.subcart_total);
            vatTotal += subcart.subcart_vat;
            commissionTotal += subcart.subcart_commission;
          });

          //setProducts(allProducts);
          setSubTotal(total);
          setVAT(vatTotal);
          setBuyerCommission(commissionTotal);
          const fullTotal = total + vatTotal + commissionTotal;
          setFinalTotal(fullTotal);
          const groupedProducts = groupProductsBySellerId(
            responseData.subcarts
          );
          const groupedProducts2 = groupProductsByCompanyId(
            responseData.subcarts
          );
          setAllProducts(allProducts);
          console.log("ALL products", allProducts)
          setApiData({
            products: allProducts,
            subTotal: total,
            VAT: vatTotal,
            buyerCommission: commissionTotal,
            finalTotal: fullTotal,
            gProducts: groupedProducts,
            cProducts: groupedProducts2,
          });
        }
      } catch (error) {
        console.log("Error fetching cart data:", error);
      }
    };

    
    fetchCartData();
    
  }, [currentUser?.id, session_id]);

  const groupProductsBySellerId = (subcarts) => {
    const groupedProducts = subcarts.map((subcart) => {
      const products = subcart.products;
      const cartData = {
        seller_id: products[0].sellerId,
        sellerName: products[0].sellerName,
        company_id: products[0].company_id,
        company: products[0].company,
        subcart_commission: subcart.subcart_commission,
        subcart_total: subcart.subcart_total,
        subcart_vat: subcart.subcart_vat,
        products,
      };
      return cartData;
    });

    return groupedProducts;
  };

  const groupProductsByCompanyId = (subcarts) => {
    const groupedProducts = subcarts.map((subcart) => {
      const products = subcart.products;
      const cartData = {
        company_id: products[0].company_id,
        company: products[0].company,
        subcart_commission: subcart.subcart_commission,
        subcart_total: subcart.subcart_total,
        subcart_vat: subcart.subcart_vat,
        products,
      };
      return cartData;
    });

    return groupedProducts;
  };

  async function sendPurchaseRequests(products) {
    const apiUrl = "/make_purchaseorder";

    for (const product of products) {
      const companyId = product.company_id;
      const sellerId = product.seller_id;
      const items = product.products.map((p) => ({
        itemId: p.id,
        itemPrice: parseFloat(p.price),
        itemQuantity: p.quantity,
        totalPrice: parseFloat(p.price) * p.quantity,
        type: p.type,
      }));

      const requestData = {
        sellerId: sellerId,
        companyId: companyId,
        buyerId: id, // Replace with the actual buyerId
        subtotal: product.subcart_total,
        shipping: 0, // Replace with the actual shipping value
        VAT: product.subcart_vat,
        commission: product.subcart_commission,
        total:
          parseFloat(product.subcart_total) +
          product.subcart_vat +
          product.subcart_commission,
        items: items,
      };

      try {
        const response = await axios.post(apiUrl, requestData);
        console.log(
          `Purchase request for company ${companyId} sent successfully`,
          response.data
        );
      } catch (error) {
        console.error(
          `Error sending purchase request for company ${companyId}`,
          error.response.data
        );
      }
    }
  }

  async function sendInvoiceRequests(products) {
    try {
      const invoiceResponse = await axios.post("/make_invoice", {
        buyer: id,
        subtotal: subTotal,
        VAT: VAT,
        commission: buyerCommission,
        total: finalTotal,
      });

      const invoiceId = invoiceResponse.data.invoiceId;
      console.log(invoiceId);
      setFetchedInvoiceId(invoiceId);

      for (const company of products) {
        const companyId = company.company_id;
        const companySubTotal = company.subcart_total;
        const companyVAT = company.subcart_vat;
        const companyCommission = company.subcart_commission;
        const companyTotal =
          parseFloat(companySubTotal) +
          parseFloat(companyVAT) +
          parseFloat(companyCommission);

        const items = company.products.map((product) => {
          return {
            itemId: product.id,
            itemPrice: product.price,
            itemQuantity: product.quantity,
            type: product.type,
            totalPrice:
              parseFloat(product.price) * parseFloat(product.quantity),
          };
        });

        try {
          const companyInvoiceResponse = await axios.post(
            "/make_companywiseinvoice",
            {
              invoiceId: invoiceId,
              companyId: companyId,
              buyerId: id,
              subtotal: companySubTotal,
              shipping: 0,
              VAT: companyVAT,
              commission: companyCommission,
              total: companyTotal,
              items: items,
            }
          );

          console.log(
            `Company-wise invoice for company ${companyId} created successfully`,
            companyInvoiceResponse.data
          );
        } catch (error) {
          console.error(
            `Error while creating company-wise invoice for company ${companyId}`,
            error.response.data
          );
        }
      }
      return invoiceId;
    } catch (error) {
      console.error("Error while creating invoice:", error.response.data);
    }
  }

  function handleClick(selected) {
    navigate("/product-detail/123", { state: selected });
  }

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/");
  };
  const handleCloseModal2 = () => {
    setShowModal2(false);
    navigate("/");
  };

  const addToWishList = (productName) => {
    let tempFavourites = [...favourites];
    const index = tempFavourites.indexOf(productName);

    if (index === -1) {
      tempFavourites.push(productName);
      toast.success(productName + " has been added to your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    } else {
      tempFavourites.splice(index, 1);
      toast.success(productName + " has been removed from your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    }

    setFavourites(tempFavourites);
  };

  return (
    <>
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          <SearchHeader wishListCount={favourites.length} />
          <Header logOut={props.logOut} />

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />

          <div className="container main-container app_container">
            <h1
              className="text-gray-600 font-bold text-[25px] inline-block float-left"
              style={{ color: "#0d6efd" }}
            >
              PAYMENT
            </h1>
            <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
              <ul className="page-hierarchy">
                <li>
                  <a href="/cart">Your Cart</a>
                </li>
                <li>
                  <a href="/checkout">Check Out</a>
                </li>
                <li>
                  <a href="/payment-method">Payment</a>
                </li>
              </ul>
            </div>
            <br />
            <hr style={{ width: "100%" }} />
            <br />

            <div className="row payment_product_details">
              <div className="col-12 col-lg-6">
                <label className="label" style={{ paddingLeft: "0" }}>
                  <span className="font-bold text-[15px]">
                    SHIPPING INFORMATION
                  </span>
                  <a
                    href="#/"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    style={{ float: "right" }}
                  >
                    Change
                  </a>
                </label>

                <div className="shipping-container">
                <h1>Contact</h1>
                                    <span>{userInput.email_phone}</span>
                                    <hr style = {{ margin: '0.5rem 0' }} />
                                    <h1>Ship To</h1>
                                    <span>{userInput.address} - {userInput.city}, {userInput.country}</span>
                                    <hr style = {{ margin: '0.5rem 0' }} />
                                    <h1>Estimated Cost</h1>
                                    <span>TBC <p className = "text-muted"></p></span>
                </div>
                <br />

                <label className="label" style={{ paddingLeft: "0" }}>
                  <span className="font-bold text-[15px]">PAYMENT</span>
                </label>
                <p
                  className="text-muted text-[14px] methodText"
                  style={{ fontWeight: "600" }}
                >
                  Credit Card
                </p>

                {/* <form onSubmit={handleSubmit}>
                  <div className="row w-100">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="tel"
                          name="cardNumber"
                          value={cardNumber}
                          placeholder="Card Number"
                          onChange={(event) => handleChange(event)}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.cardNumber}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row w-100 justify-content-between"
                    style={{ marginTop: "0.8em" }}
                  >
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="cardName"
                          placeholder="Name on Card"
                          onChange={(event) => handleChange(event)}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.cardName}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row w-100 justify-content-between"
                    style={{ marginTop: "0.8em" }}
                  >
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          type="tel"
                          name="expiryDate"
                          value={expiryDate}
                          placeholder="Expiration (MM/YY)"
                          onChange={(event) => handleChange(event)}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.expiryDate}</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          type="tel"
                          name="cvc"
                          value={cvc}
                          placeholder="Security Code"
                          onChange={(event) => handleChange(event)}
                          className="input form-control"
                        />
                        <div className="text-danger">
                          <small>{errors.cvc}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-gray-600 checkbox-container"
                    style={{ color: "#0d6efd" }}
                  >
                    <input
                      type="checkbox"
                      className="checkbox checkbox-sm mt-3"
                      name="save-information"
                    />
                    <label htmlFor="save-information">Save Information</label>
                  </div>
                  <div className="row">
                    <div className="col-6" style={{ marginTop: "1em" }}>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: "100%", fontSize: "13px" }}
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-6" style={{ marginTop: "1em" }}>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ width: "100%", fontSize: "13px" }}
                      >
                        Pay Now
                      </button>
                    </div>
                  </div>
                </form> */}

                {/* default stripe components */}
                {/* <div>
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm />
                    </Elements>
                  )}
                </div> */}
                {/* END default stripe components */}

                {/* checkout button */}
                <div className="row">
                  <div className="col-12" style={{ marginTop: "1em" }}>
                    <button
                      onClick={redirectToPayment}
                      disabled={!pubKey}
                      className="btn btn-primary"
                      style={{ width: "100%", fontSize: "13px" }}
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
                {/* end checkout button */}
              </div>

              <div
                className="col-12 col-lg-6 products_container"
                style={{ backgroundColor: "#F1F1F1" }}
              >
                <div
                  className="scrollable-container"
                  style={{
                    maxHeight: "500px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div className="row">
                    {apiData?.gProducts.map((companyProducts, companyIndex) => (
                      <div className="col-12" key={companyIndex}>
                        <div className="card my-3">
                          <div className="card-body">
                            <h4>
                              {companyProducts.company}(
                              {companyProducts.sellerName})
                            </h4>
                            {companyProducts.products.map((product, index) => (
                              <div
                                className="product-container d-flex justify-content-between align-items-center"
                                key={index}
                              >
                                <div className="d-flex">
                                  <div style={{ position: "relative" }}>
                                    <img loading="lazy" 
                                      src={`${product.image}`}
                                      alt=""
                                      className="h-[60px] w-[60px]"
                                    />
                                    <div
                                      className="quantity"
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      {product.quantity}
                                    </div>
                                  </div>
                                  <div className="ml-3">
                                    <h1 className="text-[13px] font-bold">
                                      {product.name}
                                    </h1>
                                    {/* <h1 className="text-[13px] font-bold">
                                          Industry: {product.itemIndustry}
                                        </h1> */}
                                    <h1 className="text-[13px] font-bold">
                                      Price: {product.price}{" "}
                                      {currentUser.currency}
                                    </h1>
                                  </div>
                                </div>
                                <div>
                                  <h1
                                    className="text-[13px] font-bold"
                                    style={{ color: "#0d6efd" }}
                                  >
                                    {(product.price * product.quantity).toFixed(
                                      2
                                    )}{" "}
                                    {currentUser.currency}
                                  </h1>
                                </div>
                              </div>
                            ))}
                            <div className="text-right">
                              <h1 className="text-[13px] font-bold">
                                Sub Total: {companyProducts.subcart_total}{" "}
                                {currentUser.currency}
                              </h1>
                              <h1 className="text-[13px] font-bold">
                                Shipping: EXW
                              </h1>
                              <h1 className="text-[13px] font-bold">
                                Insurance: TBC
                              </h1>
                              <h1 className="text-[13px] font-bold">
                                Inspection: TBC
                              </h1>
                              <h1 className="text-[13px] font-bold">
                                VAT: {companyProducts.subcart_vat.toFixed(2)}{" "}
                                {currentUser.currency}
                              </h1>
                              <h1 className="text-[13px] font-bold">
                                Commission:
                                {companyProducts.subcart_commission.toFixed(
                                  2
                                )}{" "}
                                {currentUser.currency}
                              </h1>
                              <h1 className="text-[13px] font-bold">
                                Total:
                                {(
                                  parseFloat(
                                    companyProducts.subcart_commission.toFixed(
                                      2
                                    )
                                  ) +
                                  parseFloat(
                                    companyProducts.subcart_vat.toFixed(2)
                                  ) +
                                  parseFloat(companyProducts.subcart_total)
                                ).toFixed(2)}{" "}
                                {currentUser.currency}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <hr />
                  <h1 className="text-[12px] font-bold">
                    Sub Total
                    <span className="text-gray-500" style={{ float: "right" }}>
                      {apiData?.subTotal} {currentUser.currency}
                    </span>
                  </h1>
                  <h1 className="text-[12px] font-bold">
                    Shipping
                    <span
                      className="ml-[106px] text-gray-500"
                      style={{ float: "right" }}
                    >
                      EXW
                    </span>
                  </h1>
                  <h1 className="text-[12px] font-bold">
                    Insurance
                    <span
                      className="ml-[106px] text-gray-500"
                      style={{ float: "right" }}
                    >
                      TBC
                    </span>
                  </h1>
                  <h1 className="text-[12px] font-bold">
                    Inspection
                    <span
                      className="ml-[106px] text-gray-500"
                      style={{ float: "right" }}
                    >
                      TBC
                    </span>
                  </h1>
                  <h1 className="text-[12px] font-bold">
                    VAT
                    <span
                      className="ml-[106px] text-gray-500"
                      style={{ float: "right" }}
                    >
                      {apiData?.VAT.toFixed(2)} {currentUser.currency}
                    </span>
                  </h1>
                  <h1 className="text-[12px] font-bold">
                    Commission
                    <span
                      className="ml-[106px] text-gray-500"
                      style={{ float: "right" }}
                    >
                      {apiData?.buyerCommission.toFixed(2)}{" "}
                      {currentUser.currency}
                    </span>
                  </h1>
                  <br />
                  <h1 className="text-[14px] font-bold">
                    TOTAL
                    <span
                      className="ml-[122px] text-gray-500"
                      style={{ float: "right" }}
                    >
                      {apiData?.finalTotal.toFixed(2)} {currentUser.currency}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <br />
            <hr />

            <h1 className="related_products_heading text-gray-600 font-bold text-[25px] inline-block float-left mt-[13px]">
              RELATED
            </h1>
            {/* <div className = "w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
                            <div className = "flex flex-row space-x-2">
                                <div className = "bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                                    <svg xmlns = "http://www.w3.org/2000/svg" width = "16" height = "16" fill = "currentColor" className = "bi bi-arrow-left" viewBox = "0 0 16 16"> <path fillRule = "evenodd" d = "M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /> </svg>
                                </div>
                                <div className = "bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                                    <svg style = {{ color: 'rgb(78, 77, 70)' }} xmlns = "http://www.w3.org/2000/svg" width = "16" height = "16" fillRule = "currentColor" className = "bi bi-arrow-right" viewBox = "0 0 16 16"> <path fillRule = "evenodd" d = "M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill = "#4e4d46"></path> </svg>
                                </div>
                            </div>
                        </div> */}
            <br />
            <br />
            <br />
            <br />
            <div className="row related_products">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("Raw Materials")}
                  >
                    <img loading="lazy" 
                      src={require("../../images/image002.jpg")}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${
                              favourites.indexOf("ISO Propyl Alcohol") > -1
                                ? "900"
                                : ""
                            }`,
                          }}
                          onClick={() => addToWishList("ISO Propyl Alcohol")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("Packaging Materials")}
                  >
                    <img loading="lazy" 
                      src={require("../../images/image001.png")}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Wood</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${
                              favourites.indexOf("Wood") > -1 ? "900" : ""
                            }`,
                          }}
                          onClick={() => addToWishList("Wood")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("")}
                  >
                    <img loading="lazy" 
                      src="https://placeimg.com/400/225/arch"
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Sample Product 1</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${
                              favourites.indexOf("Sample Product 1") > -1
                                ? "900"
                                : ""
                            }`,
                          }}
                          onClick={() => addToWishList("Sample Product 1")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure
                    style={{ margin: "0", height: "200px", cursor: "pointer" }}
                    onClick={() => handleClick("")}
                  >
                    <img loading="lazy" 
                      src="https://placeimg.com/400/225/arch"
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                      <input
                        type="radio"
                        name="rating-6"
                        className="mask mask-star-2 bg-orange-400"
                      />
                    </div>
                    <div
                      className="flex flex-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h2 className="text-[14px] mt-1">Sample Product 2</h2>
                      <div className="rating w-30px" style={{ float: "right" }}>
                        <input
                          type="radio"
                          name="rating-3"
                          className="whishlist_icon hover:bg-red-600"
                          style={{
                            fontWeight: `${
                              favourites.indexOf("Sample Product 2") > -1
                                ? "900"
                                : ""
                            }`,
                          }}
                          onClick={() => addToWishList("Sample Product 2")}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                  </div>
                  <div className="product_price" style={{ padding: "0 1em" }}>
                    <p className="text-[14px]">
                      Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                      consectetur.
                    </p>
                    <br />
                    <h1
                      className="text-[20px] font-bold mb-3"
                      style={{ color: "#0d6efd" }}
                    >
                      $225.50
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            {(session_id !== null) | (session_id !== "") && (
              <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header
                  closeButton={handleCloseModal}
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="confirmationMessage">
                  <BsCheckCircle className="checkIcon" />
                  <br />
                  <span>
                    Your order has been {checkoutResponse?.payment_status}.
                    <br />
                    <small style={{ color: "#F75F5C", fontWeight: "600" }}>
                      Please visit your portal to track the status of dispatch
                      and delivery.
                    </small>
                  </span>
                  <h3>Order Number: {checkoutResponse?.metadata?.order_id}</h3>
                  <br />
                </Modal.Body>
              </Modal>
            )}
            {(session_id !== null) | (session_id !== "") && (
              <Modal centered show={showModal2} onHide={handleCloseModal2}>
                <Modal.Header
                  closeButton={handleCloseModal2}
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="confirmationMessage">
  <BsXCircle className="checkIcon" style={{ color: 'red' }} />
  <br />
  <span>
    Your order has been cancelled.
    <br />
  </span>
  <h3>Payment Cancelled</h3>
  <br />
</Modal.Body>

              </Modal>
            )}

            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default PaymentByCreditCard;
