import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import TextField from '@mui/material/TextField'
import { useNavigate, Navigate } from "react-router-dom";
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "./Tooltip";
import { Button } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import "../../css/style.css";
import "../../css/inventory.css";
import { Category } from "@mui/icons-material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { toast } from "react-toastify";





const CreateLot = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const currentDate = new Date();
  const formattedDate1 = currentDate.toISOString().split('T')[0];
  const formattedTime1 = currentDate.toTimeString().split(' ')[0].slice(0, 5); // Only HH:MM

  const id = currentUser.id;
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [columns, setColumns] = useState([]);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [selectedRowDetails, setSelectedRowDetails] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [limit, setLimit] = useState(null);
  const [image, setImage] = useState(require("../../images/no-image.jpg"));
  const [images, setImages] = useState([]);
  const [lot, setLot] = useState({});
  const [files, setFiles] = useState([]);
  const [docName, setDocName] = useState([]);
  const [types, setTypes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const navigate = useNavigate();
  const imageInput = useRef();
  const [values, setValues] = useState([]);
  const [firstRowAddress, setFirstRowAddress] = useState(null);
  const styles = {
    container: {
      height: "500px",
      widht: "100px" // set the height of the container
    },
  };



  const buttonStyle = {
    backgroundColor: '#0d70a5', // Bootstrap primary blue
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    marginLeft: '5px',
    marginRight: '5px',
  };
  const buttonStyle2 = {
    backgroundColor: '#4ca9d6', // Lighter blue color
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    marginLeft: '5px',
    marginRight: '5px',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const quantityStyle = {
    width: '40px',  // Adjust this value as per your requirement
    textAlign: 'center',
    marginRight: '5px',
    marginLeft: '5px',
    backgroundColor: "white"
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    axios
      .get("/get_categories")
      .then((response) => {
        console.log(response.data);
        const valuesArr = response.data.map(
          (category) => category.categoryName
        );
        setValues(valuesArr.slice(1));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/get_documentTypes")
      .then((response) => {
        console.log(response.data);
        const newArr = response.data.map((type) => type.documentType);
        setTypes(newArr);
      })
      .catch((error) => {
        console.log(error);
      });
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  useEffect(() => {
    // Ensure currentUser and currentUser.id are available
    if (!currentUser || !currentUser.id) {
        
        return;
    }

    const fetchLimit = async () => {
        try {
            // Replace with your actual API endpoint
            const response = await axios.get(`/getImageLimit?userId=${currentUser.id}`);
            setLimit(response.data.imageLimit);
            
        } catch (err) {
            // Handle error (e.g., API not reachable, user not found, etc.)
            
            
        }
    };

    fetchLimit();
}, [currentUser]);
  useEffect(() => {
    setIsLoading(true)
    const tempColumns = [
      {
        field: "InventoryNumber",
        headerName: "Inventory #",
        flex: 1,
        align: 'right',
      },
      {
        field: "Name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "Description",
        headerName: "Description",
        flex: 1,
        valueGetter: (params) => params.row.Description || "",
        cellClassName: "wrap-text",
      },
      {
        field: "address",
        headerName: "Address",
        flex: 1,
        valueGetter: (params) => params.row.address || "",
        cellClassName: "wrap-text",
      },
    
      {
        field: "itemPrice",
        headerName: "Price",
        flex: 1,
        align: 'right',
      },
      {
        field: "alreadyPublished",
        headerName: "Published Quantity",
        flex: 1,
        align: 'right',
        width: 150,
        minWidth: 150
      },
      {
        field: "alreadyInLots",
        headerName: "Quantity in Lots",
        flex: 1,
        align: 'right',
        width: 150,
        minWidth: 150
      },
      {
        field: "RemainingQuantity",
        headerName: "Available Quantity",
        flex: 1,
        align: 'right',
        width: 150,
        minWidth: 150
      },
      {
        field: 'lotQuantity',
        headerName: 'Quantity To Add',
        flex: 2,
        editable: false,
        align: 'center',
        renderCell: (params) => (
          <div style={containerStyle}>
            <button style={buttonStyle2} onClick={(e) => handleDecrease(params, e)}> - </button>
            <input
              type="number"
              min="0"
              value={params.getValue(params.id, 'lotQuantity') || ''}
              placeholder="Enter"
              onChange={(e) => handleChange(params, e)}
              style={quantityStyle}
            />
            <button style={buttonStyle} onClick={(e) => handleIncrease(params, e)}> + </button>
          </div>
        ),

      }




    ];

    axios.get(`/items/${id}`)
      .then((res) => {
        let data = res.data.items;
        console.log(data);

        data.forEach((row) => {
          if (row.ExpiryDate) {
            row.ExpiryDate = moment(new Date(row.ExpiryDate)).format(
              "DD MMM YYYY"
            );
            row.Shipping = moment(new Date(row.ExpiryDate)).format(
              "DD MMM YYYY"
            );
            //row.lotQuantity = parseInt(row.lotQuantity);
          }
        });
        setIsLoading(false)
        setColumns(tempColumns);
        setRows(data);
        setFilteredRows(data);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });

    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  const handleCellClick = (params) => {
    const clickedColumn = params.field;
    const clickedRowId = params.id;
  
    // Exclude the specific column from triggering row selection
    if (clickedColumn !== 'excludedColumnName') {
      if (selected.includes(clickedRowId)) {
        setSelected((prevSelected) => prevSelected.filter((id) => id !== clickedRowId));
      } else {
        setSelected((prevSelected) => [...prevSelected, clickedRowId]);
      }
    }
  };
  const handleIncrease = (params, event) => {
    event.stopPropagation();
    console.log("Clicked increase for params.id:", params.id);
    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, lotQuantity: row.lotQuantity + 1 };
        } else {
          return row;
        }
      });
    });
  }


  const handleDecrease = (params, event) => {
    event.stopPropagation();
    console.log("Clicked decrease for params.id:", params.id);
    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id && row.lotQuantity > 0) {
          return { ...row, lotQuantity: row.lotQuantity - 1 };
        } else {
          return row;
        }
      });
    });
  }


  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };
  const handleChange = (params, event) => {
    event.stopPropagation();

    const inputVal = event.target.value;
    let newQuantity = parseInt(inputVal, 10);

    if (inputVal === "") {
      newQuantity = 0;
    }

    if (isNaN(newQuantity) || newQuantity < 0) return; // Ensure valid and non-negative input

    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, lotQuantity: newQuantity };
        } else {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, lotQuantity: newQuantity };
        } else {
          return row;
        }
      });
    });
  }
  const handleImageChange = (e) => {
    const newImageFiles = Array.from(e.target.files);
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    const allFilesValid = Array.from(newImageFiles).every(file => allowedExtensions.test(file.name));

    if (!allFilesValid) 
    {
      toast.error('Invalid file type. Please upload images only.');
      // You can also clear the input field here if needed
      return;
    }

    // Check if adding new images exceeds the limit
    if (images.length + newImageFiles.length > limit) {
        // Optionally, alert the user or handle this case differently
        toast.error("Cannot add more images. Limit reached.");
        return; // Prevent adding new images
    }

    // If within the limit, add the new images
    setImages((prevImages) => [...prevImages, ...newImageFiles]);
    console.log(newImageFiles[0]?.name, 'newImageFiles?.name');
};

  const handleFileChange = (e) => {
    //console.log(e.target.files);
    const docfiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...docfiles]);
    setDocName([...docName, ...new Array(e.target.files.length).fill('technical datasheet')]);

  };
  const handleFileName = (index, e) => {
    setDocName((prevDocNames) => {
      const newDocNames = [...prevDocNames];
      newDocNames[index] = e.target.value;
      return newDocNames;
    });
    console.log(docName);
  };

  const handleInputChange = (event) => {
    let tempLot = { ...lot };

    if (event.target.name === "name") tempLot["Name"] = event.target.value;
    else if (event.target.name === "price")
      tempLot["Price"] = event.target.value;
    else if (event.target.name === "bid-choice")
      tempLot["BidChoice"] = event.target.value;
    else if (event.target.name === "description")
      tempLot["Description"] = event.target.value;
    else if (event.target.name === "category")
      tempLot["Category"] = event.target.value;
    else if (event.target.name === "expiry-date") {
      const selectedDate = new Date(event.target.value);
      setFormattedDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
      tempLot["ExpiryDate"] = event.target.value;
    } else if (event.target.name === "quantity")
      tempLot["Quantity"] = event.target.value;

    console.log(tempLot);
    setLot(tempLot);
    console.log("Lot", lot);
  };
  const handleEditCellChange = ({ id, field, props }) => {
    console.log("INSIDE CHANGE");
    console.log(id);
    console.log(field);
    console.log(props);
    const updatedRows = rows.map((row) => {
      if (row.InventoryNumber === id) {
        return { ...row, [field]: props.value };
      }
      return row;
    });
    console.log(updatedRows);
    setRows(updatedRows);
  };
  const handleQuantityChange = (inventoryNumber, value) => {
    const rowIndex = rows.findIndex(
      (row) => row.InventoryNumber === inventoryNumber
    );

    // Create a new copy of the row object with the updated BidChoice property
    const updatedRow = {
      ...rows[rowIndex],
      lotQuantity: value,
    };

    // Create a new array with the updated row and the other rows
    const updatedRows = [
      ...rows.slice(0, rowIndex),
      updatedRow,
      ...rows.slice(rowIndex + 1),
    ];

    // Update the state with the new rows array
    setRows(updatedRows);
  };
  const handleRowSelection = (selectedRowIds) => {
    setSelected(selectedRowIds);
  
    // Assuming 'rows' is the full dataset and contains 'InventoryNumber', 'address', 'lat', 'lng'
    const newSelectedRowDetails = rows.filter(row => 
      selectedRowIds.includes(row.InventoryNumber)
    ).map(row => ({
      id: row.InventoryNumber,
      address: row.address,
      lat: row.lat,
      lng: row.lng
    }));
  
    setSelectedRowDetails(newSelectedRowDetails);
  };
  // const handleRowSelection = (selectedRows) => {
  //   setSelected(selectedRows);
  //   // if (selectedRows.length === 0) {
  //   //   // Resetting when no rows are selected
  //   //   setFirstRowAddress(null);
  //   //   setSelected([]);
  //   //   return;
  //   // }
  
  //   // if (!firstRowAddress) {
  //   //   // Storing the address of the first selected row
  //   //   const firstRow = rows.find(row => row.InventoryNumber === selectedRows[0]);
  //   //   setFirstRowAddress(firstRow?.address);
  //   //   setSelected(selectedRows);
  //   // } else {
  //   //   const newSelectedRow = rows.find(row => row.InventoryNumber === selectedRows[selectedRows.length - 1]);
  //   //   if (newSelectedRow?.address === firstRowAddress) {
  //   //     // Add to selection if addresses match
  //   //     setSelected(selectedRows);
  //   //   } else {
  //   //     // Show error if addresses don't match
  //   //     toast.error("Only items from the same address can be added to the lot");
  //   //   }
  //   // }
  
  //   console.log(selectedRows);
  // };

  const handleCancel = () => {
    navigate("/admin/item-listing/inventory-list");
  };

  const handleDone = async (event) => 
  {
    event.preventDefault();

    const formData = new FormData();
    formData.append("lotName", lot.Name);
    formData.append('lotBid', lot.BidChoice);
    formData.append("lotDesc", lot.Description);
    formData.append("lotSize", lot.Size);
    formData.append("lotExpiry", lot.ExpiryDate);
    formData.append("lotQuantity", lot.Quantity);
    formData.append("lotPrice", lot.Price);
    formData.append("lotCategory", lot.Category);
    formData.append("sellerId", currentUser.id);
    formData.append("address", address);
    formData.append("lat", latitude);
    formData.append("lng", longitude);

    if (selected.length <= 0) 
    {
      toast.error("Select items to add in the lot");
      return;
    }
    
    let selectedInventoryNumbers = [];
    let publishedQuantities = [];

    for (let inventoryNumber of selected) 
    {
      const row = rows.find(row => row.InventoryNumber === inventoryNumber);

      if (row) 
      {
        if (row.lotQuantity <= 0) 
        {
          toast.error("Cannot add item in lot with 0 quantity");
          return;
        }
        
        selectedInventoryNumbers.push(row.InventoryNumber);
        publishedQuantities.push(row.lotQuantity);
      }
    }
      
    formData.append("selectedInventoryNumbers", JSON.stringify(selectedInventoryNumbers));
    formData.append("publishedQuantities", JSON.stringify(publishedQuantities));

    images.forEach((image, index) => {
      formData.append(`image_${index}`, image);
    });

    files.forEach((doc, index) => {
      formData.append(`doc_${index}`, doc);
    });
    
    docName.forEach((name, index) => {
      formData.append(`docName_${index}`, name);
    });
    
    try 
    {
      await axios.post("/create_lot", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      props.setMessage("Lot Created Successfully.");
      navigate("/admin/item-listing/lot-list", { state: lot });
    } 
    catch (error) 
    {
      console.log("Error occurred:", error);
      setIsLoading(false);

      // Checking if the error response is in the expected format
      if (error.response && error.response.data && error.response.data.error) 
      {
        toast.error(error.response.data.error);
      } 
      else 
      {
        toast.error("An unexpected error occurred.");
      }
    }
  }

  const handleLocationSelect = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const [selectedAddress, latLng] = selectedValue.split(';');
      const [lat, lng] = latLng.split(',');

      setAddress(selectedAddress);
      setLatitude(lat);
      setLongitude(lng);
    }
  };

  return (
    <div className="create_items">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                  }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>
              <div
                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                  }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}

                <div
                  className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                    }`}
                >
                  <div className="content_panel">
                    <h3 className="pageName_heading">{localStorage.getItem('logoUrl') && (
  <img
    src={localStorage.getItem('logoUrl')}
    alt="Company Logo"
    style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }}
  />
)}
Create Lot</h3>
                    <form onSubmit={handleDone}>
                      <div className="createItem d-md-flex">
                        <div
                          className="upload_image"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          <label>
                            Upload Image <span style={{ color: "red" }}>*</span>
                            <input
                              className="ml-5"
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              multiple
                              required
                            />
                          </label>
                          {images &&
                            images.length > 0 &&
                            images.map((image, index) => (
                              <div
                                key={index}
                                style={{ flex: "0 0 25%", padding: "10px" }}
                              >
                                <img loading="lazy" 
                                  src={URL.createObjectURL(image)}
                                  alt=""
                                  style={{ maxWidth: "100%", height: "auto" }}
                                />
                                <span>Image {index + 1}</span>
                              </div>
                            ))}
                        </div>

                        <div className="buttons_group">
                          <button
                            type="button"
                            className="cancel"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="done">
                            <AiOutlineCheckCircle />
                            Create
                          </button>
                        </div>
                      </div>
                      <div className="secondaryContainer">
                        <span>Core Information</span>
                        <br />
                        <span>
                          <small>
                            <RiErrorWarningLine
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                marginTop: "-3px",
                              }}
                            />
                            Specify the primary description for your item.
                          </small>
                        </span>
                      </div>

                      <div className="createItemForm">
                        <div className="row" style={{ marginTop: "0" }}>
                          <div className="col-md-3">
                            <label>
                              Lot Name <span>*</span>
                            </label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter Lot Name"
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Input the name of your lot, can not be more than 1024 characters.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label>Bid Choice <span>*</span></label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <select name="bid-choice" className="form-select" defaultValue="" onChange={handleInputChange} required>
                                <option value="" hidden>Select Bid Choice</option>
                                {/* <option value="Fixed Price">Fixed Price</option> */}
                                <option value="Open Bidding">Open Bidding</option>
                                <option value="Close Bidding">Close Bidding</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Choose the bid choice for your lot. For bidding type: 'Open Bidding' is indefinite and displays previous bids on the lot to buyers. Counter bids are allowed in this mode. On the other hand, 'Close Bidding' has a set expiry. Once the deadline has passed, you can either accept or reject the bids. Counter bids are not permitted in close bidding.">
                              <BsInfoCircle />
                            </Tooltip>

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label>
                              Category <span>*</span>
                            </label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <select
                                name="category"
                                className="form-select"
                                defaultValue=""
                                onChange={handleInputChange}
                                required
                              >
                                <option value="" disabled hidden>
                                  Enter category
                                </option>
                                {values.map((value, index) => {
                                  return (
                                    <option value={value} key={index}>
                                      {value}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* <input type="text" name="category" className="form-control" placeholder="Enter Category" onChange={handleInputChange} required /> */}
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Enter the best known keyword which can be used to describe your item.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label>Documents <span>*</span></label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="file"
                                name="documents"
                                className="form-control"
                                placeholder="Choose file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                multiple
                                required
                              />
                              {files && files.length > 0 && files.map((file, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center"
                                  style={{ flex: "0 0 25%", padding: "10px" }}
                                >
                                  <select
                                    className="form-select"
                                    style={{ marginRight: '10px', flex: '0 0 160px' }}
                                    type="text"
                                    placeholder="Enter file name"
                                    onChange={(e) => handleFileName(index, e)}
                                  >
                                    {types.map((option, optionIndex) => (
                                      <option
                                        key={optionIndex}
                                        value={option}
                                      >
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                  <span style={{ flex: '1 1 auto' }}>{file.name}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Please upload any relevant documents or files related to this item. Only enter PDFs.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>



                        <div className="row">
                          <div className="col-md-3">
                            <label>Price (USD, exclusive of taxes)</label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="number"
                                name="price"
                                className="form-control"
                                placeholder="Enter Price"
                                onChange={handleInputChange}
                                step="0.01"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
  <div className="col-md-3">
    <label>Address & Location <span>*</span></label>
  </div>
  <div className="col-md-5">
    <div className="form-group">
      <select name="address-location" className="form-select" defaultValue="" onChange={handleLocationSelect} required>
        <option value="" hidden>Select Address & Location</option>
        {selectedRowDetails.map((row, index) => (
          <option key={index} value={`${row.address};${row.lat},${row.lng}`}>
            {`${row.address} (${row.lat}, ${row.lng})`}
          </option>
        ))}
      </select>
    </div>
  </div>
  <div className="col-1 col-md-2 tooltip_container">
    <Tooltip content="Select an address and its corresponding location. The location includes latitude and longitude coordinates.">
      <BsInfoCircle />
    </Tooltip>
  </div>
</div>

                        <div className="row">
                          <div className="col-md-3">
                            <label>Description <span>*</span></label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <textarea
                                rows="4"
                                name="description"
                                className="form-control"
                                style={{ resize: "none" }}
                                placeholder="Enter Description"
                                onChange={handleInputChange}
                                required
                                maxLength="1024"
                              />
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="Input the name of your item, can not be more than 1024 characters.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>

                        </div>
                        {lot.BidChoice === 'Close Bidding' && (
                          <div className="row">
                            <div className="col-md-3">
                              <label>Bid Deadline <span>*</span></label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="datetime-local"
                                  name="expiry-date"
                                  style={{
                                    border: "1px solid #ced4da",
                                    borderRadius: "0.25rem",
                                    padding: "0.375rem 0.75rem",
                                    backgroundColor: "#ffffff",
                                    color: "#000000", // Changed from "background-color" to "backgroundColor"
                                  }}
                                  placeholder="Enter Expiry Date and Time"
                                  min={`${formattedDate1}T${formattedTime1}`}

                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                    </form>
                    <div style={styles.container} className="customCard">
                      <GridContextProvider>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          getRowId={(row) => row.InventoryNumber}
                          onEditCellPropsChange={handleEditCellChange}
                          checkboxSelection={!isLoading}
                          onSelectionModelChange={handleRowSelection}
                          selectionModel={selected}
                          loading={isLoading}
                          rowHeight={40}
                          // rowsPerPageOptions={[5]}
                          pagination
                          density="compact"
                          disableExtendRowFullWidth={true}
                          disableSelectionOnClick={true}
                          
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                          }
                        />
                      </GridContextProvider>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </>
      )}
    </div>
  );
};

export default CreateLot;
