// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact_page {
    background-color: #f5f5f5;
}

.contact-form {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.contact-form label {
    font-weight: bold;
    margin: 10px 0;
}

.contact-form input,
.contact-form textarea {
    margin: 5px 0 15px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s;
    width: 100%;
}

.contact-form textarea {
    resize: vertical;
    min-height: 100px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #007BFF;
}

.contact-form button {
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/css/contact.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;;IAEI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".contact_page {\n    background-color: #f5f5f5;\n}\n\n.contact-form {\n    display: flex;\n    flex-direction: column;\n    margin: 20px 0;\n}\n\n.contact-form label {\n    font-weight: bold;\n    margin: 10px 0;\n}\n\n.contact-form input,\n.contact-form textarea {\n    margin: 5px 0 15px;\n    padding: 12px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    font-size: 1rem;\n    outline: none;\n    transition: border-color 0.3s;\n    width: 100%;\n}\n\n.contact-form textarea {\n    resize: vertical;\n    min-height: 100px;\n}\n\n.contact-form input:focus,\n.contact-form textarea:focus {\n    border-color: #007BFF;\n}\n\n.contact-form button {\n    background-color: #007BFF;\n    color: white;\n    cursor: pointer;\n    border: none;\n    border-radius: 5px;\n    padding: 10px 20px;\n    font-size: 1rem;\n    transition: background-color 0.3s;\n}\n\n.contact-form button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
