import React from "react";
import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../css/signup.css";
import "../../css/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";

const EditProfile = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState("");
    const [designation, setDesignation] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState();
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );
    useEffect(() => {
        // Fetch data from the API endpoint and fill in the state variables
        const fetchData = async () => {
            try {
                const response = await axios.get(`/autofill_user`, {
                    params: {
                        userId: currentUser.id
                    }
                });
                const data = response.data.data;

                setName(data.name);
                setFName(data.fname);
                setLName(data.lname);
                setEmail(data.email);
                //setCompanies(data.companies);
                setCompany(data.company);
                setDesignation(data.designation);
                setCountry(data.country);
                setPhone(data.phone);
                // setPassword(data.password);
                // setConfirmPass(data.password);
                setIndustry(data.industry);
                setOrganizationType(data.organization);
                setCheckFlag(data.anonymous);
                if (data.userRole === 1) {
                    setSelectedInventoryRole('buyer')
                }
                if (data.userRole === 2) {
                    setSelectedInventoryRole('seller')
                }
                if (data.userRole === 4) {
                    let updatedRoles = [...inventoryRole];
                    if (!updatedRoles.includes('seller')) updatedRoles.push('seller');
                    if (!updatedRoles.includes('buyer')) updatedRoles.push('buyer');

                    let validationErrors = { ...errors };
                    validationErrors["inventoryRole"] = false;

                    setInventoryRole(updatedRoles);
                    setErrors(validationErrors);
                }
            } catch (error) {
                console.error('Error fetching data from API:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    useEffect(() => {
        axios
            .get("/get_companies")
            .then((response) => setCompanies(response.data))
            .catch((error) => console.log(error));
    }, []);

    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };

    const handleChangeForEmail = (event) => {
        if (
            typeof event.target.value !== "undefined" &&
            event.target.value !== ""
        ) {
            var pattern = new RegExp(
                /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );

            if (!pattern.test(event.target.value)) {
                event.target.setCustomValidity("Please enter a valid email address.");
            } else if (
                event.target.value.includes("gmail") ||
                event.target.value.includes("yahoo") ||
                event.target.value.includes("outlook") ||
                event.target.value.includes("live") ||
                event.target.value.includes("icloud") ||
                event.target.value.includes("ymail") ||
                event.target.value.includes("hotmail")
            ) {
                event.target.setCustomValidity(
                    "Email address from this domain not allowed"
                );
            } else {
                event.target.setCustomValidity("");
                setEmail(event.target.value);
            }
        }
    };

    const handleSubmit = (event) => {
        let roleid;
        if (inventoryRole.length > 1) {
            roleid = 4; //for buyer and seller
        }
        else if (inventoryRole.includes("buyer") && inventoryRole.length === 1) {
            roleid = 1; //for buyer
        }
        else if (inventoryRole.includes("seller") && inventoryRole.length === 1) {
            roleid = 2; //for seller
        }
        event.preventDefault();
        console.log("ORGTYPE", organizationType)
        
        if (organizationType.length === 0) {
            toast.error("Organization type is empty");
            return; // Return early to prevent further execution
        }
        if (!roleid) {
            toast.error("No role is selected");
            return; // Return early to prevent further execution
        }
        
        let objToSend = {
            name: fName + ' ' + lName,
            fname: fName,
            lname: lName,
            userId: currentUser.id,
            email: email,
            country: country,
            phone: phone,
            company: company,
            designation: designation,
            anonymous: checkFlag,
            industry: industry,
            organization: organizationType,
            roleid: roleid
        };
        
        console.log(objToSend);
        objToSend = JSON.stringify(objToSend);
        axios
            .post("/update_user", objToSend, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response?.data?.data);
                    navigate("/");
                } else if (response.status === 202) {
                    toast.error("Email Already Exists");
                } else if (response.status === 203) {
                    toast.error("Passwords do not match");
                    setError("Passwords do not match");
                    console.log(error);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    const handleCompanySelect = (event) => {
        setCompany(event.target.value);
        console.log(event.target.value);
    };



    const [inventoryRole, setInventoryRole] = useState([]);
    const location = useLocation();
    const userid = location?.state?.responseData?.userid;
    // const [platformRole, setPlatformRole] = useState(null);
    const [organizationType, setOrganizationType] = useState([]);
    const [industry, setIndustry] = useState(null);
    const [errors, setErrors] = useState({});
    const [checkFlag, setCheckFlag] = useState(false);
    const dispatch = useDispatch();
    console.log(inventoryRole);
    const setSelectedInventoryRole = (selectedInventoryRole) => {
        let inventoryRoleList = [...inventoryRole];
        let validationErrors = { ...errors };
        const index = inventoryRoleList.indexOf(selectedInventoryRole);

        if (index === -1) {
            inventoryRoleList.push(selectedInventoryRole);
            validationErrors["inventoryRole"] = false;
            setInventoryRole(inventoryRoleList);
            setErrors(validationErrors);
        }
        else {
            inventoryRoleList.splice(index, 1);
            setInventoryRole(inventoryRoleList);

            if (inventoryRoleList.length === 0) {
                validationErrors["inventoryRole"] = true;
                setErrors(validationErrors);
            }
        }
    }

    /*const setSelectedPlatformRole = (event) =>
    {
        let validationErrors = {...errors};
        validationErrors["platformRole"] = false;

        setPlatformRole(event.target.value);
        setErrors(validationErrors);
    }*/

    const setSelectedOrganizationType = (selectedOrganizationType) => {
        let organizationTypeList = [...organizationType];
        let validationErrors = { ...errors };
        const index = organizationTypeList.indexOf(selectedOrganizationType);

        if (index === -1) {
            organizationTypeList.push(selectedOrganizationType);
            validationErrors["organizationType"] = false;
            setOrganizationType(organizationTypeList);
            setErrors(validationErrors);
        }
        else {
            organizationTypeList.splice(index, 1);
            setOrganizationType(organizationTypeList);

            if (organizationTypeList.length === 0) {
                validationErrors["organizationType"] = true;
                setErrors(validationErrors);
            }
        }
    }

    const setSelectedIndustry = (event) => {
        let validationErrors = { ...errors };
        validationErrors["industry"] = false;

        setIndustry(event.target.value);
        setErrors(validationErrors);
    }

    return (
        <div
        >
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                     {matches && (
                        <Header
                            getCollapseSidebar={getCollapseSidebar}
                            getCollapseDropdown={getCollapseDropdown}
                            logOut={props.logOut}
                        />
                    )}
                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}
                                <form onSubmit={handleSubmit} className="content_panel">
                                    <Row>
                                        <Col xs={12} md={5}>
                                            <div className="d-flex flex-column gap-[5px]">
                                                <h4 className="pageName_heading">
                                                {localStorage.getItem('logoUrl') && (
  <img
    src={localStorage.getItem('logoUrl')}
    alt="Company Logo"
    style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }}
  />
)}

                                                    Edit Profile
                                                </h4>
                                                {/* <div className="form-group">
                                            <label htmlFor="name" className="signUpLabels">
                                                Name <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                value={name}
                                                placeholder="Enter Name"
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div> */}
                                                <div className="form-group">
                                                    <label htmlFor="name" className="signUpLabels">
                                                        First Name <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        id="fname"
                                                        value={fName}
                                                        placeholder="Enter First Name"
                                                        onChange={(e) => setFName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="name" className="signUpLabels">
                                                        Last Name <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        id="lname"
                                                        value={lName}
                                                        placeholder="Enter First Name"
                                                        onChange={(e) => setLName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group" style={{ marginTop: "0.5em" }}>
                                                    <label htmlFor="email" className="signUpLabels">
                                                        Email <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        id="email"
                                                        value={email}
                                                        placeholder="Enter Email"
                                                        onChange={handleChangeForEmail}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group" style={{ marginTop: "0.5em" }}>
                                                    <label htmlFor="company" className="signUpLabels">
                                                        Company
                                                    </label>
                                                    <select
                                                        className="form-select country_select"
                                                        id="company"
                                                        value={company}
                                                        onChange={handleCompanySelect}
                                                        required
                                                    >
                                                        <option value="" hidden>
                                                            Select Company
                                                        </option>
                                                        {companies.map((company) => (
                                                            <option key={company.id} value={company.id}>
                                                                {company.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group" style={{ marginTop: "0.5em" }}>
                                                    <label htmlFor="designation" className="signUpLabels">
                                                        Designation
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="designation"
                                                        className="form-control"
                                                        id="designation"
                                                        placeholder="Enter Designation"
                                                        value={designation}
                                                        onChange={(e) => setDesignation(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group" style={{ marginTop: "0.5em" }}>
                                                    <label htmlFor="country" className="signUpLabels">
                                                        Country <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <select
                                                        name="country"
                                                        className="form-select country_select"
                                                        id="country"
                                                        value={country}
                                                        onChange={(e) => setCountry(e.target.value)}
                                                        required
                                                    >
                                                        <option value="" hidden>
                                                            Select Country
                                                        </option>
                                                        <option value="AF">Afghanistan</option>
                                                        <option value="AX">Aland Islands</option>
                                                        <option value="AL">Albania</option>
                                                        <option value="DZ">Algeria</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AQ">Antarctica</option>
                                                        <option value="AG">Antigua and Barbuda</option>
                                                        <option value="AR">Argentina</option>
                                                        <option value="AM">Armenia</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU">Australia</option>
                                                        <option value="AT">Austria</option>
                                                        <option value="AZ">Azerbaijan</option>
                                                        <option value="BS">Bahamas</option>
                                                        <option value="BH">Bahrain</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BY">Belarus</option>
                                                        <option value="BE">Belgium</option>
                                                        <option value="BZ">Belize</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermuda</option>
                                                        <option value="BT">Bhutan</option>
                                                        <option value="BO">Bolivia</option>
                                                        <option value="BQ">
                                                            Bonaire, Sint Eustatius and Saba
                                                        </option>
                                                        <option value="BA">Bosnia and Herzegovina</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BV">Bouvet Island</option>
                                                        <option value="BR">Brazil</option>
                                                        <option value="IO">
                                                            British Indian Ocean Territory
                                                        </option>
                                                        <option value="BN">Brunei Darussalam</option>
                                                        <option value="BG">Bulgaria</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="KH">Cambodia</option>
                                                        <option value="CM">Cameroon</option>
                                                        <option value="CA">Canada</option>
                                                        <option value="CV">Cape Verde</option>
                                                        <option value="KY">Cayman Islands</option>
                                                        <option value="CF">Central African Republic</option>
                                                        <option value="TD">Chad</option>
                                                        <option value="CL">Chile</option>
                                                        <option value="CN">China</option>
                                                        <option value="CX">Christmas Island</option>
                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                        <option value="CO">Colombia</option>
                                                        <option value="KM">Comoros</option>
                                                        <option value="CG">Congo</option>
                                                        <option value="CD">
                                                            Congo, Democratic Republic of the Congo
                                                        </option>
                                                        <option value="CK">Cook Islands</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="CI">Cote D'Ivoire</option>
                                                        <option value="HR">Croatia</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="CW">Curacao</option>
                                                        <option value="CY">Cyprus</option>
                                                        <option value="CZ">Czech Republic</option>
                                                        <option value="DK">Denmark</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="DO">Dominican Republic</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egypt</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="GQ">Equatorial Guinea</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="EE">Estonia</option>
                                                        <option value="ET">Ethiopia</option>
                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                        <option value="FO">Faroe Islands</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="FI">Finland</option>
                                                        <option value="FR">France</option>
                                                        <option value="GF">French Guiana</option>
                                                        <option value="PF">French Polynesia</option>
                                                        <option value="TF">French Southern Territories</option>
                                                        <option value="GA">Gabon</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgia</option>
                                                        <option value="DE">Germany</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GR">Greece</option>
                                                        <option value="GL">Greenland</option>
                                                        <option value="GD">Grenada</option>
                                                        <option value="GP">Guadeloupe</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GG">Guernsey</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GW">Guinea-Bissau</option>
                                                        <option value="GY">Guyana</option>
                                                        <option value="HT">Haiti</option>
                                                        <option value="HM">
                                                            Heard Island and Mcdonald Islands
                                                        </option>
                                                        <option value="VA">
                                                            Holy See (Vatican City State)
                                                        </option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HK">Hong Kong</option>
                                                        <option value="HU">Hungary</option>
                                                        <option value="IS">Iceland</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesia</option>
                                                        <option value="IR">Iran, Islamic Republic of</option>
                                                        <option value="IQ">Iraq</option>
                                                        <option value="IE">Ireland</option>
                                                        <option value="IM">Isle of Man</option>
                                                        <option value="IL">Israel</option>
                                                        <option value="IT">Italy</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japan</option>
                                                        <option value="JE">Jersey</option>
                                                        <option value="JO">Jordan</option>
                                                        <option value="KZ">Kazakhstan</option>
                                                        <option value="KE">Kenya</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KP">
                                                            Korea, Democratic People's Republic of
                                                        </option>
                                                        <option value="KR">Korea, Republic of</option>
                                                        <option value="XK">Kosovo</option>
                                                        <option value="KW">Kuwait</option>
                                                        <option value="KG">Kyrgyzstan</option>
                                                        <option value="LA">
                                                            Lao People's Democratic Republic
                                                        </option>
                                                        <option value="LV">Latvia</option>
                                                        <option value="LB">Lebanon</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Lithuania</option>
                                                        <option value="LU">Luxembourg</option>
                                                        <option value="MO">Macao</option>
                                                        <option value="MK">
                                                            Macedonia, the Former Yugoslav Republic of
                                                        </option>
                                                        <option value="MG">Madagascar</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MY">Malaysia</option>
                                                        <option value="MV">Maldives</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MH">Marshall Islands</option>
                                                        <option value="MQ">Martinique</option>
                                                        <option value="MR">Mauritania</option>
                                                        <option value="MU">Mauritius</option>
                                                        <option value="YT">Mayotte</option>
                                                        <option value="MX">Mexico</option>
                                                        <option value="FM">
                                                            Micronesia, Federated States of
                                                        </option>
                                                        <option value="MD">Moldova, Republic of</option>
                                                        <option value="MC">Monaco</option>
                                                        <option value="MN">Mongolia</option>
                                                        <option value="ME">Montenegro</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MA">Morocco</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="MM">Myanmar</option>
                                                        <option value="NA">Namibia</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="NL">Netherlands</option>
                                                        <option value="AN">Netherlands Antilles</option>
                                                        <option value="NC">New Caledonia</option>
                                                        <option value="NZ">New Zealand</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NE">Niger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="NF">Norfolk Island</option>
                                                        <option value="MP">Northern Mariana Islands</option>
                                                        <option value="NO">Norway</option>
                                                        <option value="OM">Oman</option>
                                                        <option value="PK">Pakistan</option>
                                                        <option value="PW">Palau</option>
                                                        <option value="PS">
                                                            Palestinian Territory, Occupied
                                                        </option>
                                                        <option value="PA">Panama</option>
                                                        <option value="PG">Papua New Guinea</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Peru</option>
                                                        <option value="PH">Philippines</option>
                                                        <option value="PN">Pitcairn</option>
                                                        <option value="PL">Poland</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="RE">Reunion</option>
                                                        <option value="RO">Romania</option>
                                                        <option value="RU">Russian Federation</option>
                                                        <option value="RW">Rwanda</option>
                                                        <option value="BL">Saint Barthelemy</option>
                                                        <option value="SH">Saint Helena</option>
                                                        <option value="KN">Saint Kitts and Nevis</option>
                                                        <option value="LC">Saint Lucia</option>
                                                        <option value="MF">Saint Martin</option>
                                                        <option value="PM">Saint Pierre and Miquelon</option>
                                                        <option value="VC">
                                                            Saint Vincent and the Grenadines
                                                        </option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="ST">Sao Tome and Principe</option>
                                                        <option value="SA">Saudi Arabia</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="RS">Serbia</option>
                                                        <option value="CS">Serbia and Montenegro</option>
                                                        <option value="SC">Seychelles</option>
                                                        <option value="SL">Sierra Leone</option>
                                                        <option value="SG">Singapore</option>
                                                        <option value="SX">Sint Maarten</option>
                                                        <option value="SK">Slovakia</option>
                                                        <option value="SI">Slovenia</option>
                                                        <option value="SB">Solomon Islands</option>
                                                        <option value="SO">Somalia</option>
                                                        <option value="ZA">South Africa</option>
                                                        <option value="GS">
                                                            South Georgia and the South Sandwich Islands
                                                        </option>
                                                        <option value="SS">South Sudan</option>
                                                        <option value="ES">Spain</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="SD">Sudan</option>
                                                        <option value="SR">Suriname</option>
                                                        <option value="SJ">Svalbard and Jan Mayen</option>
                                                        <option value="SZ">Swaziland</option>
                                                        <option value="SE">Sweden</option>
                                                        <option value="CH">Switzerland</option>
                                                        <option value="SY">Syrian Arab Republic</option>
                                                        <option value="TW">Taiwan, Province of China</option>
                                                        <option value="TJ">Tajikistan</option>
                                                        <option value="TZ">Tanzania, United Republic of</option>
                                                        <option value="TH">Thailand</option>
                                                        <option value="TL">Timor-Leste</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TK">Tokelau</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad and Tobago</option>
                                                        <option value="TN">Tunisia</option>
                                                        <option value="TR">Turkey</option>
                                                        <option value="TM">Turkmenistan</option>
                                                        <option value="TC">Turks and Caicos Islands</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UA">Ukraine</option>
                                                        <option value="AE">United Arab Emirates</option>
                                                        <option value="GB">United Kingdom</option>
                                                        <option value="US">United States</option>
                                                        <option value="UM">
                                                            United States Minor Outlying Islands
                                                        </option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="UZ">Uzbekistan</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela</option>
                                                        <option value="VN">Viet Nam</option>
                                                        <option value="VG">Virgin Islands, British</option>
                                                        <option value="VI">Virgin Islands, U.s.</option>
                                                        <option value="WF">Wallis and Futuna</option>
                                                        <option value="EH">Western Sahara</option>
                                                        <option value="YE">Yemen</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabwe</option>
                                                    </select>
                                                </div>
                                                <div className="form-group" style={{ marginTop: "0.5em" }}>
                                                    <label htmlFor="phone" className="signUpLabels">
                                                        Phone <span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <PhoneInput
                                                        defaultCountry={country}
                                                        placeholder="Enter phone number (without country code)"
                                                        value={phone}
                                                        onChange={setPhone}
                                                    />
                                                    {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                                </div>




                                            </div>
                                        </Col>

                                        <Col xs={12} md={5} className="tell_more_con">
                                            <div className="aboutMe_container d-flex flex-column gap-[5px]">
                                                <h5 className="form_tagline create_account">Tell us more about yourself</h5>
                                                <div className="form-group sm:text-center">
                                                    <div className="form_label mb-[0.5em]">
                                                        <label htmlFor="country" className="signUpLabels">
                                                            I am joining as an inventory
                                                        </label>
                                                    </div>
                                                    {errors.inventoryRole && (
                                                        <h1 className="text-[12px] text-danger">*</h1>
                                                    )}
                                                    <div className='buyer_seller_btn'>
                                                        <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${inventoryRole && inventoryRole.indexOf('buyer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${inventoryRole && inventoryRole.indexOf('buyer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedInventoryRole('buyer')}>Buyer</button>
                                                        <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${inventoryRole && inventoryRole.indexOf('seller') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${inventoryRole && inventoryRole.indexOf('seller') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedInventoryRole('seller')}>Seller</button>

                                                        {inventoryRole && inventoryRole.indexOf("seller") !== -1 && (
                                                            <div className="form-check mt-2">
                                                                <input className="form-check-input" type="checkbox" value={checkFlag} id="confidentialNameCheck" onChange={() => { setCheckFlag(!checkFlag) }} />
                                                                <label className="form-check-label" for="confidentialNameCheck" style={{ color: '#595959', fontSize: '11.5px' }}>
                                                                    Keep Name Confidential
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* <div className = "form-group mb-[1em]">
                                            <div className = "mb-[0.5em]" style = {{ display: 'flex', justifyContent: 'space-between' }}>
                                                <h1 className = "text-[12px] text-slate-400" style = {{ display: 'block' }}>Select your role on the platform</h1>
                                                {errors.platformRole && (
                                                    <h1 className = "text-[12px] text-danger">*Required</h1>
                                                )}
                                            </div>
                                            <select className = "form-select" onChange = {(event) => setSelectedPlatformRole(event)}>
                                                <option defaultValue = "role" hidden>Role</option>
                                                <option value = "AnalyzeDifferentSuppliers">Analyze Different Suppliers</option>
                                                <option value = "EvaluatingNewShipments">Evaluating New Shipments</option>
                                            </select>
                                        </div> */}
                                                <div className="form-group">
                                                    <div className="form_label mb-[0.5em]">
                                                        <label htmlFor="country" className="signUpLabels">
                                                            Please state your organization type
                                                        </label>
                                                        {errors.organizationType && (
                                                            <h1 className="text-[12px] text-danger">*</h1>
                                                        )}
                                                    </div>
                                                    <div className='about_buttons_group'>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('manufacturer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('manufacturer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("manufacturer")}>Manufacturer</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('oem') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('oem') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("oem")}>OEM</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('retailer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('retailer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("retailer")}>Retailer</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('wholesaler') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('wholesaler') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("wholesaler")}>Wholesaler</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('distribution agent') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('distribution agent') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("distribution agent")}>Distribution Agents</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('scrap dealer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('scrap dealer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("scrap dealer")}>Scrap Dealer</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('service provider') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('service provider') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("service provider")}>Service Providers</button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="form_label">
                                                        <label htmlFor="country" className="signUpLabels">
                                                            Select your industry
                                                        </label>
                                                        {errors.industry && (
                                                            <h1 className="text-[12px] text-danger">*</h1>
                                                        )}
                                                    </div>
                                                    <select className="form-select" value={industry} onChange={(event) => setSelectedIndustry(event)}>
                                                        <option value="" hidden>Industry</option>
                                                        <option value="aerospace">Aerospace</option>
                                                        <option value="agriculture">Agriculture</option>
                                                        <option value="computer">Computer</option>
                                                        <option value="construction">Construction</option>
                                                        <option value="education">Education</option>
                                                        <option value="electronics">Electronics</option>
                                                        <option value="energy">Energy</option>
                                                        <option value="entertainment">Entertainment</option>
                                                        <option value="food">Food</option>
                                                        <option value="healthcare">Health Care</option>
                                                        <option value="hospitality">Hospitality</option>
                                                        <option value="manufacturing">Manufacturing</option>
                                                        <option value="mining">Mining</option>
                                                        <option value="music">Music</option>
                                                        <option value="news-media">News Media</option>
                                                        <option value="pharmaceutical">Pharmaceutical</option>
                                                        <option value="telecommunication">Telecommunication</option>
                                                        <option value="transport">Transport</option>
                                                        <option value="world-wide-web">World Wide Web</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary custom_btn m-auto-sm" style={{ marginTop: '15px', marginLeft: 'auto' }}>Submit</button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* )} */}
        </div>
    );
};

export default EditProfile;
