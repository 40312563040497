import React, { useState } from 'react';
import '../../css/tooltip.css';

const Tooltip = (props) => 
{
    const [timeout, setTimeOut] = useState(null);
    const [active, setActive] = useState(false);

    const showTooltip = () => 
    {
        let tempTimeOut = setTimeout(() => 
        {
            setActive(true);
        }, props.delay || 400);

        setTimeOut(tempTimeOut);
    }

    const hideTooltip = () => 
    {
        clearInterval(timeout);
        setActive(false);
    }

    return (
        <div
            className = "tooltip-wrapper"
            onMouseEnter = {showTooltip}
            onMouseLeave = {hideTooltip}
        >
            {props.children}
            {active && (
                <div className = {`tooltip right`}>
                    {props.content}
                </div>
            )}
        </div>
    );
}

export default Tooltip;