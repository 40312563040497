import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { ImSearch } from 'react-icons/im';
import { BsFunnel } from 'react-icons/bs';
import { FiRefreshCcw } from 'react-icons/fi';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import DataTable from 'react-data-table-component';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import '../../css/style.css';
import '../../css/inventory.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const PublishedProducts = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Items');
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchColumn, setSearchColumn] = useState(null);
  const [csvHeaders, setCSVHeaders] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);
  const navigate = useNavigate();
  let { state } = useLocation();

  useEffect(() => {
    setIsLoading(true)
    if (selectedTab === 'Items') {
      const tempColumns = [
     
        {
          name: "Item ID",
          selector: (row) => row.itemId,
          right:'true'
        },
        {
          name: "Product Name",
          selector: (row) => row.itemName,
          left:'true'
        },  
        {
          name: "Description",
          selector: (row) => row.itemDesc,
          style: { whiteSpace: 'unset' },
          left:'true'
        },
        {
          name: "Published Price",
          selector: (row) => `${row.itemPrice.toLocaleString()} ${currentUser.currency}`,
          right: true,
        },       
        {
          name: "Published Quantity",
          selector: (row) => row.itemPublishedQuantity.toLocaleString(),
          right:'true'
        }
      ];
      axios.post("/getbypublished", { userId: currentUser.id })
        .then(res => {
          setIsLoading(false)
          let data = res.data;
          console.log(data);
          let tempRows = [];

          data.forEach(row => {
            if (row.ExpiryDate) {
              row.ExpiryDate = moment(new Date(row.ExpiryDate)).format("DD MMM YYYY");
              row.Shipping = moment(new Date(row.ExpiryDate)).format("DD MMM YYYY");
            }
            console.log(`https://invx-images.storage.googleapis.com/${row.itemImage}`)
            tempRows.push(row);
          });

          setColumns(tempColumns);
          setRows(tempRows);
          setFilteredRows(tempRows);
        })
        .catch(error => {
          setIsLoading(false)
          console.log(error);
        });

    }
    else if (selectedTab === 'Lots') {
      const tempColumns = [
       
        {
          name: "Lot ID",
          selector: (row) => row.lotId,
          right:'true'
        },
        {
          name: "Lot Name",
          selector: (row) => row.lotName
        },
        {
          name: "Description",
          selector: (row) => row.lotDesc
        },
        {
          name: "Bid Type",
          selector: (row) => row.bidType
        },
        {
          name: "Price",
          selector: (row) => `${row.price.toLocaleString()} ${currentUser.currency}`,
          'right': true
        },
        {
          name: "Deadline",
          selector: (row) => row.deadline
        },
        
      ];

      axios.post("/getbypublishedLot", { userId: currentUser.id })
        .then(res => {
          setIsLoading(false)
          let data = res.data;
          console.log(data);
          let tempRows = [];

          data.forEach(row => {
            tempRows.push(row);
          });

          setColumns(tempColumns);
          setRows(tempRows);
          setFilteredRows(tempRows);
        })
        .catch(error => {
          setIsLoading(false)
          console.log(error);
        });
    }

    window.matchMedia("(max-width: 1024px)").addEventListener('change', e => setMatches(e.matches));
  }, [state, selectedTab]);

  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  }

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  }

  const handleInputChange = (event) => {
    let userInput = event.target.value;
    userInput = userInput.trim();
  
    if (userInput === "") {
      setFilteredRows(rows);
    } else {
      if (searchColumn) {
        if (selectedTab === 'Items'){
          if (searchColumn === "Name") {
            const result = rows.filter((search) =>
              search?.itemName?.toLowerCase()?.includes(userInput?.toLowerCase())
            );
            setFilteredRows(result);
          } else if (searchColumn === "Description") {
            const result = rows.filter((search) =>
              search?.itemDesc?.toLowerCase()?.includes(userInput?.toLowerCase())
            );
            setFilteredRows(result);
          }
        } else {
          if (searchColumn === "Name") {
            const result = rows.filter((search) =>
              search?.lotName?.toLowerCase()?.includes(userInput?.toLowerCase())
            );
            setFilteredRows(result);
          } else if (searchColumn === "Description") {
            const result = rows.filter((search) =>
              search?.lotDesc?.toLowerCase()?.includes(userInput?.toLowerCase())
            );
            setFilteredRows(result);
          }
        }
      } else {
        let result;
        if(selectedTab === 'Lots') {
          result = rows.filter((search) =>
            search?.lotName?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
        } else if(selectedTab === 'Items') {
          result = rows.filter((search) =>
            search?.itemName?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
        }  
        setFilteredRows(result);
      }
    }
  };
  

  const handleDropdownClick = (event, search) => {
    event.preventDefault();
    setSearchColumn(search);
  }

  const handleRefresh = () => {
    window.location.reload();
  }

  const handleCSVClick = () => {
    let headers = [];

    for (var i = 0; i < columns.length; i++) {
      if (i === 0) {
        headers.push({ label: "Product Image", key: Object.keys(rows[0])[i] });
      }
      else {
        headers.push({ label: columns[i].name, key: Object.keys(rows[0])[i] });
      }
    }

    setCSVHeaders(headers);
    setCSVData(filteredRows);
  }

  return (
    <div className='published_products'>
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header getCollapseSidebar={getCollapseSidebar} getCollapseDropdown={getCollapseDropdown} logOut={props.logOut} />
          )}

          <div className='admin_panel'>
            <div className='d-lg-flex'>
              <div className={`${collapseSidebar ? 'pinched_sidebar' : 'open_sidebar'}`}>
                <Sidebar collapsed={collapseSidebar} collapsedDropdown={collapseDropdown} />
               </div>

              <div className={`inventory_list_content ${collapseSidebar ? 'open_content' : 'pinched_content'}`}>
                {!matches && (
                  <Header getCollapseSidebar={getCollapseSidebar} getCollapseDropdown={getCollapseDropdown} logOut={props.logOut} />
                )}
                <div className={`w-100 content_panel ${collapseSidebar ? 'slide-in' : 'slide-out'}`}>
                  <div style={{ height: `${filteredRows && filteredRows.length > 0 ? 'auto' : '100vh'}` }}>
                    <div className="publish_heading d-flex justify-content-between custom_section">
                      <h4 className="pageName_heading">
                      {localStorage.getItem('logoUrl') && (
  <img
    src={localStorage.getItem('logoUrl')}
    alt="Company Logo"
    style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }}
  />
)}
Published List</h4>

                      <div className="btn-group" role="group" style={{ justifySelf: "center" }}>
                        <button
                          type="button"
                          className={`btn custom_btn ${selectedTab === 'Items' ? 'btn-primary' : 'btn-light'}`}
                          onClick={() => setSelectedTab('Items')}
                        >
                          Items
                        </button>
                        <button
                          type="button"
                          className={`btn custom_btn ${selectedTab === 'Lots' ? 'btn-primary' : 'btn-light'}`}
                          onClick={() => setSelectedTab('Lots')}
                        >
                          Lots
                        </button>
                      </div>
                      <div></div>
                    </div>



                    {/* <div className="row justify-content-center my-3">
                                    <div className="col-md-8 text-center">
                                        <div className="btn-group" role="group">
                                            
                                        </div>
                                    </div>
                                </div> */}

                    <div className="listing row">
                      <div className='col-12 col-md-6 position-relative' style={{ height: '40px' }}>
                        <span className='search_icon'><ImSearch style={{ fontSize: '15px', display: 'inline-block' }} /></span>
                        <div className='admin_panel_searchbar'>
                          <input type={`${searchColumn === "Expiry Date" ? 'date' : 'text'}`} placeholder={`Search ${searchColumn ? searchColumn : 'Inventory'}`} className="input py-[1.5em] px-[3em]" onChange={(event) => handleInputChange(event)} />
                          <button className="filter dropdown-toggle" type="button" id="filterButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <BsFunnel style={{ color: 'black', fontSize: '18px' }} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="filterButton" style={{ zIndex: '1', fontSize: '14px' }}>
                            <li><a className="dropdown-item" href="#/" onClick={(event) => handleDropdownClick(event, "Name")}>Filter By Name</a></li>
                            <li><a className="dropdown-item" href="#/" onClick={(event) => handleDropdownClick(event, "Description")}>Filter By Description</a></li>
                          </ul>
                        </div>
                      </div>

                      <div className='col-12 col-md-6'>
                        <div className='admin_couple_buttons'>
                          <div className='text-center'>
                            <button type="button" className="refresh" onClick={handleRefresh}>
                              <FiRefreshCcw className="refresh-icon" />
                              Refresh
                            </button>
                          </div>

                          {/* {filteredRows && filteredRows.length > 0 && (
                            <div className='text-center'>
                              <button className='export'>
                                <CSVLink
                                  data={csvData}
                                  headers={csvHeaders}
                                  asyncOnClick={true}
                                  filename={"Inventory List.csv"}
                                  onClick={handleCSVClick}
                                  style={{ color: '#000000de' }}
                                >
                                  EXPORT TO CSV
                                </CSVLink>
                              </button>
                            </div>
                          )} */}

                        </div>
                      </div>
                    </div>
                    <div className="table-responsive custom_table_container customCard">
                      {
                        isLoading ? (
                          <div className="d-flex justify-content-center">
                            <br />
                            <div className="spinner-border text-info" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        ) : (
                          <>

                            <DataTable
                              columns={columns}
                              data={filteredRows}
                              pagination={true}
                              // onRowClicked={() => { navigate("/admin/item-listing/published-products/product-detail") }}
                              rowHeight={40}
                            />
                          </>

                        )
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PublishedProducts;