import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';

const RolePieChart = () => {
  const [roleCounts, setRoleCounts] = useState({});

  // Fetch data
  useEffect(() => {
    const fetchDataForRolePieChart = async () => {
      try {
        const response = await axios.get('/dashboard/rolecount');
        setRoleCounts(response.data.roleCounts);
      } catch (error) {
        console.log('Error fetching data for role pie chart: ', error);
      }
    };
    fetchDataForRolePieChart();
  }, []);

  // Render chart
  useEffect(() => {
    if (Object.keys(roleCounts).length === 0) {
      return;
    }

    const canvas = document.getElementById('rolePieCanvas');
    const ctx = canvas.getContext('2d');
    const labels = Object.keys(roleCounts);
    const data = Object.values(roleCounts);

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            // Hex color codes: light purple, turquoise, orange, and a complementary soft blue
            backgroundColor: ['#C8A2C8', '#40E0D0', '#FFA500', '#7494EA'],
          },
        ],
      },
    };
    

    
    const chartInstance = new Chart(ctx, config);

    // Destroy chart instance to avoid memory leaks when component unmounts or data changes
    return () => {
      chartInstance.destroy();
    };
    
  }, [roleCounts]);

  return <canvas id="rolePieCanvas" width="400" height="400" />;
};

export default RolePieChart;
