import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
function TxModal(props) {

  const { title, msg, show, setShow, confirmTx, type, login } = props //type [1- confirmation, 2- processing, 3- done]

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-secondary">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-secondary text-center">
        <p>{msg}</p>
        {
          type === 2 && <Spinner animation="border" variant="secondary" />
        }
      </Modal.Body>
      {type === 1 &&
        <Modal.Footer className="custom_modal_footer">
          <Button className="btn custom_btn btn-secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          {
            login === true &&
            <Button className="btn custom_btn btn-primary" onClick={(e) => confirmTx(e)}>
              Login
            </Button>
          }
          {
            login === false &&
            <Button className="btn custom_btn btn-primary" onClick={(e) => confirmTx(e)}>
              Delete
            </Button>
          }


        </Modal.Footer>
      }
      {type !== 1 &&
        <Modal.Footer style={type === 2 || type === 3 ? { display: 'flex', justifyContent: 'center' } : {}}>
          <Button className="btn custom_btn btn-secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>

      }



    </Modal>
  );
}

export default TxModal;
