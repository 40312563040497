import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Col, Modal, Row, Button } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaEye } from 'react-icons/fa';
import { MdCancel, MdOutlineDone } from "react-icons/md";
import { ToastContainer, Toast } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { ImSearch } from "react-icons/im";
import { BsFunnel } from "react-icons/bs";
import "../../css/requests.css";
import "../../css/inventory.css";
import "../../css/style.css";
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

const Invoices = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [fileName, setFileName] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const logoUrl = localStorage.getItem('logoUrl');

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceItemSlots, setInvoiceItemSlots] = useState([]);

  const [searchColumn, setSearchColumn] = useState(null);
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );









  useEffect(() => {
    setIsLoading(true)
    let tempColumns = [];
    let tempRows = [];

    tempColumns = [
      {
        name: "Invoice ID",
        selector: (row) => row.invoiceId,
        right:'true'
      },
      {
        name: "Invoice Number",
        selector: (row) => row.invoiceNumber,
        right:'true'
      },
      {
        name: "SubTotal",
        selector: (row) => `${row.SubTotal.toLocaleString()} ${currentUser.currency}`,
        right:'true'
      },
      {
        name: "Shipping",
        selector: (row) => row.Shipping,
      },
      {
        name: "Insurance",
        selector: (row) => row.Insurance,
      },
      {
        name: "Inspection",
        selector: (row) => row.Inspection,
      },
      {
        name: "VAT",
        selector: (row) => `${row.VAT.toLocaleString()} ${currentUser.currency}`,
        'right': true
      },
      {
        name: "Commission",
        selector: (row) => `${row.Commission.toLocaleString()} ${currentUser.currency}`,
        'right': true
      },
      {
        name: "Total",
        selector: (row) => `${row.Total.toLocaleString()} ${currentUser.currency}`,
        right:'true'
      },
      {
        name: "View PDF",
        cell: row => (
          <div
            onClick={() => handleGeneratePDF(row.invoiceId)}
            style={{
              backgroundColor: '#0D70A5',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              cursor: 'pointer'
            }}
          >
            <FaEye />
          </div>
        )
      }
    ];

    axios
      .get("/invoice/" + currentUser.id)
      .then((response) => {
        setIsLoading(false)
        console.log(response.data);
        tempRows = response.data.invoices;
        setColumns(tempColumns);
        setRows(tempRows);
        console.log("TEMP", tempRows);
        setFilteredRows(tempRows);
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error); // Handle any errors that may occur
      });

    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  useEffect(() => {
    axios.post('/setNotificationAsSeen', {
      userId: currentUser.id,
      url: window.location.pathname
    })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error('Error updating notification', error);
      });
  }, []);
  const handleGeneratePDF = (invoiceId) => {
    axios.get(`/invoicepdf/${invoiceId}`)
      .then((response) => {
        const pdfSasUrl = response.data.pdf_url;
        if (Capacitor.isNativePlatform()) {
          Browser.open({ url: pdfSasUrl });
        } else {
          setFileName(pdfSasUrl); // Set SAS URL to fileName state
          setShowModal(true);     // Open the modal
        }
      })
      .catch((error) => {
        console.error(error);  // Handle any errors that may occur
      });
  };
  
  
  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };



  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };





  const handleRowClicked = (row) => {
    axios
      .get(`/invoiceitems/${row.invoiceId}`)
      .then((response) => {
        const { invoiceitems, invoiceitemslots } = response.data;
        // Update state with the received data
        setInvoiceItems(invoiceitems);
        setInvoiceItemSlots(invoiceitemslots);
        setShowDetailModal(true);  // Open the modal
      })
      .catch((error) => {
        console.error(error);
      });
  }


  const handleCloseModal = () => {
    setShowModal(false);
    // setShowStatusModal(false);
  };

  const handleDropdownClick = (event, search) => {
    event.preventDefault();
    setSearchColumn(search);
  };

  const handleInputChange = (event) => {
    let userInput = event.target.value;
    userInput = userInput.trim();

    if (userInput === "") {
      setFilteredRows(rows);
    } else {
      if (searchColumn) {
        if (searchColumn === "Bid Choice") {
          const result = rows.filter((search) =>
            search?.itemBid?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
          setFilteredRows(result);
        } else if (searchColumn === "Status") {
          const result = rows.filter((search) =>
            search?.Status?.toLowerCase()?.includes(userInput?.toLowerCase())
          );
          setFilteredRows(result);
        }
      } else {
        let result;


        result = rows.filter((search) =>
          search?.invoiceNumber?.toLowerCase()?.includes(userInput?.toLowerCase())
        );




        setFilteredRows(result);
      }
    }
  };

  return (
    <div className="purchase_request_page">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                  }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>

              <div
                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                  }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}



                <div
                  className={`w-100 content_panel ${collapseSidebar ? "slide-in" : "slide-out"
                    }`}
                >
                  <div style={{ height: "100vh" }}>
                    <div className="purchase_request d-flex justify-content-between">
                       <h4 className="pageName_heading">
    {logoUrl && <img src={logoUrl} alt="Company Logo" style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }} />}Invoices</h4>


                      <div className="position-relative d-flex">
                       
                        <input
                          type="text"
                          placeholder={`Search ${searchColumn ? searchColumn : "Invoice Number"}`}
                          className="input py-[1.5em] px-[3em]"
                          onChange={(event) => handleInputChange(event)}
                        />
                        <button
                          className="request-filter dropdown-toggle"
                          type="button"
                          id="filterButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <BsFunnel
                            style={{ color: "black", fontSize: "18px" }}
                          />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="filterButton"
                          style={{ zIndex: "1", fontSize: "14px" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Item Name")
                              }
                            >
                              Filter By Item Name
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Bid Choice")
                              }
                            >
                              Filter By Bid Choice
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Status")
                              }
                            >
                              Filter By Status
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="table-responsive custom_table_container customCard">
                      {
                        isLoading ? (
                          <div className="d-flex justify-content-center">
                            <br />
                            <div className="spinner-border text-info" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        ) : (

                          <DataTable
                            columns={columns}
                            data={filteredRows}
                            pagination={true}
                            rowHeight={40}
                            rowStyle={(row, rowIndex) => ({
                              ...(rowIndex % 2 === 0 && row.id === "row-0" ? { backgroundColor: "#f5f5f5" } : {})
                            })}
                          />
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {fileName && (
  <Modal
    centered
    show={showModal}
    onHide={handleCloseModal}
    className="product-document"
  >
    <Modal.Header closeButton={handleCloseModal}></Modal.Header>
    <Modal.Body>
      <object
        data={`${fileName}`}
        aria-labelledby="pdf-viewer"
        type="application/pdf"
        style={{ width: "100%", height: "580px" }}
      ></object>
    </Modal.Body>
  </Modal>
)}


          <Modal centered show={showDetailModal} onHide={handleCloseModal}>
            <Modal.Header closeButton={handleCloseModal}>
              <Modal.Title style={{ color: "#0d70a5" }}>INVOICE DETAILS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Iterate over invoiceItems */}
              {invoiceItems.map(item => (
                // For each item, render a row with its details
                <Row style={{ marginBottom: "0.5em" }} key={item.InvoiceItemId}>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Item Number
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {item.ItemId}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Item Name
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {item.ItemName}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Item Price
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {item.ItemPrice}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Item Quantity
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {item.ItemQuantity}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Item Total Price
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {item.TotalPrice}
                  </Col>
                </Row>

                // ...Add more rows for other item properties...
              ))}
              {/* Iterate over invoiceItemSlots (lots) */}
              {invoiceItemSlots.map(lot => (
                // For each lot, render a row with its details
                <Row style={{ marginBottom: "0.5em" }} key={lot.InvoiceItemId}>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Lot Number
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {lot.LotId}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Lot Name
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {lot.LotName}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Lot Price
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {lot.LotPrice}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Lot Quantity
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {lot.LotQuantity}
                  </Col>
                  <Col xs={4} style={{ color: "#9F9F9F" }}>
                    Lot Total Price
                  </Col>
                  <Col xs={8} style={{ color: "#707070" }}>
                    {lot.TotalPrice}
                  </Col>
                </Row>
                // ...Add more rows for other lot properties...
              ))}
            </Modal.Body>
          </Modal>


        </>
      )}
    </div>
  );
};


export default Invoices;
