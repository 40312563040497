import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
function PopUp(props) {

  const { title, msg, show, setShow, confirmTx, type } = props //type [1- confirmation, 2- processing, 3- done]
  
  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-secondary">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-secondary text-center">
          <p>{msg}</p>
          {
            type === 2 && <Spinner animation="border" variant="primary" />
          }
      </Modal.Body>
      {type === 1 && 
      <Modal.Footer className="d-grid" style={{gridTemplateColumns:"1fr 1fr"}}>
      <Button variant="secondary" className="custom_btn" onClick={() => setShow(false)}>
          Close
      </Button>
      <Button variant="primary" className="custom_btn" onClick={(e) => confirmTx(e)}>
          Confirm
      </Button> 
        
</Modal.Footer>
      }
      {type !== 1 &&
      <Modal.Footer style={type === 2 || type === 3 ? {display: 'flex', justifyContent: 'center'} : {}}>
      <Button variant="secondary" className="custom_btn" onClick={() => setShow(false)}>
          Close
      </Button>
    </Modal.Footer>
    
      }
      


    </Modal>
  );
}

export default PopUp;
