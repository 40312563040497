// UserInputContext.js
import React, { createContext, useContext, useState } from 'react';

const UserInputContext = createContext();

export const useUserInput = () => {
  const context = useContext(UserInputContext);
  if (!context) {
    throw new Error("useUserInput must be used within a UserInputProvider");
  }
  return context;
};

export const UserInputProvider = ({ children }) => {
  const [userInput, setUserInput] = useState({});
  return (
    <UserInputContext.Provider value={{ userInput, setUserInput }}>
      {children}
    </UserInputContext.Provider>
  );
};
