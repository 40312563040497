import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import SearchHeader from "../header/SearchHeader";
import Footer from "../footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import ProductRow from "./ProductRow";
import { BsCart4 } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "../../css/style.css";
import axios from "axios";
const Cart = (props) => {
  const [products, setProducts] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [favourites, setFavourites] = useState([]);
  const [whole, setWhole] = useState({});
  const [lots, setLots] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    axios.post('/setNotificationAsSeen', {
      userId: currentUser.id,
      url: window.location.pathname
    })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error('Error updating notification', error);
      });
  }, []);

  useEffect(() => {
    if (products && lots) {
      var sum = 0;

      products?.forEach(function (product) {
        sum += product.price * product.quantity;
      });
      lots.forEach(function (product) {
        sum += product.price;
      });

      setTotalPrice(sum);
    }
  }, [products, lots]);
  useEffect(() => {
    setProducts(null);
    let tempProducts = [];
    axios
      .post("/get_cart", { userId: currentUser.id })
      .then((response) => {
        console.log(response,'cart items');
        if (response.status === 200) {
          setWhole(response.data);
          setProducts(response.data.cart_items);
          setLots(response.data.lot_items);
        }
        else if(response.status === 202){
          setProducts([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [state]);

  const handleButtonClick = (selectedButton) => {
    if (selectedButton === "Continue Shopping") navigate("/");
    else if (selectedButton === "Make An Offer") navigate("/negotiate-cart");
    else if (selectedButton === "Check Out")
      navigate("/checkout", { state: whole });
  };

  const handleClick = (selected) => {
    navigate("/product-detail", { state: selected });
  };
  const addToWishList = (productName) => {
    let tempFavourites = [...favourites];
    const index = tempFavourites.indexOf(productName);

    if (index === -1) {
      tempFavourites.push(productName);
      toast.success(productName + " has been added to your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    } else {
      tempFavourites.splice(index, 1);
      toast.success(productName + " has been removed from your wish list.", {
        style: {
          fontSize: "13px",
        },
      });
    }

    setFavourites(tempFavourites);
  };
  // Define the incrementQuanity function to update the quantity of the product with the given itemId
  const incrementQuanity = (itemId) => {
    // Find the index of the product with the matching itemId in the products array
    const index = products?.findIndex((product) => product.itemId === itemId);

    // If a product with the matching itemId is found, update its quantity by 1
    if (index !== -1) {
      axios
        .post("/update_cart_item_quantity", {
          itemId: itemId,
          userId: currentUser.id,
          quantity: products[index].quantity + 1,
        })
        .then((response) => {
          if (response.status === 200) {
            const updatedProduct = {
              ...products[index],
              quantity: products[index].quantity + 1,
            };
            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
            axios
              .post("/get_cart", {
                userId: currentUser.id,
              })
              .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                  setWhole(response.data);
                  //setProducts(response.data.cart_items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (response.status === 202) {
            toast.error("Quantity exceeds the available quantity");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Define the decrementQuanity function to update the quantity of the product with the given itemId
  const decrementQuanity = (itemId) => {
    // Find the index of the product with the matching itemId in the products array
    const index = products?.findIndex((product) => product.itemId === itemId);

    // If a product with the matching itemId is found and its quantity is greater than 0, update its quantity by -1
    if (index !== -1 && products[index].quantity > 0) {
      axios
        .post("/update_cart_item_quantity", {
          itemId: itemId,
          userId: currentUser.id,
          quantity: products[index].quantity - 1,
        })
        .then((response) => {
          if (response.status === 200) {
            const updatedProduct = {
              ...products[index],
              quantity: products[index].quantity - 1,
            };
            const updatedProducts = [...products];
            updatedProducts[index] = updatedProduct;
            setProducts(updatedProducts);
            axios
              .post("/get_cart", {
                userId: currentUser.id,
              })
              .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                  setWhole(response.data);
                  //setProducts(response.data.cart_items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const checkUpdates = () => {
    if (products) {
      return products?.some((product) => {
        return (
          Date.parse(product.itemUpdated) >= Date.parse(product.cartItemUpdated)
        );
      });
    } else {
      return false;
    }
  };

  const handleDelete = (productId, type) => {
    if(type === 'item'){
      axios
      .post("/delete_from_cart", {
        userId: currentUser.id,
        itemId: productId,
        type
      })
      .then((response) => {
        console.log(response.data);
        axios
          .post("/get_cart", { userId: currentUser.id })
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              setWhole(response.data);
              setProducts(response.data.cart_items);
              setLots(response.lot_items);
              var sum = 0;

              products?.forEach(function (product) {
                sum += product.price * product.quantity;
              });

              setTotalPrice(sum);
            } else if (response.status === 202) {
              setProducts([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });

    }
    else if( type === 'lot'){
      axios
      .post("/delete_from_cart", {
        userId: currentUser.id,
        lotId: productId,
        type
      })
      .then((response) => {
        console.log(response.data);
        axios
          .post("/get_cart", { userId: currentUser.id })
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              setWhole(response.data);
              setProducts(response.data.cart_items);
              setLots(response.lot_items);
              var sum = 0;

              products?.forEach(function (product) {
                sum += product.price * product.quantity;
              });

              setTotalPrice(sum);
            } else if (response.status === 202) {
              setProducts([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  return (
    <>
      {/* <SearchHeader
        cartCount={products?.length}
        cartTotal={totalPrice}
        wishListCount={favourites.length}
      /> */}
      <Header logOut={props.logOut} />

      <div className="container main-container app_container">
      <h4 className="pageName_heading">  YOUR CART</h4>
     
        <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
          <ul className="page-hierarchy">
            <li>
              <a href="/cart">Your Cart</a>
            </li>
          </ul>
        </div>
        <br />
        <hr style={{ width: "100%" }} />
        <br />

        {(!products || products?.length === 0) && (!lots || lots.length === 0) ? (
          <>
            {!products ? (
              <div
                className="d-flex justify-content-center"
              >
                <br />
                <div className="spinner-border text-info" role="status"></div>
              </div>
            ) : (
              <>
                <BsCart4
                  style={{ margin: "auto auto 10px auto", fontSize: "50px" }}
                />
                <h6 style={{ textAlign: "center", fontWeight: "400" }}>
                  Your cart is currently empty.
                </h6>
                <div
                  className="d-md-flex cart_button d_sm_center"
                  style={{ marginTop: "1em", columnGap: "15px" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary w-[200px]"
                    onClick={() => handleButtonClick("Continue Shopping")}
                  >
                    CONTINUE SHOPPING
                  </button>
                </div>
              </>
            )

            }

          </>
        ) : (
          <>
            <div className="overflow-x-auto w-full">
              <table
                className="table w-full border-collapse border border-slate-200 cart-table"
                style={{ textAlign: "center" }}
              >
                <thead style={{ backgroundColor: "#F1F1F1" }}>
                  <tr>
                    {/* <th className = "border border-slate-200"></th> */}
                    <th className="border border-slate-200">Product Name</th>
                    <th className="border border-slate-200">Product Company</th>
                    <th className="border border-slate-200">Product Bid</th>
                    <th className="border border-slate-200">Final Price</th>
                    <th className="border border-slate-200">Quantity</th>
                    <th className="border border-slate-200">Total</th>
                    <th className="border border-slate-200"></th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products?.length > 0 &&
                    products?.map((product, index) => (
                      <tr key={index}>
                        {/* <td className = "border border-slate-200">
                        <label>
                          <input type = "checkbox" className = "checkbox cart-checkbox" />
                        </label>
                      </td> */}
                        <td
                          className="border border-slate-200"
                          style={{ textAlign: "left" }}
                        >
                          <div className="flex items-center space-x-3">
                            <div className="avatar rounded-none">
                              <div className="mask w-18 h-16">
                                <img loading="lazy" 
                                  src={`${product.itemImage}`}
                                  alt=""
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                            </div>

                            <div
                              className={
                                Date.parse(product.itemUpdated) >=
                                  Date.parse(product.cartItemUpdated)
                                  ? "text-red-600"
                                  : "text-gray-600"
                              }
                            >
                              <div className="font-bold text-[13px]">
                                {product.itemName}
                              </div>
                              {/* <div className="font-bold text-[12px]">
                                Industry: {product.itemIndustry}
                              </div>
                              <div className="font-bold text-[13px]">
                                Size: {product.itemSize} {product.itemUom}
                              </div> */}
                            </div>
                            {Date.parse(product.itemUpdated) >=
                              Date.parse(product.cartItemUpdated) ? (
                              <div style={{ color: "red" }}>
                                {product.updatedFields} have been modified
                              </div>
                            ) : null}
                          </div>
                        </td>
                        <td className="border border-slate-200">
                          {product.company}
                        </td>
                        <td className="border border-slate-200">
                          {product.bidChoice}
                        </td>

                        <td className="border border-slate-200">
                          {product.price} {currentUser.currency}
                        </td>
                        <td className="border border-slate-200">
                          {product.itemBid === "Fixed Price" ? (
                            <>
                              <span
                                className="bg-slate-700 text-white"
                                style={{ padding: "3.5px 8px", cursor: "pointer" }}
                                onClick={() => decrementQuanity(product.itemId)}
                              >
                                <span style={{ marginTop: "-3px" }}>-</span>
                              </span>
                              <input
                                type="number"
                                value={product.quantity}
                                readOnly
                                disabled
                                className="w-[90px] pl-10 h-7 border border-slate-300"
                                style={{ backgroundColor: "white" }}
                              />
                              <span
                                className="bg-slate-700 text-white"
                                style={{ padding: "3.5px 8px", cursor: "pointer" }}
                                onClick={() => incrementQuanity(product.itemId)}
                              >
                                <span style={{ marginTop: "-3px" }}>+</span>
                              </span>
                            </>
                          ) : (
                            <input
                              type="number"
                              value={product.quantity}
                              readOnly
                              disabled
                              className="w-[90px] pl-10 h-7 border border-slate-300"
                              style={{ backgroundColor: "white" }}
                            />
                          )}
                        </td>


                        <td className="border border-slate-200">
                          {(product.price * product.quantity).toFixed(2)} {currentUser.currency}
                        </td>
                        <td className="border border-slate-200">
                          <svg
                            style={{ margin: "auto", cursor: "pointer" }}
                            onClick={() => handleDelete(product.itemId, 'item')}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            id="IconChangeColor"
                          >
                            <path
                              d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074z"
                              fill="blue"
                              id="mainIconPathAttribute"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                    ))}
                  {lots &&
                    lots.length > 0 &&
                    lots.map((product, index) => (
                      <tr key={index}>
                        {/* <td className = "border border-slate-200">
                        <label>
                          <input type = "checkbox" className = "checkbox cart-checkbox" />
                        </label>
                      </td> */}
                        <td
                          className="border border-slate-200"
                          style={{ textAlign: "left" }}
                        >
                          <div className="flex items-center space-x-3">
                            <div className="avatar rounded-none">
                              <div className="mask w-18 h-16">
                                <img loading="lazy" 
                                  src={`${product.lotImage}`}
                                  alt=""
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                            </div>

                            <div
                              className=
                              "text-gray-600"

                            >
                              <div className="font-bold text-[13px]">
                                {product.lotName}
                              </div>
                              {/* <div className="font-bold text-[13px]">
                                Industry: {product.itemIndustry}
                              </div>
                              <div className="font-bold text-[13px]">
                                Size: {product.itemSize} {product.itemUom}
                              </div> */}
                            </div>
                          </div>
                        </td>
                        <td className="border border-slate-200">
                          {product.company}
                        </td>
                        <td className="border border-slate-200">
                          {product.bidChoice}
                        </td>

                        <td className="border border-slate-200">
                          {product.price} {currentUser.currency}
                        </td>
                        <td className="border border-slate-200">

                          <input
                            type="number"
                            value={product.quantity}
                            readOnly
                            disabled
                            className="w-[90px] pl-10 h-7 border border-slate-300"
                            style={{ backgroundColor: "white" }}
                          />

                        </td>


                        <td className="border border-slate-200">
                          {product.price * product.quantity} {currentUser.currency}
                        </td>
                        <td className="border border-slate-200">
                          <svg
                            style={{ margin: "auto", cursor: "pointer" }}
                            onClick={() => handleDelete(product.lotId, 'lot')}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            id="IconChangeColor"
                          >
                            <path
                              d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074z"
                              fill="blue"
                              id="mainIconPathAttribute"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot style={{ backgroundColor: "#F1F1F1" }}>
                  <tr>
                    {/* <th></th> */}
                    <th></th>
                    <th></th>
                    <th className="border">Total</th>
                    <th>{totalPrice.toFixed(2)} {currentUser.currency}</th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="cart_buttons_group">
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-[210px] hover:text-white"
                  onClick={() => handleButtonClick("Continue Shopping")}
                >
                  CONTINUE SHOPPING
                </button>
              </div>

              {/* <button type = "button" className = "btn btn-warning w-[210px]" onClick = {() => handleButtonClick("Make An Offer")}>MAKE AN OFFER</button> */}
              <div>
                <button
                  type="button"
                  className="btn btn-primary w-[210px]"
                  disabled={checkUpdates()}
                  onClick={() => handleButtonClick("Check Out")}
                >
                  CHECK OUT
                </button>
              </div>
            </div>
          </>
        )}

        <hr className="relatedProductsLine" />
        <div className="d_sm_center">
        <h1 className="related_products_heading text-gray-600 font-bold text-[25px] inline-block float-left mt-[13px]">
          RELATED
        </h1>
        </div>
        {/* <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
          <div className="flex flex-row space-x-2">
            <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /> </svg>
            </div>
            <div className="bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
              <svg style={{ color: 'rgb(78, 77, 70)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill="#4e4d46"></path> </svg>
            </div>
          </div>
        </div> */}
        <br className="d_sm_none"/>
        <br className="d_sm_none"/>
        <br />
        {/* <div className="row related_products">
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick("Raw Materials")}
              >
                <img loading="lazy" 
                  src={require("../../images/image002.jpg")}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon hover:bg-red-600"
                      style={{
                        backgroundColor: `${favourites.indexOf("ISO Propyl Alcohol") > -1
                          ? "red"
                          : "#E2E8F0"
                          }`,
                      }}
                      onClick={() => addToWishList("ISO Propyl Alcohol")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick("Packaging Materials")}
              >
                <img loading="lazy" 
                  src={require("../../images/image001.png")}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">Wood</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon hover:bg-red-600"
                      style={{
                        backgroundColor: `${favourites.indexOf("Wood") > -1 ? "red" : "#E2E8F0"
                          }`,
                      }}
                      onClick={() => addToWishList("Wood")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick("")}
              >
                <img loading="lazy" 
                  src="https://placeimg.com/400/225/arch"
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">Sample Product 1</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon hover:bg-red-600"
                      style={{
                        backgroundColor: `${favourites.indexOf("Sample Product 1") > -1
                          ? "red"
                          : "#E2E8F0"
                          }`,
                      }}
                      onClick={() => addToWishList("Sample Product 1")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
            <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
              <figure
                style={{ margin: "0", height: "200px", cursor: "pointer" }}
                onClick={() => handleClick("")}
              >
                <img loading="lazy" 
                  src="https://placeimg.com/400/225/arch"
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </figure>
              <div className="card-body">
                <div className="rating rating-sm space-x-2">
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                  <input
                    type="radio"
                    name="rating-6"
                    className="mask mask-star-2 bg-orange-400"
                  />
                </div>
                <div
                  className="flex flex-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <h2 className="text-[14px] mt-1">Sample Product 2</h2>
                  <div className="rating w-30px" style={{ float: "right" }}>
                    <input
                      type="radio"
                      name="rating-3"
                      className="whishlist_icon hover:bg-red-600"
                      style={{
                        backgroundColor: `${favourites.indexOf("Sample Product 2") > -1
                          ? "red"
                          : "#E2E8F0"
                          }`,
                      }}
                      onClick={() => addToWishList("Sample Product 2")}
                    />
                  </div>
                </div>
                <hr style={{ margin: "0" }} />
              </div>
              <div className="product_price" style={{ padding: "0 1em" }}>
                <p className="text-[14px]">
                  Lorem ipsum dolor sit amet, consectetur dolor sit amet,
                  consectetur.
                </p>
                <br />
                <h1
                  className="text-[20px] font-bold mb-3"
                  style={{ color: "#0d6efd" }}
                >
                  $225.50
                </h1>
              </div>
            </div>
          </div>
        </div> */}
        <ProductRow/>
        <Footer />
      </div>
    </>
  );
};

export default Cart;
