import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';

const LotPieChart = ({ startDate, endDate }) => {
  const [pieData, setPieData] = useState([]);

  // Fetch data
  useEffect(() => {
    const fetchDataForLotPieChart = async () => {
      const url = `/dashboard/lotstats?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(url, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        setPieData(response.data.lotStats);
      } catch (error) {
        console.log('Error fetching data for lot pie chart: ', error);
      }
    };
    fetchDataForLotPieChart();
  }, [startDate, endDate]);

  // Render chart
  useEffect(() => {
    if (pieData.length === 0) {
      return;
    }
    const labelMap = {
      'publishedLots': 'Published Lots',
      'unpublishedLots': 'Unpublished Lots',
      
    };

    const canvas = document.getElementById('lotPieCanvas');
    const ctx = canvas.getContext('2d');
    const labels = Object.keys(pieData).map(key => labelMap[key] || key);
    const data = Object.values(pieData);

    const config = {
      type: 'pie',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor: ['#C8A2C8', '#40E0D0'],
          },
        ],
      },
    };

    const chartInstance = new Chart(ctx, config);

    // Destroy chart instance to avoid memory leaks when component unmounts or data changes
    return () => {
      chartInstance.destroy();
    };
  }, [pieData]);

  return <canvas id="lotPieCanvas" width="200" height="200" />;
};

export default LotPieChart;
