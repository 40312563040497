import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import '../../css/modal.css';

function BidModal({ showModal, handleClose, product, handleEnter }) {
  const [newPrice, setNewPrice] = useState(product.newPrice || "");
  const handleChange = (event) => {
    setNewPrice(event.target.value);
  };

  const isInputDisabled = product.status === "In progress" || product.status === "Accepted";
  const isEnterButtonDisabled =
    !product.newPrice ||
    product.status === "In progress" ||
    product.bidChoice === "Fixed Price" ||
    product.status === "Rejected" ||
    product.status === "Accepted";

  return (
    <Modal show={showModal} onHide={handleClose} className="bid_modal">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-4 mb-3 text-end">
              <Form.Label>Enter Your Bid:</Form.Label>
            </div>
            <div className="col-md-4 mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{height:'30px'}}>SAR</span>
                </div>
                <Form.Control
                  type="number"
                  placeholder="Your Price"
                  value={newPrice}
                  onChange={handleChange}
                  disabled={isInputDisabled}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              className="btn-sm btn_custom_secondary"
              disabled={isEnterButtonDisabled}
              onClick={() => handleEnter(product, newPrice)}
              style={{height: "25px",textTransform:'capitalize'}} // Adjust the height here
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default BidModal;
