import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { styled } from '@mui/system';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import "../../css/style.css";
import "../../css/muiDataTable.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUp from "../helperComponents/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import "../../css/requests.css";
import "../../css/inventory.css";
import "../../css/style.css";
//import { BsPersonFillCheck, BsPersonFillSlash } from "react-icons/bs";
const Users = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState("");
    const [selectedTab, setSelectedTab] = useState("existing");
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showTxModal, setShowTxModal] = useState(false);
    const [txModalType, setTxModalType] = useState();
    const [txTitle, setTxTitle] = useState("");
    const [txMsg, setTxMsg] = useState("");
    const [txData, setTxData] = useState();
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );

    const styles = {
        container: {
            height: "500px", // set the height of the container
        },
    };

    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    const timeOut = () => {
        const timeId = setTimeout(() => {
            setShowTxModal(false)
        }, 2000)
        return () => {
            clearTimeout(timeId)
        }
    }
    useEffect(() => {
        const getUsersData = async () => {
            setIsLoading(true);
            await axios({
              method: "get",
              url: "/get_users",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                const dynamicColumns = response?.data?.columns.map((columnName) => {
                //   return { field: columnName, headerName: columnName, width: columnName === 'User Email' ? 250 : 150 };
                return { field: columnName, headerName: columnName, width: 200};
                });
          
                const actionsColumn = {
                  field: 'actions',
                  headerName: 'Actions',
                  minWidth: 200,
                  flex: 1,
                  align: 'center',
                  renderCell: (params) => (
                    <div className="d-flex">
                      <button
                        className={`btn outline_disabled_button btn-outline-${params?.row?.Status === 'Enabled' ? 'danger' : 'success'}`}
                        onClick={() => handleAccessUser(params.row)}
                      >
                        {params?.row?.Status === 'Enabled' ? 'Disable' : 'Enable'}
                      </button>
                      <button
                        className={`btn outline_disabled_button btn-outline-success`}
                        onClick={() => resetPassword(params.row)}
                      >
                        Reset Password
                      </button>
                    </div>
                  ),
                };
          
                setColumns([...dynamicColumns, actionsColumn]);
                setRows(response?.data?.rowData);
                setIsLoading(false);
              })
              .catch((error) => {
                setAlertError("something went wrong");
                setIsLoading(false);
                console.log("Get Filters Api: ", error);
              });
          };
          
        const getnewUsersData = async () => {
            setIsLoading(true);
            await axios({
                method: "get",
                url: "/get_newusers",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    const dynamicColumns = response?.data?.columns.map((columnName) => {
                        // return { field: columnName, headerName: columnName, width: columnName === 'User Email' ? 250 : 150 };
                        return { field: columnName, headerName: columnName, width: 200};
                    });

                    const enableDisableColumn = {
                        field: 'enableDisable',
                        headerName: 'Approve/Disapprove',
                        minWidth: 200,
                        flex: 1,
                        renderCell: (params) => (
                            <button
                                className={`btn outline_disabled_button btn-outline-${params?.row?.Status === 'Approved' ? 'danger' : 'success'}`}
                                onClick={() => handleAccessUser(params.row)}
                            >
                                {params?.row?.Status === 'Approved' ? 'Reject' : 'Approve'}
                            </button>
                        ),
                    };

                    setColumns([...dynamicColumns, enableDisableColumn]);
                    setRows(response?.data?.rowData);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setAlertError("something went wrong");
                    setIsLoading(false);
                    console.log("Get Filters Api: ", error);
                });
        };
        if (selectedTab === 'existing') {
            getUsersData();
        }
        else if (selectedTab === 'new') {
            getnewUsersData();
        }


    }, [selectedTab])


    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };




    const handleAccessUser = async (row) => {
        setIsLoading(true);
        setSelectedTab('existing');
        let status = 0; // Default status is 0 (disabled)

        if (row.Status === "Enabled") {
            status = 1; // If status is "Enabled", set status to 1 (enabled)
        } else if (row.Status === "Not Approved") {
            status = 2; // If status is "Not Approved", set status to 2
        }

        await axios({
            method: "post",
            url: "/change_user_status",
            data: {
                user_id: row?.ID,
                status: status
            }
        })
            .then((response) => {
                console.log(response, "channel Pc");
                const dynamicColumns = response?.data?.columns.map((columnName) => {
                    // return { field: columnName, headerName: columnName, width: columnName === 'User Email' ? 250 : 150 };
                    return { field: columnName, headerName: columnName, width: 200};
                });

                const actionsColumn = {
                    field: 'actions',
                    headerName: 'Actions',
                    minWidth: 300,
                    flex: 1,
                    renderCell: (params) => {
                        return (
                            <div className="d-flex gap-3">
                                <div className='d-flex'>
                                    <button className={`custom_btn btn  outline_disabled_button btn-outline-${params?.row?.Status === 'Enabled' ? 'danger' : 'success'}`} onClick={() => handleAccessUser(params.row)}>{params?.row?.Status === 'Enabled' ? 'Disable' : 'Enable'}</button>
                                </div>
                                <div className='d-flex'>
                                    <button className={`custom_btn btn  outline_disabled_button btn-outline-${'success'}`} onClick={() => resetPassword(params.row)}> Reset Password </button>
                                </div>
                            </div>
                        )
                    },
                };

                setColumns([...dynamicColumns, actionsColumn]);
                setRows(response?.data?.rowData);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                setAlertError("Process Failed!");
                setTxModalType(3);
                setTxMsg("Process Failed");
                //toast.danger("Password not updated");
                //setShowTxModal(false);
            }
            )
    }
    const resetPassword = async (row) => {
        setTxModalType(1);
        setTxTitle(`Reset Password Confirmation`);
        setTxMsg(`Are you sure you want to reset password`);
        setTxData(row);
        setShowTxModal(true);
    }
    const reveal = async (row) => {
        setTxModalType(2);
        setTxTitle("Updating Password");
        setTxMsg("Password is being updated");


        setIsLoading(true);
        setAlertError("");
        await axios({
            method: "post",
            url: "/reset_password",
            data: {
                'user_id': row?.ID,
            }
        })
            .then((response) => {
                console.log(response, ' reset respne')
                //toast.success("Password updated successfully");
                //setShowTxModal(false);
                setTxModalType(3);
                setTxMsg(response?.data?.data);
                setIsLoading(false);
                timeOut()
            })
            .catch(function (error) {
                setIsLoading(false);
                setAlertError("Process Failed!");
                setTxModalType(3);
                setTxMsg("Process Failed");
                //toast.danger("Password not updated");
                //setShowTxModal(false);
            }
            )



    }


    const filteredRows = searchQuery
        ? rows?.filter((row) =>
            Object.values(row).some((value) =>
                String(value)?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            )
        )
        : rows;

    const onAddUserClick = () => {
        navigate("/admin/admin-section/add-user");
    }
    return (
        <div className="create_items">
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header
                            getCollapseSidebar={getCollapseSidebar}
                            getCollapseDropdown={getCollapseDropdown}
                            logOut={props.logOut}
                        />
                    )}

                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}

                                <div
                                    className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                                        }`}
                                >
                                    <div className="content_panel container-fluid row_gap">
                                        <div className="purchase_request d-flex justify-content-between custom_section">
                                            <h3 className="pageName_heading">Users</h3>

                                            <div className="btn-group" role="group">
                                                <button
                                                    type="button"
                                                    className={`btn custom_btn ${selectedTab === 'existing' ? 'btn-primary' : 'btn-light'}`}
                                                    onClick={() => setSelectedTab('existing')}
                                                >
                                                    Existing
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn custom_btn ${selectedTab === 'new' ? 'btn-primary' : 'btn-light'}`}
                                                    onClick={() => setSelectedTab('new')}
                                                >
                                                    New
                                                </button>
                                            </div>

                                            <button className="btn custom_btn btn-primary align-items-center" onClick={onAddUserClick}>
                                                Add User
                                            </button>
                                        </div>
                                        {/* <h3 className="pageName_heading">Users</h3> */}
                                        <div className="row justify-content-between">
                                            <div className="col-md-5">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="input py-[1.5em] px-[3em]"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </div>
                                            {/* <div className="col-md-3 addUser">
                                                <button className="btn custom_btn btn-primary align-items-center" onClick={onAddUserClick}>
                                                    Add User
                                                </button>
                                            </div> */}
                                        </div>
                                        <div style={styles.container} className="row customCard">
                                            <GridContextProvider>
                                                <DataGrid rows={filteredRows ? filteredRows : []} columns={columns}
                                                    getRowId={(row) => row['ID']}
                                                    loading={isLoading}
                                                    rowHeight={40}
                                                    // rowsPerPageOptions={[5]}
                                                    pagination
                                                    density="compact"
                                                    disableExtendRowFullWidth={true}
                                                    disableSelectionOnClick={false}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                                                    }
                                                />
                                            </GridContextProvider>
                                            <PopUp
                                                title={txTitle}
                                                msg={txMsg}
                                                show={showTxModal}
                                                setShow={setShowTxModal}
                                                confirmTx={() =>
                                                    reveal(txData)}
                                                type={txModalType}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Users;
