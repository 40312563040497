import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Header from './Header';
import Sidebar from './Sidebar';
import ChatBox from './ChatBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { ImSearch } from "react-icons/im";
import { BsFunnel } from "react-icons/bs";
import io from 'socket.io-client';
import { Navigate } from 'react-router-dom';
import '../../css/chat.css';
import '../../css/style.css';
import "../../css/requests.css";
import "../../css/inventory.css";
import axios from 'axios';

const ChatSupport = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchColumn, setSearchColumn] = useState(null);
    const navigate = useNavigate();
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const id = currentUser.id;
    const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const location = useLocation();
    console.log(location)
    
    
    const [showModal, setShowModal] = useState(false);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [messenger, setMessenger] = useState("");
    const [responded, setResponded] = useState(false);
    const logoUrl = localStorage.getItem('logoUrl');
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [openRow, setOpenRow] = useState();
    const [socketInstance, setSocketInstance] = useState("");
    const locationState = location && location.state; // Extract state from location if available
    const initialTabValue = locationState && locationState.tab ? locationState.tab : "items"; 
    const initialChatValue = locationState && locationState.chatId ? locationState.chatId : null; 
    const [selectedTab, setSelectedTab] = useState(initialTabValue);
    const [chatIdToOpen, setChatIdToOpen] = useState(initialChatValue);
    const [receiver, setReceiver] = useState("");
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);
    const fetchChatData = async () => {
        if (selectedTab === 'items') {
            return await fetchChats(id);
        } else if (selectedTab === 'lots') {
            return await fetchGroupChats(id);
        } else {
            return [];
        }
    };
    useEffect(() => {
        // Check if it's a page reload
        if (performance.navigation.type === 1) {
          // If location.state exists, clear it on page reload
          if (location.state) {
            navigate(location.pathname, { state: undefined, replace: true });
          }
        }
      }, []);
    
    const fetchGroupChats = async (userId) => {
        try {
            const response = await fetch(`/get_group_chats?userId=${userId}`);
            const data = await response.json();
            const formattedData = data.map((chat) => ({
                Name: chat.lot,  // using lot name as it's a group chat
                MessageCount: chat.messageCount,
                Item: chat.lot,
                updateDate: new Date(chat.updatedOn),
                Status: chat.status,
                chatId: chat.chatId,
                chatEnabled: chat.chatEnabled,
            }));
            console.log(formattedData);
            return formattedData;
        } catch (error) {
            console.error('Error fetching group chats:', error);
            return [];
        }
    };
    
    
    const fetchChats = async (userId) => {
        try {
            const response = await fetch(`/get_chats?userId=${userId}`);
            const data = await response.json();
            const formattedData = data.map((chat) => ({
                Name: chat.otherCompanyName,
                MessageCount: chat.messageCount,
                Item: chat.item,
                updateDate: new Date(chat.updatedOn),
                Status: chat.status,
                chatId: chat.chatId,
                chatEnabled: chat.chatEnabled,
            }));
            console.log(formattedData);

            return formattedData;
        } catch (error) {
            console.error('Error fetching chats:', error);
            return [];
        }
    };

    useEffect(() => {
        setIsLoading(true)
        let tempColumns;
        if (selectedTab === 'items'){
            tempColumns = [
                // {
                //     name: "",
                //     selector: (row) =>
                //         <div className="avatar active:scale-[1.01] inline-block align-middle" style={{ padding: '0.5em 0' }}>
                //             <div className="w-5 rounded-full">
                //                 <img loading="lazy"  src={row.Image} alt="" />
                //             </div>
                //         </div>,
                //     style: { justifyContent: 'center' }
                // },
                {
                    name: "Name",
                    selector: (row) => row.Name
                },
                {
                    name: "Product Name",
                    selector: (row) => row.Item
                },
                {
                    name: "Message Count",
                    selector: (row) => row.MessageCount,
                    right:'true'
                },
                // {
                //     name: "Date",
                //     selector: (row) => row.updateDate.toLocaleString()
    
                // },
                {
                    name: "Date",
                    selector: (row) => row.updateDate.toLocaleString(),
                    cell: (row) => <div style={{ color: 'black' }}>{row.updateDate.toLocaleString()}</div>
                },
    
                {
                    name: "Status",
                    selector: (row) => row.Status,
                    cell: row => <div style={{ backgroundColor: `${row.Status === "Unread" ? '#F62D51' : row.Status === "Read" ? '#55CE63' : '#009EFB'}` }} onClick={() => handleShowModal(row)}>{row.Status}</div>,
                }
            ];

        }
        else{
            tempColumns = [
                {
                    name: "Product Name",
                    selector: (row) => row.Item
                },
                {
                    name: "Message Count",
                    selector: (row) => row.MessageCount,
                    right:'true'
                },
                // {
                //     name: "Date",
                //     selector: (row) => row.updateDate.toLocaleString()
    
                // },
                {
                    name: "Date",
                    selector: (row) => row.updateDate.toLocaleString(),
                    cell: (row) => <div style={{ color: 'black' }}>{row.updateDate.toLocaleString()}</div>
                },
    
                {
                    name: "Status",
                    selector: (row) => row.Status,
                    cell: row => <div style={{ backgroundColor: `${row.Status === "Unread" ? '#F62D51' : row.Status === "Read" ? '#55CE63' : '#009EFB'}` }} onClick={() => handleShowModal(row)}>{row.Status}</div>,
                }
            ];
        }
        
        fetchChatData().then((fetchedChats) => {
            setIsLoading(false);
            setRows(fetchedChats);
            setFilteredRows(fetchedChats);
        });
        

        setColumns(tempColumns);
        console.log(chatIdToOpen);

        window.matchMedia("(max-width: 1024px)").addEventListener('change', e => setMatches(e.matches));
    }, [selectedTab]);

    useEffect(() => {
        console.log("ROWS", rows)
        if (rows.length >= 1) {
            if (chatIdToOpen) {
                const row = rows.find((r) => r.chatId === chatIdToOpen);
                if (row) {
                    handleShowModal(row);
                }
            }
        }
    }, [rows, chatIdToOpen])

    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    }

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    }
    const handleShowDisclaimer = () => {
        setShowDisclaimer(true);
    };

    const handleCloseDisclaimer = () => {
        setShowDisclaimer(false);
    };

    const handleShowModal = (row) => {
        if (row.chatEnabled === 1) {
            openChat(row);
        } else {
            setOpenRow(row.chatId);
            handleShowDisclaimer();
        }
    };
    const handleAgree = async () => {
        try {
            await axios.post(`/enable_chat`, { userId: id });

            // Update chatEnabled for all rows
            setRows((prevRows) => {
                return prevRows.map((row) => {
                    return { ...row, chatEnabled: 1 };
                });
            });

            // Find the row with the current chatId
            console.log(chatIdToOpen);
            let rowIndex = -1;
            if (chatIdToOpen) {
                rowIndex = rows.findIndex((row) => row.chatId === chatIdToOpen);
            }
            else {
                rowIndex = rows.findIndex((row) => row.chatId === openRow);
            }

            if (rowIndex >= 0) {
                // Open the chat
                openChat(rows[rowIndex]);
            }
        } catch (error) {
            console.error("Error enabling chat:", error);
        }
        handleCloseDisclaimer();
    };
    const handleDropdownClick = (event, search) => {
        event.preventDefault();
        setSearchColumn(search);
      };
    
      const handleInputChange = (event) => {
        let userInput = event.target.value;
        userInput = userInput?.trim();
    
        if (userInput === "") {
          setFilteredRows(rows);
        } else {
          if (searchColumn) {
            if (searchColumn === "Item") {
              const result = rows.filter((search) =>
                search?.Item?.toLowerCase()?.includes(userInput?.toLowerCase())
              );
              setFilteredRows(result);
            } else if (searchColumn === "Name") {
              const result = rows.filter((search) =>
                search?.Name?.toLowerCase()?.includes(userInput?.toLowerCase())
              );
              setFilteredRows(result);
            }
          } else {
            let result;
            
            
              result = rows.filter((search) =>
                search?.Item?.toLowerCase()?.includes(userInput?.toLowerCase())
              );
            
    
    
            setFilteredRows(result);
          }
        }
      };



    const openChat = (row) => {
        console.log("IN")
        setMessenger(row.chatId);
        setReceiver(row.Name);
        const socket = io(`${process.env.REACT_APP_CHAT_URL}/`);
        // const socket = io();
        setSocketInstance(socket);

        window.onbeforeunload = () => {
            socket?.disconnect();
        }
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setRows(prevRows => {
            let tempRows = [...prevRows];
            const index = tempRows.findIndex(tempRow => tempRow.Name === messenger);

            if (index > -1) {
                const { Status } = prevRows[index];
                tempRows[index] = { ...tempRows[index], Status: `${Status === "Unread" && responded === false ? "Read" : responded === true ? "Responded" : Status}` };
                return tempRows;
            }

            return prevRows;
        });


        setShowModal(false);
        setResponded(false);
    }

    return (
        <div>
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header getCollapseSidebar={getCollapseSidebar} getCollapseDropdown={getCollapseDropdown} logOut={props.logOut} />
                    )}

                    <div className='admin_panel'>
                        <div className='d-lg-flex'>
                            <div className={`${collapseSidebar ? 'pinched_sidebar' : 'open_sidebar'}`}>
                                <Sidebar collapsed={collapseSidebar} collapsedDropdown={collapseDropdown} />
                            </div>

                            <div className={`inventory_list_content ${collapseSidebar ? 'open_content' : 'pinched_content'}`}>
                                {!matches && (
                                    <Header getCollapseSidebar={getCollapseSidebar} getCollapseDropdown={getCollapseDropdown} logOut={props.logOut} />
                                )}

                                <div className={`w-100 content_panel ${collapseSidebar ? 'slide-in' : 'slide-out'}`}>
                                    {/* <button onClick={handleShowDisclaimer} className="disclaimer-button">
                                        Disclaimer
                                    </button> */}
                                    <div style={{ height: '100vh' }}>
                                    <div className="d-flex justify-content-between">
    <h3 className='pageName_heading'>  
    {logoUrl && <img src={logoUrl} alt="Company Logo" style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }} />}Ongoing Chats</h3>
    
    {/* Spacer to push heading and button group apart */}
    <div className="flex-grow-1 d-flex justify-content-center">
        <div className="btn-group" role="group">
            <button
                type="button"
                className={`btn custom_btn ${selectedTab === 'items' ? 'btn-primary' : 'btn-light'}`}
                onClick={() => setSelectedTab('items')}
            >
                Items
            </button>
            <button
                type="button"
                className={`btn custom_btn ${selectedTab === 'lots' ? 'btn-primary' : 'btn-light'}`}
                onClick={() => setSelectedTab('lots')}
            >
                Lots
            </button>
        </div>
    </div>

    {/* Another Spacer */}
    <div></div>
</div>

                                        

                                        <div className="position-relative d-flex">
                       
                        <input
                          type="text"
                          placeholder={`Search ${searchColumn ? searchColumn : "Item"}`}
                          className="input py-[1.5em] px-[3em]"
                          onChange={(event) => handleInputChange(event)}
                        />
                        <button
                          className="request-filter dropdown-toggle"
                          type="button"
                          id="filterButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <BsFunnel
                            style={{ color: "black", fontSize: "18px" }}
                          />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="filterButton"
                          style={{ zIndex: "1", fontSize: "14px" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Name")
                              }
                            >
                              Filter By User Name
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Item")
                              }
                            >
                              Filter By Item Name
                            </a>
                          </li>
                          {/* <li>
                            <a
                              className="dropdown-item"
                              href="#/"
                              onClick={(event) =>
                                handleDropdownClick(event, "Status")
                              }
                            >
                              Filter By Status
                            </a>
                          </li> */}
                        </ul>
                      </div>
                                        <div style={{ backgroundColor: '#FEFEFE' }}>
                                            <div className="table-responsive custom_table_container customCard">
                                                {
                                                    isLoading ? (
                                                        <div className="d-flex justify-content-center">
                                                            <br />
                                                            <div className="spinner-border text-info" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                        </div>
                                                    ) : (

                                                        <DataTable
                                                            columns={columns}
                                                            data={filteredRows}
                                                            pagination={true}
                                                            onRowClicked={(row) => handleShowModal(row)}
                                                            rowHeight={40}
                                                            rowStyle={(row, rowIndex) => ({
                                                                ...(rowIndex % 2 === 0 && row.id === "row-0" ? { backgroundColor: "#f5f5f5" } : {})
                                                            })}
                                                        />
                                                    )
                                                }
                                            </div>
                                            {/* <div className="table-overflow">
                                                <div className="datatable-filter">
                                                    <div className="card">
                                                        <div className="table-overflow chat">
                                                            <DataTable
                                                                columns={columns}
                                                                data={rows}
                                                                pagination={true}
                                                                onRowClicked={(row) => handleShowModal(row)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                        <Modal show={showModal} onHide={handleCloseModal}>
                                            <ChatBox messenger={messenger} setResponded={setResponded} handleCloseModal={handleCloseModal} socket={socketInstance} receiver={receiver} tab = {selectedTab} />
                                        </Modal>
                                        <Modal show={showDisclaimer} onHide={handleCloseDisclaimer}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Disclaimer</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                Welcome to our chat! We uphold the privacy of our users, and do not sell, communicate or divulge your information. Users are advised against sharing any personal, private or other sensitive information (including, without limitation, name, address, email address, contact number) and any such information shared will be at the user’s own risk. We undertake no obligation or liability in relation to the exchange of information between users. We reserve the right to terminate a user account for violation of the privacy policy
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={handleAgree}>
                                                    Agree
                                                </Button>
                                                <Button variant="secondary" onClick={handleCloseDisclaimer}>
                                                    Disagree
                                                </Button>

                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default ChatSupport;