import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "./Tooltip";
import { Button } from "react-bootstrap";
import "../../css/style.css";
import "../../css/inventory.css";
import { type } from "@testing-library/user-event/dist/type";

const ViewItem = (props) => {
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [image, setImage] = useState(require("../../images/no-image.jpg"));
  const [item, setItem] = useState({});
  const [pcount, setPcount] = useState(0);
  const [newLabels, setNewLabels] = useState([]);
  const [counter, setCounter] = useState(0);
  const [initialItem, setInitialItem] = useState({});
  const [additionFields, setAdditionFields] = useState();
  const [additionVals, setAdditionVals] = useState();
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const imageInput = useRef();
  const [formFields, setFormFields] = useState([]);
  const [images, setImages] = useState([]);
  const [types, setTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [inputImages, setInputImages] = useState([]);
  const [inputFiles, setInputFiles] = useState([]);
  const [docName, setDocName] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [delImg, setDelImg] = useState([]);
  const [delDoc, setDelDoc] = useState([]);
  // const [flag, setFlag] = useState(true);
  const [newLabel, setNewLabel] = useState("");
  const [typeObj, setTypeObj] = useState([]);
  //const [newFields, setNewFields] = useState({});
  const [dynamicFields, setDynamicFields] = useState([]);
  console.log(typeObj)
  const handleLabelInput = (e) => {
    setNewLabel(e.target.value);
  };

  const addFormField = () => {
    setNewLabels([...newLabels, newLabel]);
    setDynamicFields((prevFields) => {
      const newField = {
        label: newLabel,
        name: `new-field${counter}`,
        placeholder: "Enter New Field",
        value: initialItem[newLabels[counter]],
        type: "text",
      };
      return [...prevFields, newField];
    });
    setCounter(counter + 1);
  };

  useEffect(() => {
    axios
      .get(`/get_item/${location.state.InventoryNumber}`)
      .then((res) => {
        console.log(res.data);
        setInitialItem(res.data.item[0]);
        setAdditionFields(res.data.item[0].extendedFieldName?.split(","));

        setAdditionVals(res.data.item[0].extendedFieldValues?.split(","));
        console.log(additionVals);

        const formattedDate = moment(
          new Date(res.data.item[0].itemExpiry)
        ).format("YYYY-MM-DD");
      })
      .catch((error) => {
        console.log(error);
      });

    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    if (Object.keys(initialItem).length > 0) {
      if (pcount === 0) {
        console.log("ANDARRRR", initialItem);
        setAdditionFields(initialItem.extendedFieldName?.split(","));
        console.log(additionFields);
        setAdditionVals(initialItem.extendedFieldValues?.split(","));
        axios.get("/get_documentTypes").then((response) => {
          console.log(response.data);
          const newArr = response.data.map((type) => type.documentType);
          setTypes(newArr);
        });
        const imageArr = [];
        let filesfromAPI = [];
        imageArr.push(initialItem.itemImage);
        additionFields?.forEach((field, index) => {
          if (field === "document") {
            filesfromAPI.push(additionVals[index]);
          }
          if (field === "images") {
            console.log("INNNN", additionVals[index]);
            imageArr.push(additionVals[index]);
          }
        });
        console.log(filesfromAPI);
        setImages(imageArr);
        setFiles(filesfromAPI);
        setDocName(initialItem.extendedDoc?.split(","));
        setFileName(initialItem.extendedFileNames?.split(","));
        
        setPcount(pcount + 1);
      }
      const fileDocPairs = files.map((file, index) => ({ file: file, docname: docName[index] }));
      setTypeObj(fileDocPairs)
      console.log(additionFields);
      let formattedDate = ""; // Declare outside the if statement

      if (initialItem.itemExpiry) {
        formattedDate = moment(new Date(initialItem.itemExpiry)).format("YYYY-MM-DD");
      }
      
      console.log(files);

      setFormFields([
        {
          label: "Size",
          name: "size",
          placeholder: "Enter Size",
          type: "number",
          value: initialItem.itemSize,
          step: "any",
        },
        {
          label: "Physical State",
          name: "physical-state",
          placeholder: "Enter Physical State",
          value: initialItem.itemState,
          type: "text",
        },
        {
          label: "Grade Standard",
          name: "grade-standard",
          placeholder: "Enter Grade Standard",
          value: initialItem.itemGrade,
          type: "text",
        },
        {
          label: "Material Number",
          name: "material-number",
          placeholder: "Enter Material Number",
          value: initialItem.itemMaterialNumber,
          type: "text",
        },
        {
          label: "Expiry Date",
          name: "expiry-date",
          placeholder: "Enter Expiry Date",
          value: formattedDate,
          type: "date",
        },
        {
          label: "Quantity",
          name: "quantity",
          placeholder: "Enter Quantity",
          type: "number",
          value: initialItem.itemQuantity,
          step: "any",
        },
        {
          label: "Minimum Buying Quantity",
          name: "min-buy-quantity",
          placeholder: "Enter Minimum Buying Quantity",
          type: "number",
          value: initialItem.itemMinQuantity,
          step: "any",
        },
        ...additionFields.reduce((acc, fieldName, index) => {
          if (fieldName !== "images" && fieldName !== "document") {
            acc.push({
              label: fieldName,
              name: fieldName,
              value: additionVals[index],
              type: "text",
            });
          }
          return acc;
        }, []),
      ]);
    }
  }, [initialItem]);

  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };

  
  

  const handleCancel = () => {
    navigate("/admin/item-listing/inventory-list");
  };
  

  const values = [
    "Raw Materials",
    "Packaging Materials",
    "Semi Finished Products",
    "Finished Products",
    "Machines & Tools",
    "Machine Spare Parts",
    "Scrap Material",
  ];
  const extractUUIDAndExtension = (url) => {
    if (typeof url !== 'string') {
        console.error("The provided URL is not a string:", url);
        return null;
    }

    const segments = url.split("/");
    const fullFilename = segments[segments.length - 1].split("?")[0];
    return fullFilename;
};

  

  return (
    <div className="create_items">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}
          {initialItem && (
            <div className="admin_panel">
              <div className="d-lg-flex">
                <div
                  className={`${
                    collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                  }`}
                >
                  <Sidebar
                    collapsed={collapseSidebar}
                    collapsedDropdown={collapseDropdown}
                  />
                </div>
                <div
                  className={`inventory_list_content ${
                    collapseSidebar ? "open_content" : "pinched_content"
                  }`}
                >
                  {!matches && (
                    <Header
                      getCollapseSidebar={getCollapseSidebar}
                      getCollapseDropdown={getCollapseDropdown}
                      logOut={props.logOut}
                    />
                  )}

                  <div
                    className={`w-100 ${
                      collapseSidebar ? "slide-in" : "slide-out"
                    }`}
                  >
                    <div className="content_panel">
                      <h4 className="pageName_heading">Update Item</h4>
                      <form onSubmit={() => {}}>
                        <div className="createItem d-md-flex">
                          <div
                            className="upload_image"
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              
                              multiple
                              required={!(images && images.length > 0) && !(inputImages && inputImages.length > 0)}
                              disabled
                              
                            />
                            {images &&
                              images.length > 0 &&
                              images.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    flex: "0 0 25%",
                                    padding: "10px",
                                  }}
                                >
                                  <img loading="lazy" 
                                    src={`${image}`}
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                  {/* <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      backgroundColor: "#ffffff",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="12"
                                      height="12"
                                      fill="#000000"
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z" />
                                    </svg>
                                  </span> */}
                                </div>
                              ))}

                            {inputImages &&
                              inputImages.length > 0 &&
                              inputImages.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    flex: "0 0 25%",
                                    padding: "10px",
                                  }}
                                >
                                  <img loading="lazy" 
                                    src={URL.createObjectURL(image)}
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                  {/* <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      backgroundColor: "#ffffff",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleImageDelete2(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="12"
                                      height="12"
                                      fill="#000000"
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z" />
                                    </svg>
                                  </span> */}
                                </div>
                              ))}
                          </div>
                          <div className="buttons_group">
                            <button
                              type="button"
                              className="cancel"
                              onClick={handleCancel}
                            >
                              Back
                            </button>
                            {/* <button type="submit" className="done">
                              <AiOutlineCheckCircle />
                              Update
                            </button> */}
                          </div>
                        </div>
                        <div className="secondaryContainer">
                          <span>Core Information</span>
                          <br />
                          <span>
                            <small>
                              <RiErrorWarningLine
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              Specify the primary description for your item.
                            </small>
                          </span>
                        </div>

                        <div className="createItemForm">
                          <div className="row" style={{ marginTop: "0" }}>
                            <div className="col-md-3">
                              <label>
                                Item Name <span>*</span>
                              </label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  value={initialItem.itemName}
                                  className="form-control"
                                  placeholder="Enter Item Name"
                                  disabled
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>
                                Target Industry <span>*</span>
                              </label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <select
                                  name="industry"
                                  className="form-select"
                                  defaultValue=""
                                  value={initialItem.itemIndustry}
                                  
                                  required
                                  disabled
                                >
                                  <option value="" hidden>
                                    Select Target Industry
                                  </option>
                                  <option value="aerospace">Aerospace</option>
                                  <option value="agriculture">
                                    Agriculture
                                  </option>
                                  <option value="computer">Computer</option>
                                  <option value="construction">
                                    Construction
                                  </option>
                                  <option value="education">Education</option>
                                  <option value="electronics">
                                    Electronics
                                  </option>
                                  <option value="energy">Energy</option>
                                  <option value="entertainment">
                                    Entertainment
                                  </option>
                                  <option value="food">Food</option>
                                  <option value="healthcare">
                                    Health Care
                                  </option>
                                  <option value="hospitality">
                                    Hospitality
                                  </option>
                                  <option value="manufacturing">
                                    Manufacturing
                                  </option>
                                  <option value="mining">Mining</option>
                                  <option value="music">Music</option>
                                  <option value="news-media">News Media</option>
                                  <option value="pharmaceutical">
                                    Pharmaceutical
                                  </option>
                                  <option value="telecommunication">
                                    Telecommunication
                                  </option>
                                  <option value="transport">Transport</option>
                                  <option value="world-wide-web">
                                    World Wide Web
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>
                                Category <span>*</span>
                              </label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <select
                                  name="category"
                                  value={initialItem.itemCategory}
                                  className="form-select"
                                  disabled
                                  required
                                >
                                  {values.map((value, index) => {
                                    return (
                                      <option value={value} key={index}>
                                        {value}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-1 col-md-2 tooltip_container">
                              <Tooltip content="Enter the best known keyword which can be used to describe your item.">
                                <BsInfoCircle />
                              </Tooltip>
                            </div>
                          </div>
                          {/* <div className="row">
                          <div className="col-md-3">
                            <label>Bid Choice <span>*</span></label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <select name="bid-choice" className="form-select" defaultValue="" value = {initialItem[4]} required>
                                <option value="" hidden>Select Bid Choice</option>
                                <option value="Fixed Price">Fixed Price</option>
                                <option value="Open Bidding">Open Bidding</option>
                                <option value="Close Bidding">Close Bidding</option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                          <div className="row">
                            <div className="col-md-3">
                              <label>Packaging <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="packaging"
                                  className="form-control"
                                  placeholder="Enter Packaging"
                                  value={initialItem.itemPackaging}
                                  disabled
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-1 col-md-2 tooltip_container">
                              <Tooltip content="How is your item packed with respect to its state e.g., drums for liquid etc.">
                                <BsInfoCircle />
                              </Tooltip>
                            </div>
                          </div>
                          {/* <div className="row">
                          <div className="col-md-3">
                            <label>Documents</label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="file"
                                name="documents"
                                className="form-control"
                                placeholder="Choose file"
                                onChange={handleFileChange}
                                multiple
                              />
                              {files &&
                                files.length > 0 &&
                                files.map((file, index) => (
                                  <div
                                    key={index}
                                    style={{ flex: "0 0 25%", padding: "10px" }}
                                  >
                                    <span>{file.name}</span>
                                    <select
                                      className="form-select"
                                      type="text"
                                      placeholder="Enter file name"
                                      onChange={(e) => handleFileName(index, e)}
                                    >
                                      {types.map((option, optionIndex) => (
                                        <option
                                          key={optionIndex}
                                          value={option}
                                        >
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="How is your item packed with respect to its state e.g., drums for liquid etc.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div> */}
                          <div className="row">
                            <div className="col-md-3">
                              <label>Documents  <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="file"
                                  name="documents"
                                  className="form-control"
                                  accept=".pdf"
                                  placeholder="Choose file"
                                  
                                  multiple
                                  required={!(fileName && fileName.length > 0)}
                                  disabled
                                />

                                {fileName &&
                                  fileName.length > 0 &&
                                  fileName.map((file, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        flex: "0 0 25%",
                                        padding: "10px",
                                      }}
                                    >
                                        <span>
                                        {fileName[index]}{" "}
                                        
                                      </span>
                                      

                                      <select
                                        className="form-select"
                                        type="text"
                                        placeholder="Enter file name"
                                        value={docName[index]}
                                        disabled
                        
                                      >
                                        {types.map((option, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={option}
                                          >
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="col-1 col-md-2 tooltip_container">
                              <Tooltip content="Please upload any relevant documents or files related to this item. Only enter PDFs.">
                                <BsInfoCircle />
                              </Tooltip>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>Price <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="price"
                                  className="form-control"
                                  placeholder="Enter Price"
                                  value={initialItem.itemPrice}
                                  disabled
                                  step="0.01"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>Unit of Measurement <span>*</span></label>
                            </div>
                            <div className="col-11 col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="uom"
                                  className="form-control"
                                  placeholder="Enter UoM"
                                  value={initialItem.itemUom}
                                  disabled
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <label>Description <span>*</span></label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <textarea
                                  rows="4"
                                  name="description"
                                  className="form-control"
                                  value={initialItem.itemDesc}
                                  style={{ resize: "none" }}
                                  placeholder="Enter Description"
                                  
                                  required
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="secondaryContainer">
                          <span>Extended Information</span>
                          <br />
                          <span>
                            <small>
                              <RiErrorWarningLine
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              Specify other additional details for your item.
                            </small>
                          </span>
                        </div>

                        <div className="createItemForm">
                          {initialItem &&
                            formFields.map((field, index) => (
                              <div className="row" key={index}>
                                <div className="col-md-3">
                                  <label>
        {field.label} {field.name === 'quantity' || field.name === 'min-buy-quantity' ? <span>*</span> : null}
      </label>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-group">
                                    {field.type === "date" ? (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        //className="form-control"
                                        placeholder={field.placeholder}
                                        value={field.value}
                                        
                                        style={{
                                          border: "1px solid #ced4da",
                                          borderRadius: "0.25rem",
                                          padding: "0.375rem 0.75rem",
                                          backgroundColor:"white"
                                        }}
                                        disabled
                                        // required
                                      />
                                    ) : (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        value={field.value}
                                        className="form-control"
                                        placeholder={field.placeholder}
                                        
                                        required={field.name === 'quantity' || field.name === 'min-buy-quantity'}
                                        disabled
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* <div className="row">
                            <div className="col-md-3">
                              <label>Enter New Label:</label>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="new-label"
                                  className="form-control"
                                  placeholder="Enter Label"
                                  value={newLabel}
                                  onChange={handleLabelInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-5 offset-md-3">
                              <Button
                                className="add-field-button"
                                onClick={addFormField}
                                disabled={newLabel === ""}
                              >
                                Add Label
                              </Button>
                            </div>
                          </div> */}
                        {/* </div> */}
                        {/* <div className="secondaryContainer">
                          <span>Additional Information</span>
                          <br />
                          <span>
                            <small>
                              <RiErrorWarningLine
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              Specify other additional details for your item.
                            </small>
                          </span>
                        </div> */}
                        {/* <div className="createItemForm"> */}
                          {initialItem &&
                            dynamicFields.map((field, index) => (
                              <div className="row" key={index}>
                                <div className="col-md-3">
                                <label>
        {field.label} {field.name === 'quantity' || field.name === 'min-buy-quantity' || field.type === 'date'? <span>*</span> : null}
      </label>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-group">
                                    {field.type === "date" ? (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        //className="form-control"
                                        placeholder={field.placeholder}
                                        value={field.value}
                                        
                                        style={{
                                          border: "1px solid #ced4da",
                                          borderRadius: "0.25rem",
                                          padding: "0.375rem 0.75rem",
                                          backgroundColor: "#ffffff",
                                          color: "#000000",
                                        }}
                                        min={new Date().toISOString().split('T')[0]}
                                        required
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type={field.type}
                                        step={field.step}
                                        name={field.name}
                                        value={field.value}
                                        className="form-control"
                                        placeholder={field.placeholder}
                                        required={field.name === 'quantity' || field.name === 'min-buy-quantity'}
                                        min={(field.name === 'quantity' || field.name === 'min-buy-quantity') ? 1 : undefined}
                                        disabled
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ViewItem;
