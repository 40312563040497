// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqContainer {
    position: relative;
    z-index: 2;
    grid-gap: 30px 0;
}

.faqItem {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    padding: 1em 1.5em;
    border-radius: 10px;
    z-index: 1;
    background-color: white;
}
.faq_heading{
    font-size: 25px;
    line-height: 1.6;
    font-weight: 500;
    color: #333;
}
.no-faq-matches {
    text-align: center;
}

.faqQuestion {
    font-size: 20px;
    margin: 0;
    text-rendering: optimizeLegibility;
    color: #232f3e;
    font-weight: 500;
}


.faqAnswer {
    padding-left: 40px;
    position: relative;
    line-height: 1.6;
    color: #232F3E;
}

.faqAnswer:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #26875C;
    width: 20px;
    height: 2px;
}

@media (max-width: 768px) {
    .faqContainer {
        padding: 0 5%;
    }
}

@media (max-width: 992px) {
    .companyLogo {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/FAQ.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,sFAAsF;IACtF,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;IACV,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,kCAAkC;IAClC,cAAc;IACd,gBAAgB;AACpB;;;AAGA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,yBAAyB;IACzB,WAAW;IACX,WAAW;AACf;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".faqContainer {\n    position: relative;\n    z-index: 2;\n    grid-gap: 30px 0;\n}\n\n.faqItem {\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n    padding: 1em 1.5em;\n    border-radius: 10px;\n    z-index: 1;\n    background-color: white;\n}\n.faq_heading{\n    font-size: 25px;\n    line-height: 1.6;\n    font-weight: 500;\n    color: #333;\n}\n.no-faq-matches {\n    text-align: center;\n}\n\n.faqQuestion {\n    font-size: 20px;\n    margin: 0;\n    text-rendering: optimizeLegibility;\n    color: #232f3e;\n    font-weight: 500;\n}\n\n\n.faqAnswer {\n    padding-left: 40px;\n    position: relative;\n    line-height: 1.6;\n    color: #232F3E;\n}\n\n.faqAnswer:before {\n    content: '';\n    position: absolute;\n    left: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    background-color: #26875C;\n    width: 20px;\n    height: 2px;\n}\n\n@media (max-width: 768px) {\n    .faqContainer {\n        padding: 0 5%;\n    }\n}\n\n@media (max-width: 992px) {\n    .companyLogo {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
