import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { styled } from '@mui/system';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import "../../css/style.css";
import "../../css/muiDataTable.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUp from "../helperComponents/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import "../../css/requests.css";
import "../../css/inventory.css";
import "../../css/style.css";
import RolePieChart from "./RolePieChart";
import CustomDownloader from "./CustomDownloader";
//import { BsPersonFillCheck, BsPersonFillSlash } from "react-icons/bs";
const UserReports = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState("");
    const [selectedTab, setSelectedTab] = useState("existing");
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showTxModal, setShowTxModal] = useState(false);
    const [txModalType, setTxModalType] = useState();
    const [txTitle, setTxTitle] = useState("");
    const [txMsg, setTxMsg] = useState("");
    const [txData, setTxData] = useState();
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );

    const styles = {
        container: {
            height: "500px", // set the height of the container
        },
    };

    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    const timeOut = () => {
        const timeId = setTimeout(() => {
            setShowTxModal(false)
        }, 2000)
        return () => {
            clearTimeout(timeId)
        }
    }
    useEffect(() => {
        const getUsersData = async () => {
            setIsLoading(true);
            await axios({
                method: "get",
                url: "/dashboard/get_users",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    const dynamicColumns = response?.data?.columns.map((columnName) => {
                        //   return { field: columnName, headerName: columnName, width: columnName === 'User Email' ? 250 : 150 };
                        return { field: columnName, headerName: columnName, width: 200 };
                    });

                    setColumns([...dynamicColumns]);
                    setRows(response?.data?.rowData);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setAlertError("something went wrong");
                    setIsLoading(false);
                    console.log("Get Filters Api: ", error);
                });
        };


        if (selectedTab === 'existing') {
            getUsersData();
        }



    }, [selectedTab])


    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };







    const filteredRows = searchQuery
        ? rows?.filter((row) =>
            Object.values(row).some((value) =>
                String(value)?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            )
        )
        : rows;

    const onAddUserClick = () => {
        navigate("/admin/admin-section/add-user");
    }
    return (
        <div className="create_items">
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header
                            getCollapseSidebar={getCollapseSidebar}
                            getCollapseDropdown={getCollapseDropdown}
                            logOut={props.logOut}
                        />
                    )}

                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}

                                <div
                                    className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                                        }`}
                                >
                                    <div className="content_panel container-fluid row_gap">
                                        <div className="purchase_request d-flex justify-content-between custom_section">
                                            <h3 className="pageName_heading">Users</h3>




                                        </div>
                                        {/* <h3 className="pageName_heading">Users</h3> */}
                                        <div className="d-sm-flex justify-content-center">
                                            <div style={{ flexBasis: '50%' }}>
                                                <div className="row justify-content-between">
                                                    <div className="col-md-5">
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            className="input py-[1.5em] px-[3em]"
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ flexBasis: '50%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <RolePieChart />
                                            </div>
                                        </div>

                                        <div style={styles.container} className="row customCard">
                                            <GridContextProvider>
                                                <DataGrid rows={filteredRows ? filteredRows : []} columns={columns}
                                                    getRowId={(row) => row['ID']}
                                                    loading={isLoading}
                                                    rowHeight={40}
                                                    // rowsPerPageOptions={[5]}
                                                    pagination
                                                    density="compact"
                                                    disableExtendRowFullWidth={true}
                                                    disableSelectionOnClick={false}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                                                    }
                                                />
                                            </GridContextProvider>

                                        </div>
                                        {rows && rows.length > 0 && (
                
                <CustomDownloader
                    rows={rows}
                    title={'User Reports'}
                />
                
        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default UserReports;
