import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const dailyQuantitySalesData = [
  {
    day: 'Mon',
    sales: 4

  },
  {
    day: 'Tue',
    sales: 8

  },
  {
    day: 'Wed',
    sales: 6
  },
  {
    day: 'Thur',
    sales: 12
  },
  {
    day: 'Fri',
    sales: 19
  },
  {
    day: 'Sat',
    sales: 24
  },
  {
    day: 'Sun',
    sales: 32
  }
];

const monthlyQuantitySalesData = [
  {
    month: 'Jan',
    sales: 30

  },
  {
    month: 'Feb',
    sales: 20

  },
  {
    month: 'Mar',
    sales: 90
  },
  {
    month: 'Apr',
    sales: 60
  },
  {
    month: 'May',
    sales: 53
  },
  {
    month: 'Jun',
    sales: 24
  },
  {
    month: 'Jul',
    sales: 68
  },
  {
    month: 'Aug',
    sales: 12
  },
  {
    month: 'Sep',
    sales: 80
  },
  {
    month: 'Oct',
    sales: 92
  },
  {
    month: 'Nov',
    sales: 99
  },
  {
    month: 'Dec',
    sales: 91
  }
];

const yearlyQuantitySalesData = [
  {
    year: '2020',
    sales: 32890

  },
  {
    year: '2021',
    sales: 784329

  },
  {
    year: '2022',
    sales: 73411
  }
];

const dailyValueSalesData = [
  {
    day: 'Mon',
    sales: 120

  },
  {
    day: 'Tue',
    sales: 80

  },
  {
    day: 'Wed',
    sales: 16
  },
  {
    day: 'Thur',
    sales: 22
  },
  {
    day: 'Fri',
    sales: 199
  },
  {
    day: 'Sat',
    sales: 98
  },
  {
    day: 'Sun',
    sales: 132
  }
];

const monthlyValueSalesData = [
  {
    month: 'Jan',
    sales: 300

  },
  {
    month: 'Feb',
    sales: 220

  },
  {
    month: 'Mar',
    sales: 490
  },
  {
    month: 'Apr',
    sales: 160
  },
  {
    month: 'May',
    sales: 153
  },
  {
    month: 'Jun',
    sales: 324
  },
  {
    month: 'Jul',
    sales: 98
  },
  {
    month: 'Aug',
    sales: 102
  },
  {
    month: 'Sep',
    sales: 89
  },
  {
    month: 'Oct',
    sales: 92
  },
  {
    month: 'Nov',
    sales: 199
  },
  {
    month: 'Dec',
    sales: 191
  }
];

const yearlyValueSalesData = [
  {
    year: '2020',
    sales: 768900

  },
  {
    year: '2021',
    sales: 343291

  },
  {
    year: '2022',
    sales: 973411
  }
];

class InventorySalesTrendGraph extends PureComponent 
{
  constructor(props)
  {
    super(props);
    this.state = {
      timePeriod: this.props.timePeriod,
      data: this.props.graph === "quantityGraph" ? monthlyQuantitySalesData : monthlyValueSalesData
    }; 
  }

  static getDerivedStateFromProps(nextProps, prevState) 
  {
    if (nextProps.timePeriod !== prevState.timePeriod) 
    {
      if (nextProps.graph === "quantityGraph")
      {
        if (nextProps.timePeriod === "day")
        {
          return ({ timePeriod: nextProps.timePeriod, data: dailyQuantitySalesData });
        }
        else if (nextProps.timePeriod === "month")
        {
          return ({ timePeriod: nextProps.timePeriod, data: monthlyQuantitySalesData });
        }
        else if (nextProps.timePeriod === "year")
        {
          return ({ timePeriod: nextProps.timePeriod, data: yearlyQuantitySalesData });
        }
      }
      else if (nextProps.graph === "valueGraph")
      {
        if (nextProps.timePeriod === "day")
        {
          return ({ timePeriod: nextProps.timePeriod, data: dailyValueSalesData });
        }
        else if (nextProps.timePeriod === "month")
        {
          return ({ timePeriod: nextProps.timePeriod, data: monthlyValueSalesData });
        }
        else if (nextProps.timePeriod === "year")
        {
          return ({ timePeriod: nextProps.timePeriod, data: yearlyValueSalesData });
        }
      }
    }
    
    return null;
  }

  render() 
  {
    return (
      <div style = {{ height: '41.5vh' }}>
        <ResponsiveContainer width = "100%" height = "90%">
          <BarChart
            data = {this.state.data}
            margin = {{ top: 40, bottom: 10 }}
            barCategoryGap = {16}
          >
            <CartesianGrid vertical = {false} strokeDasharray = "3 3" />
            <XAxis dataKey = {this.props.timePeriod} interval = {0} />
            <YAxis type = 'number' allowDataOverflow = {true}/>
            <Tooltip />
            <Bar dataKey = "sales" barSize = {10} fill = "#6CA5DA" plot style = {{ clipPath: 'inset(0% 0% -2px 0% round 10px)' }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default InventorySalesTrendGraph;