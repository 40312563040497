import React, { useState } from 'react';
import Header from '../header/Header';
import { Row } from 'react-bootstrap';
import axios from 'axios';
import '../../css/contact.css';
import '../../css/style.css';
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import '../../css/style.css';
import "../../css/inventory.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "react-bootstrap";
const ContactUs = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: currentUser ? currentUser.username : '',
        email: currentUser ? currentUser.email : '',
        subject: '',
        message: '',
        country: '',
        contactNumber: '',
        linkedin: '',
        url: '',
        company: currentUser ? currentUser.companyName : '',
        designation: currentUser ? currentUser.designation : '',
        userId: currentUser ? currentUser.id : -1
    });

    const handleChange = (e) => {
        const value = e.target.type === 'file' ? e.target.files[0] : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, contactNumber: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const { name, email, subject, message, contactNumber, company, designation, linkedin, url } = formData;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        
        if (!name || !email || !subject || !message || !contactNumber || !company || !designation || !linkedin || !url) {
            toast.error("All fields are required.");
            return;
        }
        
        if (!email.match(emailPattern)) {
            toast.error("Invalid email address.");
            return;
        }

    
    
        // Send a POST request
        axios.post('/add_contact_us', formData)
             .then(response => {
                 console.log(response.data);
                 if(response.data.data === "Entry Successfully Added") {
                    toast.success("Thank you for contacting us. Our customer team will contact you.");
                    navigate("/");
                     // Handle success - maybe show a success message or redirect user
                 }
             })
             .catch(error => {
                 console.error("Error submitting form:", error);
                 // Handle error - maybe show an error message to the user
             });
    }

    return (
        <div className='contact_page' style={{ height: '100vh', overflow: 'auto' }}>
        <Header logOut={props.logOut} />

        {/* Deducting an estimated height for the Header (e.g., 10vh). Adjust as necessary. */}
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
    <div className="card contact_us_card" style={{ width: '50%' }}>
        <div className="card-body">
            <h4 className="pageName_heading">Request a Demo</h4>
            <div className="createItemForm">
                
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>Name <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            className="form-control"
                            placeholder="Enter Name"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>Email <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            className="form-control"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                

      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-3">
          <label>Contact Number <span>*</span></label>
        </div>
        <div className="col-md-5">
          <PhoneInput
            defaultCountry={formData.country}
            value={formData.contactNumber}
            placeholder="Enter phone number (without country code)"
            onChange={handlePhoneChange}
            required
          />
        </div>
      </div>
      <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>Company Name <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="company"
                            name="company"
                            value={formData.company}
                            className="form-control"
                            placeholder="Enter company"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>Designation <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="designation"
                            name="designation"
                            value={formData.designation}
                            className="form-control"
                            placeholder="Enter designation"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>Company URL <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="url"
                            name="url"
                            value={formData.url}
                            className="form-control"
                            placeholder="Enter url"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>LinkedIn <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="linkedin"
                            name="linkedin"
                            value={formData.linkedin}
                            className="form-control"
                            placeholder="Enter linkedin"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                {/* <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>Contact Number <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="text"
                            name="contactNumber"
                            className="form-control"
                            placeholder="Enter Contact Number"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div> */}

                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3">
                        <label>Subject <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <input
                            type="text"
                            name="subject"
                            className="form-control"
                            placeholder="Enter Subject"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="row" style={{ marginBottom: '25px' }}>
                    <div className="col-md-3">
                        <label>Message <span>*</span></label>
                    </div>
                    <div className="col-md-5">
                        <textarea
                            rows="25"
                            name="message"
                            className="form-control message-textarea" 
                            style={{ resize: "none", height: "150px" }} 
                            placeholder="Enter Message"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>


                <div className="text-center" style={{ marginTop: '20px' }}>
                    <button type="submit" className="btn btn-primary custom_btn" onClick={(e) => handleSubmit(e)}>Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>





        </div>
    );
}

export default ContactUs;