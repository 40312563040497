import React from 'react';
import { useState, useEffect, useRef } from "react";
import Header from '../header/Header';
import Logo from '../../images/Logo.png';
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { Button, Row, Col, Card, ToggleButton, Modal, Form } from 'react-bootstrap';
import { BsCheck, BsX, BsXCircle, BsCheckCircle } from 'react-icons/bs';
import axios from "axios";
import '../../css/style.css';
import '../../css/pricing.css';

const Pricing = (props) => {
    const [pubKey, setPubKey] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const [plantoSend, setPlantoSend] = useState("");
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const [checkoutResponse, setCheckoutResponse] = useState(null);
    const [planDetails, setPlanDetails] = useState(null);
    const [error, setError] = useState('');
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [isYearly, setIsYearly] = useState(false);



    const navigate = useNavigate();
    
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);
    const hasApiBeenCalledRef = useRef(false);

    // Get the value of session_id from the query parameters
    const session_id = queryParams.get("session_id");
    const plan_made = queryParams.get("plan");

    useEffect(() => {
      console.log("useEffect triggered", { session_id, plan_made });
      async function fetchCheckoutSessionDetails() {
        if (hasApiBeenCalledRef.current) {
          return;
        }
        console.log("INSIDE FUNCTION", { session_id, plan_made });
        try {
          const res = await fetch("/retrieve-checkout-session-details", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ session_id: session_id }),
          });
          const data = await res.json();
          
          setCheckoutResponse(data?.response);
          
          if (data?.response?.payment_status === "paid" && !isUpdatingSubscription) {
            setIsUpdatingSubscription(true);
            hasApiBeenCalledRef.current = true;
            setShowModal(true);
            try {
              await axios.post('/update_subscription', {
                userId: currentUser.id,
                plan: plan_made
              });
              
            } catch (error) {
              console.error('Error updating subscription:', error);
            }
            finally{
              setIsUpdatingSubscription(false);
            }
    
            // setShowModal(true);
          } 
          
        } catch (error) {
          console.error("Error fetching API data:", error);
        }
      }
    
      if (session_id !== null && session_id !== "" && plan_made !== null && plan_made !== "") {
        fetchCheckoutSessionDetails();
      }
    }, []);

    useEffect(() => {
      const fetchSubscriptionDetails = async () => {
        try {
          const userId = currentUser.id
          const response = await axios.get(`/get_subscription_details`, { params: { userId } });
          setSubscriptionDetails(response.data);
          
        } catch (err) {
          setError(err.response?.data?.error || 'An error occurred');
        }
      };
  
      if (currentUser) {
        fetchSubscriptionDetails();
      }
    }, []);
    
    
    useEffect(() => {
        fetch("/config")
          .then((result) => result.json())
          .then((data) => {
            setPubKey(data?.publicKey);
          });
      }, []);

      useEffect(() => {
        const fetchPlanDetails = async () => {
          try {
            const response = await axios.get('/get_plan_details');
            const sortedPlanDetails = sortPlans(response.data);
            setPlanDetails(sortedPlanDetails);
            // setPlanDetails(response.data);
          } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while fetching plan details');
          }
        };
    
        fetchPlanDetails();
      }, []);

      const planOrder = ['Free', 'Standard', 'Advanced', 'Enterprise'];

      function sortPlans(planDetails) {
          return Object.entries(planDetails)
              .sort(([planNameA], [planNameB]) => {
                  return planOrder.indexOf(planNameA) - planOrder.indexOf(planNameB);
              })
              .reduce((acc, [planName, features]) => {
                  acc[planName] = features;
                  return acc;
              }, {});
      }

      async function redirectToSubscriptionPayment(plan) {
        if (!currentUser){
          toast.error("Login to subscribe to the plan")
          return;
        }
        try {
          setPlantoSend(plan);
      
          loadStripe(pubKey).then((stripe) => {
            // Fetch the Stripe Checkout session ID from your backend
            fetch("/create-checkoutsub-session", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: currentUser.email, // Assuming currentUser is in scope
                plan: plan,
                yearly: isYearly
              }),
            })
            .then((response) => response.json())
            .then((data) => {
              // Redirect to the Stripe Checkout page with the session ID
              stripe.redirectToCheckout({
                sessionId: data.sessionId,
              }).then((result) => {
                if (result.error) {
                  // Log the error to the console and display a message to the user
                  console.error("Error redirecting to Stripe Checkout:", result.error);
                  toast.error("Error redirecting to Stripe Checkout.");
                }
              });
            })
            .catch((error) => {
              console.error("Error fetching Checkout session ID:", error);
              toast.error("An error occurred while fetching Checkout session ID.");
            });
          }).catch((error) => {
            console.error("Error loading Stripe:", error);
            toast.error("An error occurred while loading Stripe.");
          });
        } catch (error) {
          // Handle any errors that occur during the API request
          if (error.response) {
            // The server responded with a status code that falls out of the range of 2xx
            toast.error(error.response.data.message);
          } else {
            // The request was made but no response was received or an error occurred in setting up the request
            toast.error("An error occurred: " + error.message);
          }
        }
      }
      const togglePricingPlan = () => {
        setIsYearly(!isYearly);
      };


      const translateFeature = (feature, value) => {
        const allowedIcon = <BsCheck className="checkIcon" style={{ color: 'green' }} />;
        const notAllowedIcon = <BsX className="checkIcon" style={{ color: 'red' }} />;

      
        const featureText = (text) => <span className="featureText">{text}</span>;
      
        switch (feature) {
          case 'Items':
            return <div className="featureContainer">{allowedIcon}{featureText(`Up to ${value} inventory items can be published`)}</div>;
          case 'closedBids':
            return <div className="featureContainer">{allowedIcon}{featureText(`Up to ${value} auctions can be entered per month`)}</div>;
          case 'openBids':
            return <div className="featureContainer">{allowedIcon}{featureText(`Up to ${value} open bids can be made per month`)}</div>;
          case 'dashboard':
            return <div className="featureContainer">{value === 'enable' ? allowedIcon : notAllowedIcon}{featureText('Dashboard')}</div>;
          case 'chatAllowed':
            return <div className="featureContainer">{value === 'enable' ? allowedIcon : notAllowedIcon}{featureText('Chat')}</div>;
          case 'commission':
            return <div className="featureContainer">{allowedIcon}{featureText(`${value}% platform commission`)}</div>;
          case 'fileSizeAllowed':
            return <div className="featureContainer">{allowedIcon}{featureText(`Attachments as large as ${value} MB can be uploaded`)}</div>;
          case 'searchPriority':
            return <div className="featureContainer">{value === 'enable' ? allowedIcon : notAllowedIcon}{featureText('Priority in product searches')}</div>;
          case 'relatedPriority':
            return <div className="featureContainer">{value === 'enable' ? allowedIcon : notAllowedIcon}{featureText('Priority in related product section')}</div>;
          case 'imageLimit':
            return <div className="featureContainer">{allowedIcon}{featureText(`Up to ${value} images can be uploaded per item/lot`)}</div>;
          case 'attachmentView':
            return <div className="featureContainer">{value === 'enable' ? allowedIcon : notAllowedIcon}{featureText('View PDFs')}</div>;
          case 'Lots':
            return <div className="featureContainer">{allowedIcon}{featureText(`Up to ${value} lots can be published`)}</div>;
          default:
            return <div className="featureContainer">{value === 'enable' ? allowedIcon : notAllowedIcon}</div>;
        }
      };
      
      

      
      

      const handleCloseModal = () => {
        setShowModal(false);
        navigate("/");
      };
      
    return (
        <div className='price_page' style = {{ height: '200vh' }}>
            <Header logOut={props.logOut} />

            <div style={{ backgroundColor: '#F8F8F8' }}>
                <img loading="lazy"  src={require("../../images/pattern.PNG")} className="pattern" alt="" />
                <img loading="lazy"  src={require("../../images/pattern.PNG")} className="flippedPattern" alt="" />

                <div style={{background : '#F8F8F8', position : 'relative', zIndex:'5', textAlign: 'center'}}>
                    {/* <img loading="lazy"  src={Logo} alt="Inv-X" className="companyLogo" /> */}
                    <br />
                    <h4 className="pageName_heading justify-content-center">ONE TOOL FOR YOUR <br /> WHOLE TEAM NEEDS</h4>
                    <h1 style={{ fontWeight: '400', fontSize: '45px' }}></h1>
                    {/* <span>Cancel any time. No credit card required.</span> */}
                </div>
                <br />
                <br />
                <div className='container pb-5'>
               
                <div className="text-center my-3">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label={isYearly ? 'Yearly Plan' : 'Monthly Plan'}
                        checked={isYearly}
                        onChange={togglePricingPlan}
                    />
                </div>
                <Row className="pricingPlanContainer">
  {planDetails && Object.entries(planDetails).map(([planName, features]) => (
    <Col xs={12} sm={6} md={4} lg={3} key={planName}>
      <div className={`pricingPlan ${subscriptionDetails?.plan === planName ? 'grayBackground' : ''}`}>
      <div className="topContainer">
  <div className="planInfo">
    <h3 className="planTitle">{planName}</h3>
    <p className="planDuration">Per {isYearly ? 'Year' : 'Month'}, Inclusive of taxes</p>
  </div>
  <div className="planPriceSection">
    <span className={`originalPrice ${features.discount > 0 ? 'hasDiscount' : ''}`}>
      ${isYearly ? features.pricePerYear : features.Price}
    </span>
    {features.discount > 0 && (
      <span className="discountedPrice">
        ${isYearly ? 
          (features.pricePerYear - (features.pricePerYear * features.discount / 100)).toFixed(2) : 
          (features.Price - (features.Price * features.discount / 100)).toFixed(2)}
      </span>
    )}
  </div>
</div>

        <div className="bottomContainer">
          {Object.entries(features).map(([featureName, featureValue]) => (
            featureName !== 'Price' && featureName !== 'discount' && featureName !== 'pricePerYear' && <span key={featureName}>{translateFeature(featureName, featureValue)}</span>
          ))}
        </div>
        <button type="button" className="subscribe" disabled = {subscriptionDetails?.plan === planName} onClick={() => redirectToSubscriptionPayment(planName)}>Start {planName}</button>
      </div>
    </Col>
  ))}
</Row>

    </div>
            </div>
            {/* {(session_id !== null) | (session_id !== "") && (
              <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header
                  closeButton={handleCloseModal}
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="confirmationMessage">
                  <BsCheckCircle className="checkIcon" />
                  <br />
                  <span>
                    You have bought ${plan} plan
                    <br />
                    <small style={{ color: "#F75F5C", fontWeight: "600" }}>
                      Please visit your portal to track the status of dispatch
                      and delivery.
                    </small>
                  </span>
                  <h3>Order Number: {checkoutResponse?.metadata?.order_id}</h3>
                  <br />
                </Modal.Body>
              </Modal>
            )} */}
            {(session_id !== null) | (session_id !== "") && (
              <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header
                  closeButton={handleCloseModal}
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="confirmationMessage">
                  <BsCheckCircle className="checkIcon" />
                  <br />
                  <span>
                    Your monthly subscription has started with {plan_made} plan.
                    <br />
                    <small style={{ color: "#F75F5C", fontWeight: "600" }}>
                      You can use the features of your new plan now.
                    </small>
                  </span>
                  
                  <br />
                </Modal.Body>
              </Modal>
            )}
            
        </div>
        
    );
}

export default Pricing;