import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import InventorySalesTrendGraph from './InventorySalesTrendGraph';
import { Card } from 'react-bootstrap';
import { FiTrendingUp, FiTrendingDown } from 'react-icons/fi';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../css/dashboard.css';
import '../../css/style.css';

const Dashboard = (props) => 
{
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [timePeriod, setTimePeriod] = useState("month");
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);

  useEffect(() => 
  {
    window.matchMedia("(max-width: 1024px)").addEventListener('change', e => setMatches(e.matches));
  }, []);

  const getCollapseSidebar = (value) => 
  {
    setCollapseSidebar(value);
  }

  const getCollapseDropdown = (value) => 
  {
    setCollapseDropdown(value);
  }

  const changeTimePeriod = (selectedTimePeriod) => 
  {
    setTimePeriod(selectedTimePeriod);
  }

  return (
    <div className = 'dashboard_page'>
      {!currentUser ? (
        <Navigate to = "/admin/login" replace = {true} />
      ) : currentUser && props.userRole.access !== "admin" ? (
        <Navigate to = "/admin/item-listing/inventory-list" replace = {true} />
      ) : (
        <>
          {matches && (
            <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut={props.logOut} />
          )}
          
          <div className = "admin_panel">
            <div className = "d-lg-flex">
              <div className = {`${collapseSidebar ? 'pinched_sidebar' : 'open_sidebar'}`}>
                <Sidebar collapsed = {collapseSidebar} collapsedDropdown = {collapseDropdown} />
              </div>

              <div className = {`inventory_list_content ${collapseSidebar ? 'open_content' : 'pinched_content'}`}>
                {!matches && (
                  <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut={props.logOut} />
                )}

                <div className = {`w-100 ${collapseSidebar ? 'slide-in' : 'slide-out'}`}>
                  <div className = "container-fluid  m-0" style = {{background : '#EEF0F6'}} >
                    <h3 className = "pageName_heading">Dashboard</h3>
                    
                    <div className = "d-lg-flex">
                      <Card className = "sales-graph-container">
                        <div className = "row">
                          <div style = {{ width: '95%' }}>
                            <h6>Sales Trend by Quantity</h6>
                            <InventorySalesTrendGraph timePeriod = {timePeriod} graph = "quantityGraph" />
                          </div>
                        </div>
                      </Card>
                      
                      {/* INVENTORY TIME PERIOD BUTTONS */}
                      <div className = "button-container">
                        <button type = "button" style={{ backgroundColor: `${timePeriod === 'year' ? '#D32446' : '#D8E3E7'}`, color: `${timePeriod === 'year' ? 'white' : '#306F7B'}` }} onClick={() => changeTimePeriod("year")}>Yearly</button>
                        <button type = "button" style={{ backgroundColor: `${timePeriod === 'month' ? '#D32446' : '#D8E3E7'}`, color: `${timePeriod === 'month' ? 'white' : '#306F7B'}` }} onClick={() => changeTimePeriod("month")}>Monthly</button>
                        <button type = "button" style={{ backgroundColor: `${timePeriod === 'day' ? '#D32446' : '#D8E3E7'}`, color: `${timePeriod === 'day' ? 'white' : '#306F7B'}` }} onClick={() => changeTimePeriod("day")}>Daily</button>
                      </div>

                      <Card className = "sales-graph-container">
                        <div className = "row">
                          <div style = {{ width: '95%' }}>
                            <h6>Sales Trend by Value (USD )</h6>
                            <InventorySalesTrendGraph timePeriod = {timePeriod} graph = "valueGraph" />
                          </div>
                        </div>
                      </Card>
                    </div>

                    <div className = "d-lg-flex sales-cards">
                      <div className = "sales-card">
                        <small>Total Sales MTD</small>
                        <h4 style = {{ marginTop: '0', textAlign: 'center' }}>$26,750</h4>
                      </div>
                      <div className = "sales-card">
                        <small>Total Items in Inventory</small>
                        <h4 style = {{ marginTop: '0', textAlign: 'center' }}>8100</h4>
                      </div>
                      <div className = "sales-card">
                        <small>Total Carried Inventory Value</small>
                        <h4 style = {{ marginTop: '0', textAlign: 'center' }}>$148,763</h4>
                      </div>
                    </div>
                    
                    <div className = "new_members">
                      {/* INVENTORY MEMBER STATISTICS */}
                      <div className = "members-container">
                        <Card style = {{ backgroundColor: '#0D70A5' }}>
                          <div className = "d-flex justify-content-around align-items-center">
                            <div style = {{ width: '100px' }}>
                              <h6 style = {{ color: 'white' }}>New Members</h6>
                            </div>

                            <div style = {{ display: 'flex', columnGap: '3px', justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
                              <div className = "avatar active:scale-[1.01] inline-block align-middle">
                                <div className = "w-11 rounded-full ml-[-3px]" style={{ backgroundColor: '#ADEA65' }}>
                                  <FiTrendingUp style = {{ fontSize: '20', color: '#4A2C6E', margin: '10px auto' }} />
                                </div>
                              </div>

                              <div>
                                <small style = {{ color: 'white' }}>This Year</small>
                                <h6 style = {{ marginTop: '3px', color: 'white' }}>+2116</h6>
                              </div>
                            </div>

                            <div style = {{ display: 'flex', columnGap: '3px', justifyContent: 'center', alignItems: 'center', width: '30%', marginRight: '5px' }}>
                              <div className = "avatar active:scale-[1.01] inline-block align-middle">
                                <div className = "w-11 rounded-full ml-[-3px]" style = {{ backgroundColor: '#F4828F' }}>
                                  <FiTrendingDown style = {{ fontSize: '20', color: '#4A2C6E', margin: '10px auto' }} />
                                </div>
                              </div>

                              <div>
                                <small style = {{ color: 'white' }}>This Month</small>
                                <h6 style = {{ marginTop: '3px', color: 'white' }}>+73</h6>
                              </div>
                            </div>

                            <div style = {{ display: 'flex', columnGap: '3px', justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
                              <div className = "avatar active:scale-[1.01] inline-block align-middle">
                                <div className = "w-11 rounded-full ml-[-3px]" style = {{ backgroundColor: '#ADEA65' }}>
                                  <FiTrendingUp style = {{ fontSize: '20', color: '#4A2C6E', margin: '10px auto' }} />
                                </div>
                              </div>

                              <div>
                                <small style = {{ color: 'white' }}>Today</small>
                                <h6 style = {{ marginTop: '3px', color: 'white' }}>+8</h6>
                              </div>
                            </div>
                          </div>
                        </Card>
                        
                        <br />

                        <Card className = "bottom-container">
                          <div className = "row" style = {{ alignItems: 'center' }}>
                            <div className = "col-4">
                              <h6>Total Members</h6>
                            </div>

                            <div className = "col-4" style = {{ display: 'flex', columnGap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                              <div>
                                <FiTrendingUp style = {{ fontSize: '40px', color: '#ADEA65' }} />
                              </div>

                              <div>
                                <small>Last Year</small>
                                <h6 style = {{ marginTop: '3px' }}>1,457,062</h6>
                              </div>
                            </div>

                            <div className = "col-4">
                              <h6 style = {{ fontSize: '20', textAlign: 'right' }}>1,632,407</h6>
                            </div>
                          </div>

                          <hr />
                          
                          <div className = "row" style = {{ alignItems: 'center' }}>
                            <div className = "col-4">
                              <h6>Active Members</h6>
                            </div>

                            <div className = "col-4" style = {{ display: 'flex', columnGap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                              <div>
                                <FiTrendingDown style = {{ fontSize: '40px', color: '#F4828F' }} />
                              </div>

                              <div>
                                <small>Last Year</small>
                                <h6 style = {{ marginTop: '3px' }}>1,457,062</h6>
                              </div>
                            </div>

                            <div className = "col-4">
                              <h6 style = {{ fontSize: '20', textAlign: 'right' }}>801,366</h6>
                            </div>
                          </div>

                          <hr />
                          
                          <div className = "row" style = {{ alignItems: 'center' }}>
                            <div className = "col-4">
                              <h6>Deleted Members</h6>
                            </div>

                            <div className = "col-4" style = {{ display: 'flex', columnGap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                              <div>
                                <FiTrendingUp style = {{ fontSize: '40px', color: '#ADEA65' }} />
                              </div>

                              <div>
                                <small>Last Year</small>
                                <h6 style = {{ marginTop: '3px' }}>46,073</h6>
                              </div>
                            </div>
                            
                            <div className = "col-4">
                              <h6 style = {{ fontSize: '20', textAlign: 'right' }}>23,884</h6>
                            </div>
                          </div>
                        </Card>
                      </div>
                      
                      <Card className = "list-container" style = {{padding: '3%'}}>
                        <h6>Latest Listing</h6>
                        <br />
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ lineHeight: '15px', paddingTop: '0' }}>
                                Ball Bearings
                                <br />
                                <small>Quanity: 6</small>
                              </td>
                              <td>12 April 2022</td>
                              <td>$211.11</td>
                            </tr>
                            <tr>
                              <td style={{ lineHeight: '15px' }}>
                                Iron Pipes
                                <br />
                                <small>Quantity: 2</small>
                              </td>
                              <td>19 June 2022</td>
                              <td>$1500.10</td>
                            </tr>
                            <tr>
                              <td style={{ lineHeight: '15px' }}>
                                ISO Propyl Alcohol
                                <br />
                                <small>Quanity: 1</small>
                              </td>
                              <td>30 July 2022</td>
                              <td>$2340.00</td>
                            </tr>
                            <tr>
                              <td style={{ lineHeight: '15px' }}>
                                Wood
                                <br />
                                <small>Quanity: 1</small>
                              </td>
                              <td>20 August 2022</td>
                              <td>$2000.00</td>
                            </tr>
                            <tr>
                              <td style={{ lineHeight: '15px' }}>
                                Baskets
                                <br />
                                <small>Quantity: 3</small>
                              </td>
                              <td>25 August 2022</td>
                              <td>$500.34</td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <a href="/admin/purchase-requests" className="links">See All Tickets</a>
                      </Card>

                      <Card className = "list-container" style = {{padding: '3%'}}>
                        <h6>Latest Sales</h6>
                        <br />

                        <table>
                          <tbody>
                            <tr>
                              <td style = {{ lineHeight: '15px', paddingTop: '0' }}>
                                Cellphone
                                <br />
                                <small>Quanity: 1</small>
                              </td>
                              <td>9 January 2022</td>
                              <td>$1200.10</td>
                            </tr>
                            <tr>
                              <td style = {{ lineHeight: '15px' }}>
                                Laptop
                                <br />
                                <small>Quanity: 1</small>
                              </td>
                              <td>17 January 2022</td>
                              <td>$5000.21</td>
                            </tr>
                            <tr>
                              <td style = {{ lineHeight: '15px' }}>
                                Ball Bearings
                                <br />
                                <small>Quantity: 16</small>
                              </td>
                              <td>25 Febraury 2022</td>
                              <td>$1022.11</td>
                            </tr>
                            <tr>
                              <td style = {{ lineHeight: '15px' }}>
                                Hammer
                                <br />
                                <small>Quanity: 1</small>
                              </td>
                              <td>12 March 2022</td>
                              <td>$310.00</td>
                            </tr>
                            <tr>
                              <td style = {{ lineHeight: '15px' }}>
                                Shears
                                <br />
                                <small>Quantiy: 1</small>
                              </td>
                              <td>25 June 2022</td>
                              <td>$210.50</td>
                            </tr>
                          </tbody>
                        </table>
                        <br />

                        <a href = "/admin/sales-list" className = "links">See All Transactions</a>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userRole: state.userRole
  };
};

export default connect(mapStateToProps)(Dashboard);