import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { setRole } from '../../actions/userRole';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import ChatSupport from '../admin/ChatSupport';
import Dashboard from '../admin/Dashboard';
import HomePage from '../home/Homepage';
import PurchaseRequests from '../admin/PurchaseRequests';
import CheckOut from '../checkout/Checkout';
import PaymentMethod from '../payment/PaymentMethod';
import ProductDetail from '../product/ProductDetail';
import Cart from '../product/Cart';
import Login from '../admin/Login';
import SignUp from '../admin/SignUp';
import PaymentByCreditCard from '../payment/PaymentByCreditCard';
import PaymentByBankTransfer from '../payment/PaymentByBankTransfer';
import DeliveryMethod from '../payment/DeliveryMethod';
import DeliveryByDeliveryServices from '../payment/DeliveryByDeliveryServices';
import DeliveryByFreightInspectionServices from '../payment/DeliveryByFreightInspectionServices';
import DeliveryByFreightInsuranceServices from '../payment/DeliveryByFreightInsuranceServices';
import SearchProduct from '../product/SearchProduct';
import NegotiateCart from '../product/NegotiateCart';
import AboutMe from '../admin/AboutMe';
import CreateItem from '../admin/CreateItem';
import SalesList from '../admin/SalesList';
import PageNotFound from '../../PageNotFound';
import PublishedProducts from '../admin/PublishedProducts';
import AdminProductDetail from '../admin/ProductDetail';
import Pricing from '../subscribe/Pricing';
import InventoryList from '../admin/InventoryList';
import ImportBulkList from '../admin/ImportBulkList';
import SalesRequests from '../admin/SalesRequests';
import UpdateItem from '../admin/UpdateItem';
import CreateLot from '../admin/CreateLot';
import LotList from '../admin/LotList';
import UpdateLot from '../admin/UpdateLot';
import Invoices from '../admin/Invoices';
import PurchaseOrders from '../admin/PurchaseOrders';
import Users from '../admin/Users';
import AddUser from '../admin/AddUser';
import ExtensionRequests from '../admin/ExtensionRequests';
import RfqRequests from '../admin/RfqRequests';
import CompanyDetails from '../admin/CompanyDetails';
import AddCompany from '../admin/AddCompany';
import EditCompany from '../admin/EditCompany';
import EditProfile from '../admin/EditProfile';
import ViewItem from '../admin/ViewItem';
import ViewLot from '../admin/ViewLot';
import UpdateRequests from '../admin/UpdateRequests';
import ViewUpdatedLot from '../admin/ViewUpdatedLot';
import UpdateRequestLot from '../admin/UpdateRequestLot';
import FrequentlyAskedQuestions from '../FrequentlyAsked/FrequentlyAskedQuestions';
import ChangeProfilePassword from '../admin/ChangeProfilePassword';
import ContactUs from '../contactus/ContactUs';
import ContactUs2 from '../admin/ContactUs';
import StripeSuccess from '../payment/success-failure/StripeSuccess';
import StripeFailure from '../payment/success-failure/StripeFailure';
import CompanyCommission from '../admin/CompanyCommission';
import ItemSelling from '../admin/ItemSelling';
import LotSelling from '../admin/LotSelling';
import CategoryReport from '../admin/CategoryReport';
import InactiveCompanies from '../admin/InactiveCompanies';
import AdminItem from '../admin/AdminItem';
import AdminLot from '../admin/AdminLot';
import ArticleReports from '../admin/ArticleReports';
import UserReports from '../admin/UserReports';
import SubscriptionProfile from '../subscription/SubscriptionProfile';
import ForgetPassword from '../admin/ForgetPassword';
import ResetPassword from '../admin/ResetPassword';
import AccountVerification from '../admin/AccountVerification';

const RouterLinks = () => 
{
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => 
  {
    dispatch(setRole(0, null, null));
    localStorage.clear();
    localStorage.setItem("IsLoggedOut", true);
    navigate("/admin/login");
  }

  return (
    <Routes>
      <Route 
        path = "admin/login" 
        element = {<Login />} 
      />
      
      <Route 
        path = "admin/forget-password" 
        element = {<ForgetPassword />} 
      />

      <Route
        path = "admin/reset-password"
        element = {<ResetPassword />}
      />
      
      <Route 
        path = "admin/sign-up" 
        element = {<SignUp />} 
      />
      
      <Route 
        path = "admin/sign-up/about-me" 
        element = {<AboutMe />} 
      />

      <Route
        path = "admin/account-verification"
        element = {<AccountVerification />}
      />
      
      <Route 
        path = "/" 
        element = {<HomePage logOut = {logOut} />} 
      />
      
      <Route 
        path = "admin/dashboard" 
        element = {<Dashboard logOut = {logOut} />} 
      />
      
      <Route
        path = "admin/purchase-requests"
        element = {<PurchaseRequests logOut = {logOut} />}
      />
      
      <Route
        path = "admin/sales-requests"
        element = {<SalesRequests logOut = {logOut} />}
      />
      
      <Route 
        path = "admin/sales-list" 
        element = {<SalesList logOut = {logOut} />} 
      />
      
      <Route
        path = "/admin/profile/edit-profile"
        element = {<EditProfile logOut = {logOut} />}
      />
      
      <Route
        path = "/admin/profile/change-password"
        element = {<ChangeProfilePassword logOut = {logOut} />}
      />

      <Route
        path = "/admin/profile/change-password"
        element = {<ChangeProfilePassword logOut = {logOut} />}
      />

      <Route
        path = "admin/item-listing/inventory-list"
        element = {
          <InventoryList
            message = {message}
            setMessage = {setMessage}
            logOut = {logOut}
          />
        }
      />

      <Route
        path = "admin/admin-section/update-requests"
        element = {
          <UpdateRequests
            message = {message}
            setMessage = {setMessage}
            logOut = {logOut}
          />
        }
      />
      
      <Route
        path = "admin/item-listing/lot-list"
        element = {
          <LotList message = {message} setMessage = {setMessage} logOut = {logOut} />
        }
      />
      
      <Route
        path = "admin/item-listing/create-item"
        element = {<CreateItem setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/item-listing/create-lot"
        element = {<CreateLot setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/item-listing/update-item"
        element = {<UpdateItem setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/item-listing/view-item"
        element = {<ViewItem setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/item-listing/view-lot"
        element = {<ViewLot setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/view-updatedlot"
        element = {<ViewUpdatedLot setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/edit-updatedlot"
        element = {<UpdateRequestLot setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/item-listing/update-lot"
        element = {<UpdateLot setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/import-bulk-list"
        element = {<ImportBulkList setMessage = {setMessage} logOut = {logOut} />}
      />
      
      <Route
        path = "admin/item-listing/published-products"
        element = {<PublishedProducts logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/users"
        element = {<Users logOut = {logOut} />}
      />
      
      <Route
        path = "/admin/admin-section/demo-requests"
        element = {<ContactUs2 logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/add-user"
        element = {<AddUser logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/extension-requests"
        element = {<ExtensionRequests logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/company-reports"
        element = {<CompanyCommission logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/sales-report"
        element = {<ItemSelling logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/lot-sales-report"
        element = {<LotSelling logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/category-sales-report"
        element = {<CategoryReport logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/inactive-report"
        element = {<InactiveCompanies logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/all-items"
        element = {<AdminItem logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/all-lots"
        element = {<AdminLot logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/article-reports"
        element = {<ArticleReports logOut = {logOut} />}
      />
      
      <Route
        path = "dashboard/user-reports"
        element = {<UserReports logOut = {logOut} />}
      />

      <Route
        path = "admin/admin-section/rfq-requests"
        element = {<RfqRequests logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/company-details"
        element = {<CompanyDetails logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/add-company"
        element = {<AddCompany logOut = {logOut} />}
      />
      
      <Route
        path = "admin/admin-section/edit-company"
        element = {<EditCompany logOut = {logOut} />}
      />
      
      <Route 
        path = "admin/invoices" 
        element = {<Invoices logOut = {logOut} />} 
      />
      
      <Route
        path = "admin/purchase-orders"
        element = {<PurchaseOrders logOut = {logOut} />}
      />
      
      <Route
        path = "admin/item-listing/published-products/product-detail"
        element = {<AdminProductDetail logOut = {logOut} />}
      />
      
      <Route
        path = "admin/chat-support"
        element = {<ChatSupport logOut = {logOut} />}
      />
      
      <Route 
        path = "/pricing" 
        element = {<Pricing logOut = {logOut} />} 
      />
      
      <Route
        path = "/faqs"
        element = {<FrequentlyAskedQuestions logOut = {logOut} />}
      />

      <Route
        path = "/subscription-profile"
        element = {<SubscriptionProfile logOut = {logOut} />}
      />
      
      <Route 
        path = "/request-demo" 
        element = {<ContactUs logOut = {logOut} />} 
      />
      
      <Route
        path = "product-detail/:type/:id"
        element = {<ProductDetail logOut = {logOut} />}
      />
      
      <Route
        path = "search-product/:category"
        element = {<SearchProduct logOut = {logOut} />}
      />
      
      <Route 
        path = "cart" 
        element = {<Cart logOut = {logOut} />} 
      />
      
      <Route
        path = "negotiate-cart"
        element = {<NegotiateCart logOut = {logOut} />}
      />
      
      <Route 
        path = "checkout" 
        element = {<CheckOut logOut = {logOut} />} 
      />
      
      <Route
        path = "delivery-method"
        element = {<DeliveryMethod logOut = {logOut} />}
      />
      
      <Route
        path = "delivery-by-freight-inspection-services"
        element = {<DeliveryByFreightInspectionServices logOut = {logOut} />}
      />
      
      <Route
        path = "delivery-by-freight-insurance-services"
        element = {<DeliveryByFreightInsuranceServices logOut = {logOut} />}
      />
      
      <Route
        path = "delivery-by-delivery-services"
        element = {<DeliveryByDeliveryServices logOut = {logOut} />}
      />
      
      <Route
        path = "payment-method"
        element = {<PaymentMethod logOut = {logOut} />}
      />
      
      <Route
        path = "payment-by-credit-card"
        element = {<PaymentByCreditCard logOut = {logOut} />}
      />

      <Route
        path = "payment-by-bank-transfer"
        element = {<PaymentByBankTransfer logOut = {logOut} />}
      />

      <Route
        path = "stripe-success"
        element = {<StripeSuccess logOut = {logOut} />}
      />

      <Route
        path = "stripe-failure"
        element = {<StripeFailure logOut = {logOut} />}
      />

      <Route 
        path = "*" 
        element = {<PageNotFound />} 
      />
    </Routes>
  );
}

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole
  };
};

export default connect(mapStateToProps)(RouterLinks);