import React, { useState, useEffect } from 'react';
import { Launcher } from 'react-chat-window';
import io from 'socket.io-client';
import axios from 'axios';
import { toast } from "react-toastify";

// const socket = io('http://localhost:5000'); // Replace with your server's address

const ChatBox = (props) => {
  const [messageList, setMessageList] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [socket, setSocket] = useState(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setSocket(props.socket);
  }, []);

  useEffect(() => {
    if (!socket) return;
    
    async function fetchMessages() {
      const currentUser = JSON.parse(localStorage.getItem('Inv-X Current User'));
      const id = currentUser.id;
      const chatId = props.messenger;
      
      let apiUrl = `/get_messages?chatId=${chatId}&userId=${id}`; // Default API for items

      if (props.tab === 'lots') {
        apiUrl = `/get_group_messages?chatId=${chatId}&userId=${id}`; // API for lots
      }
      
      try {
        const response = await axios.get(apiUrl);
        const messages = response.data;
        let tempMessageList;
        if(props.tab === 'lots'){
          tempMessageList = messages.map((message) => ({
            author: message.ownerId === id ? 'me' : 'them',
            type: 'text',
            data: {
            text: message.ownerId === id 
                  ? message.text 
                  : `🔵 ${message.ownerUserName}:\n\n${message.text}`
            }
          }));

        }
        else{
          tempMessageList = messages.map((message) => ({
            author: message.ownerId === id ? 'me' : 'them',
            type: 'text',
            data: { text: message.text },
          }));
        }
        
        setMessageList(tempMessageList);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    }

    fetchMessages();

    // Join the chat room when the component is mounted
    socket.emit('join_chat', { chatId: props.messenger });

    // Leave the chat room when the component is unmounted
    return () => {
      socket.emit('leave_chat', { chatId: props.messenger });
    };
}, [props.messenger, socket, props.tab]);


  // Call this function when a new message is received from the server
  useEffect(() => {
    if (!socket) return;
    console.log("INSIDE");

    // Clean up any previous listeners
    socket.off("receive_message");
    socket.off("receive_group_message");

    if (props.tab === "items") {
        socket.on("receive_message", (message) => { onMessageReceived(message) });
    } else if (props.tab === "lots") {
        socket.on("receive_group_message", (message) => { onMessageReceived(message) });
    }

    return () => {
      // Clean up the listeners when the component is unmounted
      socket.off('receive_message');
      socket.off('receive_group_message');
    };
}, [socket, props.tab]);

  function onMessageReceived(message) {
    const currentUser = JSON.parse(localStorage.getItem('Inv-X Current User'));
    const id = currentUser.id;
    console.log("RECEIVING");
    if (props.tab === 'lots'){
      const formattedMessage = {
        author: message.ownerId === id ? 'me' : 'them',
        type: 'text',
        data: {
    text: message.ownerId === id 
          ? message.text 
          : `🔵 ${message.ownerUserName}:\n\n${message.text}`
  }
      };
    
      setMessageList((prevMessageList) => [...prevMessageList, formattedMessage]);

    }
    else{
      const formattedMessage = {
        author: message.ownerId === id ? 'me' : 'them',
        type: 'text',
        data: { text: message.text },
      };
    
      setMessageList((prevMessageList) => [...prevMessageList, formattedMessage]);
    }

    
  
    // Depending on the tab, decide the API endpoint
    let apiEndpoint;
    if (props.tab === "items") {
        apiEndpoint = '/message_received';
    } else if (props.tab === "lots") {
        apiEndpoint = '/group_message_received';
    }
  
    // Send request to appropriate API endpoint based on the active tab
    axios.post(apiEndpoint, {
      chatId: props.messenger,
      userId: id,
    })
    .then(response => {
      console.log('Message received status updated:', response.data);
    })
    .catch(error => {
      console.error('Error updating message received status:', error);
    });
}


  function sendMessage(text) {
    const currentUser = JSON.parse(localStorage.getItem('Inv-X Current User'));
    const id = currentUser.id;
    const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const emailRegex = /\S+@\S+\.\S+/;

    if (phoneNumberRegex.test(text) || emailRegex.test(text)) {
      setCounter(counter + 1);
      toast.error('Sharing personal information like phone numbers or email addresses is not allowed.');
      return;
    }
    if (text.length > 0 && text.length <= 1024) {
      console.log(text.length);

      const emitEvent = props.tab === "items" ? 'send_message' : 'send_group_message';
      socket.emit(emitEvent, {
        chatId: props.messenger,
        ownerId: id,
        text: text,
      });
      console.log("MESSAGE SENT FROM FRONTEND");

      const message = {
        author: 'me',
        type: 'text',
        data: { text },
      };
      setMessageList((prevMessageList) => [...prevMessageList, message]);
    } else if (text.length > 1024) {
      toast.error('Message should be in 1024 characters limit!');
    }
}


  function onMessageWasSent(message) {
    props.setResponded(true);
    sendMessage(message.data.text);
    // setMessageList([...messageList, message]);
}


  function handleClick() {
    setIsOpen(false);
    props.handleCloseModal();
  }

  return (
    <Launcher
      agentProfile={{
        teamName: `${props.receiver}`,
        imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      }}
      mute = {true}
      onMessageWasSent={onMessageWasSent}
      handleClick={handleClick}
      messageList={messageList}
      // showEmoji={true}
      showEmoji={false} 
      isOpen={isOpen}
      showFilePicker={false}
      onFilesSelected = {() => {toast.error("Users are not allowed to send files")}}
      
    />
  );
};

export default ChatBox;
