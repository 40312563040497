import React, { Fragment, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GoShield, GoShieldCheck } from 'react-icons/go';
import { Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import '../../css/login.css'

const AccountVerification = () => 
{
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    /* ACCOUNT VERIFICATION VARIABLES */
    const [alertSuccess, setAlertSuccess] = useState(null);
    const [alertError, setAlertError] = useState(null);
    
    useEffect(() =>
    {
        const setData = async () => 
        {
            await axios.post("/verify_account", {
                token: token
            })
            .then(response => 
            {
                const responseData = response?.data || null;
                
                if (response.status === 200)
                {
                    setAlertSuccess("Your account has been verified successfully.");
                }
                else
                {
                    console.log("Verify Account Api: ", responseData);
                    setAlertError(responseData?.data);
                }
            })
            .catch(error => 
            {
                console.log("Verify Account Api: ", error);
                setAlertError("Something went wrong. Please try again later.");
            });
        }

        setData();
    }, []);

    return (
        <>
            {currentUser?.id ? (
                <Navigate to = {state?.path || "/"} replace = {true} />
            ) : (          
                <div className = "login_page !bg-[#f0f0f0]">
                    <div className = "flex items-center justify-center min-h-full">
                        <div className = "bg-white p-4 account-verification-container rounded-2 w-[500px] flex flex-col justify-center items-center gap-3">
                            {!alertSuccess ? (
                                <Fragment>
                                    <GoShield className = "text-[90px] [&>path]:fill-[green]" />
                                    <h3>Please wait...</h3>
                                    <h5 className = "font-semibold text-center">Your account is being verified.</h5>

                                    {!alertError ? (
                                        <div className = "d-flex justify-content-center">
                                            <br />
                                            <div className = "spinner-border text-info" role = "status"></div>
                                        </div>
                                    ) : (
                                        <Alert variant = "danger" className = "text-[14px]">{alertError}</Alert>
                                    )}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <GoShieldCheck className = "text-[90px] [&>path]:fill-[green]" />
                                    <h3>Verified!</h3>
                                    <h5 className = "font-semibold text-center">{alertSuccess}</h5>
                                    
                                    <Button variant = "primary" className = "w-[100px]" onClick = {() => navigate("/admin/login")}>
                                        Ok
                                    </Button>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AccountVerification;