import React, { useState, useEffect } from 'react';
import TimeCard from './TimeCard';

const CountDownTimer = ({ subscriptionDetails }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(subscriptionDetails.expirationDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timer);
  }, [subscriptionDetails.expirationDate]);

  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  return (
    <div className='d-flex justify-content-between'>
      {timeLeft.days > 0 && <TimeCard value={timeLeft.days} unit={'Days'} />}
      {timeLeft.hours > 0 && (
        <>
          <TimeCard value={formatTime(timeLeft.hours)} unit="hours" />
          <TimeCard value={formatTime(timeLeft.minutes)} unit="minutes" />
          <TimeCard value={formatTime(timeLeft.seconds)} unit="seconds" />
        </>
      )}
      {timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes > 0 && (
        <>
          <TimeCard value={formatTime(timeLeft.minutes)} unit="minutes" />
          <TimeCard value={formatTime(timeLeft.seconds)} unit="seconds" />
        </>
      )}
      {timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds > 0 && (
        <>
          <TimeCard value={formatTime(timeLeft.seconds)} unit="seconds" />
        </>
      )}
      {timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0 && (
        <div>Subscription Expired</div>
      )}
    </div>
  );
};

export default CountDownTimer;
