import React from "react";
import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Card, Row, Col, Button, Form, Tabs, Tab } from "react-bootstrap";
import "react-bootstrap";
import { useNavigate } from "react-router-dom";
import signUpImage from "../../images/2.png";
import { toast } from "react-toastify";
import "../../css/signup.css";
import "../../css/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";

const AddCompany = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [PObox, setPObox] = useState("");
    const [country, setCountry] = useState("");
    const [district, setDistrict] = useState("");
    const [tel, setTel] = useState("");
    const [mobile, setMobile] = useState("");
    const [VATNum, setVATNum] = useState("");
    const [CRNum, setCRNum] = useState("");
    const [responsiblePerson, setResponsiblePerson] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [fax, setFax] = useState("");
    const [email, setEmail] = useState("");
    const [customerCode, setCustomerCode] = useState("");
    const [zakatNum, setZAKATNum] = useState("");
    const [creditdays, setCreditdays] = useState(null);
    const [anonymous, setAnonymous] = useState(false);
    const [creditlimit, setCreditlimit] = useState(null);
    const [hofName, setHOFName] = useState("");
    const [hofAddress, setHOFAddress] = useState("");
    const [hofMobile, setHOFMobile] = useState("");
    const [hofEmail, setHOFEmail] = useState("");
    const [hofTel, setHOFTel] = useState("");
    const [accName, setAccName] = useState("");
    const [accAddress, setAccAddress] = useState("");
    const [accMobile, setAccMobile] = useState("");
    const [accTel, setAccTel] = useState("");
    const [accEmail, setAccEmail] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [servicesGroup, setServicesGroup] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [logiPointKeyOfficer, setLogiPointKeyOfficer] = useState("");
    const [originalContract, setOriginalContract] = useState("");
    const [commercialRegistration, setCommercialRegistration] = useState("");
    const [vatCertificate, setVATCertificate] = useState("");
    const [originalQuote, setOriginalQuote] = useState("");
    const [copyOfAOA, setCopyOfAOA] = useState("");
    const [zakatCertificate, setZAKATCertificate] = useState("");

    const [error, setError] = useState();
    const [key, setKey] = useState('buyer');
    const [tableData, setTableData] = useState([{ min: '', max: '', userType: '', commission: '' }]);
    const [buyerData, setBuyerData] = useState([{ min: '', max: '', commission: '' }]);
    const [sellerData, setSellerData] = useState([{ min: '', max: '', commission: '' }]);
    const [logo, setLogo] = useState(null);


    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );


    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);


    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        let objToSend = {
            name: name,
            country: country,
            phone: phone,
            address: address,
            url: url,
            buyerData: buyerData,
            sellerData: sellerData,
        };
        const formData = new FormData();

        formData.append('name', name);
        formData.append('url', url);
        formData.append('address', address);
        formData.append('phone', phone);
        formData.append('PObox', PObox);
        formData.append('country', country);
        formData.append('district', district);
        formData.append('tel', tel);
        formData.append('mobile', mobile);
        formData.append('VATNum', VATNum);
        formData.append('CRNum', CRNum);
        formData.append('responsiblePerson', responsiblePerson);
        formData.append('postalCode', postalCode);
        formData.append('city', city);
        formData.append('region', region);
        formData.append('fax', fax);
        formData.append('email', email);
        formData.append('customerCode', customerCode);
        formData.append('zakatNum', zakatNum);
        formData.append('creditdays', creditdays);
        formData.append('creditlimit', creditlimit);
        formData.append('hofName', hofName);
        formData.append('hofAddress', hofAddress);
        formData.append('hofMobile', hofMobile);
        formData.append('hofEmail', hofEmail);
        formData.append('hofTel', hofTel);
        formData.append('accName', accName);
        formData.append('accAddress', accAddress);
        formData.append('accMobile', accMobile);
        formData.append('accTel', accTel);
        formData.append('accEmail', accEmail);
        formData.append('serviceDescription', serviceDescription);
        formData.append('servicesGroup', servicesGroup);
        formData.append('contactPerson', contactPerson);
        formData.append('anonymous', anonymous);
        formData.append('logiPointKeyOfficer', logiPointKeyOfficer);

        // For files, you append the actual File object
        if (originalContract) formData.append('originalContract', originalContract);
        if (commercialRegistration) formData.append('commercialRegistration', commercialRegistration);
        if (vatCertificate) formData.append('vatCertificate', vatCertificate);
        if (originalQuote) formData.append('originalQuote', originalQuote);
        if (copyOfAOA) formData.append('copyOfAOA', copyOfAOA);
        if (zakatCertificate) formData.append('zakatCertificate', zakatCertificate);
        if (logo) formData.append('logo', logo);

        console.log(objToSend);
        objToSend = JSON.stringify(objToSend);
        console.log("FORMDATA", formData)

        axios
            .post("/create_company", formData, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json"
                },
            })
            .then((response) => {
                console.log(response.status);
                if (response.status === 200) {
                    toast.success(response?.data?.message);
                    navigate("/admin/admin-section/company-details");
                } else {
                    toast.error(response?.error);
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error("Something went wrong. Please try again later.");
            });
    };


    return (
        <div
        >
            <div className="admin_panel">
                <div className="d-lg-flex">
                    <div
                        className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                            }`}
                    >
                        <Sidebar
                            collapsed={collapseSidebar}
                            collapsedDropdown={collapseDropdown}
                        />
                    </div>
                    <div
                        className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                            }`}
                    >
                        {!matches && (
                            <Header
                                getCollapseSidebar={getCollapseSidebar}
                                getCollapseDropdown={getCollapseDropdown}
                                logOut={props.logOut}
                            />
                        )}
                        <form onSubmit={handleSubmit} className="content_panel">
                            <Row className="customCard">
                                <Col xs={12} md={5}>
                                    <div>
                                        <h5 className="form_tagline mb-[0.8em] create_account">
                                            Add Company
                                        </h5>
                                        <div className="form-group">
                                            <label htmlFor="name" className="signUpLabels">
                                                Name <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                placeholder="Enter Name"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        
<div className="form-group" style={{ marginTop: "0.5em" }}>
    <label htmlFor="logo" className="signUpLabels">
        Company Logo
    </label>
    <input
        type="file"
        name="logo"
        className="form-control"
        id="logo"
        accept="image/*" // Accepts only image files
        onChange={(e) => setLogo(e.target.files[0])}
        style={{ height: '30px' }}
    />
</div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="country" className="signUpLabels">
                                                Country <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <select
                                                name="country"
                                                className="form-select country_select"
                                                id="country"
                                                defaultValue=""
                                                onChange={(e) => setCountry(e.target.value)}
                                                required
                                            >
                                                <option value="" hidden>
                                                    Select Country
                                                </option>
                                                <option value="AF">Afghanistan</option>
                                                <option value="AX">Aland Islands</option>
                                                <option value="AL">Albania</option>
                                                <option value="DZ">Algeria</option>
                                                <option value="AS">American Samoa</option>
                                                <option value="AD">Andorra</option>
                                                <option value="AO">Angola</option>
                                                <option value="AI">Anguilla</option>
                                                <option value="AQ">Antarctica</option>
                                                <option value="AG">Antigua and Barbuda</option>
                                                <option value="AR">Argentina</option>
                                                <option value="AM">Armenia</option>
                                                <option value="AW">Aruba</option>
                                                <option value="AU">Australia</option>
                                                <option value="AT">Austria</option>
                                                <option value="AZ">Azerbaijan</option>
                                                <option value="BS">Bahamas</option>
                                                <option value="BH">Bahrain</option>
                                                <option value="BD">Bangladesh</option>
                                                <option value="BB">Barbados</option>
                                                <option value="BY">Belarus</option>
                                                <option value="BE">Belgium</option>
                                                <option value="BZ">Belize</option>
                                                <option value="BJ">Benin</option>
                                                <option value="BM">Bermuda</option>
                                                <option value="BT">Bhutan</option>
                                                <option value="BO">Bolivia</option>
                                                <option value="BQ">
                                                    Bonaire, Sint Eustatius and Saba
                                                </option>
                                                <option value="BA">Bosnia and Herzegovina</option>
                                                <option value="BW">Botswana</option>
                                                <option value="BV">Bouvet Island</option>
                                                <option value="BR">Brazil</option>
                                                <option value="IO">
                                                    British Indian Ocean Territory
                                                </option>
                                                <option value="BN">Brunei Darussalam</option>
                                                <option value="BG">Bulgaria</option>
                                                <option value="BF">Burkina Faso</option>
                                                <option value="BI">Burundi</option>
                                                <option value="KH">Cambodia</option>
                                                <option value="CM">Cameroon</option>
                                                <option value="CA">Canada</option>
                                                <option value="CV">Cape Verde</option>
                                                <option value="KY">Cayman Islands</option>
                                                <option value="CF">Central African Republic</option>
                                                <option value="TD">Chad</option>
                                                <option value="CL">Chile</option>
                                                <option value="CN">China</option>
                                                <option value="CX">Christmas Island</option>
                                                <option value="CC">Cocos (Keeling) Islands</option>
                                                <option value="CO">Colombia</option>
                                                <option value="KM">Comoros</option>
                                                <option value="CG">Congo</option>
                                                <option value="CD">
                                                    Congo, Democratic Republic of the Congo
                                                </option>
                                                <option value="CK">Cook Islands</option>
                                                <option value="CR">Costa Rica</option>
                                                <option value="CI">Cote D'Ivoire</option>
                                                <option value="HR">Croatia</option>
                                                <option value="CU">Cuba</option>
                                                <option value="CW">Curacao</option>
                                                <option value="CY">Cyprus</option>
                                                <option value="CZ">Czech Republic</option>
                                                <option value="DK">Denmark</option>
                                                <option value="DJ">Djibouti</option>
                                                <option value="DM">Dominica</option>
                                                <option value="DO">Dominican Republic</option>
                                                <option value="EC">Ecuador</option>
                                                <option value="EG">Egypt</option>
                                                <option value="SV">El Salvador</option>
                                                <option value="GQ">Equatorial Guinea</option>
                                                <option value="ER">Eritrea</option>
                                                <option value="EE">Estonia</option>
                                                <option value="ET">Ethiopia</option>
                                                <option value="FK">Falkland Islands (Malvinas)</option>
                                                <option value="FO">Faroe Islands</option>
                                                <option value="FJ">Fiji</option>
                                                <option value="FI">Finland</option>
                                                <option value="FR">France</option>
                                                <option value="GF">French Guiana</option>
                                                <option value="PF">French Polynesia</option>
                                                <option value="TF">French Southern Territories</option>
                                                <option value="GA">Gabon</option>
                                                <option value="GM">Gambia</option>
                                                <option value="GE">Georgia</option>
                                                <option value="DE">Germany</option>
                                                <option value="GH">Ghana</option>
                                                <option value="GI">Gibraltar</option>
                                                <option value="GR">Greece</option>
                                                <option value="GL">Greenland</option>
                                                <option value="GD">Grenada</option>
                                                <option value="GP">Guadeloupe</option>
                                                <option value="GU">Guam</option>
                                                <option value="GT">Guatemala</option>
                                                <option value="GG">Guernsey</option>
                                                <option value="GN">Guinea</option>
                                                <option value="GW">Guinea-Bissau</option>
                                                <option value="GY">Guyana</option>
                                                <option value="HT">Haiti</option>
                                                <option value="HM">
                                                    Heard Island and Mcdonald Islands
                                                </option>
                                                <option value="VA">
                                                    Holy See (Vatican City State)
                                                </option>
                                                <option value="HN">Honduras</option>
                                                <option value="HK">Hong Kong</option>
                                                <option value="HU">Hungary</option>
                                                <option value="IS">Iceland</option>
                                                <option value="IN">India</option>
                                                <option value="ID">Indonesia</option>
                                                <option value="IR">Iran, Islamic Republic of</option>
                                                <option value="IQ">Iraq</option>
                                                <option value="IE">Ireland</option>
                                                <option value="IM">Isle of Man</option>
                                                <option value="IL">Israel</option>
                                                <option value="IT">Italy</option>
                                                <option value="JM">Jamaica</option>
                                                <option value="JP">Japan</option>
                                                <option value="JE">Jersey</option>
                                                <option value="JO">Jordan</option>
                                                <option value="KZ">Kazakhstan</option>
                                                <option value="KE">Kenya</option>
                                                <option value="KI">Kiribati</option>
                                                <option value="KP">
                                                    Korea, Democratic People's Republic of
                                                </option>
                                                <option value="KR">Korea, Republic of</option>
                                                <option value="XK">Kosovo</option>
                                                <option value="KW">Kuwait</option>
                                                <option value="KG">Kyrgyzstan</option>
                                                <option value="LA">
                                                    Lao People's Democratic Republic
                                                </option>
                                                <option value="LV">Latvia</option>
                                                <option value="LB">Lebanon</option>
                                                <option value="LS">Lesotho</option>
                                                <option value="LR">Liberia</option>
                                                <option value="LY">Libyan Arab Jamahiriya</option>
                                                <option value="LI">Liechtenstein</option>
                                                <option value="LT">Lithuania</option>
                                                <option value="LU">Luxembourg</option>
                                                <option value="MO">Macao</option>
                                                <option value="MK">
                                                    Macedonia, the Former Yugoslav Republic of
                                                </option>
                                                <option value="MG">Madagascar</option>
                                                <option value="MW">Malawi</option>
                                                <option value="MY">Malaysia</option>
                                                <option value="MV">Maldives</option>
                                                <option value="ML">Mali</option>
                                                <option value="MT">Malta</option>
                                                <option value="MH">Marshall Islands</option>
                                                <option value="MQ">Martinique</option>
                                                <option value="MR">Mauritania</option>
                                                <option value="MU">Mauritius</option>
                                                <option value="YT">Mayotte</option>
                                                <option value="MX">Mexico</option>
                                                <option value="FM">
                                                    Micronesia, Federated States of
                                                </option>
                                                <option value="MD">Moldova, Republic of</option>
                                                <option value="MC">Monaco</option>
                                                <option value="MN">Mongolia</option>
                                                <option value="ME">Montenegro</option>
                                                <option value="MS">Montserrat</option>
                                                <option value="MA">Morocco</option>
                                                <option value="MZ">Mozambique</option>
                                                <option value="MM">Myanmar</option>
                                                <option value="NA">Namibia</option>
                                                <option value="NR">Nauru</option>
                                                <option value="NP">Nepal</option>
                                                <option value="NL">Netherlands</option>
                                                <option value="AN">Netherlands Antilles</option>
                                                <option value="NC">New Caledonia</option>
                                                <option value="NZ">New Zealand</option>
                                                <option value="NI">Nicaragua</option>
                                                <option value="NE">Niger</option>
                                                <option value="NG">Nigeria</option>
                                                <option value="NU">Niue</option>
                                                <option value="NF">Norfolk Island</option>
                                                <option value="MP">Northern Mariana Islands</option>
                                                <option value="NO">Norway</option>
                                                <option value="OM">Oman</option>
                                                <option value="PK">Pakistan</option>
                                                <option value="PW">Palau</option>
                                                <option value="PS">
                                                    Palestinian Territory, Occupied
                                                </option>
                                                <option value="PA">Panama</option>
                                                <option value="PG">Papua New Guinea</option>
                                                <option value="PY">Paraguay</option>
                                                <option value="PE">Peru</option>
                                                <option value="PH">Philippines</option>
                                                <option value="PN">Pitcairn</option>
                                                <option value="PL">Poland</option>
                                                <option value="PT">Portugal</option>
                                                <option value="PR">Puerto Rico</option>
                                                <option value="QA">Qatar</option>
                                                <option value="RE">Reunion</option>
                                                <option value="RO">Romania</option>
                                                <option value="RU">Russian Federation</option>
                                                <option value="RW">Rwanda</option>
                                                <option value="BL">Saint Barthelemy</option>
                                                <option value="SH">Saint Helena</option>
                                                <option value="KN">Saint Kitts and Nevis</option>
                                                <option value="LC">Saint Lucia</option>
                                                <option value="MF">Saint Martin</option>
                                                <option value="PM">Saint Pierre and Miquelon</option>
                                                <option value="VC">
                                                    Saint Vincent and the Grenadines
                                                </option>
                                                <option value="WS">Samoa</option>
                                                <option value="SM">San Marino</option>
                                                <option value="ST">Sao Tome and Principe</option>
                                                <option value="SA">Saudi Arabia</option>
                                                <option value="SN">Senegal</option>
                                                <option value="RS">Serbia</option>
                                                <option value="CS">Serbia and Montenegro</option>
                                                <option value="SC">Seychelles</option>
                                                <option value="SL">Sierra Leone</option>
                                                <option value="SG">Singapore</option>
                                                <option value="SX">Sint Maarten</option>
                                                <option value="SK">Slovakia</option>
                                                <option value="SI">Slovenia</option>
                                                <option value="SB">Solomon Islands</option>
                                                <option value="SO">Somalia</option>
                                                <option value="ZA">South Africa</option>
                                                <option value="GS">
                                                    South Georgia and the South Sandwich Islands
                                                </option>
                                                <option value="SS">South Sudan</option>
                                                <option value="ES">Spain</option>
                                                <option value="LK">Sri Lanka</option>
                                                <option value="SD">Sudan</option>
                                                <option value="SR">Suriname</option>
                                                <option value="SJ">Svalbard and Jan Mayen</option>
                                                <option value="SZ">Swaziland</option>
                                                <option value="SE">Sweden</option>
                                                <option value="CH">Switzerland</option>
                                                <option value="SY">Syrian Arab Republic</option>
                                                <option value="TW">Taiwan, Province of China</option>
                                                <option value="TJ">Tajikistan</option>
                                                <option value="TZ">Tanzania, United Republic of</option>
                                                <option value="TH">Thailand</option>
                                                <option value="TL">Timor-Leste</option>
                                                <option value="TG">Togo</option>
                                                <option value="TK">Tokelau</option>
                                                <option value="TO">Tonga</option>
                                                <option value="TT">Trinidad and Tobago</option>
                                                <option value="TN">Tunisia</option>
                                                <option value="TR">Turkey</option>
                                                <option value="TM">Turkmenistan</option>
                                                <option value="TC">Turks and Caicos Islands</option>
                                                <option value="TV">Tuvalu</option>
                                                <option value="UG">Uganda</option>
                                                <option value="UA">Ukraine</option>
                                                <option value="AE">United Arab Emirates</option>
                                                <option value="GB">United Kingdom</option>
                                                <option value="US">United States</option>
                                                <option value="UM">
                                                    United States Minor Outlying Islands
                                                </option>
                                                <option value="UY">Uruguay</option>
                                                <option value="UZ">Uzbekistan</option>
                                                <option value="VU">Vanuatu</option>
                                                <option value="VE">Venezuela</option>
                                                <option value="VN">Viet Nam</option>
                                                <option value="VG">Virgin Islands, British</option>
                                                <option value="VI">Virgin Islands, U.s.</option>
                                                <option value="WF">Wallis and Futuna</option>
                                                <option value="EH">Western Sahara</option>
                                                <option value="YE">Yemen</option>
                                                <option value="ZM">Zambia</option>
                                                <option value="ZW">Zimbabwe</option>
                                            </select>
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="pobox" className="signUpLabels">
                                                District  <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="pobox"
                                                className="form-control"
                                                id="pobox"
                                                placeholder="Enter District"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setDistrict(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="phone" className="signUpLabels">
                                                Mobile <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <PhoneInput
                                                defaultCountry={country}
                                                placeholder="Enter phone number (without country code)"
                                                value={phone}
                                                onChange={setPhone}
                                                required
                                            />
                                            {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="phone" className="signUpLabels">
                                                Tel <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <PhoneInput
                                                defaultCountry={country}
                                                placeholder="Enter phone number (without country code)"
                                                value={tel}
                                                onChange={setTel}
                                                required
                                            />
                                            {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="url" className="signUpLabels">
                                                URL
                                            </label>
                                            <input
                                                type="text"
                                                name="url"
                                                className="form-control"
                                                id="url"
                                                placeholder="Enter URL"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setUrl(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="address" className="signUpLabels">
                                                Address  <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="address"
                                                className="form-control"
                                                id="address"
                                                placeholder="Enter Address"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setAddress(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="pobox" className="signUpLabels">
                                                PO Box  <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="pobox"
                                                className="form-control"
                                                id="pobox"
                                                placeholder="Enter PO Box"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setPObox(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="pobox" className="signUpLabels">
                                                Responsible Person  <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="responsible person"
                                                className="form-control"
                                                id="responsible person"
                                                placeholder="Enter PO Box"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setResponsiblePerson(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="postalCode" className="signUpLabels">
                                                Postal Code <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="postalCode"
                                                className="form-control"
                                                id="postalCode"
                                                placeholder="Enter Postal Code"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setPostalCode(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="city" className="signUpLabels">
                                                City <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="city"
                                                className="form-control"
                                                id="city"
                                                placeholder="Enter City"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setCity(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="region" className="signUpLabels">
                                                Region
                                            </label>
                                            <input
                                                type="text"
                                                name="region"
                                                className="form-control"
                                                id="region"
                                                placeholder="Enter Region"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setRegion(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="fax" className="signUpLabels">
                                                Fax
                                            </label>
                                            <input
                                                type="text"
                                                name="fax"
                                                className="form-control"
                                                id="fax"
                                                placeholder="Enter Fax"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setFax(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="email" className="signUpLabels">
                                                Email <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter Email"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="customervat" className="signUpLabels">
                                                Customer VAT Number
                                            </label>
                                            <input
                                                type="text"
                                                name="customervat"
                                                className="form-control"
                                                id="customervat"
                                                placeholder="Enter Customer Code"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setVATNum(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="customercrnum" className="signUpLabels">
                                                Customer CR Number
                                            </label>
                                            <input
                                                type="text"
                                                name="customercrnum"
                                                className="form-control"
                                                id="customercrnum"
                                                placeholder="Enter Customer CR Number"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setCRNum(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="customerCode" className="signUpLabels">
                                                Customer Code
                                            </label>
                                            <input
                                                type="text"
                                                name="customerCode"
                                                className="form-control"
                                                id="customerCode"
                                                placeholder="Enter Customer Code"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setCustomerCode(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="zakatNum" className="signUpLabels">
                                                ZAKAT Num
                                            </label>
                                            <input
                                                type="text"
                                                name="zakatNum"
                                                className="form-control"
                                                id="zakatNum"
                                                placeholder="Enter ZAKAT Number"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setZAKATNum(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="creditdays" className="signUpLabels">
                                                Credit Days
                                            </label>
                                            <input
                                                type="number"
                                                name="creditdays"
                                                className="form-control"
                                                id="creditdays"
                                                placeholder="Enter Credit Days"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setCreditdays(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group" style={{ marginTop: "0.5em" }}>
                                            <label htmlFor="creditlimit" className="signUpLabels">
                                                Credit Limit
                                            </label>
                                            <input
                                                type="number"
                                                name="creditlimit"
                                                className="form-control"
                                                id="creditlimit"
                                                placeholder="Enter Credit Limit"
                                                style={{ height: '30px' }}
                                                onChange={(e) => setCreditlimit(e.target.value)}
                                            />
                                        </div>

                                        <br />

                                    </div>
                                    <h5 className="form_tagline mb-[0.8em] create_account">
                                        Company's Head of Finance
                                    </h5>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="hofname" className="signUpLabels">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="hofname"
                                            className="form-control"
                                            id="hofname"
                                            placeholder="Enter Name"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setHOFName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="hofaddress" className="signUpLabels">
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            name="hofaddress"
                                            className="form-control"
                                            id="hofaddress"
                                            placeholder="Enter Address"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setHOFAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="hofname" className="signUpLabels">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            name="hofemail"
                                            className="form-control"
                                            id="hofemail"
                                            placeholder="Enter Email"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setHOFEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="phone" className="signUpLabels">
                                            Tel <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <PhoneInput
                                            defaultCountry={country}
                                            placeholder="Enter phone number (without country code)"
                                            value={hofTel}
                                            onChange={setHOFTel}
                                        />
                                        {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="phone" className="signUpLabels">
                                            Mobile <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <PhoneInput
                                            defaultCountry={country}
                                            placeholder="Enter phone number (without country code)"
                                            value={hofMobile}
                                            onChange={setHOFMobile}
                                        />
                                        {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                    </div>
                                    <h5 className="form_tagline mb-[0.8em] create_account">
                                        Company's Accountant
                                    </h5>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="accname" className="signUpLabels">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="accname"
                                            className="form-control"
                                            id="accname"
                                            placeholder="Enter Name"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setAccName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="accaddress" className="signUpLabels">
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            name="accaddress"
                                            className="form-control"
                                            id="accaddress"
                                            placeholder="Enter Address"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setAccAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="accemail" className="signUpLabels">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            name="accemail"
                                            className="form-control"
                                            id="accemail"
                                            placeholder="Enter Email"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setAccEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="phone" className="signUpLabels">
                                            Tel <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <PhoneInput
                                            defaultCountry={country}
                                            placeholder="Enter phone number (without country code)"
                                            value={accTel}
                                            onChange={setAccTel}
                                        />
                                        {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="phone" className="signUpLabels">
                                            Mobile <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <PhoneInput
                                            defaultCountry={country}
                                            placeholder="Enter phone number (without country code)"
                                            value={accMobile}
                                            onChange={setAccMobile}
                                        />
                                        {/* <label htmlFor="phone" className="signUpLabels">Phone <span style={{ color: 'red' }}>*</span></label>
                                            <input type="tel" pattern="[0-9]{11}" name="phone" className="form-control" id="phone" placeholder="Enter Phone Number" onChange={e => setPhone(e.target.value)} required /> */}
                                    </div>
                                    <h5 className="form_tagline mb-[0.8em] create_account">
                                        Operational Data
                                    </h5>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="servicedesc" className="signUpLabels">
                                            Service Description
                                        </label>
                                        <input
                                            type="text"
                                            name="servicedesc"
                                            className="form-control"
                                            id="servicedesc"
                                            placeholder="Enter Service Description"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setServiceDescription(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="serviceroup" className="signUpLabels">
                                            Services Group
                                        </label>
                                        <input
                                            type="text"
                                            name="serviceroup"
                                            className="form-control"
                                            id="serviceroup"
                                            placeholder="Enter Services Group"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setServicesGroup(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="contactperson" className="signUpLabels">
                                            Contact Person
                                        </label>
                                        <input
                                            type="text"
                                            name="contactperson"
                                            className="form-control"
                                            id="contactperson"
                                            placeholder="Enter Contact Person"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setContactPerson(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="logipoint" className="signUpLabels">
                                            Address
                                        </label>
                                        <input
                                            type="text"
                                            name="logipoint"
                                            className="form-control"
                                            id="logipoint"
                                            placeholder="Enter Address"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setLogiPointKeyOfficer(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="originalContract" className="signUpLabels">
                                            Original Contract <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="file"
                                            name="originalContract"
                                            className="form-control"
                                            id="originalContract"
                                            accept=".pdf"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setOriginalContract(e.target.files[0])}

                                        />
                                    </div>

                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="commercialRegistration" className="signUpLabels">
                                            Commercial Registration
                                        </label>
                                        <input
                                            type="file"
                                            name="commercialRegistration"
                                            className="form-control"
                                            id="commercialRegistration"
                                            accept=".pdf"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setCommercialRegistration(e.target.files[0])}

                                        />
                                    </div>

                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="vatCertificate" className="signUpLabels">
                                            VAT Certificate
                                        </label>
                                        <input
                                            type="file"
                                            name="vatCertificate"
                                            className="form-control"
                                            id="vatCertificate"
                                            accept=".pdf"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setVATCertificate(e.target.files[0])}
                                        />
                                    </div>

                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="originalQuote" className="signUpLabels">
                                            Original Quote
                                        </label>
                                        <input
                                            type="file"
                                            name="originalQuote"
                                            className="form-control"
                                            id="originalQuote"
                                            accept=".pdf"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setOriginalQuote(e.target.files[0])}
                                        />
                                    </div>

                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="copyOfAOA" className="signUpLabels">
                                            Copy of AOA
                                        </label>
                                        <input
                                            type="file"
                                            name="copyOfAOA"
                                            className="form-control"
                                            id="copyOfAOA"
                                            accept=".pdf"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setCopyOfAOA(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
                                        <label htmlFor="zakatcertficate" className="signUpLabels">
                                            Zakat Certificate
                                        </label>
                                        <input
                                            type="file"
                                            name="zakatcertficate"
                                            className="form-control"
                                            id="zakatcertficate"
                                            accept=".pdf"
                                            style={{ height: '30px' }}
                                            onChange={(e) => setZAKATCertificate(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "0.5em" }}>
    <label className="signUpLabels">Anonymous</label>
    <div>
        <input
            type="radio"
            name="anonymous"
            value="true"
            id="anonymousTrue"
            onChange={(e) => setAnonymous(e.target.value)}
        />
        <label htmlFor="anonymousTrue" style={{ marginRight: "10px" }}>Yes</label>
        
        <input
            type="radio"
            name="anonymous"
            value="false"
            id="anonymousFalse"
            defaultChecked
            onChange={(e) => setAnonymous(e.target.value)}
        />
        <label htmlFor="anonymousFalse">No</label>
    </div>
</div>
                                    <button className="btn btn-primary py-0" style={{ marginTop: '15px', marginLeft: 'auto', minHeight: '30px', height: '30px' }}>Submit</button>

                                </Col>

                            </Row>

                        </form>

                    </div>



                </div>
            </div>
            {/* )} */}
        </div>
    );
};

export default AddCompany;
