import React, { useEffect, useState, useRef } from 'react';
// import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import teamImage from '../../images/MicrosoftTeams-image.png';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineMenuFold, AiOutlineMenuUnfold, AiOutlineSearch, AiOutlineBell } from 'react-icons/ai';
import { setNotifications } from '../../actions/notificationsAction';
import Notifications from 'react-notifications-menu';
import { FiMenu } from 'react-icons/fi';
import { connect } from 'react-redux';
import Logo from '../../images/Logo.png';
import '../../css/style.css';
import axios from "axios";
import moment from "moment";

const NotificationsDropdown = ({ notifications, onClick }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"))

  const onClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);
  const handleBellClick = async () => {
    setIsActive(!isActive);
    if (!isActive) {
      await fetch('/mark_notification_seen', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: currentUser.id })
      });
    }
  };

  const seenNotifications = notifications.filter(notification => notification.seen === 0);

  return (
    <div className="notification-menu" ref={dropdownRef}>
      <div onClick={() => handleBellClick()}>
        <button>
          <AiOutlineBell size={20} />
        </button>
        {seenNotifications.length > 0 && <div className="notification-count">{seenNotifications.length}</div>}
      </div>
      {isActive && (
        <div className={`notification-items ${isActive ? 'active' : 'inactive'}`}>
          {notifications.length === 0 ? (
            <div className="notification-item">No notifications</div>
          ) : (
            notifications.map((notification, index) => (
              <div key={index} className={`notification-item ${notification.seen === 0 ? 'seen' : ''}`} onClick={() => onClick(index)} style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                <img loading="lazy"  src={notification.image} alt="Notification" style={{ height: '40px', width: '40px', marginRight: '10px' }} />
                <div>
                  <h4 style={{ fontSize: '14px', fontWeight: notification.seen === 0 ? 'bold' : 'normal' }}>{notification.message}</h4>
                  <p style={{ fontSize: '12px', color: 'grey' }}>{notification.receivedTime}</p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};



const Header = (props) => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"))
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [notification, setNotification] = useState([]);
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`/get_notifications?userId=${currentUser.id}`);
        console.log("NOTIF", response)
        const data = response.data.map(notification => ({
          image: "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png",
          message: notification.description,
          detailPage: notification.url,
          receivedTime: moment(notification.createdOn).fromNow(),
          seen: notification.seen
        }));
        setNotification(data);
      } catch (error) {
        console.error("Error fetching notifications", error);
      }
    };

    fetchNotifications();

  }, []);

  const sendCollapseSidebar = () => {
    const originalCollapseSidebar = !collapseSidebar;
    setCollapseSidebar(originalCollapseSidebar);
    localStorage.setItem("CollapseSidebar", JSON.stringify(originalCollapseSidebar));

    if (props && props.getCollapseSidebar) {
      props.getCollapseSidebar(originalCollapseSidebar);
    }
  }
  const handleNotificationClick = (index) => {
    navigate(notification[index].detailPage);
  };


  const sendCollapseDropdown = () => {
    const originalCollapseDropdown = !collapseDropdown;
    setCollapseDropdown(originalCollapseDropdown);

    if (props && props.getCollapseDropdown) {
      props.getCollapseDropdown(originalCollapseDropdown);
    }
  }
  const handleProfileClick = () => {
    setProfileDropdownVisible(!profileDropdownVisible);
  };
  const handleNotificationDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  /*const viewCart = () => 
  {
    navigate("/cart");
  }

  const viewWishlist = () => 
  {
    navigate("/negotiate-cart");
  }*/

  return (
    <div className={`customHeader ${collapseSidebar ? 'slide-in' : 'slide-out'}`}>
      <div className="navbar text-white header justify-content-center">
        {matches ? (
          <>
            <div className='row justify-content-between w-100'>
              <div className='col-3'>
                <button className="btn text-white menu-button m-0" onClick={sendCollapseDropdown}>
                  <FiMenu className="header-icon" />
                </button>
              </div>
              <div className='col-3'>
                <a href="/">
                  <img loading="lazy"  src={Logo} alt="Inv-X" className="border-logo" />
                </a>
              </div>

              <div className="p-0 col-3 notification_pan d-flex align-items-center justify-content-between" >
                
                <div className="notification-wrapper">
                  <NotificationsDropdown
                    notifications={notification}
                    onClick={handleNotificationClick}
                  />
                </div>
                {/* </button> */}

              <div className = "profile dropdown dropdown-end text-gray-600 header-padding" style = {{ alignItems: 'center' }}>
                <label tabIndex = "0" className = "btn-header btn btn-ghost btn-circle avatar">
                  <div className = "w-10 rounded-full">
                    <img loading="lazy"  src = "/static/media/MicrosoftTeams-image.0697505b4b03333e13a7.png" alt = "" />
                  </div>
                </label>
                
                <ul tabIndex = "0" className = "menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 bg-slate-50 page-list">
                  {props?.userRole?.access === "admin" ? (
                    <li><a href = "/admin/dashboard">Dashboard</a></li>
                  ) : (
                    <>
                    {[2, 3, 4].includes(currentUser.role) && (
                    <li><a href = "/admin/item-listing/inventory-list">Portal</a></li>
                    )}
                    {[1].includes(currentUser.role) && (
                    <li><a href = "/admin/sales-requests">Portal</a></li>
                    )}
                    <li><a href = "/cart">Cart</a></li>
                  <li><a href = "/negotiate-cart">Wishlist</a></li>
                  <li><a href = "/admin/profile/edit-profile">Edit Profile</a></li>
                  
                  </>
                  )}

                    <li><a href="#/" onClick={(event) => { event.preventDefault(); props.logOut(); }}>Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6">
              {/* <div className = {`${collapseSidebar ? 'col-md-2 slide-in' : 'col-md-4 slide-out'}`}>
                <a href = "/">
                  <img loading="lazy"  src = {Logo} alt = "Inv-X" className = "border-logo" style = {{ width: `${collapseSidebar ? '80px' : '100px'}`, margin: `${collapseSidebar ? 'unset' : 'auto'}` }} />
                </a>
              </div> */}

              <div className={`d-none d-lg-block ${collapseSidebar ? 'col-md-5 slide-in' : 'col-md-2 slide-out'}`}>
                <button className="btn text-white menu-button m-0" onClick={sendCollapseSidebar} style={{ float: 'left' }}>
                  {collapseSidebar ? (
                    <AiOutlineMenuUnfold className="header-icon" />
                  ) : (
                    <AiOutlineMenuFold className="header-icon" />
                  )}
                  Menu
                </button>
              </div>
            </div>

            <div className="col-md-6" style={{ justifyContent: 'flex-end' }}>
              <div className="col-md-2">
                {/* <button className = "btn btn-ghost btn-circle" style = {{ float: 'right', marginTop: '5px' }}> */}
                {/* <div className = "indicator position-relative">
                  {notification.length > 0 && <div className = "notification_count">{notification.length}</div> }
                    <Notifications 
                    data = {notification}
                    // headerBackgroundColor = {'blue'}
                    // header={{
                    //   title: 'Notifications', 
                    //   option: { text: 'Mark all as read', onClick: () => console.log('Clicked') },
                    // }} 
                    
                  />
                  </div> */}
                <div className="notification-wrapper">
                  <div className="notification-wrapper">
                    <NotificationsDropdown
                      notifications={notification}
                      onClick={handleNotificationClick}
                    />
                  </div>
                </div>

                {/* </button> */}
              </div>

              <div className="col-md-4 profile dropdown dropdown-end text-gray-600 header-padding">
                <span className="text-center text-white text-[15px]">
                  {currentUser.username}
                </span>
                <label tabIndex="0" className="btn-header btn btn-ghost btn-circle avatar">
                  <div className="w-10 rounded-full">
                    <img loading="lazy"  src={teamImage} alt="" />
                  </div>
                </label>
                <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 bg-slate-50 page-list">
                  {props?.userRole?.access === "admin" ? (
                    <li><a href="/admin/dashboard">Dashboard</a></li>
                  ) : (
                    <>
                    <li><a href = "/admin/item-listing/inventory-list">Portal</a></li>
                    <li><a href = "/cart">Cart</a></li>
                  <li><a href = "/negotiate-cart">Wishlist</a></li>
                  <li><a href = "/admin/profile/edit-profile">Edit Profile</a></li>
                  </>
                  )}


                  <li><a href="#/" onClick={(event) => { event.preventDefault(); props.logOut(); }}>Logout</a></li>
                </ul>
              </div>
            </div>

          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userRole: state.userRole
  };
};

export default connect(mapStateToProps)(Header);