import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { styled } from '@mui/system';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import axios from "axios";
import { MdCancel, MdOutlineDone } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "../../css/style.css";
import "../../css/muiDataTable.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUp from "../helperComponents/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
//import { BsPersonFillCheck, BsPersonFillSlash } from "react-icons/bs";
const ContactUs2 = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ subject: '', message: '' });


    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showTxModal, setShowTxModal] = useState(false);
    const [txModalType, setTxModalType] = useState();
    const [txTitle, setTxTitle] = useState("");
    const [txMsg, setTxMsg] = useState("");
    const [txData, setTxData] = useState();
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );
    const styles = {
        container: {
            height: "500px" // set the height of the container
        },
    };
   
    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    const timeOut = () => {
        const timeId = setTimeout(() => {
            setShowTxModal(false)
        }, 2000)
        return () => {
            clearTimeout(timeId)
        }
    }
    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            await axios({
                method: "post",
                url: "/get_contact_us",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response, "contact us data");
                
                // Adjusting to the structure returned by get_contact_us
                const rowData = response?.data?.contactUsList;
                
                // If there's no data, return early
                if (!rowData || rowData.length === 0) {
                    setIsLoading(false);
                    return;
                }
        
                // Check the first row to dynamically generate columns
                const dynamicColumns = Object.keys(rowData[0]).map((columnName) => {
                    const columnValue = rowData[0][columnName];
                    const isInteger = Number.isInteger(columnValue);
                
                    // Basic column definition
                    const columnDef = {
                        field: columnName,
                        headerName: columnName,
                        minWidth: isInteger ? 100 : (columnName === 'Message' ? 250 : 150),  // Increase width for 'Message' column
                        type: isInteger ? 'number' : 'string',
                        flex: 1
                    };
                
                    return columnDef;
                });
                
                
                // Separate integer columns and string columns
                const integerColumns = dynamicColumns.filter((column) => column.type === 'number');
                const stringColumns = dynamicColumns.filter((column) => column.type === 'string');
                
                // Combine columns
                const combinedColumns = [...integerColumns, ...stringColumns];
                console.log(combinedColumns);
                
                setColumns(combinedColumns);
                setRows(rowData);
                setIsLoading(false);
            })
            .catch((error) => {
                setAlertError("Something went wrong");
                setIsLoading(false);
                console.log("Get contact us API error: ", error);
            });
        };
        
        
    
        getData();
    }, [])
    
    






    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };
    








    const filteredRows = searchQuery
        ? rows?.filter((row) =>
            Object.values(row).some((value) =>
                String(value)?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            )
        )
        : rows;


    return (
        <div className="create_items">
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header
                            getCollapseSidebar={getCollapseSidebar}
                            getCollapseDropdown={getCollapseDropdown}
                            logOut={props.logOut}
                        />
                    )}

                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}

                                <div
                                    className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                                        }`}
                                >
                                    <div className="content_panel container-fluid row_gap">
                                        <h4 className="pageName_heading">Demo Requests</h4>
                                        <div className="row justify-content-between">
                                            <div className="col-md-5">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="input py-[1.5em] px-[3em]"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                        <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>{modalContent.subject}</Modal.Title>
  </Modal.Header>
  <Modal.Body>{modalContent.message}</Modal.Body>
  <Modal.Footer>
    <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
      Close
    </button>
  </Modal.Footer>
</Modal>

                                        <div style={styles.container} className="customCard">
                                            <GridContextProvider>
                                                <DataGrid
                                                    rows={filteredRows ? filteredRows : []} columns={columns}
                                                    getRowId={(row) => row['Id']}
                                                    loading={isLoading}
                                                    rowHeight={40}
                                                    // rowsPerPageOptions={[5]}
                                                    pagination
                                                    density="compact"
                                                    disableExtendRowFullWidth={true}
                                                    disableSelectionOnClick={false}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                                                    }
                                                    onRowClick={(params) => {
                                                        const clickedRow = params.row;
                                                        setModalContent({
                                                            subject: clickedRow['Subject'],
                                                            message: clickedRow['Message'],
                                                        });
                                                        setShowModal(true);
                                                    }}
                                                />
                                            </GridContextProvider>
                                        </div>
                                        

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ContactUs2;
