import React from 'react';

const TimeCard = ({ value,unit }) => (
  <div className="time-card">
    <div className="time-value">{value}</div>
    <div className="time-unit">{unit}</div>
  </div>
);

export default TimeCard;
