import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const StripeSuccess = () => {
  // code
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the value of session_id from the query parameters
  const session_id = queryParams.get("session_id");
  // states
  const [checkoutResponse, setCheckoutResponse] = useState(null);

  useEffect(() => {
    async function fetchCheckoutSessionDetails() {
      await fetch("/retrieve-checkout-session-details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: session_id }),
      })
        .then((res) => res.json())
        .then((data) => {
          setCheckoutResponse(data?.response);
          console.log(data?.response);
        })
        .catch((error) => {
          console.error("Error fetching API data:", error);
        });
    }
    if (session_id) {
      fetchCheckoutSessionDetails();
    }
  }, [session_id]);

  // RETURN
  return (
    <div>
      <h1>Your Component</h1>
      {checkoutResponse ? (
        <div>
          <p>Session ID: {checkoutResponse.id}</p>
          <p>Total Amount: {checkoutResponse.amount_total}</p>
          <p>Currency: {checkoutResponse.currency}</p>
          <p>Status: {checkoutResponse.payment_status}</p>
          {/* Render other data as needed */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default StripeSuccess;
