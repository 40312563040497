import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { styled } from '@mui/system';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import "../../css/style.css";
import "../../css/muiDataTable.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUp from "../helperComponents/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import CustomDownloader from "./CustomDownloader";
//import { BsPersonFillCheck, BsPersonFillSlash } from "react-icons/bs";
const InactiveCompanies = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [commissionType, setCommissionType] = useState("buying");
    const [alertError, setAlertError] = useState("");
    const [startDate, setStartDate] = useState(null);  // Initialize with your date logic
    const [endDate, setEndDate] = useState(null);

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );
    const styles = {
        container: {
            height: "500px",
            widht: "100px" // set the height of the container
        },
    };

    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            let url = `/dashboard/neversold?startDate=${startDate}&endDate=${endDate}`


            try {
                const response = await axios.get(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                });

                const apiRows = response.data.companiesNeverSold;
                setRows(apiRows);

                if (apiRows.length > 0) {
                    const exampleRow = apiRows[0];
                    const dynamicColumns = Object.keys(exampleRow).map((key) => ({
                        field: key,
                        headerName: key,
                        width: key === 'ID' ? 90 : 270,
                        type: typeof exampleRow[key] === 'number' ? 'number' : 'string',
                        align: typeof exampleRow[key] === 'number' ? 'right' : 'left'
                    }));
                    const idColumnIndex = dynamicColumns.findIndex(col => col.field === 'ID');
                    if (idColumnIndex !== -1) {
                        const [idColumn] = dynamicColumns.splice(idColumnIndex, 1);
                        dynamicColumns.unshift(idColumn);
                    }

                    setColumns(dynamicColumns);



                    // setColumns(dynamicColumns);
                }

            } catch (error) {
                console.log("Error fetching data: ", error);
                // Handle error appropriately here
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate]);








    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };






    return (
        <div className="create_items">
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header
                            getCollapseSidebar={getCollapseSidebar}
                            getCollapseDropdown={getCollapseDropdown}
                            logOut={props.logOut}
                        />
                    )}

                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}

                                <div
                                    className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                                        }`}
                                >
                                    <div className="content_panel container-fluid row_gap">
                                        <div className="d-flex justify-content-between align-items-center custom_section">
                                            <h3 className="pageName_heading m-0">Company Reports</h3>

                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-md-4">
                                                <label className="input-label">Start Date</label>
                                                <input
                                                    type="date"
                                                    className="input"
                                                    style={{ height: '2em' }}
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="input-label">End Date</label>
                                                <input
                                                    type="date"
                                                    className="input"
                                                    style={{ height: '2em' }}
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>




                                        <div style={styles.container} className="customCard">
                                            <GridContextProvider>
                                                <DataGrid
                                                    rows={rows ? rows : []} columns={columns}
                                                    getRowId={(row) => row['ID']}
                                                    loading={isLoading}
                                                    rowHeight={40}
                                                    // rowsPerPageOptions={[5]}
                                                    pagination
                                                    density="compact"
                                                    disableExtendRowFullWidth={false}
                                                    disableSelectionOnClick={false}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                                                    }
                                                />
                                            </GridContextProvider>
                                        </div>
                                        {rows && rows.length > 0 && (
                
                <CustomDownloader
                    rows={rows}
                    title={'Companies List'}
                />
                
        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default InactiveCompanies;
