import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const StripeFailure = () => {
  // code
  const { session_id } = useParams();
  // states
  const [checkoutResponse, setCheckoutResponse] = useState(null);

  useEffect(() => {
    async function fetchCheckoutSessionDetails() {
      await fetch("/retrieve-checkout-session-details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: session_id }),
      })
        .then((res) => res.json())
        .then((data) => {
          setCheckoutResponse(data);
          console.log(data);
        });
    }
    if (session_id) {
      fetchCheckoutSessionDetails();
    }
  }, [session_id]);

  return <pre>{checkoutResponse && checkoutResponse}</pre>;
};

export default StripeFailure;
