import React, { Fragment, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import { GrMailOption } from 'react-icons/gr';
import loginImage from '../../images/1.png';
import Logo from '../../images/Logo.png';
import axios from 'axios';
import '../../css/login.css'

const ForgetPassword = () => 
{
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const { state } = useLocation();

    /* FORM VARIABLES */
    const [isLoading, setIsLoading] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(null);
    const [alertError, setAlertError] = useState(null);

    const handleChange = (event) => 
    {
        if (typeof event.target.value !== "undefined" && event.target.value !== "") 
        {
            var pattern = new RegExp(
                /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );

            if (!pattern.test(event.target.value)) 
            {
                event.target.setCustomValidity("Please enter a valid email address.");
            }
            else 
            {
                event.target.setCustomValidity("");
            }
        }
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();
        setIsLoading(true);
        setAlertSuccess(null);
        setAlertError(null);

        const email = event.target.elements[0].value;

        await axios.post("/forgot_password", {
            email: email
        })
        .then(response => 
        {
            setIsLoading(false);
            const responseData = response?.data || null;
            
            if (response.status === 200)
            {
                setAlertSuccess("Please check your inbox for the reset password link.");
            }
            else
            {
                console.log("Forget Password Api: ", responseData);
                setAlertError(responseData?.data);
            }
        })
        .catch(error => 
        {
            console.log("Forget Password Api: ", error);
            setIsLoading(false);
            setAlertError("Something went wrong. Please try again later.");
        });
    }

    return (
        <>
            {currentUser?.id ? (
                <Navigate to = {state?.path || "/"} replace = {true} />
            ) : (          
                <div className = "login_page">
                    <div className = "loginCard loginPageCard">
                        <div className = "card_body">
                            <Row className = "m-auto">
                                <Col xs = {12} md = {5} className = "m-auto !pr-0">
                                    <div className = "login-form-container">
                                        {!alertSuccess ? (
                                            <Fragment>
                                                <a href = "/">
                                                    <img 
                                                        loading = "lazy" 
                                                        src = {Logo} 
                                                        alt = "Inv-X"  
                                                        className = "border-logo" 
                                                    />
                                                </a>

                                                <form onSubmit = {handleSubmit}>
                                                    <div className = "form-group">
                                                        <label htmlFor = "email">Email</label>
                                                        <input type = "text" id = "email" name = "email" className = "form-control" placeholder = "Enter Email" onChange = {handleChange} required />
                                                    </div>
                                                    <a href = "/admin/login" className = "text-gray-500 text-[13px] login-link text-decoration-none">
                                                        Login
                                                    </a>
                                                    <br />

                                                    {alertError && (
                                                        <Alert variant = "danger" className = "text-[13px]">{alertError}</Alert>
                                                    )}

                                                    <div className = "login">
                                                        <button type = "submit" className = "custom_btn login_button btn m-auto">
                                                            {isLoading ? (
                                                                <div className = "d-flex justify-content-center">
                                                                    <br />
                                                                    <div className = "spinner-border text-info" role = "status"></div>
                                                                </div>
                                                            ) : 
                                                                'Submit'
                                                            }
                                                        </button>
                                                    </div>
                                                </form>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <GrMailOption className = "m-auto text-[60px] [&>path]:stroke-[green]" />
                                                <p className = "text-center font-semibold">{alertSuccess}</p>
                                            </Fragment>
                                        )}
                                    </div>
                                </Col>

                                <Col md = {7} className = "d-none d-md-block !pr-0">
                                    <div className = "background-login">
                                        <div className = "imageText">
                                            Fast. Reliable. <br />
                                            Tracking Service.
                                        </div>
                                        
                                        <img 
                                            loading = "lazy" 
                                            className = "bg-image-login img-fluid"
                                            src = {loginImage} 
                                            alt = "" 
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ForgetPassword;