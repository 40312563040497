import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import signUpImage from '../../images/2.png';
import axios from 'axios';
import { setRole } from '../../actions/userRole';
import { GrMailOption } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import '../../css/signup.css';
import '../../css/style.css';

const AboutMe = (props) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("Inv-X Current User")));
    const [inventoryRole, setInventoryRole] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    console.log("LOCATION", location);
    console.log(location?.state?.responseData);
    const userid = location?.state?.responseData?.userid;
    // const [platformRole, setPlatformRole] = useState(null);
    const [organizationType, setOrganizationType] = useState([]);
    const [industry, setIndustry] = useState(null);
    const [errors, setErrors] = useState({});
    const [checkFlag, setCheckFlag] = useState(false);
    const [registrationCompleted, setRegistrationCompleted] = useState();
    const dispatch = useDispatch();

    const [isNewUserCreated, setIsNewUserCreated] = useState(false);

    useEffect(() => {
        // Check if the registration flag exists in local storage
        setRegistrationCompleted(localStorage.getItem('registrationCompleted'));

    }, []);
    const setSelectedInventoryRole = (selectedInventoryRole) => {
        let inventoryRoleList = [...inventoryRole];
        let validationErrors = { ...errors };
        const index = inventoryRoleList.indexOf(selectedInventoryRole);

        if (index === -1) {
            inventoryRoleList.push(selectedInventoryRole);
            validationErrors["inventoryRole"] = false;
            setInventoryRole(inventoryRoleList);
            setErrors(validationErrors);
        }
        else {
            inventoryRoleList.splice(index, 1);
            setInventoryRole(inventoryRoleList);

            if (inventoryRoleList.length === 0) {
                validationErrors["inventoryRole"] = true;
                setErrors(validationErrors);
            }
        }
    }


    const setSelectedOrganizationType = (selectedOrganizationType) => {
        let organizationTypeList = [...organizationType];
        let validationErrors = { ...errors };
        const index = organizationTypeList.indexOf(selectedOrganizationType);

        if (index === -1) {
            organizationTypeList.push(selectedOrganizationType);
            validationErrors["organizationType"] = false;
            setOrganizationType(organizationTypeList);
            setErrors(validationErrors);
        }
        else {
            organizationTypeList.splice(index, 1);
            setOrganizationType(organizationTypeList);

            if (organizationTypeList.length === 0) {
                validationErrors["organizationType"] = true;
                setErrors(validationErrors);
            }
        }
    }

    const setSelectedIndustry = (event) => {
        let validationErrors = { ...errors };
        validationErrors["industry"] = false;

        setIndustry(event.target.value);
        setErrors(validationErrors);
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        if (ValidateForm()) {
          
            let roleid = 1;
            if (inventoryRole.length > 1) {
                roleid = 4; //for buyer and seller
            }
            else if (inventoryRole.includes("buyer") && inventoryRole.length === 1) {
                roleid = 1; //for buyer
            }
            else if (inventoryRole.includes("seller") && inventoryRole.length === 1) {
                roleid = 2; //for seller
            }
            let objToSend = { "userid": userid, "roleid": roleid, "organization": organizationType, "industry": industry, "anonymous": checkFlag }
            objToSend = JSON.stringify(objToSend);
            axios.post('/about-me', objToSend, {
                headers: {
                    'Access-Control-Allow-Origin': "*"
                }
            })
                .then(response => {
                    console.log(response);
                    setIsNewUserCreated(true);
                    localStorage.setItem('registrationCompleted', 'true');
                    // navigate("/admin/login");
                })
                .catch(error => {
                    console.log(error);
                });


        }
    };

    const ValidateForm = () => {
        let errors = {};
        let isValid = true;

        if (!inventoryRole) {
            isValid = false;
            errors["inventoryRole"] = true;
        }


        if (organizationType.length === 0) {
            isValid = false;
            errors["organizationType"] = true;
        }

        if (!industry) {
            isValid = false;
            errors["industry"] = true;
        }

        setErrors(errors);
        return isValid;
    }

    return (
        <div className='about_me register' style={{ padding: '1em 0', height: '110vh', backgroundColor: '#ffffff' }}>
            {currentUser && props.userRole.access === "admin" ? (
                <Navigate to="/admin/dashboard" replace={true} />
            ) : currentUser && props.userRole.access === "seller" ? (
                <Navigate to="/admin/item-listing/inventory-list" replace={true} />
            ) : currentUser && props.userRole.access !== "buyer" ? (
                <Navigate to="/" replace={true} />
            ) : registrationCompleted && !isNewUserCreated ? (
                <Navigate to="/*" replace={true} />
            ) : !userid ? (
                <Navigate to="/*" replace={true} />
            ) : (
                <div className="loginCard">
                    <div className='card_body'>
                        <Row>
                            <Col xs={12} md={5}>
                                <div className='aboutMe_container min-h-full flex flex-col items-center justify-center'>
                                    {isNewUserCreated ? (
                                        <Fragment>
                                            <GrMailOption className = "text-[60px] [&>path]:stroke-[green]" />
                                            <p className = "text-center font-semibold">
                                                Please check your inbox to verify your email and activate your account.
                                            </p>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <h4 className="form_tagline mt-[0.3em] mb-[0.8em] text-[25px]">Tell us more about yourself</h4>
                                            <form onSubmit={handleSubmit}>
                                                <div className={`form-group ${inventoryRole && inventoryRole.indexOf("seller") !== -1 ? 'mb-[1em]' : 'mb-[2.5em]'}`}>
                                                    <div className="form_label mb-[0.5em]">
                                                        <h1 className="signUpLabels text-[12px] text-slate-400" style={{ display: 'block' }}>I am joining as an inventory</h1>
                                                        {(inventoryRole.length <= 0) && (
                                                            <h1 className="text-[12px] text-danger">*Required</h1>
                                                        )}
                                                    </div>
                                                    <div className='buyer_seller_btn'>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${inventoryRole && inventoryRole.indexOf('buyer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${inventoryRole && inventoryRole.indexOf('buyer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedInventoryRole('buyer')}>Buyer</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${inventoryRole && inventoryRole.indexOf('seller') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${inventoryRole && inventoryRole.indexOf('seller') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedInventoryRole('seller')}>Seller</button>

                                                            {inventoryRole && inventoryRole.indexOf("seller") !== -1 && (
                                                                <div className="form-check mt-2">
                                                                    <input className="form-check-input" type="checkbox" value={checkFlag} id="confidentialNameCheck" onChange={() => { setCheckFlag(!checkFlag) }} />
                                                                    <label className="form-check-label" for="confidentialNameCheck" style={{ color: '#595959', fontSize: '11.5px' }}>
                                                                        Keep Name Confidential
                                                                    </label>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="form-group mb-[2.5em]">
                                                    <div className="form_label mb-[0.5em]">
                                                        <h1 className="signUpLabels text-[12px] text-slate-400" style={{ display: 'block' }}>Please state your organization type</h1>
                                                        {(organizationType.length <= 0) && (
                                                            <h1 className="text-[12px] text-danger">*Required</h1>
                                                        )}
                                                    </div>
                                                    <div className='about_buttons_group'>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('manufacturer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('manufacturer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("manufacturer")}>Manufacturer</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('oem') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('oem') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("oem")}>OEM</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('retailer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('retailer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("retailer")}>Retailer</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('wholesaler') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('wholesaler') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("wholesaler")}>Wholesaler</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('distribution agent') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('distribution agent') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("distribution agent")}>Distribution Agents</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('scrap dealer') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('scrap dealer') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("scrap dealer")}>Scrap Dealer</button>
                                                            <button type="button" className="btn border-none btn-active hover:bg-blue-400 aboutMeButtons" style={{ backgroundColor: `${organizationType && organizationType.indexOf('service provider') !== -1 ? '#50A7D6' : '#E0E0E0'}`, color: `${organizationType && organizationType.indexOf('service provider') !== -1 ? 'white' : 'black'}` }} onClick={() => setSelectedOrganizationType("service provider")}>Service Providers</button>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-[2.5em]">
                                                    <div className="form_label mb-[0.5em]">
                                                        <h1 className="signUpLabels text-[12px] text-slate-400" style={{ display: 'block' }}>Select your industry</h1>
                                                        {!industry && (
                                                            <h1 className="text-[12px] text-danger">*Required</h1>
                                                        )}
                                                    </div>
                                                    <select className="form-select" onChange={(event) => setSelectedIndustry(event)}>
                                                        <option defaultValue="industry" hidden>Industry</option>
                                                        <option value="aerospace">Aerospace</option>
                                                        <option value="agriculture">Agriculture</option>
                                                        <option value="computer">Computer</option>
                                                        <option value="construction">Construction</option>
                                                        <option value="education">Education</option>
                                                        <option value="electronics">Electronics</option>
                                                        <option value="energy">Energy</option>
                                                        <option value="entertainment">Entertainment</option>
                                                        <option value="food">Food</option>
                                                        <option value="healthcare">Health Care</option>
                                                        <option value="hospitality">Hospitality</option>
                                                        <option value="manufacturing">Manufacturing</option>
                                                        <option value="mining">Mining</option>
                                                        <option value="music">Music</option>
                                                        <option value="news-media">News Media</option>
                                                        <option value="pharmaceutical">Pharmaceutical</option>
                                                        <option value="telecommunication">Telecommunication</option>
                                                        <option value="transport">Transport</option>
                                                        <option value="world-wide-web">World Wide Web</option>
                                                    </select>
                                                </div>
                                                <div className='submit'>
                                                    <button type="submit" className="btn btn_submit">Submit</button>
                                                </div>
                                            </form>
                                        </Fragment>
                                    )}
                                </div>
                            </Col>
                            <Col md={7} className="d-none d-md-block">
                                <div className="background-signup">
                                    <div className="imageText">
                                        Subscribe to Inv-X <br />
                                        Premium <br />
                                        and Save More.
                                    </div>
                                    <img loading="lazy"  src={signUpImage} className="bg-image-signup-2" alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userRole: state.userRole
    };
};

export default connect(mapStateToProps)(AboutMe);