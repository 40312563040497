import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Alert, Scrollable } from 'react-bootstrap';
// import Scrollable from 'react-bootstrap/Scrollable';
import { setRole } from '../../actions/userRole';
import { setRedirected } from '../../actions/userSession';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Button, Form, Modal } from 'react-bootstrap';
import loginImage from '../../images/1.png';
import Logo from '../../images/Logo.png';
import axios from 'axios';
import '../../css/login.css'
import { Plugins } from '@capacitor/core';

const Login = (props) => {
const { Storage } = Plugins;
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("Inv-X Current User")));
    const [currentUser1, setCurrentUser1] = useState();
    const [alertError, setAlertError] = useState(null);
    const [alertError1, setAlertError1] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [isChecked, setChecked] = useState(false);
    const [isCheckedBuyer, setIsCheckedBuyer] = useState(false);
    const [isCheckedSeller, setIsCheckedSeller] = useState(false);

    const [isLoggedOut, setIsLoggedOut] = useState(localStorage.getItem("IsLoggedOut"));
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [roleUser, setRoleUser] = useState(0);
    const [status, setStatus] = useState(0);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const sellerTerms = `1.	By pressing ‘Register’ you, being a user who intends to register as a Seller of Goods (defined hereunder) (the “Seller”) with Inv-X Technologies Limited (the “Inv-X” and collectively with the Seller as the “Parties”) on its website and/or application (collectively the “Apps”) shall have agreed to be legally bound by this set of Seller terms (the “Terms”), notwithstanding any other formal or informal relationship, understanding or representation made by or between any of the Parties or any other entities associates therewith, which arise from, relate to or concern the Goods (defined hereunder), including the Parties rights with respect thereto and the Seller’s registration on and use of the Apps, as mutually agreed between the Parties, if any (the “Arrangement”).
    2.	The Parties understand and agree that: -
    (a)	Inv-X shall, upon receipt and in light of the requisite particulars from the Seller (which the Seller represents to be accurate) and in consideration of the Terms, register it as a Seller/user of the Apps (the “Registration”) and pursuant thereto, Inv-X shall have authorized/licensed/permitted the Seller to use the Apps in accordance with these Terms (the “User License”);
    (b)	Subject to the terms contained herein and the Arrangement, these Terms shall bind the Parties for a period of [•] months (the “Period”) and shall stand automatically renewed for the duration of the Period unless terminated earlier by Inv-X, at will.
    (c)	The Seller may use the Apps pursuant to the User License to make sale of goods of/owned by the Seller, which it unilaterally offers for sale via the Apps (the “Good(s)”) to individuals/businesses registered on the Apps as buyers (the “Buyer(s)” and collectively with the Seller as the “Contracting Parties”) on terms that the Seller represents as its unilateral offer to sell to a Buyer through the Apps, (the “Set Terms”) and which a Buyer accepts, as recorded by Inv-X under any and all requisite authorizations/licenses/permissions which the Seller hereby confers thereon (the “Transaction”);
    (d)	The Set Terms and terms of registration of the Buyer (available here) may be read as an integral part of these Terms;
    (e)	The Terms exhaustively enumerate the nature and extent of the Parties rights and obligations (if any) that arise from, relate to, are associated with or concern terms governing the Seller’s use of the Apps, and the Goods;
    (f)	The Seller does not intend to confer on Inv-X and Inv-X does not intend to nor shall acquire, whether through agreement, under law or any other basis, any legal or equitable ownership of and/or rights, power, entitlement, interests or claim in/to any Good or to make sale thereof, which property and the associated risk is and shall always remain vested in and with the Seller till it is actually purchased and property and risk therein is transferred to the Buyer pursuant thereto, in terms of a Transaction;
    (g)	Each Good the Seller offers the Buyer to purchase in terms of a Transaction shall be of such quality and in such form, presentation and packaging as required by law. All representations regarding the quality, quantity, branding, packaging, standard, import, export, manufacture, supply, mixture, strength, warranty, expiry, registration, flavor, composition, ingredients, components, packaging or any matter relating to, associated with or concerning the Goods, or which has encouraged, induced or enabled the Buyer to execute the Transaction, shall be and are of the Seller that it makes to the Buyers, without the knowledge, approval, involvement or consent of Inv-X or entities affiliated therewith (the “Affiliates” and collectively with Inv-X as the “Inv-X Entities”);
    (h)	The Seller exclusively bears the obligation to comply with all applicable laws, including executive instructions that are or may become applicable to the Goods, Transaction and all matters arising from these Terms, including payment of all applicable duties, taxes, levies fees and cess; 
    (i)	As also reflected in the Set Terms, amounts a Seller charges a Buyer under a Transaction shall be paid by the Buyer to the Seller either through the Apps, Inv-X or any third party application recognized in the Terms (the “Contracted Sum”), and shall be inclusive of such amounts as required at law;
    (j)	Subjected to the Terms, the Seller shall enjoy exclusive right to unilaterally set, amend or alter the Contracted Sum;
    (k)	The Seller will make payment of a non-refundable transaction fee to Inv-X for each transaction as provided on the App from time to time.
    (l)	The App may include services (such as transportation, insurance etc.) provided by third party service providers. The Seller may, at its sole discretion, obtain such services from the third party service provides on the App in accordance with the terms and conditions offered by such third party service providers from time to time. Inv-X will not at any time be responsible for the quality, duration, warranty or any other matter relating to or arising out of or in connection with such services. It is understood and acknowledged that such services will be obtained at the sole risk, cost and expense of the Seller;
    (m)	Inv-X shall be entitled to and may unilaterally suspend performance of any and all obligations under these Terms, without notice, at its sole and absolute discretion, for whatever reason, including, inter alia, technical and/or system development, maintenance etc. and for such durations that shall not exceed [•] days;
    (n)	No right, obligation and/or claim of Inv-X, under, arising from, relating to, associated with or concerning the arrangement, these Terms, is intended to establish nor shall be construed as establishing any formal or informal relationship between any of the Inv-X Entities and the Buyers, particularly with respect to the Transaction, which transaction shall have been executed by and shall exclusively exist between the Contracting Parties, to the absolute exclusion of Inv-X and other Inv-X Entities;
    (o)	Inv-X may collect all such information of the Seller that is necessary for the working of the Apps and/or which the Seller submits or causes to be submitted on the Apps and may use such information in the manner and mode it deems fit and in light of all relevant laws and Inv-X’s own Policies (as defined below).
    (p)	Nothing in these Terms, the Arrangement or otherwise shall give the Seller any right in/to the Apps which are and shall remain the sole and exclusive ownership of Inv-X including all copyrights and trademarks thereof.
    (q)	The Seller has read, understood, reviewed and agrees to be legally bound by the Terms and all policies formulated and revised by Inv-X from time to time regarding any matter relating to or arising from the Terms, including those relating to Privacy, Fair Usage, Refunds, Deregistration & Banning of Users, Information & Data Collection (available here) etc. (the “Policies”). The Seller further agrees to be bound by all revisions, alterations and/or modifications to the Terms and Policies, that Inv-X may make unilaterally, after notice to but without approval of the Seller;
    (r)	Any action, claim, suit, complaint, liability and/or right arising from, relating to, associated with or concerning the Services , including the vehicle (including the driver of such vehicle) its respective quality, branding, standard, import, export, manufacture, strength, supply, , warranty, expiry, registration, or packaging, and/or the Seller’s representations, and/or the Transaction, shall be exclusively due against and borne by the Seller, and the Seller indemnifies Inv-X and the other Inv-X Entities to this end;
    (s)	The Parties shall resolve and determine any and all disputes, differences and disagreements associated with, connected to and arising from these Terms, including matters relating to the interpretation, validity, breach, violation and enforceability of the terms, rights and obligations embodied therein, through arbitration proceedings conducted at [•] under the Arbitration Rules of the DIFC – LCIA Arbitration Centre;
    (t)	Inv-X may perform under these Terms itself or through any other entity including other Inv-X Entities;
    (u)	The laws governing the Terms and the relationship between the Parties shall be the applicable laws of England and Wales;
    (v)	Either Party may terminate their relationship under these Terms and/or Arrangement, by issuing a notice to the other Party, which shall become effective [•] months from the date of receipt thereof by the other Party. However, the Seller’s breach of the Terms shall entitle Inv-X to terminate the contractual relationship under these Terms, immediately.
    `

    const buyerTerms = `1.	By pressing ‘Register’ you, being a user who intends to register as a buyer of Goods (defined hereunder) (the “Buyer”) with Inv-X Technologies Limited ( “Inv-X” and collectively with the Buyer as the “Parties”) on its website and/or application (collectively the “App”) shall have agreed to be legally bound by this set of Buyer Terms (the “Terms”) notwithstanding any other formal or informal relationship, understanding or representation made by or between any of the Parties or any other entities associated therewith, which arise from, relate to or concern the Goods (defined hereunder), including the Parties rights with respect thereto and the Buyer registration on and use of the Apps, as mutually agreed between the Parties, if any (the “Arrangement”).
    2.	The Parties understand and agree that: -
    (a)	Inv-X shall, upon receipt and in light of the requisite particulars from the Buyer (which the Buyer represents to be accurate) and in consideration of the Terms, register it as a Buyer/user of the Apps (the “Registration”) and pursuant thereto, Inv-X shall have permitted the Buyer to use the Apps in accordance with these Terms (the “User License”);
    (b)	Subject to the terms contained herein and the Arrangement, these Terms shall bind the Parties for a period of [•] months (the “Period”) and shall stand automatically renewed upon expiry for the duration of the Period unless terminated earlier by Inv-X, at will.
    (c)	The Buyer may use the Apps pursuant to the User License, to make purchase of goods of/owned by individuals/businesses registered on the Apps as the Sellers (the “Seller(s)” and collectively with the Buyer as the “Contracting Parties”) through the Apps (the “Goods”), by accepting the terms of sale unilaterally offered by the Seller to the Buyers through the Apps, whether collectively with other Sellers or otherwise (the “Set Terms”), and pursuant thereto obtaining the Goods, as recorded by Inv-X vide the Apps, under any and all requisite authorizations/licenses/permissions which the Buyer hereby confers thereon (the “Transaction”) 
    (d)	The Set Terms and terms of Registration of the Seller (available here) may be read as an integral part of these Terms.
    (e)	The Terms exhaustively enumerate the nature and extent of the Parties rights and obligations (if any) that arise from, relate to, are associated with or concern terms governing the Buyers use of the Apps under the User License, and the Goods;
    (f)	By pressing ‘Request’ on the Apps, the Buyer shall intimate its interest to obtain the Good(s) from the Seller, pursuant to which the Seller shall, as communicated, cause the Goods to be delivered to the Buyer on the basis of information provided by the Buyer. If the Buyer accepts the Sellers offer and purchases the Good(s) therefrom in terms of a Transaction as per and in accordance with the Set Terms, as recorded by Inv-X under authorization/license/permission from the Seller (the “Approval”), and pursuant thereto, taking possession of the Goods so purchased ;
    (g)	The Buyer understands and agrees that it shall only be entitled to either accept the Seller’s offer in terms of an approval or reject it, and is not entitled to make nor shall ever make or intend to make any counter-offer to the Seller. The Buyer may however share its suggestions in the Apps comment box for Sellers to review and consider, as and when such suggestions are made available by Inv-X;
    (h)	The Buyer shall not be entitled to cancel the Transaction, after its execution, as recorded by Inv-X in terms of the Apps;
    (i)	The Terms exhaustively enumerate the nature and extent of the Parties rights and obligations (if any) that arise from, relate to, are associated with or concern terms governing the Buyer’s use of the Apps under the User License, and the Goods;
    (j)	All representations regarding the quality, quantity, branding, packaging, standard, import, export, manufacture, supply, mixture, strength, warranty, expiry, Registration, flavour, composition, ingredients, components, packaging or any matter relating to, associated with or concerning the Goods, shall be and are of the Seller that it makes to the Buyers through the Apps, without the knowledge, approval, involvement or consent, and to the absolute and unqualified exclusion of Inv-X and any entities affiliated therewith (the “Affiliates” and collectively with Inv-X as the “Inv-X Entities”);
    (k)	The Buyer does not intend to confer on Inv-X nor any other Inv-X Entities, and the Inv-X Entities do not intend to nor shall acquire, whether through agreement, under law or any other basis, any legal or equitable ownership of and/or rights, power, entitlement, interests or claim in/to any Good or to make sale or purchase thereof, which property and the associated risk is and shall always remain vested in and with the Seller, till it is actually purchased and property and risk therein is transferred to the Buyer pursuant thereto, in terms of a Transaction; 
    (l)	No right, obligation and/or claim of Inv-X under, arising from, relating to, associated with or concerning the arrangement, Goods or these Terms, if any, is intended to establish nor shall be construed as establishing any formal or informal relationship between any of the Inv-X Entities and the Buyers, particularly with respect to the Transaction, which transaction shall have been executed by and shall exclusively exist between the Contracting Parties, to the absolute exclusion of Inv-X and other Inv-X Entities;
    (m)	Inv-X may collect all such information of the Buyer that is necessary for the working of the Apps and/or which the Buyer submits or causes to be submitted on the Apps and may use such information in the manner and mode it deems fit and in light of all relevant laws and Inv-X’s own Policies (as defined below).
    (n)	Any action, claim, suit, complaint, liability and/or right arising from, relating to, associated with or concerning the Buyers obligations under these Terms and/or the Transaction, a Good, including its respective quality, quantity, branding, packaging, ingredients, components, standard, import, export, manufacture, strength, supply, mixture, warranty, expiry, Registration, flavour, composition, or packaging, and/or the Seller’s representations and/or the Transaction, shall be exclusively due against the Seller, to the absolute exclusion of Inv-X and other Inv-X Entities, as applicable, and the Buyer indemnifies the Inv-X Entities (if applicable) to this end;
    (o)	The Contracting Parties respectively and exclusively bear any and all obligations to comply with any laws, including (i) all laws governing and/or regulating direct and indirect taxation, duties, charges, fees, cess and/or any other levies which are or may be made applicable to the Goods and/or Transaction, from time to time and (ii) executive instructions that are or may become applicable to the Goods, Transaction and all matters arising from these Terms, to the absolute exclusion of Inv-X; 
    (p)	As also reflected in the Set Terms, amounts a Seller charges a Buyer under a Transaction shall be paid by the Buyer to the Seller either through the Apps, Inv-X or any third party application recognized in the Terms (the “Contracted Sum”), and shall be inclusive of such amounts as required at law;
    (q)	Subjected to the Terms, the Seller shall enjoy exclusive right to unilaterally set, amend or alter the Contracted Sum expressed in the Set Terms, prior to the approval by the Buyer and the execution of the Transaction pursuant thereto;
    (r)	The App may include services (such as transportation, insurance etc.) provided by third party service providers. The Buyer may, at its sole discretion, obtain such services from the third party service provides on the App in accordance with the terms and conditions offered by such third party service providers from time to time. Inv-X will not at any time be responsible for the quality, duration, warranty or any other matter relating to or arising out of or in connection with such services. It is understood and acknowledged that such services will be obtained at the sole risk, cost and expense of the Buyer.
    (s)	The Customer will make payment of a non-refundable transaction fee to Inv-X for each transaction as provided on the App from time to time.
    (t)	The Buyer has read, understood, reviewed and agrees to be legally bound by these Terms and all policies formulated and revised by Inv-X from time to time regarding any matter relating to or arising from the Terms, including those relating to Privacy, Fair Usage, Refunds, Deregistration & Banning of Users, Information & Data Collection (available here) etc. (the “Policies”). The Buyer further agrees to be bound by all revisions, alterations and/or modifications to the Terms and Policies, that Inv-X may make unilaterally, after notice to but without approval of the Seller;
    (u)	Only Inv-X may perform under these Terms itself or through any other entity including other Inv-X Entities;
    (v)	The laws governing the Terms and the relationship between the Parties shall be the applicable laws of England and Wales;
    (w)	The Parties shall resolve and determine any and all disputes, differences and disagreements associated with, connected to and arising from these Terms, including matters relating to the interpretation, validity, breach, violation and enforceability of the terms, rights and obligations embodied therein, through arbitration proceedings conducted at [•] under the Arbitration Rules of the DIFC – LCIA Arbitration Centre; and
    (x)	Either Party may terminate their relationship under these Terms and/or Arrangement, by issuing a notice to the other Party, which shall become effective [•] months from the date of receipt thereof by the other Party. However, the Buyers breach of the Terms shall entitle Inv-X to terminate the contractual relationship under these Terms, immediately.
    `

    useEffect(() => {
        if (window.performance || (window.performance.navigation && window.performance.navigation.type) === 1) {
            localStorage.removeItem("IsLoggedOut");
            setIsLoggedOut(false);
        }
    }, [isLoggedOut]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAgree = () => {
        if (status === 0) {
            setShow(false);
            setShowChangePasswordModal(true);
        }
        else if (status === 1) {
            setIsLoading(true);
            axios.post("/change_password", {
                user_id: currentUser1.id,
                old_password: password,
                new_password: password
            })
                .then(res => {
                    // handleCloseChangePasswordModal();
                    dispatch(setRole(0, 1, null));
                    setIsLoading(false)
                    setCurrentUser(currentUser1);
                    localStorage.setItem("Inv-X Current User", JSON.stringify(currentUser1));
                    axios.get('/vat').then(response => {
                        localStorage.setItem('vat', response.data.vat);
                        setShow(false);
                        const loginTime = new Date().getTime();
                        const oneHourInMillis = 10800000;  // Adjust this to your desired duration
                        localStorage.setItem('loginTime', loginTime);
                        localStorage.setItem('expirationDuration', oneHourInMillis);
                        navigateAfterLogin(currentUser1.role);


                    })
                        .catch(error => {
                            setIsLoading(false)
                            console.log(error)
                        });
                })
                .catch(error => {
                    setAlertError1(error.response.data.error);
                });

        }

    }
    const handleCloseChangePasswordModal = () => setShowChangePasswordModal(false);
    const handleShowChangePasswordModal = () => setShowChangePasswordModal(true);

    const handleSubmitChangePassword = (event) => {
        event.preventDefault();
        // Call API to change password
        if (newPassword !== confirmPassword) {
            setAlertError1("New password and confirm password must match.");
            return;
        }

        axios.post("/change_password", {
            user_id: currentUser1.id,
            old_password: oldPassword,
            new_password: newPassword
        })
            .then(res => {
                handleCloseChangePasswordModal();
                dispatch(setRole(0, 1, null));
                setIsLoading(false)
                setCurrentUser(currentUser1);
                localStorage.setItem("Inv-X Current User", JSON.stringify(currentUser1));
                axios.get('/vat').then(response => {
                    localStorage.setItem('vat', response.data.vat);
                    const loginTime = new Date().getTime();
                    const oneHourInMillis = 10800000;  // Adjust this to your desired duration
                    localStorage.setItem('loginTime', loginTime);
                    localStorage.setItem('expirationDuration', oneHourInMillis);
                    navigateAfterLogin(currentUser1.role);


                })
                    .catch(error => {
                        setIsLoading(false)
                        console.log(error)
                    });
            })
            .catch(error => {
                setAlertError1(error.response.data.error);
            });
    }

    const handleSubmit = (event) => {
        setIsLoading(true)
        event.preventDefault();

        const email = event.target.elements[0].value;
        const password = event.target.elements[1].value;
        setPassword(event.target.elements[1].value);
        axios.post("/authenticate_user", {
            email: email,
            password: password
        })
            .then(res => {
                console.log(res.data);
                if (res.data.login && res.status === 200) {
                    const user = { email: email, id: res.data.id, CB: res.data.buyerCom, company: res.data.userscompany, username: res.data.username, role: res.data.role, currency: res.data.currency, companyName : res.data.companyName, designation: res.data.designation  }
                    // dispatch(setRole(0, 1, null));
                    setIsLoading(false)
                    setCurrentUser(user);
                    setRole(res.data.role);
                    localStorage.setItem("Inv-X Current User", JSON.stringify(user));
                    axios.get('/vat')
                        .then(response => {
                            // Store VAT value in localStorage
                            localStorage.setItem('vat', response.data.vat);
                            const loginTime = new Date().getTime();
                            const oneHourInMillis = 10800000;   // Adjust this to your desired duration
                            localStorage.setItem('loginTime', loginTime);
                            localStorage.setItem('expirationDuration', oneHourInMillis);
                            navigateAfterLogin(user.role);


                        })
                        .catch(error => {
                            setIsLoading(false)
                            console.log(error)
                        });
                }
                else if (res.data.login && res.status === 202) {
                    const user = { email: email, id: res.data.id, CB: res.data.buyerCom, company: res.data.userscompany, username: res.data.username, role: res.data.role, currency: res.data.currency, companyName : res.data.companyName, designation: res.data.designation }
                    // dispatch(setRole(0, 1, null));
                    setRole(res.data.role);
                    setIsLoading(false)
                    setCurrentUser1(user);
                    handleShow();
                }
                else if (res.data.login && res.status === 203) {
                    const user = { email: email, id: res.data.id, CB: res.data.buyerCom, company: res.data.userscompany, username: res.data.username, role: res.data.role, currency: res.data.currency, companyName : res.data.companyName, designation: res.data.designation  }
                    // dispatch(setRole(0, 1, null));
                    setRole(res.data.role);
                    setStatus(1);
                    setIsLoading(false)
                    setCurrentUser1(user);
                    handleShow();
                }
                else {
                    setIsLoading(false)
                    setAlertError(res.data.data)
                }
            })
            .catch(error => {
                setIsLoading(false)
                console.log(error);
            });

    };
    const navigateAfterLogin = (role) => {
        console.log("ROLE", role);
        dispatch(setRedirected(false));
        const from = location.state?.from || (state && state.previousPath) || (role === 3 ? "/admin/admin-section/users" : role === 2 ? "/admin/item-listing/inventory-list" : "/");
        const product = location.state?.additional;
        const type = location.state?.type;
        navigate(from, { state: { product, type } });
    };

    return (
        <div className='login_page' style={{ padding: '0.5em 0', height: '100vh', overflowX: 'auto', backgroundColor: '#ffffff' }}>
            {!currentUser && (
                <div className="loginCard loginPageCard">
                    <div className='card_body'>
                        <Row>
                            <Col xs={12} md={5} style={{ display: 'flex' }}>
                                <div style={{ margin: 'auto', padding: '1.5em', width: '100%' }}>
                                    <a href="/">
                                        <img loading="lazy"  src={Logo} alt="Inv-X" className="border-logo" style={{ borderRadius: '40px' }} />
                                    </a>

                                    {isLoggedOut && (
                                        <Alert variant="success">Logged Out Successfully.</Alert>
                                    )}
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="username">Email</label>
                                            <input type="text" name="email" className="form-control" id="username" placeholder="Enter Email" required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" name="password" className="form-control" id="password" placeholder="Enter Password" required />
                                        </div>
                                        <br />
                                        {alertError && (
                                            <Alert variant="danger">{alertError}</Alert>
                                        )}
                                        <div className='login'>
                                            <a href="/admin/forget-password" className="text-gray-500 text-[12px]" style={{ textDecoration: 'none' }}>Forgot your password?</a>
                                            <button type="submit" className="custom_btn login_button btn" style={{ width: '120px' }}>
                                                {isLoading ? (<div className="d-flex justify-content-center">
                                                    <br />
                                                    <div className="spinner-border text-info" role="status" style={{ height: '20px', width: '20px' }}></div>
                                                </div>) : ('Log In')}
                                            </button>
                                        </div>
                                    </form>
                                    <br />
                                    <a href="/admin/sign-up" className="text-[12px] already_registered" style={{ textDecoration: 'none' }}><span className="text-gray-500">Don't Have An Account?</span> Register Here</a>
                                </div>
                            </Col>
                            <Col md={7} className="d-none d-md-block">
                                <div className="background-login">
                                    <div className="imageText">
                                        Fast. Reliable. <br />
                                        Tracking Service.
                                    </div>
                                    <img loading="lazy"  src={loginImage} alt="" className="bg-image-login img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Terms and Conditions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                            {currentUser1?.role === 1 &&
                                <div>
                                    <h5>CUSTOMER TERMS</h5>
                                    <p>
                                        {buyerTerms}
                                    </p>
                                </div>
                            }

                            {currentUser1?.role === 2 &&
                                <div>
                                    <h5>SELLER TERMS</h5>
                                    <p>
                                        {sellerTerms}
                                    </p>
                                </div>
                            }

                            {currentUser1?.role === 4 &&
                                <div>
                                    <h5>BUYER TERMS</h5>
                                    <p>
                                        {buyerTerms}
                                    </p>
                                    <h5>SELLER TERMS</h5>
                                    <p>
                                        {sellerTerms}
                                    </p>
                                </div>
                            }
                        </Modal.Body>

                        <div style={{ padding: '20px' }}>
                            {currentUser1?.role !== 4 ?
                                <><input
                                    type="checkbox"
                                    checked={isCheckedBuyer}
                                    onChange={() => setIsCheckedBuyer(!isCheckedBuyer)}
                                    style={{ marginRight: '10px' }}
                                />
                                    <label>I have read and agree to the Terms and Conditions</label></>
                                :
                                <><input
                                    type="checkbox"
                                    checked={isCheckedBuyer}
                                    onChange={() => setIsCheckedBuyer(!isCheckedBuyer)}
                                    style={{ marginRight: '10px' }}
                                />
                                    <label>I have read and agree to the Buyer's Terms and Conditions</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        checked={isCheckedSeller}
                                        onChange={() => setIsCheckedSeller(!isCheckedSeller)}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <label>I have read and agree to the Seller's Terms and Conditions</label></>}
                        </div>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>

                            <Button variant="primary" onClick={handleAgree} disabled={currentUser1?.role === 4 ? (!isCheckedBuyer || !isCheckedSeller) : !isCheckedBuyer}>
                                {isLoading ? (<div className="d-flex justify-content-center">
                                    <br />
                                    <div className="spinner-border text-info" role="status" style={{ height: '20px', width: '20px' }}></div>
                                </div>) : ('Proceed')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showChangePasswordModal} onHide={handleCloseChangePasswordModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmitChangePassword} style={{ display: 'flex', flexDirection: 'column', gap: '10px 0' }}>
                                <Form.Group controlId="formOldPassword">
                                    <Form.Label className='m-0'>Old Password</Form.Label>
                                    <Form.Control type="password" placeholder="Old Password" value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="formNewPassword">
                                    <Form.Label className='m-0'>New Password</Form.Label>
                                    <Form.Control type="password" placeholder="New Password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="formConfirmPassword">
                                    <Form.Label className='m-0'>Confirm New Password</Form.Label>
                                    <Form.Control type="password" placeholder="Confirm New Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                </Form.Group>
                                {alertError1 && (
                                    <Alert variant="danger">{alertError1}</Alert>
                                )}
                                <button className='btn custom_btn btn-primary' style={{ width: '200px', margin: 'auto' }} type="submit">
                                    Change Password
                                </button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>

            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userRole: state.userRole
    };
};

export default connect(mapStateToProps)(Login);