import React, { useState, useEffect } from 'react';
import Header from '../admin/Header';
import Sidebar from './Sidebar';
import { Col, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { MdOutlineDone } from 'react-icons/md';
import { ToastContainer, Toast } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import '../../css/requests.css';
import '../../css/style.css';

const SalesList = (props) => 
{
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [bid, setBid] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);
    
    const conditionalRowStyles = [
        {
            when: row => row.Status === "Accepted",
            style: {
                cursor: 'pointer'
            }
        }
    ];

    useEffect(() => {
        let tempColumns = [];
        let tempRows = [];
        let x = 1;

        if (x===1) {
            tempColumns = [
                {
                    name: "",
                    selector: (row) => 
                        <div className = "avatar active:scale-[1.01] inline-block align-middle" style = {{ padding: '0.5em 0' }}>
                            <div className = "w-12 rounded-full">
                                <img loading="lazy"  src={row.ItemName === "Wood" ? `${require('../../images/image001.png')}` : row.ItemName === "Iso Propyl Alcohol" ? `${require('../../images/image002.jpg')}` : row.ItemImage} alt="" />
                            </div>
                        </div>,
                    style: { justifyContent: 'center' }
                },
                {
                    name: "Item No.",
                    selector: (row) => row.ItemNumber
                },
                {
                    name: "Item Name",
                    selector: (row) => row.ItemName
                },
                {
                    name: "Item Value",
                    selector: (row) => row.ItemValue
                },
                {
                    name: "Bid Choice",
                    selector: (row) => row.BidChoice
                },
                {
                    name: "Bid Range",
                    selector: (row) => row.BidRange
                },
                {
                    name: "Status",
                    selector: (row) => row.Status,
                    cell: (row) => <span>{row.Status}</span>
                }
            ];

            tempRows = [
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12121131",
                    ItemName: "Wood",
                    ItemValue: "$2000",
                    BidChoice: "Close Bidding",
                    BidRange: <span>$1000 &#8211; $1550</span>,
                    Status: "In-Progress"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12131412",
                    ItemName: "Airpods",
                    ItemValue: "$433",
                    BidChoice: "Open Bidding",
                    BidRange: <span>$400 &#8211; $316</span>,
                    Status: "Rejected"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A1231123",
                    ItemName: "Laptop",
                    ItemValue: "$1233",
                    BidChoice: "Fixed Price",
                    BidRange: <span>$1233</span>,
                    Status: "Accepted"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A1231123",
                    ItemName: "Iso Propyl Alcohol",
                    Vendor: "Jacob Murphy",
                    ItemValue: "$2340",
                    BidChoice: "Open Bidding",
                    BidRange: <span>$1233 &#8211; $2000</span>,
                    Status: "Rejected"
                }
            ];
        }
        else if (props.userRole.access === "seller") {
            tempColumns = [
                {
                    name: "",
                    selector: (row) => 
                        <div className = "avatar active:scale-[1.01] inline-block align-middle" style = {{ padding: '0.5em 0' }}>
                            <div className = "w-12 rounded-full">
                                <img loading="lazy"  src={row.ItemName === "Wood" ? `${require('../../images/image001.png')}` : row.ItemName === "Iso Propyl Alcohol" ? `${require('../../images/image002.jpg')}` : row.ItemImage} alt="" />
                            </div>
                        </div>,
                    style: { justifyContent: 'center' }
                },
                {
                    name: "Item No.",
                    selector: (row) => row.ItemNumber
                },
                {
                    name: "Item Name",
                    selector: (row) => row.ItemName
                },
                {
                    name: "Item Value",
                    selector: (row) => row.ItemValue
                },
                {
                    name: "Bid Choice",
                    selector: (row) => row.BidChoice
                },
                {
                    name: "Buyer",
                    selector: (row) => row.BuyerName
                },
                {
                    name: "Bid",
                    selector: (row) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <span>$</span>
                                    <input type = "number" step = "any" className = "bidInput" placeholder = "Your Bid" defaultValue = {row.Bid ? row.Bid : ""} onChange = {(event) => handleChange(event, row)} disabled = {(row.Status === "In-Progress" && row.BidChoice === "Close Bidding") || row.Status === "Accepted" || row.Status === "Rejected"} style = {{ borderRadius: `${(row.Status === "In-Progress" && row.BidChoice === "Close Bidding") || row.Status === "Accepted" || row.Status === "Rejected" ? '5px' : '5px 0 0 5px'}`, textAlign: 'right' }} />
                                </div>
                                {(row.Status === "In-Progress" && row.BidChoice !== "Close Bidding") && row.Status !== "Accepted" && row.Status !== "Rejected" &&
                                    <div>
                                        <button type = "button" style = {{ background: '#0D70A5', padding: '0.55em 0.3em', borderRadius: '0 5px 5px 0' }} onClick = {() => handleEnter(row)}>
                                            <MdOutlineDone style = {{ color: 'white', fontSize: '15px' }} />
                                        </button>
                                    </div>
                                }
                            </div>
                        )
                    }
                },
                {
                    name: "Bid Count",
                    selector: (row) => row.BidCount
                },
                {
                    name: "Bid Range",
                    selector: (row) => row.BidRange
                },
                {
                    name: "Status",
                    selector: (row) => row.Status,
                    cell: (row) => <div style = {{ backgroundColor: `${row.Status === "In-Progress" ? '#55CE63' : row.Status === "Accepted" ? '#009EFB' : '#F62D51'}` }} onClick = {() => handleRowClicked(row)}>{row.Status}</div>
                }
            ];

            tempRows = [
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12121131",
                    ItemName: "Wood",
                    ItemValue: "$2000",
                    BidChoice: "Close Bidding",
                    BuyerName: "Sarah Evans",
                    Bid: 1430,
                    BidCount: 2,
                    BidRange: <span>$1000 &#8211; $1550</span>,
                    Status: "In-Progress"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12121131",
                    ItemName: "Wood",
                    ItemValue: "$2000",
                    BidChoice: "Close Bidding",
                    BuyerName: "Matthew Davis",
                    Bid: 1550,
                    BidCount: 2,
                    BidRange: <span>$1000 &#8211; $1550</span>,
                    Status: "In-Progress"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12131412",
                    ItemName: "Airpods",
                    ItemValue: "$433",
                    BidChoice: "Open Bidding",
                    BuyerName: "Dave Smith",
                    Bid: 322,
                    BidCount: 10,
                    BidRange: <span>$400 &#8211; $316</span>,
                    Status: "Rejected"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A1231123",
                    ItemName: "Laptop",
                    ItemValue: "$1233",
                    BidChoice: "Fixed Price",
                    BuyerName: "Raymond Holt",
                    Bid: 1233,
                    BidCount: 87,
                    BidRange: <span>$1233</span>,
                    Status: "Accepted"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A1231123",
                    ItemName: "Iso Propyl Alcohol",
                    ItemValue: "$2340",
                    BidChoice: "Open Bidding",
                    BuyerName: "Raymond Holt",
                    Bid: 1233,
                    BidCount: 1,
                    BidRange: <span>$1233 &#8211; $2000</span>,
                    Status: "Rejected"
                }
            ];
        }
        else if (props.userRole.access === "admin") {
            tempColumns = [
                {
                    name: "",
                    selector: (row) => 
                        <div className = "avatar active:scale-[1.01] inline-block align-middle" style = {{ padding: '0.5em 0' }}>
                            <div className = "w-12 rounded-full">
                                <img loading="lazy"  src={row.ItemName === "Wood" ? `${require('../../images/image001.png')}` : row.ItemName === "Iso Propyl Alcohol" ? `${require('../../images/image002.jpg')}` : row.ItemImage} alt="" />
                            </div>
                        </div>,
                    style: { justifyContent: 'center' }
                },
                {
                    name: "Item No.",
                    selector: (row) => row.ItemNumber
                },
                {
                    name: "Item Name",
                    selector: (row) => row.ItemName
                },
                {
                    name: "User Name",
                    selector: (row) => row.UserName
                },
                {
                    name: "Item Value",
                    selector: (row) => row.ItemValue
                },
                {
                    name: "Bid Choice",
                    selector: (row) => row.BidChoice
                },
                {
                    name: "Buyer",
                    selector: (row) => row.BuyerName
                },
                {
                    name: "Bid",
                    selector: (row) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <span>$</span>
                                    <input type = "number" step = "any" className = "bidInput" placeholder = "Your Bid" defaultValue = {row.Bid ? row.Bid : ""} onChange = {(event) => handleChange(event, row)} disabled = {(row.Status === "In-Progress" && row.BidChoice === "Close Bidding") || row.Status === "Accepted" || row.Status === "Rejected"} style = {{ borderRadius: `${(row.Status === "In-Progress" && row.BidChoice === "Close Bidding") || row.Status === "Accepted" || row.Status === "Rejected" ? '5px' : '5px 0 0 5px'}`, textAlign: 'right' }}/>
                                </div>
                                {(row.Status === "In-Progress" && row.BidChoice !== "Close Bidding") && row.Status !== "Accepted" && row.Status !== "Rejected" &&
                                    <div>
                                        <button type = "button" style = {{ background: '#0D70A5', padding: '0.55em 0.3em', borderRadius: '0 5px 5px 0' }} onClick = {() => handleEnter(row)}>
                                            <MdOutlineDone style = {{ color: 'white', fontSize: '15px' }} />
                                        </button>
                                    </div>
                                }
                            </div>
                        )
                    }
                },
                {
                    name: "Bid Count",
                    selector: (row) => row.BidCount
                },
                {
                    name: "Bid Range",
                    selector: (row) => row.BidRange
                },
                {
                    name: "Status",
                    selector: (row) => row.Status,
                    cell: (row) => <div style = {{ backgroundColor: `${row.Status === "In-Progress" ? '#55CE63' : row.Status === "Accepted" ? '#009EFB' : '#F62D51'}` }} onClick = {() => handleRowClicked(row)}>{row.Status}</div>
                }
            ];

            tempRows = [
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12121131",
                    ItemName: "Wood",
                    UserName: "haroon.timbers@gmail.com",
                    ItemValue: "$2000",
                    BidChoice: "Close Bidding",
                    BuyerName: "Sarah Evans",
                    Bid: 1430,
                    BidCount: 2,
                    BidRange: <span>$1000 &#8211; $1550</span>,
                    Status: "In-Progress"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12121131",
                    ItemName: "Wood",
                    UserName: "haroon.timbers@gmail.com",
                    ItemValue: "$2000",
                    BidChoice: "Close Bidding",
                    BuyerName: "Matthew Davis",
                    Bid: 2550,
                    BidCount: 2,
                    BidRange: <span>$1000 &#8211; $1550</span>,
                    Status: "In-Progress"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A12131412",
                    ItemName: "Airpods",
                    UserName: "samsung@gmail.com",
                    ItemValue: "$433",
                    BidChoice: "Open Bidding",
                    BuyerName: "Dave Smith",
                    Bid: 322,
                    BidCount: 10,
                    BidRange: <span>$400 &#8211; $316</span>,
                    Status: "Rejected"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A1231123",
                    ItemName: "Laptop",
                    UserName: "samsung@gmail.com",
                    ItemValue: "$1233",
                    BidChoice: "Fixed Price",
                    BuyerName: "Raymond Holt",
                    Bid: 1233,
                    BidCount: 87,
                    BidRange: <span>$1233</span>,
                    Status: "Accepted"
                },
                {
                    ItemImage: "https://placeimg.com/192/192/people",
                    ItemNumber: "A1231123",
                    ItemName: "Iso Propyl Alcohol",
                    UserName: "ulta.chemicals@gmail.com",
                    ItemValue: "$2340",
                    BidChoice: "Open Bidding",
                    BuyerName: "Raymond Holt",
                    Bid: 1233,
                    BidCount: 1,
                    BidRange: <span>$1233 &#8211; $2000</span>,
                    Status: "Rejected"
                }
            ];
        }

        setColumns(tempColumns);
        setRows(tempRows);

        window.matchMedia("(max-width: 1024px)").addEventListener('change', e => setMatches(e.matches));

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    }

    const getCollapseDropdown = (value) => 
    {
      setCollapseDropdown(value);
    }  

    const handleChange = (event, selectedRow) => {
        setRows(prevRows => {
            let tempRows = [...prevRows];
            const index = tempRows.findIndex(tempRow => tempRow.ItemNumber === selectedRow.ItemNumber);

            if (index > -1) {
                tempRows[index] = { ...tempRows[index], Bid: event.target.value };
                return tempRows;
            }

            return prevRows;
        });
    }

    const handleEnter = (selectedRow) => {
        setBid({ itemNumber: selectedRow.ItemNumber, bidValue: selectedRow.Bid });
        setShowToast(true);
    }

    const handleRowClicked = (row) => {
        if (row.Status === "Accepted") {
            setSelectedRow(row);
            setShowModal(true);
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    return (
        <div>
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut={props.logOut} />
                    )}

                    <div className='admin_panel'>
                        <div className='d-lg-flex'>
                            <div className={`${collapseSidebar ? 'pinched_sidebar' : 'open_sidebar'}`}>
                                <Sidebar collapsed={collapseSidebar} collapsedDropdown = {collapseDropdown} />
                            </div>

                            <div className={`inventory_list_content ${collapseSidebar ? 'open_content' : 'pinched_content'}`}>
                                {!matches && (
                                    <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut={props.logOut} />
                                )}

                                <ToastContainer className="messageToast">
                                    <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                                        <Toast.Header>
                                            <strong className="me-auto">Message</strong>
                                            <small>1 sec ago</small>
                                        </Toast.Header>
                                        <Toast.Body>New bid entered for <b>{bid.itemNumber}</b>: {bid.bidValue}</Toast.Body>
                                    </Toast>
                                </ToastContainer>

                                <div className={`w-100 content_panel ${collapseSidebar ? 'slide-in' : 'slide-out'}`}>
                                    <div style = {{ height: '100vh' }}>
                                      <h3  className='pageName_heading'>Sales List</h3>
                                        <div style={{ backgroundColor: '#FEFEFE' }}>
                                            <div className="table-overflow">
                                                <div className="datatable-filter">
                                                    <div className="card">
                                                        <div className={`table-overflow sales-list ${props.userRole.access === "seller" ? 'sales-list-seller' : props.userRole.access === "admin" ? 'sales-list-admin' : 'sales-list-buyer'}`}>
                                                            <DataTable
                                                                columns={columns}
                                                                data={rows}
                                                                pagination={true}
                                                                conditionalRowStyles={conditionalRowStyles}
                                                                onRowClicked={(row) => handleRowClicked(row)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Modal centered show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton={handleCloseModal}>
                                        <Modal.Title style={{ color: '#0d70a5' }}>DETAILS</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row style={{ marginBottom: '0.5em' }}>
                                            <Col xs={4} style={{ color: '#9F9F9F' }}>
                                                REQUEST DATE
                                            </Col>
                                            <Col xs={8} style={{ color: '#707070' }}>
                                                24 - JUL - 2022
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: '0.5em' }}>
                                            <Col xs={4} style={{ color: '#9F9F9F' }}>
                                                ITEM NUMBER
                                            </Col>
                                            <Col xs={8} style={{ color: '#707070' }}>
                                                {selectedRow.ItemNumber}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: '0.5em' }}>
                                            <Col xs={4} style={{ color: '#9F9F9F' }}>
                                                ITEM NAME
                                            </Col>
                                            <Col xs={8} style={{ color: '#707070' }}>
                                                {selectedRow.ItemName}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: '0.5em' }}>
                                            <Col xs={4} style={{ color: '#9F9F9F' }}>
                                                ITEM QUANTITY
                                            </Col>
                                            <Col xs={8} style={{ color: '#707070' }}>
                                                1
                                            </Col>
                                        </Row>
                                        {(props.userRole.access === "seller" || props.userRole.access === "admin") && (
                                            <Row style={{ marginBottom: '0.5em' }}>
                                                <Col xs={4} style={{ color: '#9F9F9F' }}>
                                                    BID PLACED
                                                </Col>
                                                <Col xs={8} style={{ color: '#707070' }}>
                                                    ${selectedRow.Bid}
                                                </Col>
                                            </Row>
                                        )}
                                        <Row style={{ marginBottom: '0.5em' }}>
                                            <Col xs={4} style={{ color: '#9F9F9F' }}>
                                                STATUS
                                            </Col>
                                            <Col xs={8} style={{ color: '#707070' }}>
                                                Bid Accepted
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userRole: state.userRole
    };
};

export default connect(mapStateToProps)(SalesList);