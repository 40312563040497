import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "./Tooltip";
import { Button } from "react-bootstrap";
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import "../../css/style.css";
import "../../css/inventory.css";
import { Category } from "@mui/icons-material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { toast } from "react-toastify";

const ViewLot = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
  const id = currentUser.id;
  const [collapseSidebar, setCollapseSidebar] = useState(
    JSON.parse(localStorage.getItem("CollapseSidebar"))
  );
  const location = useLocation();
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [extensionReason, setExtensionReason] = useState('');
  const [rows, setRows] = useState([]);
  const lotId = location.state.LotId;
  const [filteredRows, setFilteredRows] = useState([]);
  const [image, setImage] = useState(require("../../images/no-image.jpg"));
  const [images, setImages] = useState([]);
  const [inputImages, setInputImages] = useState([]);
  const currentDate = new Date();
  const formattedDate1 = currentDate.toISOString().split('T')[0];
  const formattedTime1 = currentDate.toTimeString().split(' ')[0].slice(0, 5); // Only HH:MM
  const [delImg, setDelImg] = useState([]);
  // Assuming these are your required fields
  const [lot, setLot] = useState({});
  const [updateCount, setUpdateCount] = useState(0);
  const [initialDeadline, setInitialDeadline] = useState();

  const [files, setFiles] = useState([]);
  const [docName, setDocName] = useState([]);
  const [docstoDel, setDocstoDel] = useState([]);
  const [types, setTypes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [shouldUpdateRows, setShouldUpdateRows] = useState(false);
  const [docs, setDocs] = useState([]);
  const [inputDocs, setInputDocs] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const navigate = useNavigate();
  const imageInput = useRef();
  const [values, setValues] = useState([]);
  const styles = {
    container: {
      height: "500px",
      widht: "100px", // set the height of the container
    },
  };
  const buttonStyle = {
    backgroundColor: "#0d70a5", // Bootstrap primary blue
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    fontSize: "0.875rem",
    lineHeight: "1.5",
    marginLeft: "5px",
    marginRight: "5px",
  };

  const buttonStyle2 = {
    backgroundColor: '#4ca9d6', // Lighter blue color
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    marginLeft: '5px',
    marginRight: '5px',
  };
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const quantityStyle = {
    width: "40px", // Adjust this value as per your requirement
    textAlign: "center",
    marginRight: "5px",
    marginLeft: "5px",
    backgroundColor:"white"
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    axios
      .get("/get_categories")
      .then((response) => {
        console.log(response.data);
        const valuesArr = response.data.map(
          (category) => category.categoryName
        );
        setValues(valuesArr.slice(1));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/get_documentTypes")
      .then((response) => {
        console.log(response.data);
        const newArr = response.data.map((type) => type.documentType);
        setTypes(newArr);
      })
      .catch((error) => {
        console.log(error);
      });
    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  useEffect(() => {
    setIsLoading(true)
    const tempColumns = [
      {
        field: "InventoryNumber",
        headerName: "Inventory #",
        flex: 1,
      },
      {
        field: "Name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "Description",
        headerName: "Description",
        flex: 1,
        valueGetter: (params) => params.row.Description || "",
        cellClassName: "wrap-text",
      },
      {
        field: "itemPrice",
        headerName: "Price",
        flex: 1,
      },
      {
        field: "alreadyPublished",
        headerName: "Published Quantity",
        flex: 1,
        align:'right',
        width: 150,
        minWidth:150
      },
      {
        field: "alreadyInLots",
        headerName: "Quantity in Lots",
        flex: 1,
        align:'right',
        width: 150,
        minWidth:150
      },
      {
        field: "RemainingQuantity",
        headerName: "Available Quantity",
        flex: 1,
        align:'right',
        width: 150,
        minWidth:150
      },
      {
        field: "lotQuantity",
        headerName: "Quantity to Add",
        flex: 2,
        editable: false,
        renderCell: (params) => (
          <div style={containerStyle}>
            <button style={buttonStyle2} onClick={(e) => handleDecrease(params, e)}> - </button>
            <input 
              type="number" 
              min="0" 
              value={params.getValue(params.id, 'lotQuantity') || ''}
              placeholder="Enter"
              onChange={(e) => handleChange(params, e)} 
              style={quantityStyle}
            />
            <button style={buttonStyle} onClick={(e) => handleIncrease(params, e)}> + </button>
          </div>
        ),
      },
    ];

    axios
      .get(`/items/${id}`)
      .then((res) => {
        let data = res.data.items;
        console.log(data);

        axios
      .get(`/autofill_lot?lotId=${lotId}`)
      .then((response) => {
        let newRows = [...res.data.items];
        response.data.data.items.forEach((item) => {
          let matchingRow = newRows.find(
            (row) => row.InventoryNumber === item.itemId
          );
          if (matchingRow) {
            matchingRow.lotQuantity = item.quantity;
          }
        });
        setIsLoading(false)
        setColumns(tempColumns);
        setRows(newRows);
        setFilteredRows(newRows);
        
      })

        
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });

    window
      .matchMedia("(max-width: 1024px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [lotId]);
  useEffect(() => {
    axios
      .get(`/autofill_lot?lotId=${lotId}`)
      .then((response) => {
        console.log(response);
        console.log("INITIAL DEADLINE", response.data.data.deadline)
        setInitialDeadline(dateFormatting(response.data.data.deadline));
        setLot(response.data.data);
        console.log("lot", response.data.data);
        let itemIds = response.data.data.items.map((item) =>
          Number(item.itemId)
        );
        setSelected(itemIds);
        let pics = [
          response.data.data.lotImage,
          ...response.data.data.additionalImages,
        ];
        console.log(pics);
        setImages(pics);
        let pdfs = response.data.data.documents;
        setDocs(pdfs);
        // if(lot){
        //   setShouldUpdateRows(true);
        // }
        
      })
      .catch((error) => console.error(error));
  }, [lotId]);

  const getCollapseSidebar = (value) => {
    setCollapseSidebar(value);
  };

  const getCollapseDropdown = (value) => {
    setCollapseDropdown(value);
  };
  const handleImageChange = (e) => {
      const imagefiles = Array.from(e.target.files);
      setInputImages((prevImages) => [...prevImages, ...imagefiles]);
      // setImages(files);
    
    // setImages(files);
  };
 
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newDocs = [...docs];
    const newInputDocs = [...inputDocs]; // copy the current inputDocs
  
    for (let i = 0; i < files.length; i++) {
      newDocs.push({
        doc: files[i],
        fileName: files[i].name,
        docType: 'technical datasheet',
      });
  
      newInputDocs.push(files[i]); // append the file to inputDocs
    }
  
    setDocs(newDocs);
    setInputDocs(newInputDocs); // update the inputDocs state
  };
  const handleChange = (params, event) => {
    event.stopPropagation();

    const inputVal = event.target.value;
    let newQuantity = parseInt(inputVal, 10);

    if (inputVal === "") {
        newQuantity = 0;
    }

    if (isNaN(newQuantity) || newQuantity < 0) return; // Ensure valid and non-negative input

    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, lotQuantity: newQuantity };
        } else {
          return row;
        }
      });
    });
    setFilteredRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, lotQuantity: newQuantity };
        } else {
          return row;
        }
      });
    });
}
  const handleDelete = (index) => {
    // Remove element from 'docs' array
    const toDel = [...docstoDel];
    const newDocs = [...docs];
    const docToDelete = newDocs.splice(index, 1)[0];
  
    
  
    // Check if the doc to delete is present in inputDocs
    const matchingInputDocIndex = inputDocs.findIndex(inputDoc => inputDoc.name === docToDelete.fileName);
  
    // If a match is found, remove it from inputDocs as well
    if(matchingInputDocIndex !== -1) {
      const newInputDocs = [...inputDocs];
      newInputDocs.splice(matchingInputDocIndex, 1);
      setInputDocs(newInputDocs);
    }
    else{
      toDel.push(docs[index].doc);
      setDocstoDel(toDel);
    }
    setDocs(newDocs);
  };
  
  
  function handleFileName(index, event) {
    const newDocs = [...docs]; // create a copy of docs array
    newDocs[index].docType = event.target.value; // update the docType of the document at the specified index
    setDocs(newDocs); // update the state with the new array
  }
  const handleIncrease = (params) => {
    console.log("Clicked increase for params.id:", params.id);
    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id) {
          return { ...row, lotQuantity: row.lotQuantity + 1 };
        } else {
          return row;
        }
      });
    });
  };
  const handleImageDelete = (index) => {
    const newImages = [...images];
    const deletedImage = newImages.splice(index, 1)[0];
    setImages(newImages);
    setDelImg((prevDelImg) => [...prevDelImg, deletedImage]);
    console.log(delImg);
  };

  const handleImageDelete2 = (index) => {
    const newImages = [...inputImages];
    newImages.splice(index, 1);
    setInputImages(newImages);
  };

  const handleDecrease = (params) => {
    console.log("Clicked decrease for params.id:", params.id);
    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.InventoryNumber === params.id && row.lotQuantity > 0) {
          return { ...row, lotQuantity: row.lotQuantity - 1 };
        } else {
          return row;
        }
      });
    });
  };

  const handleInputChange = (event) => {
    let tempLot = { ...lot };

    if (event.target.name === "name") tempLot["lotName"] = event.target.value;
    else if (event.target.name === "price")
      tempLot["price"] = event.target.value;
    else if (event.target.name === "bid-choice")
      tempLot["bidType"] = event.target.value;
    else if (event.target.name === "description")
      tempLot["lotDesc"] = event.target.value;
    else if (event.target.name === "expiry-date") {
      const selectedDate = new Date(event.target.value);
      setFormattedDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
      tempLot["deadline"] = event.target.value;
    } else if (event.target.name === "quantity")
      tempLot["Quantity"] = event.target.value;

    // tempLot["InventoryNumber"] = 321;
    // tempLot["OnHandInventory"] = "164 Products";
    // tempLot["Shipping"] = "Thursday, 18 Aug, 2022";
    console.log(tempLot);
    setLot(tempLot);
    console.log("Lot", lot);
  };
  const handleEditCellChange = ({ id, field, props }) => {
    console.log("INSIDE CHANGE");
    console.log(id);
    console.log(field);
    console.log(props);
    const updatedRows = rows.map((row) => {
      if (row.InventoryNumber === id) {
        return { ...row, [field]: props.value };
      }
      return row;
    });
    console.log(updatedRows);
    setRows(updatedRows);
  };
  function formatDate(dateString) {
    let date;
    if (Date.parse(dateString)) {
        date = new Date(dateString);
    } else {
        date = new Date();
    }
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return year + "-" + month + "-" + day;
}

  const handleQuantityChange = (inventoryNumber, value) => {
    const rowIndex = rows.findIndex(
      (row) => row.InventoryNumber === inventoryNumber
    );

    // Create a new copy of the row object with the updated BidChoice property
    const updatedRow = {
      ...rows[rowIndex],
      lotQuantity: value,
    };

    // Create a new array with the updated row and the other rows
    const updatedRows = [
      ...rows.slice(0, rowIndex),
      updatedRow,
      ...rows.slice(rowIndex + 1),
    ];

    // Update the state with the new rows array
    setRows(updatedRows);
  };
  const handleRowSelection = (selectedRows) => {
    setSelected(selectedRows);
    console.log(selectedRows);
  };

  const handleCancel = () => {
    navigate("/admin/item-listing/lot-list");
  };

  const handleDone = async (event) => {
    event.preventDefault();
    console.log(lot);
    console.log("COMPARISON", dateFormatting(lot.deadline), initialDeadline);
    const newDeadline = new Date(dateFormatting(lot.deadline));
    const oldDeadline = new Date(initialDeadline);
    if (lot.bidType === 'Close Bidding'){
      if(newDeadline > oldDeadline) {
        setShowModal(true);
      }
      else {
        await sendLotUpdateRequest();
      }
    }
    else{
      await sendLotUpdateRequest();
    }

  };
  const extractUUIDAndExtension = (url) => {
    if (typeof url !== 'string') {
        console.error("The provided URL is not a string:", url);
        return null;
    }

    const segments = url.split("/");
    const fullFilename = segments[segments.length - 1].split("?")[0];
    return fullFilename;
};
  const sendLotUpdateRequest = async () => {
    const formData = new FormData();
    formData.append("lotId", lot.lotId);
    formData.append("lotName", lot.lotName);
    formData.append("lotBid", lot.bidType);
    formData.append("lotDesc", lot.lotDesc);
    // formData.append("lotExpiry", lot.deadline);
    formData.append("lotPrice", lot.price);
    formData.append("sellerId", currentUser.id);
  
    let selectedInventoryNumbers = [];
    let publishedQuantities = [];
    if (selected.length <= 0){
      toast.error("Select items to add in lot")
    }
    else{
      selected.forEach((inventoryNumber) => {
        const row = rows.find((row) => row.InventoryNumber === inventoryNumber);
        if (row) {
          selectedInventoryNumbers.push(row.InventoryNumber);
          publishedQuantities.push(row.lotQuantity);
        }
      });
    
      formData.append(
        "selectedInventoryNumbers",
        JSON.stringify(selectedInventoryNumbers)
      );
      formData.append("publishedQuantities", JSON.stringify(publishedQuantities));
    
      docs.forEach((doc, index) => {
        formData.append(`doc_${index}`, doc.doc);
        formData.append(`docName_${index}`, doc.docType);
        formData.append(`fileName_${index}`, doc.fileName);
      });
    
      inputImages.forEach((image, index) => {
        formData.append(`image_${index}`, image);
      });
    
      delImg.forEach((name, index) => {
        const processedName = extractUUIDAndExtension(name);
        formData.append(`delImg${index}`, processedName);
      });
    
      inputDocs.forEach((inputDoc, index) => {
        formData.append(`inputDoc_${index}`, inputDoc);
      });
      console.log(docstoDel);
    
      docstoDel.forEach((deletedDoc, index) => {
        formData.append(`deletedDoc_${index}`, deletedDoc);
      });
    
      console.log("FORMDATA", formData);
    
      try {
        const response = await axios.post("/update_lot", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response.data);
        props.setMessage("Lot Updated Successfully.");
        navigate("/admin/item-listing/lot-list", { state: lot });
      } catch (error) {
        console.log('Caught Error:', error);
        
        if (error.response && error.response.data && error.response.data.error) {
           toast.error(error.response.data.error);
        } else {
           toast.error(error.message || "An unexpected error occurred.");
        }
     }

    }
    
  
    console.log(formData);
  
    // Your original axios request goes here
  };
  
  function dateFormatting(dateString) {
    const date = new Date(dateString);
    return date.toISOString().slice(0,16);
  }
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleExtensionReasonChange = (e) => {
    setExtensionReason(e.target.value);
  };

  const handleExtensionSubmit = async () => {
    if(extensionReason.length === 0){
      toast.error('Deadline can not be extended without a reason')
    }
    else{
      const response = await axios.post('/request_deadline', {
      deadline: dateFormatting(lot.deadline),
      lotId: lot.lotId,
      reason: extensionReason,
    });
    console.log(response)
    if(response){
      if(response.status === 203){
        toast.error('An extension request has already been made for this Lot')
      }
      
      await sendLotUpdateRequest();
      setShowModal(false);
      
    }

    }
    
    
  };

  

  return (
    <div className="create_items">
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {matches && (
            <Header
              getCollapseSidebar={getCollapseSidebar}
              getCollapseDropdown={getCollapseDropdown}
              logOut={props.logOut}
            />
          )}

          <div className="admin_panel">
            <div className="d-lg-flex">
              <div
                className={`${
                  collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                }`}
              >
                <Sidebar
                  collapsed={collapseSidebar}
                  collapsedDropdown={collapseDropdown}
                />
              </div>
              <div
                className={`inventory_list_content ${
                  collapseSidebar ? "open_content" : "pinched_content"
                }`}
              >
                {!matches && (
                  <Header
                    getCollapseSidebar={getCollapseSidebar}
                    getCollapseDropdown={getCollapseDropdown}
                    logOut={props.logOut}
                  />
                )}

                <div
                  className={`w-100 ${
                    collapseSidebar ? "slide-in" : "slide-out"
                  }`}
                >
                  <div className="content_panel">
                    <h3 className="pageName_heading">Update Lot</h3>
                    <form onSubmit={() => {}}>
                      <div className="createItem d-md-flex">
                        <div
                          className="upload_image"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            disabled
                            multiple
                          />
                          {images &&
                              images.length > 0 &&
                              images.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    flex: "0 0 25%",
                                    padding: "10px",
                                  }}
                                >
                                  <img loading="lazy" 
                                    src={`${image}`}
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                  {/* <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      backgroundColor: "#ffffff",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleImageDelete(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="12"
                                      height="12"
                                      fill="#000000"
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z" />
                                    </svg>
                                  </span> */}
                                </div>
                              ))}
                             {inputImages &&
                              inputImages.length > 0 &&
                              inputImages.map((image, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    flex: "0 0 25%",
                                    padding: "10px",
                                  }}
                                >
                                  <img loading="lazy" 
                                    src={URL.createObjectURL(image)}
                                    alt=""
                                    style={{ maxWidth: "100%", height: "auto" }}
                                  />
                                  {/* <span
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "5px",
                                      backgroundColor: "#ffffff",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleImageDelete2(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="12"
                                      height="12"
                                      fill="#000000"
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z" />
                                    </svg>
                                  </span> */}
                                </div>
                              ))}
                        </div>

                        <div className="buttons_group">
                          <button
                            type="button"
                            className="cancel"
                            onClick={handleCancel}
                          >
                            Back
                          </button>
                          
                        </div>
                      </div>
                      <div className="secondaryContainer">
                        <span>Core Information</span>
                        <br />
                        <span>
                          <small>
                            <RiErrorWarningLine
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                marginTop: "-3px",
                              }}
                            />
                            Specify the primary description for your item.
                          </small>
                        </span>
                      </div>

                      <div className="createItemForm">
                        <div className="row" style={{ marginTop: "0" }}>
                          <div className="col-md-3">
                            <label>
                              Lot Name <span>*</span>
                            </label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter Lot Name"
                                disabled
                                value={lot.lotName} // using the state to populate the field
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label>
                              Bid Choice <span>*</span>
                            </label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <select
                                name="bid-choice"
                                className="form-select"
                                value={lot.bidType}
                                disabled
                                
                                required
                              >
                                <option value="" hidden>
                                  Select Bid Choice
                                </option>
                                {/* <option value="Fixed Price">Fixed Price</option> */}
                                <option value="Open Bidding">
                                  Open Bidding
                                </option>
                                <option value="Close Bidding">
                                  Close Bidding
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label>Documents <span>*</span></label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="file"
                                name="documents"
                                className="form-control"
                                placeholder="Choose file"
                                disabled
                                multiple
                                accept=".pdf"
                              />
                              {docs &&
                                docs.length > 0 &&
                                docs.map((document, index) => (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center"
                                    style={{ flex: "0 0 25%", padding: "10px" }}
                                  >
                                    <select
                                      className="form-select"
                                      style={{
                                        marginRight: "10px",
                                        flex: "0 0 160px",
                                      }}
                                      type="text"
                                      placeholder="Enter file name"
                                     
                                      value={document.docType}
                                      disabled
                                    >
                                      {types.map((option, optionIndex) => (
                                        <option
                                          key={optionIndex}
                                          value={option}
                                        >
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                    <span style={{ flex: "1 1 auto" }}>
                                      {document.fileName}
                                    </span>
                                    
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="col-1 col-md-2 tooltip_container">
                            <Tooltip content="How is your item packed with respect to its state e.g., drums for liquid etc.">
                              <BsInfoCircle />
                            </Tooltip>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <label>Price</label>
                          </div>
                          <div className="col-11 col-md-5">
                            <div className="form-group">
                              <input
                                type="number"
                                name="price"
                                className="form-control"
                                placeholder="Enter Price"
                                value={lot.price}
                                disabled
                                
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <label>Description <span>*</span></label>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <textarea
                                rows="4"
                                name="description"
                                className="form-control"
                                style={{ resize: "none" }}
                                placeholder="Enter Description"
                                value={lot.lotDesc}
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        {lot.bidType === 'Close Bidding' && (
  <div className="row">
    <div className="col-md-3">
      <label>Bid Deadline <span>*</span></label>
    </div>
    <div className="col-md-5">
      <div className="form-group">
      <input
  type="datetime-local"
  name="expiry-date"
  style={{
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    padding: "0.375rem 0.75rem",
    backgroundColor:"white"
  }}
  value={dateFormatting(lot.deadline)}
  min={`${formattedDate1}T${formattedTime1}`}
  placeholder="Enter Expiry Date and Time"
  disable
  // required
/>
      </div>
    </div>
  </div>
)}


                      </div>
                    </form>
                    <div style={styles.container} className="customCard">
                      <GridContextProvider>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          getRowId={(row) => row.InventoryNumber}
                          //   //   editRowsModel={editRowsModel}
                          //   //   onEditRowModelChange={handleEditRowModelChange}
                          onEditCellPropsChange={handleEditCellChange}
                          //   //onCellEditCommit = {handleEditCellCommit}
                          //   //   onCellEditStop =
                          checkboxSelection={!isLoading}
                          onSelectionModelChange={()=>{}}
                          selectionModel={selected}
                          loading={isLoading}
                          rowHeight={40}
                          // rowsPerPageOptions={[5]}
                          pagination
                          density="compact"
                          disableExtendRowFullWidth={true}
                          disableSelectionOnClick={false}
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                          }
                        />
                      </GridContextProvider>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          
          </div>
          
        </>


      )}
      
    </div>
    
  );
};

export default ViewLot;
