import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import { FaPencilAlt } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoCopy, IoDocumentsSharp } from 'react-icons/io5';
import { MdOutlineCancel } from 'react-icons/md';
import { BsListUl } from 'react-icons/bs';
import { BiCurrentLocation, BiCommentDetail } from 'react-icons/bi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { FcUpload } from 'react-icons/fc';
import { FiDownload } from 'react-icons/fi';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import { Modal } from 'react-bootstrap';
import '../../css/style.css';
import '../../css/inventory.css';

const ProductDetail = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const [collapseSidebar, setCollapseSidebar] = useState(JSON.parse(localStorage.getItem("CollapseSidebar")));
    const [activeTab, setActiveTab] = useState(1);
    const [stockLocationColumns, setStockLocationColumns] = useState([]);
    const [stockLocationRows, setStockLocationRows] = useState([]);
    const [stockMovementColumns, setStockMovementColumns] = useState([]);
    const [stockMovementRows, setStockMovementRows] = useState([]);
    const [stockHistoryColumns, setStockHistoryColumns] = useState([]);
    const [stockHistoryRows, setStockHistoryRows] = useState([]);
    const [csvHeaders, setCSVHeaders] = useState([]);
    const [csvData, setCSVData] = useState([]);
    const [showQueryModal, setShowQueryModal] = useState(false);
    const [showAddStockModal, setShowAddStockModal] = useState(false);
    const [showDeleteStockModal, setShowDeleteStockModal] = useState(false);
    const [showCancelStockModal, setShowCancelStockModal] = useState(false);
    const [showStockHistoryModal, setShowStockHistoryModal] = useState(false);
    const [ignoreConflictingReservations, setIgnoreConflictingReservations] = useState(false);
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [response, setResponse] = useState("");
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 1024px)").matches);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const tempStockLocationColumns = [
            {
                name: "Location",
                selector: (row) => row.Location
            },
            {
                name: "Quantity",
                selector: (row) => row.Quantity
            },
            {
                name: "Minimum Stock",
                selector: (row) => row.MinimumStock
            },
            {
                name: "Status",
                selector: (row) => row.Status
            },
            {
                name: "Published Quantity",
                selector: (row) => row.PublishedQuantity
            }
        ];

        const tempStockLocationRows = [
            {
                Location: "Kent Complex",
                Quantity: 24,
                MinimumStock: 20,
                Status: "Excess",
                PublishedQuantity: 10
            }
        ];

        const tempStockMovementColumns = [
            {
                name: "Location",
                selector: (row) => row.Location
            },
            {
                name: "Quantity",
                selector: (row) => row.Quantity
            },
            {
                name: "Movement Type",
                selector: (row) => row.MovementType
            },
            {
                name: "Stakeholder",
                selector: (row) => row.Stakeholder
            }
        ];

        const tempStockMovementRows = [
            {
                Location: "Kent Complex",
                Quantity: 24,
                MovementType: "Purchased",
                Stakeholder: "Al-Marai"
            },
            {
                Location: "Kimadi",
                Quantity: 12,
                MovementType: "Expired",
                Stakeholder: "Internal (Norman)"
            }
        ];

        const tempStockHistoryColumns = [
            {
                selector: (row) =>
                    <button type="button" onClick={() => handleDeleteStockHistory(row.ID)}>
                        <RiDeleteBin6Line />
                    </button>
            },
            {
                name: "Action",
                selector: (row) => row.Action
            },
            {
                name: "For",
                selector: (row) => row.For
            },
            {
                name: "Quantity",
                selector: (row) => row.Quantity
            },
            {
                name: "Cost Per Unit",
                selector: (row) => row.CostPerUnit
            },
            {
                name: "Total Cost",
                selector: (row) => row.TotalCost
            },
            {
                name: "Date",
                selector: (row) => row.Date
            },
            {
                name: "From Location",
                selector: (row) => row.FromLocation
            },
            {
                name: "To Location",
                selector: (row) => row.ToLocation
            },
            {
                name: "Price Per Unit",
                selector: (row) => row.PricePerUnit
            },
            {
                name: "Total Price",
                selector: (row) => row.TotalPrice
            },
            {
                name: "Vendor",
                selector: (row) => row.Vendor
            },
            {
                name: "Comments",
                selector: (row) => row.Comments
            },
        ];

        const tempStockHistoryRows = [
            {
                ID: 0,
                Action: "Add Stock",
                For: "Alex Williams",
                Quantity: 64,
                CostPerUnit: "",
                TotalCost: "$0.00",
                Date: "16-08-2022 01:33",
                FromLocation: "",
                ToLocation: "",
                PricePerUnit: "$0.55",
                TotalPrice: "$35.00",
                Vendor: "",
                Comments: ""
            }
        ];

        setStockLocationColumns(tempStockLocationColumns);
        setStockLocationRows(tempStockLocationRows);
        setStockMovementColumns(tempStockMovementColumns);
        setStockMovementRows(tempStockMovementRows);
        setStockHistoryColumns(tempStockHistoryColumns);
        setStockHistoryRows(tempStockHistoryRows);

        window.matchMedia("(max-width: 1024px)").addEventListener('change', e => setMatches(e.matches));

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    }

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    }

    const handleCSVClick = (columns, data) => {
        let headers = [];

        for (var i = 0; i < columns.length; i++) {
            if (columns[i].name) {
                headers.push({ label: columns[i].name, key: Object.keys(data[0])[i] });
            }
        }

        setCSVHeaders(headers);
        setCSVData(data);
    }

    const handleShowQueryModal = () => {
        setShowQueryModal(true);
    }

    const handleShowAddStockModal = () => {
        setShowAddStockModal(true);
    }

    const handleShowStockHistoryModal = () => {
        setShowStockHistoryModal(true);
    }

    const handleShowDeleteStockModal = () => {
        setShowDeleteStockModal(true);
    }

    const handleShowCancelStockModal = () => {
        setShowCancelStockModal(true);
    }

    const handleCloseModal = () => {
        setShowQueryModal(false);
        setShowAddStockModal(false);
        setShowStockHistoryModal(false);
        setShowDeleteStockModal(false);
        setShowCancelStockModal(false);
        setError(null);
    }

    const handleResponse = (event) => {
        let userInput = event.target.value;
        setResponse(userInput);

        if (userInput && userInput.trim().length > 0) setError(null);
        else setError("*Response is required.");
    }

    const handleSubmitResponse = () => {
        if (response) {
            setShowQueryModal(false);
            setError(null);
        }
        else {
            setError("*Response is required.");
        }
    }

    const handleProcessOrder = (event) => {
        event.preventDefault();
        setShowAddStockModal(false);
    }

    const handleCheckboxChange = (event) => {
        setIgnoreConflictingReservations(event.target.checked);
    }

    const handleCancelStock = (event) => {
        event.preventDefault();
        setShowCancelStockModal(false);
    }

    const handleConfirmDelete = () => {
        setShowDeleteStockModal(false);
        navigate("/admin/item-listing/inventory-list");
    }

    const handleDeleteStockHistory = (index) => 
    {
        setStockHistoryRows(prevRows =>
        {
            prevRows.filter((i) => i !== index);
        });
    }

    const handleEdit = () => {
        navigate("/admin/item-listing/create-item");
    }

    const handleClone = () => {
        navigate("/admin/item-listing/create-item");
    }

    return (
        <>
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut={props.logOut} />
                    )}

                    <div className='admin_panel'>
                        <div className='d-lg-flex'>
                            <div className={`${collapseSidebar ? 'pinched_sidebar' : 'open_sidebar'}`}>
                                <Sidebar collapsed={collapseSidebar} collapsedDropdown={collapseDropdown} />
                            </div>

                            <div className={`inventory_list_content ${collapseSidebar ? 'open_content' : 'pinched_content'}`}>
                                {!matches && (
                                    <Header getCollapseSidebar = {getCollapseSidebar} getCollapseDropdown = {getCollapseDropdown} logOut={props.logOut} />
                                )}

                                <div className={`w-100 ${collapseSidebar ? 'slide-in' : 'slide-out'}`}>
                                    <div style={{ backgroundColor: '#F6F7FA', padding: '2em 1em' }}>
                                        <h3 style={{ color: '#525656' }}>Product Detail</h3>
                                        <br />

                                        <div className="productDetail">
                                            <img loading="lazy"  src={`${require('../../images/image001.png')}`} alt="" />

                                            <div style={{ width: '100%' }}>
                                                <span>Wood</span>
                                                <br />
                                                <br />
                                                <button type="button" className="product-function mr-[1.5em]" onClick={handleEdit}>
                                                    <FaPencilAlt className="icon" />
                                                    Edit
                                                </button>
                                                <button type="button" className="product-function mr-[1.5em]" onClick={handleShowDeleteStockModal}>
                                                    <RiDeleteBin6Line className="icon" />
                                                    Delete
                                                </button>
                                                <button type="button" className="product-function mr-[1.5em]" onClick={handleClone}>
                                                    <IoCopy className="icon" />
                                                    Clone
                                                </button>
                                                <button type="button" className="product-function" onClick={handleShowCancelStockModal}>
                                                    <MdOutlineCancel className="icon" />
                                                    Cancel Stock
                                                </button>
                                                <br />

                                                <div className='stock_history'>
                                                    <button type="button" className="stock-button mr-[1em]" onClick={handleShowStockHistoryModal}>Stock History</button>
                                                    <button type="button" className="stock-button" onClick={handleShowAddStockModal}>Add Stock</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab">
                                            <button className="tablinks" onClick={() => { setActiveTab(1) }} style={{ color: `${activeTab === 1 ? '#9F9F9F' : 'inherit'}`, borderBottom: `${activeTab === 1 ? '3px solid #1A0D8F' : 'none'}` }}>
                                                <BsListUl className="icon" />
                                                Details
                                            </button>
                                            <button className="tablinks" onClick={() => { setActiveTab(2) }} style={{ color: `${activeTab === 2 ? '#9F9F9F' : 'inherit'}`, borderBottom: `${activeTab === 2 ? '3px solid #1A0D8F' : 'none'}` }}>
                                                <BiCurrentLocation className="icon" />
                                                Stock Locations
                                            </button>
                                            <button className="tablinks" onClick={() => { setActiveTab(3) }} style={{ color: `${activeTab === 3 ? '#9F9F9F' : 'inherit'}`, borderBottom: `${activeTab === 3 ? '3px solid #1A0D8F' : 'none'}` }}>
                                                <AiOutlineClockCircle className="icon" />
                                                Stock Movement
                                            </button>
                                            <button className="tablinks" onClick={() => { setActiveTab(4) }} style={{ color: `${activeTab === 4 ? '#9F9F9F' : 'inherit'}`, borderBottom: `${activeTab === 4 ? '3px solid #1A0D8F' : 'none'}` }}>
                                                <BiCommentDetail className="icon" />
                                                Product Queries
                                            </button>
                                        </div>

                                        {activeTab === 1 ? (
                                            <>
                                                <div className="tabContent">
                                                    <h5>Detailed Information</h5>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <span className="title">Stock Number</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">IC000001022</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Total Stock</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">324</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Minimum Order Quantity</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">2390</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Batch Expiration Date</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">08-12-2025</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <span className="title">Description</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">Used to keep the record of time.</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Product Model Number</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">PM-060805</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Default Location</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">Head Office / Kimadi, Karachi, PK</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Units of Purchase</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">Crates</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h5 style={{ marginTop: '2em' }}>Pricing</h5>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <span className="title">Vendor</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">The Construction Crew</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">List Price</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">$45900</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Cost/Unit</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">$247</span>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '1em' }}>
                                                                <div className="col-md-6">
                                                                    <span className="title">Retire Price</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <span className="value">$299.99</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tabContent">
                                                    <h3 style={{ color: '#0D70A5' }}>
                                                        <IoDocumentsSharp style={{ display: 'inline-block', marginTop: '-8px', marginRight: '5px' }} />
                                                        Documentation
                                                    </h3>
                                                    <div className='table-responsive'>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>NAME</th>
                                                                    <th>STATUS</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <img loading="lazy"  src="https://placeimg.com/192/192/people" alt="" style={{ height: '60px' }} />
                                                                    </td>
                                                                    <td>
                                                                        <span style={{ color: '#3C52BE', display: 'block' }}>Conformity Certificate</span>
                                                                        <span style={{ color: '#ABABAB', fontSize: '11px', display: 'block' }}>08-07-2022</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="published-document">Published</span>
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" style={{ marginRight: '1em' }}>
                                                                            <FiDownload style={{ display: 'inline-block', color: '#54B9AF', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                        <button type="button" style={{ marginRight: '1em' }}>
                                                                            <FcUpload style={{ display: 'inline-block', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                        <button type="button">
                                                                            <RiDeleteBin6Line style={{ display: 'inline-block', color: '#54B9AF', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img loading="lazy"  src="https://placeimg.com/192/192/people" alt="" style={{ height: '60px' }} />
                                                                    </td>
                                                                    <td>
                                                                        <span style={{ color: '#3C52BE', display: 'block' }}>Safety Sheet</span>
                                                                        <span style={{ color: '#ABABAB', fontSize: '11px', display: 'block' }}>08-07-2022</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="uploaded-document">Uploaded</span>
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" style={{ marginRight: '1em' }}>
                                                                            <FiDownload style={{ display: 'inline-block', color: '#54B9AF', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                        <button type="button" style={{ marginRight: '1em' }}>
                                                                            <FcUpload style={{ display: 'inline-block', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                        <button type="button">
                                                                            <RiDeleteBin6Line style={{ display: 'inline-block', color: '#54B9AF', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <img loading="lazy"  src="https://placeimg.com/192/192/people" alt="" style={{ height: '60px' }} />
                                                                    </td>
                                                                    <td>
                                                                        <span style={{ color: '#3C52BE', display: 'block' }}>Specification Sheet</span>
                                                                        <span style={{ color: '#ABABAB', fontSize: '11px', display: 'block' }}>08-07-2022</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="not-published-document">Not Published</span>
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" style={{ marginRight: '1em' }}>
                                                                            <FiDownload style={{ display: 'inline-block', color: '#54B9AF', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                        <button type="button" style={{ marginRight: '1em' }}>
                                                                            <FcUpload style={{ display: 'inline-block', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                        <button type="button">
                                                                            <RiDeleteBin6Line style={{ display: 'inline-block', color: '#54B9AF', marginTop: '-3px', fontSize: '20px' }} />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <button type="button" className="addFile">ADD FILE</button>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </div>
                                            </>
                                        ) : activeTab === 2 ? (
                                            <div style = {{ height: '100vh' }}>
                                                <div className="tabContent">
                                                    <h5 className="mb-[1em]">Stock Location by Quantity</h5>
                                                    <div className="table-overflow product-detail stock-location">
                                                        <DataTable
                                                            columns={stockLocationColumns}
                                                            data={stockLocationRows}
                                                            pagination={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : activeTab === 3 ? (
                                            <div style = {{ height: '100vh' }}>
                                                <div className="tabContent">
                                                    <h5 className="mb-[1em]">Stock Movement History</h5>
                                                    <div className="table-overflow product-detail stock-movement">
                                                        <DataTable
                                                            columns={stockMovementColumns}
                                                            data={stockMovementRows}
                                                            pagination={true}
                                                        />
                                                    </div>

                                                    <CSVLink
                                                        className="extract"
                                                        data={csvData}
                                                        headers={csvHeaders}
                                                        asyncOnClick={true}
                                                        filename={"Stock Movement History.csv"}
                                                        onClick={() => handleCSVClick(stockMovementColumns, stockMovementRows)}
                                                    >
                                                        Extract History
                                                    </CSVLink>
                                                    <br />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        ) : activeTab === 4 && (
                                            <div style = {{ height: '100vh' }}>
                                                <div className="tabContent" style={{ padding: '1.5em 0' }}>
                                                    <h3 style={{ color: '#0D70A5', padding: '0 0.5em' }}>
                                                        <BiCommentDetail style={{ display: 'inline-block', marginRight: '5px' }} />
                                                        Queries
                                                    </h3>
                                                    <div className="table-responsive">
                                                        <table className="table product-query-table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">NAME</th>
                                                                    <th scope="col">QUERY</th>
                                                                    <th scope="col">STATUS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr onClick={handleShowQueryModal}>
                                                                    <td>
                                                                        <img loading="lazy"  src="https://placeimg.com/192/192/people" alt="" style={{ height: '60px' }} />
                                                                    </td>
                                                                    <td>
                                                                        <span style={{ color: '#3C52BE' }}>John Smith</span>
                                                                    </td>
                                                                    <td>
                                                                        <span style={{ color: '#3C52BE' }}>Does this product comply with API 224A7SE?</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`${response ? "answered" : "unanswered"}`}>{response ? 'Answered' : 'Unanswered'}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Modal centered show={showQueryModal} onHide={handleCloseModal}>
                                                        <Modal.Header closeButton={handleCloseModal}>
                                                            <h3>Query</h3>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div>
                                                                <div className="avatar active:scale-[1.01] inline-block align-middle">
                                                                    <div className="w-12 rounded-full ml-[-3px]">
                                                                        <img loading="lazy"  src="https://placeimg.com/192/192/people" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="inline-block ml-[1em]">
                                                                    <span style={{ fontSize: '18px' }}>John Smith</span>
                                                                </div>
                                                            </div>
                                                            <br />

                                                            <span style={{ color: '#9F9F9F', display: 'block', marginBottom: '0.3em' }}>QUERY</span>
                                                            <span style={{ display: 'block', fontSize: '14px', marginBottom: '1.3em' }}>Does this product comply with API 224A7SE?</span>
                                                            <span style={{ color: '#9F9F9F', display: 'block', marginBottom: '0.5em' }}>RESPONSE</span>
                                                            <textarea className="responseInput" rows="4" placeholder="Enter text here." onChange={(event) => handleResponse(event)} value={response} />
                                                            <div className="text-danger mt-[-5px]">
                                                                <small>{error}</small>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <button type="button" className="submit-response" onClick={handleSubmitResponse}>Submit Response</button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            </div>
                                        )}

                                        <Modal centered show={showAddStockModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton={handleCloseModal}>
                                                <h3>Add Stock</h3>
                                            </Modal.Header>
                                            <form className="processStockForm" onSubmit={handleProcessOrder}>
                                                <Modal.Body style={{ overflowY: 'auto', height: '400px' }}>
                                                    <div className="form-group">
                                                        <label htmlFor="location">Location</label>
                                                        <select name="location" className="form-select" id="location" defaultValue="">
                                                            <option value="" hidden>Select Country</option>
                                                            <option value="AF">Afghanistan</option>
                                                            <option value="AX">Aland Islands</option>
                                                            <option value="AL">Albania</option>
                                                            <option value="DZ">Algeria</option>
                                                            <option value="AS">American Samoa</option>
                                                            <option value="AD">Andorra</option>
                                                            <option value="AO">Angola</option>
                                                            <option value="AI">Anguilla</option>
                                                            <option value="AQ">Antarctica</option>
                                                            <option value="AG">Antigua and Barbuda</option>
                                                            <option value="AR">Argentina</option>
                                                            <option value="AM">Armenia</option>
                                                            <option value="AW">Aruba</option>
                                                            <option value="AU">Australia</option>
                                                            <option value="AT">Austria</option>
                                                            <option value="AZ">Azerbaijan</option>
                                                            <option value="BS">Bahamas</option>
                                                            <option value="BH">Bahrain</option>
                                                            <option value="BD">Bangladesh</option>
                                                            <option value="BB">Barbados</option>
                                                            <option value="BY">Belarus</option>
                                                            <option value="BE">Belgium</option>
                                                            <option value="BZ">Belize</option>
                                                            <option value="BJ">Benin</option>
                                                            <option value="BM">Bermuda</option>
                                                            <option value="BT">Bhutan</option>
                                                            <option value="BO">Bolivia</option>
                                                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                            <option value="BA">Bosnia and Herzegovina</option>
                                                            <option value="BW">Botswana</option>
                                                            <option value="BV">Bouvet Island</option>
                                                            <option value="BR">Brazil</option>
                                                            <option value="IO">British Indian Ocean Territory</option>
                                                            <option value="BN">Brunei Darussalam</option>
                                                            <option value="BG">Bulgaria</option>
                                                            <option value="BF">Burkina Faso</option>
                                                            <option value="BI">Burundi</option>
                                                            <option value="KH">Cambodia</option>
                                                            <option value="CM">Cameroon</option>
                                                            <option value="CA">Canada</option>
                                                            <option value="CV">Cape Verde</option>
                                                            <option value="KY">Cayman Islands</option>
                                                            <option value="CF">Central African Republic</option>
                                                            <option value="TD">Chad</option>
                                                            <option value="CL">Chile</option>
                                                            <option value="CN">China</option>
                                                            <option value="CX">Christmas Island</option>
                                                            <option value="CC">Cocos (Keeling) Islands</option>
                                                            <option value="CO">Colombia</option>
                                                            <option value="KM">Comoros</option>
                                                            <option value="CG">Congo</option>
                                                            <option value="CD">Congo, Democratic Republic of the Congo</option>
                                                            <option value="CK">Cook Islands</option>
                                                            <option value="CR">Costa Rica</option>
                                                            <option value="CI">Cote D'Ivoire</option>
                                                            <option value="HR">Croatia</option>
                                                            <option value="CU">Cuba</option>
                                                            <option value="CW">Curacao</option>
                                                            <option value="CY">Cyprus</option>
                                                            <option value="CZ">Czech Republic</option>
                                                            <option value="DK">Denmark</option>
                                                            <option value="DJ">Djibouti</option>
                                                            <option value="DM">Dominica</option>
                                                            <option value="DO">Dominican Republic</option>
                                                            <option value="EC">Ecuador</option>
                                                            <option value="EG">Egypt</option>
                                                            <option value="SV">El Salvador</option>
                                                            <option value="GQ">Equatorial Guinea</option>
                                                            <option value="ER">Eritrea</option>
                                                            <option value="EE">Estonia</option>
                                                            <option value="ET">Ethiopia</option>
                                                            <option value="FK">Falkland Islands (Malvinas)</option>
                                                            <option value="FO">Faroe Islands</option>
                                                            <option value="FJ">Fiji</option>
                                                            <option value="FI">Finland</option>
                                                            <option value="FR">France</option>
                                                            <option value="GF">French Guiana</option>
                                                            <option value="PF">French Polynesia</option>
                                                            <option value="TF">French Southern Territories</option>
                                                            <option value="GA">Gabon</option>
                                                            <option value="GM">Gambia</option>
                                                            <option value="GE">Georgia</option>
                                                            <option value="DE">Germany</option>
                                                            <option value="GH">Ghana</option>
                                                            <option value="GI">Gibraltar</option>
                                                            <option value="GR">Greece</option>
                                                            <option value="GL">Greenland</option>
                                                            <option value="GD">Grenada</option>
                                                            <option value="GP">Guadeloupe</option>
                                                            <option value="GU">Guam</option>
                                                            <option value="GT">Guatemala</option>
                                                            <option value="GG">Guernsey</option>
                                                            <option value="GN">Guinea</option>
                                                            <option value="GW">Guinea-Bissau</option>
                                                            <option value="GY">Guyana</option>
                                                            <option value="HT">Haiti</option>
                                                            <option value="HM">Heard Island and Mcdonald Islands</option>
                                                            <option value="VA">Holy See (Vatican City State)</option>
                                                            <option value="HN">Honduras</option>
                                                            <option value="HK">Hong Kong</option>
                                                            <option value="HU">Hungary</option>
                                                            <option value="IS">Iceland</option>
                                                            <option value="IN">India</option>
                                                            <option value="ID">Indonesia</option>
                                                            <option value="IR">Iran, Islamic Republic of</option>
                                                            <option value="IQ">Iraq</option>
                                                            <option value="IE">Ireland</option>
                                                            <option value="IM">Isle of Man</option>
                                                            <option value="IL">Israel</option>
                                                            <option value="IT">Italy</option>
                                                            <option value="JM">Jamaica</option>
                                                            <option value="JP">Japan</option>
                                                            <option value="JE">Jersey</option>
                                                            <option value="JO">Jordan</option>
                                                            <option value="KZ">Kazakhstan</option>
                                                            <option value="KE">Kenya</option>
                                                            <option value="KI">Kiribati</option>
                                                            <option value="KP">Korea, Democratic People's Republic of</option>
                                                            <option value="KR">Korea, Republic of</option>
                                                            <option value="XK">Kosovo</option>
                                                            <option value="KW">Kuwait</option>
                                                            <option value="KG">Kyrgyzstan</option>
                                                            <option value="LA">Lao People's Democratic Republic</option>
                                                            <option value="LV">Latvia</option>
                                                            <option value="LB">Lebanon</option>
                                                            <option value="LS">Lesotho</option>
                                                            <option value="LR">Liberia</option>
                                                            <option value="LY">Libyan Arab Jamahiriya</option>
                                                            <option value="LI">Liechtenstein</option>
                                                            <option value="LT">Lithuania</option>
                                                            <option value="LU">Luxembourg</option>
                                                            <option value="MO">Macao</option>
                                                            <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                                            <option value="MG">Madagascar</option>
                                                            <option value="MW">Malawi</option>
                                                            <option value="MY">Malaysia</option>
                                                            <option value="MV">Maldives</option>
                                                            <option value="ML">Mali</option>
                                                            <option value="MT">Malta</option>
                                                            <option value="MH">Marshall Islands</option>
                                                            <option value="MQ">Martinique</option>
                                                            <option value="MR">Mauritania</option>
                                                            <option value="MU">Mauritius</option>
                                                            <option value="YT">Mayotte</option>
                                                            <option value="MX">Mexico</option>
                                                            <option value="FM">Micronesia, Federated States of</option>
                                                            <option value="MD">Moldova, Republic of</option>
                                                            <option value="MC">Monaco</option>
                                                            <option value="MN">Mongolia</option>
                                                            <option value="ME">Montenegro</option>
                                                            <option value="MS">Montserrat</option>
                                                            <option value="MA">Morocco</option>
                                                            <option value="MZ">Mozambique</option>
                                                            <option value="MM">Myanmar</option>
                                                            <option value="NA">Namibia</option>
                                                            <option value="NR">Nauru</option>
                                                            <option value="NP">Nepal</option>
                                                            <option value="NL">Netherlands</option>
                                                            <option value="AN">Netherlands Antilles</option>
                                                            <option value="NC">New Caledonia</option>
                                                            <option value="NZ">New Zealand</option>
                                                            <option value="NI">Nicaragua</option>
                                                            <option value="NE">Niger</option>
                                                            <option value="NG">Nigeria</option>
                                                            <option value="NU">Niue</option>
                                                            <option value="NF">Norfolk Island</option>
                                                            <option value="MP">Northern Mariana Islands</option>
                                                            <option value="NO">Norway</option>
                                                            <option value="OM">Oman</option>
                                                            <option value="PK">Pakistan</option>
                                                            <option value="PW">Palau</option>
                                                            <option value="PS">Palestinian Territory, Occupied</option>
                                                            <option value="PA">Panama</option>
                                                            <option value="PG">Papua New Guinea</option>
                                                            <option value="PY">Paraguay</option>
                                                            <option value="PE">Peru</option>
                                                            <option value="PH">Philippines</option>
                                                            <option value="PN">Pitcairn</option>
                                                            <option value="PL">Poland</option>
                                                            <option value="PT">Portugal</option>
                                                            <option value="PR">Puerto Rico</option>
                                                            <option value="QA">Qatar</option>
                                                            <option value="RE">Reunion</option>
                                                            <option value="RO">Romania</option>
                                                            <option value="RU">Russian Federation</option>
                                                            <option value="RW">Rwanda</option>
                                                            <option value="BL">Saint Barthelemy</option>
                                                            <option value="SH">Saint Helena</option>
                                                            <option value="KN">Saint Kitts and Nevis</option>
                                                            <option value="LC">Saint Lucia</option>
                                                            <option value="MF">Saint Martin</option>
                                                            <option value="PM">Saint Pierre and Miquelon</option>
                                                            <option value="VC">Saint Vincent and the Grenadines</option>
                                                            <option value="WS">Samoa</option>
                                                            <option value="SM">San Marino</option>
                                                            <option value="ST">Sao Tome and Principe</option>
                                                            <option value="SA">Saudi Arabia</option>
                                                            <option value="SN">Senegal</option>
                                                            <option value="RS">Serbia</option>
                                                            <option value="CS">Serbia and Montenegro</option>
                                                            <option value="SC">Seychelles</option>
                                                            <option value="SL">Sierra Leone</option>
                                                            <option value="SG">Singapore</option>
                                                            <option value="SX">Sint Maarten</option>
                                                            <option value="SK">Slovakia</option>
                                                            <option value="SI">Slovenia</option>
                                                            <option value="SB">Solomon Islands</option>
                                                            <option value="SO">Somalia</option>
                                                            <option value="ZA">South Africa</option>
                                                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                            <option value="SS">South Sudan</option>
                                                            <option value="ES">Spain</option>
                                                            <option value="LK">Sri Lanka</option>
                                                            <option value="SD">Sudan</option>
                                                            <option value="SR">Suriname</option>
                                                            <option value="SJ">Svalbard and Jan Mayen</option>
                                                            <option value="SZ">Swaziland</option>
                                                            <option value="SE">Sweden</option>
                                                            <option value="CH">Switzerland</option>
                                                            <option value="SY">Syrian Arab Republic</option>
                                                            <option value="TW">Taiwan, Province of China</option>
                                                            <option value="TJ">Tajikistan</option>
                                                            <option value="TZ">Tanzania, United Republic of</option>
                                                            <option value="TH">Thailand</option>
                                                            <option value="TL">Timor-Leste</option>
                                                            <option value="TG">Togo</option>
                                                            <option value="TK">Tokelau</option>
                                                            <option value="TO">Tonga</option>
                                                            <option value="TT">Trinidad and Tobago</option>
                                                            <option value="TN">Tunisia</option>
                                                            <option value="TR">Turkey</option>
                                                            <option value="TM">Turkmenistan</option>
                                                            <option value="TC">Turks and Caicos Islands</option>
                                                            <option value="TV">Tuvalu</option>
                                                            <option value="UG">Uganda</option>
                                                            <option value="UA">Ukraine</option>
                                                            <option value="AE">United Arab Emirates</option>
                                                            <option value="GB">United Kingdom</option>
                                                            <option value="US">United States</option>
                                                            <option value="UM">United States Minor Outlying Islands</option>
                                                            <option value="UY">Uruguay</option>
                                                            <option value="UZ">Uzbekistan</option>
                                                            <option value="VU">Vanuatu</option>
                                                            <option value="VE">Venezuela</option>
                                                            <option value="VN">Viet Nam</option>
                                                            <option value="VG">Virgin Islands, British</option>
                                                            <option value="VI">Virgin Islands, U.s.</option>
                                                            <option value="WF">Wallis and Futuna</option>
                                                            <option value="EH">Western Sahara</option>
                                                            <option value="YE">Yemen</option>
                                                            <option value="ZM">Zambia</option>
                                                            <option value="ZW">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="quantity">Quantity <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number" name="quantity" className="form-control" id="quantity" placeholder="Enter Quantity" required />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="price-per-unit">Price Per Unit</label>
                                                        <input type="number" step="any" name="price-per-unit" className="form-control" id="price-per-unit" placeholder="Enter Price Per Unit" />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="total-price">Total Price <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number" step="any" name="total-price" className="form-control" id="total-price" placeholder="Enter Total Price" required />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="vendor">Vendor</label>
                                                        <input type="text" name="vendor" className="form-control" id="vendor" placeholder="Enter Vendor" />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="ordered-by">Ordered By</label>
                                                        <input type="text" name="ordered-by" className="form-control" id="ordered-by" placeholder="Enter Ordered By" />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="purchased-on">Purchased On</label>
                                                        <input type="date" name="purchased-on" className="form-control" style={{ flexDirection: 'row' }} id="purchased-on" placeholder="Enter Purchased On" />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="comments">Comments</label>
                                                        <textarea rows="4" name="comments" className="form-control" style={{ resize: 'none' }} id="comments" />
                                                    </div>
                                                    <div className="form-check" style={{ marginLeft: '-5px', marginTop: '0.5em' }}>
                                                        <input className="form-check-input" style={{ marginTop: '7px' }} type="checkbox" defaultChecked={false} value={ignoreConflictingReservations} id="ignore-conflicting-reservations" onChange={handleCheckboxChange} />
                                                        <label className="form-check-label text-[14px]" htmlFor="ignore-conflicting-reservations">
                                                            Ignore conflicting reservations
                                                        </label>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button type="submit" className="process-stock">Process Order</button>
                                                </Modal.Footer>
                                            </form>
                                        </Modal>

                                        <Modal centered show={showStockHistoryModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton={handleCloseModal}>
                                                <h3>Stock History</h3>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {stockHistoryRows && stockHistoryRows.length > 0 && (
                                                    <CSVLink
                                                        style={{ float: 'right', textDecoration: 'none', fontSize: '14px', marginBottom: '0.5em' }}
                                                        data={csvData}
                                                        headers={csvHeaders}
                                                        asyncOnClick={true}
                                                        filename={"Stock History.csv"}
                                                        onClick={() => handleCSVClick(stockHistoryColumns, stockHistoryRows)}
                                                    >
                                                        Export to CSV
                                                    </CSVLink>
                                                )}

                                                <div className="table-overflow item-listing">
                                                    <DataTable
                                                        columns={stockHistoryColumns}
                                                        data={stockHistoryRows}
                                                        pagination={true}
                                                    />
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                        <Modal centered show={showDeleteStockModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton={handleCloseModal}>
                                                <h3 className="text-[20px]">Are you sure you want to delete this product?</h3>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <span style={{ fontSize: '14px', fontWeight: 'lighter' }}>NOTE: Deletions cannot be recovered, only proceed if you're certain you want to take the action.</span>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <div style={{ float: 'right' }}>
                                                    <button type="button" className="cancel-stock" onClick={handleCloseModal}>Cancel</button>
                                                    <button type="button" className="process-stock" style={{ float: 'none', marginTop: '0' }} onClick={handleConfirmDelete}>Confirm</button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal centered show={showCancelStockModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton={handleCloseModal}>
                                                <h3>Cancel Stock Items</h3>
                                            </Modal.Header>
                                            <form className="processStockForm" onSubmit={handleCancelStock}>
                                                <Modal.Body style={{ overflowY: 'auto', height: '400px' }}>
                                                    <div className="form-group">
                                                        <label htmlFor="quantity">Quantity</label>
                                                        <input type="number" name="quantity" className="form-control" id="quantity" placeholder="Enter Quantity" />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="salvage-value">Salvage Value / Unit</label>
                                                        <input type="number" step="any" name="salvage-value" className="form-control" id="salvage-value" placeholder="Enter Salvage Value / Unit" />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="total-salvage-value">Total Salvage Value <span style={{ color: 'red' }}>*</span></label>
                                                        <input type="number" step="any" name="total-salvage-value" className="form-control" id="total-salvage-value" placeholder="Enter Total Salvage Value" required />
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="location">Location</label>
                                                        <select name="location" className="form-select" id="location" defaultValue="">
                                                            <option value="" hidden>Select Country</option>
                                                            <option value="AF">Afghanistan</option>
                                                            <option value="AX">Aland Islands</option>
                                                            <option value="AL">Albania</option>
                                                            <option value="DZ">Algeria</option>
                                                            <option value="AS">American Samoa</option>
                                                            <option value="AD">Andorra</option>
                                                            <option value="AO">Angola</option>
                                                            <option value="AI">Anguilla</option>
                                                            <option value="AQ">Antarctica</option>
                                                            <option value="AG">Antigua and Barbuda</option>
                                                            <option value="AR">Argentina</option>
                                                            <option value="AM">Armenia</option>
                                                            <option value="AW">Aruba</option>
                                                            <option value="AU">Australia</option>
                                                            <option value="AT">Austria</option>
                                                            <option value="AZ">Azerbaijan</option>
                                                            <option value="BS">Bahamas</option>
                                                            <option value="BH">Bahrain</option>
                                                            <option value="BD">Bangladesh</option>
                                                            <option value="BB">Barbados</option>
                                                            <option value="BY">Belarus</option>
                                                            <option value="BE">Belgium</option>
                                                            <option value="BZ">Belize</option>
                                                            <option value="BJ">Benin</option>
                                                            <option value="BM">Bermuda</option>
                                                            <option value="BT">Bhutan</option>
                                                            <option value="BO">Bolivia</option>
                                                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                            <option value="BA">Bosnia and Herzegovina</option>
                                                            <option value="BW">Botswana</option>
                                                            <option value="BV">Bouvet Island</option>
                                                            <option value="BR">Brazil</option>
                                                            <option value="IO">British Indian Ocean Territory</option>
                                                            <option value="BN">Brunei Darussalam</option>
                                                            <option value="BG">Bulgaria</option>
                                                            <option value="BF">Burkina Faso</option>
                                                            <option value="BI">Burundi</option>
                                                            <option value="KH">Cambodia</option>
                                                            <option value="CM">Cameroon</option>
                                                            <option value="CA">Canada</option>
                                                            <option value="CV">Cape Verde</option>
                                                            <option value="KY">Cayman Islands</option>
                                                            <option value="CF">Central African Republic</option>
                                                            <option value="TD">Chad</option>
                                                            <option value="CL">Chile</option>
                                                            <option value="CN">China</option>
                                                            <option value="CX">Christmas Island</option>
                                                            <option value="CC">Cocos (Keeling) Islands</option>
                                                            <option value="CO">Colombia</option>
                                                            <option value="KM">Comoros</option>
                                                            <option value="CG">Congo</option>
                                                            <option value="CD">Congo, Democratic Republic of the Congo</option>
                                                            <option value="CK">Cook Islands</option>
                                                            <option value="CR">Costa Rica</option>
                                                            <option value="CI">Cote D'Ivoire</option>
                                                            <option value="HR">Croatia</option>
                                                            <option value="CU">Cuba</option>
                                                            <option value="CW">Curacao</option>
                                                            <option value="CY">Cyprus</option>
                                                            <option value="CZ">Czech Republic</option>
                                                            <option value="DK">Denmark</option>
                                                            <option value="DJ">Djibouti</option>
                                                            <option value="DM">Dominica</option>
                                                            <option value="DO">Dominican Republic</option>
                                                            <option value="EC">Ecuador</option>
                                                            <option value="EG">Egypt</option>
                                                            <option value="SV">El Salvador</option>
                                                            <option value="GQ">Equatorial Guinea</option>
                                                            <option value="ER">Eritrea</option>
                                                            <option value="EE">Estonia</option>
                                                            <option value="ET">Ethiopia</option>
                                                            <option value="FK">Falkland Islands (Malvinas)</option>
                                                            <option value="FO">Faroe Islands</option>
                                                            <option value="FJ">Fiji</option>
                                                            <option value="FI">Finland</option>
                                                            <option value="FR">France</option>
                                                            <option value="GF">French Guiana</option>
                                                            <option value="PF">French Polynesia</option>
                                                            <option value="TF">French Southern Territories</option>
                                                            <option value="GA">Gabon</option>
                                                            <option value="GM">Gambia</option>
                                                            <option value="GE">Georgia</option>
                                                            <option value="DE">Germany</option>
                                                            <option value="GH">Ghana</option>
                                                            <option value="GI">Gibraltar</option>
                                                            <option value="GR">Greece</option>
                                                            <option value="GL">Greenland</option>
                                                            <option value="GD">Grenada</option>
                                                            <option value="GP">Guadeloupe</option>
                                                            <option value="GU">Guam</option>
                                                            <option value="GT">Guatemala</option>
                                                            <option value="GG">Guernsey</option>
                                                            <option value="GN">Guinea</option>
                                                            <option value="GW">Guinea-Bissau</option>
                                                            <option value="GY">Guyana</option>
                                                            <option value="HT">Haiti</option>
                                                            <option value="HM">Heard Island and Mcdonald Islands</option>
                                                            <option value="VA">Holy See (Vatican City State)</option>
                                                            <option value="HN">Honduras</option>
                                                            <option value="HK">Hong Kong</option>
                                                            <option value="HU">Hungary</option>
                                                            <option value="IS">Iceland</option>
                                                            <option value="IN">India</option>
                                                            <option value="ID">Indonesia</option>
                                                            <option value="IR">Iran, Islamic Republic of</option>
                                                            <option value="IQ">Iraq</option>
                                                            <option value="IE">Ireland</option>
                                                            <option value="IM">Isle of Man</option>
                                                            <option value="IL">Israel</option>
                                                            <option value="IT">Italy</option>
                                                            <option value="JM">Jamaica</option>
                                                            <option value="JP">Japan</option>
                                                            <option value="JE">Jersey</option>
                                                            <option value="JO">Jordan</option>
                                                            <option value="KZ">Kazakhstan</option>
                                                            <option value="KE">Kenya</option>
                                                            <option value="KI">Kiribati</option>
                                                            <option value="KP">Korea, Democratic People's Republic of</option>
                                                            <option value="KR">Korea, Republic of</option>
                                                            <option value="XK">Kosovo</option>
                                                            <option value="KW">Kuwait</option>
                                                            <option value="KG">Kyrgyzstan</option>
                                                            <option value="LA">Lao People's Democratic Republic</option>
                                                            <option value="LV">Latvia</option>
                                                            <option value="LB">Lebanon</option>
                                                            <option value="LS">Lesotho</option>
                                                            <option value="LR">Liberia</option>
                                                            <option value="LY">Libyan Arab Jamahiriya</option>
                                                            <option value="LI">Liechtenstein</option>
                                                            <option value="LT">Lithuania</option>
                                                            <option value="LU">Luxembourg</option>
                                                            <option value="MO">Macao</option>
                                                            <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                                            <option value="MG">Madagascar</option>
                                                            <option value="MW">Malawi</option>
                                                            <option value="MY">Malaysia</option>
                                                            <option value="MV">Maldives</option>
                                                            <option value="ML">Mali</option>
                                                            <option value="MT">Malta</option>
                                                            <option value="MH">Marshall Islands</option>
                                                            <option value="MQ">Martinique</option>
                                                            <option value="MR">Mauritania</option>
                                                            <option value="MU">Mauritius</option>
                                                            <option value="YT">Mayotte</option>
                                                            <option value="MX">Mexico</option>
                                                            <option value="FM">Micronesia, Federated States of</option>
                                                            <option value="MD">Moldova, Republic of</option>
                                                            <option value="MC">Monaco</option>
                                                            <option value="MN">Mongolia</option>
                                                            <option value="ME">Montenegro</option>
                                                            <option value="MS">Montserrat</option>
                                                            <option value="MA">Morocco</option>
                                                            <option value="MZ">Mozambique</option>
                                                            <option value="MM">Myanmar</option>
                                                            <option value="NA">Namibia</option>
                                                            <option value="NR">Nauru</option>
                                                            <option value="NP">Nepal</option>
                                                            <option value="NL">Netherlands</option>
                                                            <option value="AN">Netherlands Antilles</option>
                                                            <option value="NC">New Caledonia</option>
                                                            <option value="NZ">New Zealand</option>
                                                            <option value="NI">Nicaragua</option>
                                                            <option value="NE">Niger</option>
                                                            <option value="NG">Nigeria</option>
                                                            <option value="NU">Niue</option>
                                                            <option value="NF">Norfolk Island</option>
                                                            <option value="MP">Northern Mariana Islands</option>
                                                            <option value="NO">Norway</option>
                                                            <option value="OM">Oman</option>
                                                            <option value="PK">Pakistan</option>
                                                            <option value="PW">Palau</option>
                                                            <option value="PS">Palestinian Territory, Occupied</option>
                                                            <option value="PA">Panama</option>
                                                            <option value="PG">Papua New Guinea</option>
                                                            <option value="PY">Paraguay</option>
                                                            <option value="PE">Peru</option>
                                                            <option value="PH">Philippines</option>
                                                            <option value="PN">Pitcairn</option>
                                                            <option value="PL">Poland</option>
                                                            <option value="PT">Portugal</option>
                                                            <option value="PR">Puerto Rico</option>
                                                            <option value="QA">Qatar</option>
                                                            <option value="RE">Reunion</option>
                                                            <option value="RO">Romania</option>
                                                            <option value="RU">Russian Federation</option>
                                                            <option value="RW">Rwanda</option>
                                                            <option value="BL">Saint Barthelemy</option>
                                                            <option value="SH">Saint Helena</option>
                                                            <option value="KN">Saint Kitts and Nevis</option>
                                                            <option value="LC">Saint Lucia</option>
                                                            <option value="MF">Saint Martin</option>
                                                            <option value="PM">Saint Pierre and Miquelon</option>
                                                            <option value="VC">Saint Vincent and the Grenadines</option>
                                                            <option value="WS">Samoa</option>
                                                            <option value="SM">San Marino</option>
                                                            <option value="ST">Sao Tome and Principe</option>
                                                            <option value="SA">Saudi Arabia</option>
                                                            <option value="SN">Senegal</option>
                                                            <option value="RS">Serbia</option>
                                                            <option value="CS">Serbia and Montenegro</option>
                                                            <option value="SC">Seychelles</option>
                                                            <option value="SL">Sierra Leone</option>
                                                            <option value="SG">Singapore</option>
                                                            <option value="SX">Sint Maarten</option>
                                                            <option value="SK">Slovakia</option>
                                                            <option value="SI">Slovenia</option>
                                                            <option value="SB">Solomon Islands</option>
                                                            <option value="SO">Somalia</option>
                                                            <option value="ZA">South Africa</option>
                                                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                            <option value="SS">South Sudan</option>
                                                            <option value="ES">Spain</option>
                                                            <option value="LK">Sri Lanka</option>
                                                            <option value="SD">Sudan</option>
                                                            <option value="SR">Suriname</option>
                                                            <option value="SJ">Svalbard and Jan Mayen</option>
                                                            <option value="SZ">Swaziland</option>
                                                            <option value="SE">Sweden</option>
                                                            <option value="CH">Switzerland</option>
                                                            <option value="SY">Syrian Arab Republic</option>
                                                            <option value="TW">Taiwan, Province of China</option>
                                                            <option value="TJ">Tajikistan</option>
                                                            <option value="TZ">Tanzania, United Republic of</option>
                                                            <option value="TH">Thailand</option>
                                                            <option value="TL">Timor-Leste</option>
                                                            <option value="TG">Togo</option>
                                                            <option value="TK">Tokelau</option>
                                                            <option value="TO">Tonga</option>
                                                            <option value="TT">Trinidad and Tobago</option>
                                                            <option value="TN">Tunisia</option>
                                                            <option value="TR">Turkey</option>
                                                            <option value="TM">Turkmenistan</option>
                                                            <option value="TC">Turks and Caicos Islands</option>
                                                            <option value="TV">Tuvalu</option>
                                                            <option value="UG">Uganda</option>
                                                            <option value="UA">Ukraine</option>
                                                            <option value="AE">United Arab Emirates</option>
                                                            <option value="GB">United Kingdom</option>
                                                            <option value="US">United States</option>
                                                            <option value="UM">United States Minor Outlying Islands</option>
                                                            <option value="UY">Uruguay</option>
                                                            <option value="UZ">Uzbekistan</option>
                                                            <option value="VU">Vanuatu</option>
                                                            <option value="VE">Venezuela</option>
                                                            <option value="VN">Viet Nam</option>
                                                            <option value="VG">Virgin Islands, British</option>
                                                            <option value="VI">Virgin Islands, U.s.</option>
                                                            <option value="WF">Wallis and Futuna</option>
                                                            <option value="EH">Western Sahara</option>
                                                            <option value="YE">Yemen</option>
                                                            <option value="ZM">Zambia</option>
                                                            <option value="ZW">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="retire-reason">Retire Reason <span style={{ color: 'red' }}>*</span></label>
                                                        <select name="retire-reason" className="form-select" id="retire-reason" defaultValue="" required>
                                                            <option value="" hidden>Select Retire Reason</option>
                                                            <option value="damaged">Damaged</option>
                                                            <option value="expired">Expired</option>
                                                            <option value="lost">Lost</option>
                                                            <option value="stolen">Stolen</option>
                                                            <option value="releases">Released</option>
                                                            <option value="sold">Sold</option>
                                                            <option value="gifted">Gifted</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: '0.5em' }}>
                                                        <label htmlFor="comments">Comments</label>
                                                        <textarea rows="4" name="comments" className="form-control" style={{ resize: 'none' }} id="comments" />
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button type="submit" className="process-stock">Cancel Stock</button>
                                                </Modal.Footer>
                                            </form>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default ProductDetail;