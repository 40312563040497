import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import NotFoundImage from './images/404-Image.jpeg';
import './css/404.css';

const PageNotFound = () => 
{
    const navigate = useNavigate();

    return (
        <div style = {{ padding: '0.5em 0' }}>
            <Card className = "notFoundCard" style = {{ height: '97vh' }}>
                <Card.Body>
                    <Row>
                        <Col md = {5} style = {{ display: 'flex' }}>
                            <div className = "notFoundText">
                                <h1>404</h1>
                                <h5>Page Not Found</h5>
                                <br />
                                <span>The page you requested could not be found. <br/> Please go back to the homepage.</span>
                                <button className = "btn btn-primary" onClick = {() => { navigate("/") }} style = {{ marginTop: '1em' }}>Go Home</button>
                            </div>
                        </Col>
                        <Col md = {7}>
                            <div className = "bg-image-container">
                                <img loading="lazy"  src = {NotFoundImage} className = "bg-image" alt = "404" />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default PageNotFound;