import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { styled } from '@mui/system';
import { DataGrid, GridContextProvider } from "@mui/x-data-grid";
import axios from "axios";
import { MdCancel, MdOutlineDone } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "../../css/style.css";
import "../../css/muiDataTable.css";
import "../../css/requests.css";
import "../../css/inventory.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUp from "../helperComponents/PopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
//import { BsPersonFillCheck, BsPersonFillSlash } from "react-icons/bs";
const ExtensionRequests = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState("");

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showTxModal, setShowTxModal] = useState(false);
    const [txModalType, setTxModalType] = useState();
    const [txTitle, setTxTitle] = useState("");
    const [txMsg, setTxMsg] = useState("");
    const [selectedTab, setSelectedTab] = useState("Extension Requests");
    const [txData, setTxData] = useState();
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );
    const styles = {
        container: {
            height: "500px",
            widht: "100px" // set the height of the container
        },
    };
    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);
    const timeOut = () => {
        const timeId = setTimeout(() => {
            setShowTxModal(false)
        }, 2000)
        return () => {
            clearTimeout(timeId)
        }
    }
    useEffect(() => {
        const getTemplotData = async () => {
            setIsLoading(true);
            await axios({
                method: "get",
                url: "/templots", // Update this with the correct user ID
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response, "templot data");
    
                // Assuming response.data.lots is the array of data
                const rowData = response?.data?.lots;
    
                // Check the first row to dynamically generate columns
                const dynamicColumns = Object.keys(rowData[0]).filter(columnName => !['adminResponded', 'templotId', 'sellerId'].includes(columnName)).map((columnName) => {
                    const columnValue = rowData[0][columnName];
                    const isInteger = Number.isInteger(columnValue);
                    
                    // Basic column definition
                    const columnDef = {
                        field: columnName,
                        headerName: columnName,
                        width: isInteger ? 75 : 150,
                        type: isInteger ? 'number' : 'string',
                    };
                    
                    // If column is 'Price', adjust its rendering
                    if (columnName === 'Price') {
                        columnDef.renderCell = (params) => {
                            return `${params.value} ${currentUser.currency}`;
                        }
                    }
                
                    return columnDef;
                });
    
                const actionsColumn = {
                    field: 'actions',
                    headerName: 'Actions',
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                        const isDisabled = params?.row?.adminResponded === 1;
                        return (
                            <div className="d-flex gap-3 m-auto">
                                <button
                                    className='custom_btn outline_disabled_button btn btn-outline-success'
                                    onClick={() => handleApproveLot(params.row)}
                                    disabled={isDisabled}
                                    style={{ width: '80px' }}
                                >
                                    <BsFillCheckCircleFill
                                        style={{
                                            color: `${
                                                params.row.adminResponded === 1
                                                ? "#CCC"
                                                : "green"
                                            }`,
                                            fontSize: "15px",
                                        }}
                                    />
                                </button>
                                <button
                                    className='custom_btn outline_disabled_button btn btn-outline-danger'
                                    onClick={() => handleRejectLot(params.row)}
                                    disabled={isDisabled}
                                    style={{ width: '80px' }}
                                >
                                    <MdCancel
                                        style={{
                                            color: `${
                                                params.row.adminResponded === 1
                                                ? "#CCC"
                                                : "red"
                                            }`,
                                            fontSize: "15px",
                                        }}
                                    />
                                </button>
                                
                            </div>
                        );
                    },
                };
                const viewColumn = {
                    field: 'view',
                    headerName: 'View',
                    minWidth: 100, // Adjust the width as needed
                    flex: 1,
                    renderCell: (params) => (
                        <div className="d-flex gap-3 m-auto">
                        <button>
                        <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => {
                                navigate('/admin/admin-section/view-updatedlot', {
                                    state: params.row,
                                });
                            }}
                        />
                        </button>
                        <button>
                        <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                                navigate('/admin/admin-section/edit-updatedlot', {
                                    state: params.row,
                                });
                            }}
                        />
                        </button>
                        

                        </div>
                        
                    ),
                };
                
    
                // Separate integer columns and string columns
                const integerColumns = dynamicColumns.filter((column) => column.type === 'number');
                const stringColumns = dynamicColumns.filter((column) => column.type === 'string');
    
                // Combine columns
                const combinedColumns = [...integerColumns, ...stringColumns, actionsColumn, viewColumn];
    
                setColumns(combinedColumns);
                setRows(rowData);
                setIsLoading(false);
            })
            .catch((error) => {
                setAlertError("something went wrong");
                setIsLoading(false);
                console.log("Get templot Api error: ", error);
            });
        };
        const getDeadlineReasonsData = async () => {
            setIsLoading(true);
            await axios({
                method: "get",
                url: "/get_deadlinereasons",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    console.log(response, "channel Pc");

                    const dynamicColumns = response?.data?.columns
                        .filter(columnName => columnName !== 'adminResponded') // Exclude column with name 'adminResponded'
                        .map((columnName) => {
                            const columnValue = response?.data?.rowData[0][columnName];
                            const isInteger = Number.isInteger(columnValue);

                            return {
                                field: columnName,
                                headerName: columnName,
                                width: isInteger ? 75 : 150,
                                type: isInteger ? 'number' : 'string',
                            };
                        });
                    const actionsColumn = {
                        field: 'actions',
                        headerName: 'Actions',
                        minWidth: 200,
                        flex: 1,
                        renderCell: (params) => {
                            const isDisabled = params?.row?.adminResponded === 1;
                            return (
                                <div className="d-flex gap-3 m-auto">
                                    <button
                                        className='custom_btn outline_disabled_button btn btn-outline-success'
                                        onClick={() => handleApprove(params.row)}
                                        disabled={isDisabled}
                                        style={{ width: '80px' }}
                                    >
                                        <BsFillCheckCircleFill
                        style={{
                          color: `${
                            params.row.adminResponded === 1
                            ? "#CCC"
                            : "green"
                            }`,
                          fontSize: "15px",
                        }}
                      />
                                    </button>
                                    <button
                                        className='custom_btn outline_disabled_button btn btn-outline-danger'
                                        onClick={() => handleReject(params.row)}
                                        disabled={isDisabled}
                                        style={{ width: '80px' }}// Adjust button width here
                                    >
                                        <MdCancel
                        style={{
                          color: `${
                            params.row.adminResponded === 1
                            ? "#CCC"
                            : "red"
                            }`,
                          fontSize: "15px",
                        }}
                      />
                                    </button>
                                </div>
                            )
                        },
                    };

                    // Separate integer columns and string columns based on their values
                    const integerColumns = dynamicColumns.filter((column) => column.type === 'number');
                    const stringColumns = dynamicColumns.filter((column) => column.type === 'string');

                    // Combine integer columns, string columns, and the actions column
                    const combinedColumns = [...integerColumns, ...stringColumns, actionsColumn];

                    setColumns(combinedColumns);
                    setRows(response?.data?.rowData);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setAlertError("something went wrong");
                    setIsLoading(false);
                    console.log("Get Filters Api: ", error);
                });
        };
        if (selectedTab === "Extension Requests"){
            getDeadlineReasonsData();
        }
        else if (selectedTab === "Update Requests"){
            getTemplotData();
        }
        

    }, [selectedTab]);
    const getTemplotData2 = async () => {
        setIsLoading(true);
        await axios({
            method: "get",
            url: "/templots", // Update this with the correct user ID
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response, "templot data");

            // Assuming response.data.lots is the array of data
            const rowData = response?.data?.lots;

            // Check the first row to dynamically generate columns
            const dynamicColumns = Object.keys(rowData[0]).filter(columnName => !['adminResponded', 'templotId', 'sellerId'].includes(columnName)).map((columnName) => {
                const columnValue = rowData[0][columnName];
                const isInteger = Number.isInteger(columnValue);
                
                // Basic column definition
                const columnDef = {
                    field: columnName,
                    headerName: columnName,
                    width: isInteger ? 75 : 150,
                    type: isInteger ? 'number' : 'string',
                };
                
                // If column is 'Price', adjust its rendering
                if (columnName === 'Price') {
                    columnDef.renderCell = (params) => {
                        return `${params.value} ${currentUser.currency}`;
                    }
                }
            
                return columnDef;
            });

            const actionsColumn = {
                field: 'actions',
                headerName: 'Actions',
                minWidth: 200,
                flex: 1,
                renderCell: (params) => {
                    const isDisabled = params?.row?.adminResponded === 1;
                    return (
                        <div className="d-flex gap-3 m-auto">
                            <button
                                className='custom_btn outline_disabled_button btn btn-outline-success'
                                onClick={() => handleApproveLot(params.row)}
                                disabled={isDisabled}
                                style={{ width: '80px' }}
                            >
                                <BsFillCheckCircleFill
                                    style={{
                                        color: `${
                                            params.row.adminResponded === 1
                                            ? "#CCC"
                                            : "green"
                                        }`,
                                        fontSize: "15px",
                                    }}
                                />
                            </button>
                            <button
                                className='custom_btn outline_disabled_button btn btn-outline-danger'
                                onClick={() => handleRejectLot(params.row)}
                                disabled={isDisabled}
                                style={{ width: '80px' }}
                            >
                                <MdCancel
                                    style={{
                                        color: `${
                                            params.row.adminResponded === 1
                                            ? "#CCC"
                                            : "red"
                                        }`,
                                        fontSize: "15px",
                                    }}
                                />
                            </button>
                        </div>
                    );
                },
            };

            // Separate integer columns and string columns
            const integerColumns = dynamicColumns.filter((column) => column.type === 'number');
            const stringColumns = dynamicColumns.filter((column) => column.type === 'string');

            // Combine columns
            const combinedColumns = [...integerColumns, ...stringColumns, actionsColumn];

            setColumns(combinedColumns);
            setRows(rowData);
            setIsLoading(false);
        })
        .catch((error) => {
            setAlertError("something went wrong");
            setIsLoading(false);
            console.log("Get templot Api error: ", error);
        });
    };

    const handleApproveLot = async (row) => {
        setIsLoading(true);
        await axios({
            method: "post",
            url: "/update_approved",
            data: {
                'lotId' : row?.LotId,
                'templotId': row?.templotId
            }
        })
            .then((response) => {
                getTemplotData2();
            })
            .catch(function (error) {
                setIsLoading(false);
                setAlertError("Process Failed!");
                setTxModalType(3);
                setTxMsg("Process Failed");
            });
    }

    const handleRejectLot = async (row) => {
        setIsLoading(true);
        await axios({
            method: "post",
            url: "/update_reject",
            data: {
                'lotId' : row?.LotId,
                'templotId': row?.templotId
            }
        })
            .then((response) => {
                getTemplotData2();
            })
            .catch(function (error) {
                setIsLoading(false);
                setAlertError("Process Failed!");
                setTxModalType(3);
                setTxMsg("Process Failed");
            });
    }






    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };
    const handleApprove = async (row) => {
        setIsLoading(true);
        await axios({
            method: "post",
            url: "/approve_deadline",
            data: {
                'deadlinereasonId': row?.ID,
            }
        })
            .then((response) => {
                console.log(response, "channel Pc");
                const dynamicColumns = response?.data?.columns
                    .filter(columnName => columnName !== 'adminResponded')
                    .map((columnName) => {
                        const columnValue = response?.data?.rowData[0][columnName];
                        const isInteger = Number.isInteger(columnValue);
                        return { field: columnName, headerName: columnName, width: isInteger ? 75 : 150 };
                    });
                const actionsColumn = {
                    field: 'actions',
                    headerName: 'Actions',
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                        const isDisabled = params?.row?.adminResponded === 1;
                        return (
                            <div className="d-flex gap-3 m-auto">
                                <button
                                    className='custom_btn outline_disabled_button btn btn-outline-success'
                                    onClick={() => handleApprove(params.row)}
                                    disabled={isDisabled}
                                    style={{ width: '80px' }}
                                >
                                    <BsFillCheckCircleFill
                        style={{
                          color: `${
                            params.row.adminResponded === 1
                            ? "#CCC"
                            : "green"
                            }`,
                          fontSize: "15px",
                        }}
                      />
                                </button>
                                <button
                                    className='custom_btn outline_disabled_button btn btn-outline-danger'
                                    onClick={() => handleReject(params.row)}
                                    disabled={isDisabled}
                                    style={{ width: '80px' }}// Adjust button width here
                                >
                                    <MdCancel
                        style={{
                          color: `${
                            params.row.adminResponded === 1
                            ? "#CCC"
                            : "red"
                            }`,
                          fontSize: "15px",
                        }}
                      />
                                </button>
                            </div>
                        )
                    },
                };



                setColumns([...dynamicColumns, actionsColumn]);
                setRows(response?.data?.rowData);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                setAlertError("Process Failed!");
                setTxModalType(3);
                setTxMsg("Process Failed");
            });
    }

    const handleReject = async (row) => {
        setIsLoading(true);
        await axios({
            method: "post",
            url: "/reject_deadline",
            data: {
                'deadlinereasonId': row?.ID,
            }
        })
            .then((response) => {
                console.log(response, "channel Pc");
                const dynamicColumns = response?.data?.columns
                    .filter(columnName => columnName !== 'adminResponded')
                    .map((columnName) => {
                        const columnValue = response?.data?.rowData[0][columnName];
                        const isInteger = Number.isInteger(columnValue);
                        return { field: columnName, headerName: columnName, width: isInteger ? 75 : 150, };
                    });

                const actionsColumn = {
                    field: 'actions',
                    headerName: 'Actions',
                    minWidth: 200,
                    flex: 1,
                    renderCell: (params) => {
                        const isDisabled = params?.row?.adminResponded === 1;
                        return (
                            <div className="d-flex gap-3 m-auto">
                                <button
                                    className='custom_btn outline_disabled_button btn btn-outline-success'
                                    onClick={() => handleApprove(params.row)}
                                    disabled={isDisabled}
                                    style={{ width: '80px' }}
                                >
                                    <BsFillCheckCircleFill
                        style={{
                          color: `${
                            params.row.adminResponded === 1
                            ? "#CCC"
                            : "green"
                            }`,
                          fontSize: "15px",
                        }}
                      />
                                </button>
                                <button
                                    className='custom_btn outline_disabled_button btn btn-outline-danger'
                                    onClick={() => handleReject(params.row)}
                                    disabled={isDisabled}
                                    style={{ width: '80px' }}// Adjust button width here
                                >
                                    <MdCancel
                        style={{
                          color: `${
                            params.row.adminResponded === 1
                            ? "#CCC"
                            : "red"
                            }`,
                          fontSize: "15px",
                        }}
                      />
                                </button>
                            </div>
                        )
                    },
                };

                setColumns([...dynamicColumns, actionsColumn]);
                setRows(response?.data?.rowData);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                setAlertError("Process Failed!");
                setTxModalType(3);
                setTxMsg("Process Failed");
            });
    }








    const filteredRows = searchQuery
        ? rows?.filter((row) =>
            Object.values(row).some((value) =>
                String(value)?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            )
        )
        : rows;


    return (
        <div className="create_items">
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header
                            getCollapseSidebar={getCollapseSidebar}
                            getCollapseDropdown={getCollapseDropdown}
                            logOut={props.logOut}
                        />
                    )}

                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}

                                <div
                                    className={`w-100 ${collapseSidebar ? "slide-in" : "slide-out"
                                        }`}
                                >
                                    <div className="content_panel container-fluid row_gap">
                                    
                                    <div className="purchase_request d-flex justify-content-between custom_section">
    <h4 className="pageName_heading">Extension Requests</h4>

    {/* Spacer to push heading and button group apart */}
    <div className="flex-grow-1 d-flex justify-content-center">
        <div className="btn-group" role="group">
            <button
                type="button"
                className={`btn custom_btn ${selectedTab === 'Extension Requests' ? 'btn-primary' : 'btn-light'}`}
                style={{width:'fit-content'}}
                onClick={() => setSelectedTab('Extension Requests')}
            >
                Extension Requests
            </button>
            <button
                type="button"
                className={`btn custom_btn ${selectedTab === 'Update Requests' ? 'btn-primary' : 'btn-light'}`}
                style={{width:'fit-content'}}
                onClick={() => setSelectedTab('Update Requests')}
            >
                Update Requests
            </button>
        </div>
    </div>

    {/* Another Spacer */}
    <div></div>
</div>


                                        <div className="row justify-content-between">
                                            <div className="col-md-5">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="input py-[1.5em] px-[3em]"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                        <div style={styles.container} className="customCard">
                                            <GridContextProvider>
                                                <DataGrid
                                                    rows={filteredRows ? filteredRows : []} columns={columns}
                                                    getRowId={(row) => selectedTab === 'Extension Requests' ? row['ID'] : row['templotId']}
                                                    loading={isLoading}
                                                    rowHeight={40}
                                                    // rowsPerPageOptions={[5]}
                                                    pagination
                                                    density="compact"
                                                    disableExtendRowFullWidth={true}
                                                    disableSelectionOnClick={false}
                                                    getRowClassName={(params) =>
                                                        params.indexRelativeToCurrentPage % 2 === 0 ? "dataTable_even_row" : "dataTable_odd_row"
                                                    }
                                                />
                                            </GridContextProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ExtensionRequests;
