import React from 'react';
import { useState, useEffect } from 'react';
import Header from '../header/Header';
import SearchHeader from '../header/SearchHeader';
import { Form } from 'react-bootstrap';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import Footer from '../footer/Footer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import '../../css/style.css';
import { useUserInput } from '../checkout/UserInputContext';

const DeliveryMethod = (props) => 
{
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const vat = localStorage.getItem("vat");
    const [favourites, setFavourites] = useState([]);
    const { userInput, setUserInput } = useUserInput();
    const [errors, setErrors] = useState({});
    const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location);
    const apiData = location.state.apiData;
    const address = location.state.userInput;
    console.log(apiData)

    

    

    const handleChange = ({target: {checked, value}}) => 
    {
        let deliveryMethods = [];

        if (checked) 
        {
            if (selectedDeliveryMethods.indexOf(value) === -1)
            {
                deliveryMethods = [...selectedDeliveryMethods, value];
                setSelectedDeliveryMethods(deliveryMethods);
            }
        } 
        else 
        {
            deliveryMethods = selectedDeliveryMethods.filter(deliveryMethod => deliveryMethod !== value);
            setSelectedDeliveryMethods(deliveryMethods);
        }

        var input = userInput;
        input["delivery_method"] = deliveryMethods.length;
        setUserInput(input);
        ValidateForm();
    };

    const handleSubmit = (event) => 
    {
        event.preventDefault();
    
        if (ValidateForm()) 
        {
            let deliveryMethodList = [...selectedDeliveryMethods];
            const freightInspectionIndex = deliveryMethodList.indexOf("FreightInspection");
            
            if (freightInspectionIndex > -1) 
            {
                deliveryMethodList.splice(freightInspectionIndex, 1);
                navigate("/delivery-by-freight-inspection-services", { state: {deliveryMethodList, apiData} });
            }
            else
            {
                const freightInsuranceIndex = deliveryMethodList.indexOf("FreightInsurance");
            
                if (freightInsuranceIndex > -1) 
                {
                    deliveryMethodList.splice(freightInsuranceIndex, 1);
                    navigate("/delivery-by-freight-insurance-services", { state: {deliveryMethodList, apiData} });
                }
                else
                {
                    const deliveryIndex = deliveryMethodList.indexOf("Delivery");
            
                    if (deliveryIndex > -1) 
                    {
                        deliveryMethodList.splice(deliveryIndex, 1);
                        navigate("/delivery-by-delivery-services", {state: apiData});
                    }
                }
            }
        }
    }

    function handleClick(selected) 
    {
        navigate("/product-detail/123", { state: selected });
    }

  const addToWishList = (productName) => {
    let tempFavourites = [...favourites];
    const index = tempFavourites.indexOf(productName);

    if (index === -1) {
      tempFavourites.push(productName);
      toast.success(productName + " has been added to your wish list.", {
        style: ({
          fontSize: '13px'
        })
      });
    }
    else {
      tempFavourites.splice(index, 1);
      toast.success(productName + " has been removed from your wish list.", {
        style: ({
          fontSize: '13px'
        })
      });
    }

    setFavourites(tempFavourites);
  }

  const ValidateForm = () => {
    let input = userInput;
    let errors = {};
    let isValid = true;

    if (!input["delivery_method"] || (input["delivery_method"] && input["delivery_method"]) === 0) {
      isValid = false;
      errors["delivery_method"] = "*Please select atleast one delivery method.";
    }

    setErrors(errors);
    return isValid;
  }

  return (
    <>
      {!currentUser ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          {/* <SearchHeader wishListCount = {favourites.length} /> */}
          <Header logOut={props.logOut} />

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />

          <div className="container main-container app_container">
            <div className='d_sm_center'>
              <h4 className="pageName_heading">PAYMENT</h4>
            </div>
            <div className="w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right" >
              <ul className="page-hierarchy">
                <li><a href="/cart">Your Cart</a></li>
                <li><a href="/checkout">Check Out</a></li>
                <li><a href="/delivery-method">Delivery</a></li>
              </ul>
            </div>
            <br />
            <hr style={{ width: '100%' }} />
            <div className="row payment_product_details">
              <div className="col-12 col-lg-6">
                <label className="label" style={{ paddingLeft: '0' }}>
                  <span className="font-bold text-[15px]">SHIPPING INFORMATION</span>
                  <a href="#/" onClick={(event) => { event.preventDefault() }} style={{ float: 'right' }}>Change</a>
                </label>

                                <div className = "shipping-container">
                                    <h1>Contact</h1>
                                    <span>{userInput.email_phone}</span>
                                    <hr style = {{ margin: '0.5rem 0' }} />
                                    <h1>Ship To</h1>
                                    <span>{userInput.address} - {userInput.city}, {userInput.country}</span>
                                    <hr style = {{ margin: '0.5rem 0' }} />
                                    <h1>Estimated Cost</h1>
                                    <span>TBC <p className = "text-muted"></p></span>
                                </div>
                                <br />
                                
                                <label className = "label" style = {{ paddingLeft: '0' }}>
                                    <span className = "font-bold text-[15px]">DELIVERY OPTIONS</span>
                                </label>
                                <p className = "text-muted text-[14px] methodText">Please select your delivery method.</p>
                                <Form>
                                    {['checkbox'].map((type, index) => (
                                        <div key = {index} className = "mb-3">
                                            <Form.Check 
                                                id = "1"
                                                type = {type}
                                                value = "FreightInspection"
                                                label = "Freight Inspection Services"
                                                onChange = {handleChange}
                                            />

                                            <Form.Check
                                                id = "2"
                                                type = {type}
                                                value = "FreightInsurance"
                                                label = "Freight Insurance Services"
                                                onChange = {handleChange}
                                            />

                                            <Form.Check
                                                id = "3"
                                                type = {type}
                                                value = "Delivery"
                                                label = "Delivery Services"
                                                onChange = {handleChange}
                                            />
                                        </div>
                                    ))}
                                </Form>
                                <div className = "text-danger">
                                    <small>{errors.delivery_method}</small>
                                </div>
                                <div className='cart_single_blueButton' style = {{ marginTop: '2em' }}>
                                    <button type = "submit" onClick = {handleSubmit} className = "btn btn-primary" style = {{ width: '50%', fontSize: '13px'}}>PROCEED FURTHER</button>
                                </div>
                            </div>
                            <div
                  className="col-12 col-lg-6 products_container"
                  style={{ backgroundColor: "#F1F1F1" }}
                >
                  <div
                    className="scrollable-container"
                    style={{
                      maxHeight: "500px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="row">
                      {apiData.gProducts.map((companyProducts, companyIndex) => (
                        <div className="col-12" key={companyIndex}>
                          <div className="card my-3">
                            <div className="card-body">
                              <h4>{companyProducts.company}({companyProducts.sellerName})</h4>
                              {companyProducts.products.map(
                                (product, index) => (
                                  <div
                                    className="product-container d-flex justify-content-between align-items-center"
                                    key={index}
                                  >
                                    <div className="d-flex">
                                      <div style={{ position: "relative" }}>
                                        <img loading="lazy" 
                                          src={`${product.image}`}
                                          alt=""
                                          className="h-[60px] w-[60px]"
                                        />
                                        <div
                                          className="quantity"
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                          }}
                                        >
                                          {product.quantity}
                                        </div>
                                      </div>
                                      <div className="ml-3">
                                        <h1 className="text-[13px] font-bold">
                                          {product.name}
                                        </h1>
                                        {/* <h1 className="text-[13px] font-bold">
                                          Industry: {product.itemIndustry}
                                        </h1> */}
                                        <h1 className="text-[13px] font-bold">
                                          Price: {product.price} {currentUser.currency}
                                        </h1>
                                      </div>
                                    </div>
                                    <div>
                                      <h1
                                        className="text-[13px] font-bold"
                                        style={{ color: "#0d6efd" }}
                                      >
                                        
                                        {(
                                          product.price * product.quantity
                                        ).toFixed(2)} {currentUser.currency}
                                      </h1>
                                    </div>
                                  </div>
                                )
                              )}
                              <div className="text-right">
                                <h1 className="text-[13px] font-bold">
                                  Sub Total: {companyProducts.subcart_total} {currentUser.currency}
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Shipping: EXW
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Insurance: TBC
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Inspection: TBC
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  VAT: {companyProducts.subcart_vat.toFixed(2)} {currentUser.currency}
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Commission: 
                                  {companyProducts.subcart_commission.toFixed(
                                    2
                                  )} {currentUser.currency}
                                </h1>
                                <h1 className="text-[13px] font-bold">
                                  Total: 
                                  {(parseFloat(
                                    companyProducts.subcart_commission.toFixed(
                                      2
                                    )
                                  ) +
                                    parseFloat(
                                        companyProducts.subcart_vat.toFixed(2)
                                    ) +
                                    parseFloat(companyProducts.subcart_total)).toFixed(2)} {currentUser.currency}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <hr />
                    <h1 className="text-[12px] font-bold">
                      Sub Total
                      <span
                        className="text-gray-500"
                        style={{ float: "right" }}
                      >
                        {apiData.subTotal} {currentUser.currency}
                      </span>
                    </h1>
                    <h1 className="text-[12px] font-bold">
                      Shipping
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        EXW
                      </span>
                    </h1>
                    <h1 className="text-[12px] font-bold">
                      Insurance
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        TBC
                      </span>
                    </h1>
                    <h1 className="text-[12px] font-bold">
                      Inspection
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        TBC
                      </span>
                    </h1>
                    <h1 className="text-[12px] font-bold">
                      VAT
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        {apiData.VAT.toFixed(2)} {currentUser.currency}
                      </span>
                    </h1>
                    <h1 className="text-[12px] font-bold">
                      Commission
                      <span
                        className="ml-[106px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        {apiData.buyerCommission.toFixed(2)} {currentUser.currency}
                      </span>
                    </h1>
                    <br />
                    <h1 className="text-[14px] font-bold">
                      TOTAL
                      <span
                        className="ml-[122px] text-gray-500"
                        style={{ float: "right" }}
                      >
                        {apiData.finalTotal.toFixed(2)} {currentUser.currency}
                      </span>
                    </h1>
                  </div>
                </div>
                        </div>
                        <br />
                        <hr />

                          <h1 className="related_products_heading text-gray-600 font-bold text-[25px] inline-block float-left mt-[13px]">RELATED</h1>
                        {/* <div className = "w-auto text-sm breadcrumbs text-gray-500 text-[13px] inline-block float-right">
                            <div className = "flex flex-row space-x-2">
                                <div className = "bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                                    <svg xmlns = "http://www.w3.org/2000/svg" width = "16" height = "16" fill = "currentColor" className = "bi bi-arrow-left" viewBox = "0 0 16 16"> <path fillRule = "evenodd" d = "M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" /> </svg>
                                </div>
                                <div className = "bg-gray-300 p-3 rounded-lg hover:bg-gray-600">
                                    <svg style = {{ color: 'rgb(78, 77, 70)' }} xmlns = "http://www.w3.org/2000/svg" width = "16" height = "16" fillRule = "currentColor" className = "bi bi-arrow-right" viewBox = "0 0 16 16"> <path fillRule = "evenodd" d = "M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill = "#4e4d46"></path> </svg>
                                </div>
                            </div>
                        </div> */}
            <br className='d_sm_none' />
            <br className='d_sm_none' />
            <br className='d_sm_none' />
            <br />
            <div className="row related_products">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("Raw Materials")}>
                    <img loading="lazy"  src={require('../../images/image002.jpg')} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                    </div>
                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                      <h2 className="text-[14px] mt-1">ISO Propyl Alcohol</h2>
                      <div className="rating w-30px" style={{ float: 'right' }}>
                        <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("ISO Propyl Alcohol") > -1 ? '900' : ''}` }} onClick={() => addToWishList("ISO Propyl Alcohol")} />
                      </div>
                    </div>
                    <hr style={{ margin: '0' }} />
                  </div>
                  <div className='product_price' style={{ padding: '0 1em' }}>
                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                    <br />
                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("Packaging Materials")}>
                    <img loading="lazy"  src={require('../../images/image001.png')} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                    </div>
                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                      <h2 className="text-[14px] mt-1">Wood</h2>
                      <div className="rating w-30px" style={{ float: 'right' }}>
                        <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("Wood") > -1 ? '900' : ''}` }} onClick={() => addToWishList("Wood")} />
                      </div>
                    </div>
                    <hr style={{ margin: '0' }} />
                  </div>
                  <div className='product_price' style={{ padding: '0 1em' }}>
                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                    <br />
                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("")}>
                    <img loading="lazy"  src="https://placeimg.com/400/225/arch" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                    </div>
                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                      <h2 className="text-[14px] mt-1">Sample Product 1</h2>
                      <div className="rating w-30px" style={{ float: 'right' }}>
                        <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("Sample Product 1") > -1 ? '900' : ''}` }} onClick={() => addToWishList("Sample Product 1")} />
                      </div>
                    </div>
                    <hr style={{ margin: '0' }} />
                  </div>
                  <div className='product_price' style={{ padding: '0 1em' }}>
                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                    <br />
                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 col-xl-3 d-flex justify-content-center">
                <div className="product_card card card-compact w-[240px] rounded-none bg-base-100 shadow-xl">
                  <figure style={{ margin: '0', height: '200px', cursor: 'pointer' }} onClick={() => handleClick("")}>
                    <img loading="lazy"  src="https://placeimg.com/400/225/arch" alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                  </figure>
                  <div className="card-body">
                    <div className="rating rating-sm space-x-2">
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                      <input type="radio" name="rating-6" className="mask mask-star-2 bg-orange-400" />
                    </div>
                    <div className="flex flex-row" style={{ justifyContent: 'space-between' }}>
                      <h2 className="text-[14px] mt-1">Sample Product 2</h2>
                      <div className="rating w-30px" style={{ float: 'right' }}>
                        <input type="radio" name="rating-3" className="whishlist_icon hover:bg-red-600" style={{ fontWeight: `${favourites.indexOf("Sample Product 2") > -1 ? '900' : ''}` }} onClick={() => addToWishList("Sample Product 2")} />
                      </div>
                    </div>
                    <hr style={{ margin: '0' }} />
                  </div>
                  <div className='product_price' style={{ padding: '0 1em' }}>
                    <p className="text-[14px]">Lorem ipsum dolor sit amet, consectetur dolor sit amet, consectetur.</p>
                    <br />
                    <h1 className="text-[20px] font-bold mb-3" style={{ color: '#0d6efd' }}>$225.50</h1>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default DeliveryMethod;