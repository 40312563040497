import React from 'react'
import Papa from "papaparse";
import { Button } from 'react-bootstrap';
import "../../css/inventory.css";
import "../../css/style.css";

const CustomDownloader = ({ rows, title }) => {
    const handleDownloadClick = () => {
        const correctedRows = rows?.map(function (item) {
            var key = Object.keys(item);

            for (var i in key) {
                if (isNaN(item[key[i]])) {
                    item[key[i]] = item[key[i]].replace('"', "");
                }
            }

            return item;
        });

        const csvContent =
            "\uFEFF" + Papa.unparse(correctedRows, { headers: true });

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", title + ".csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // setCSVHeaders(columns);
        // setCSVData(correctedRows);
    };
    return (
                    <button
                        className="publish ml-5"
                        onClick={handleDownloadClick}
                        disabled={rows.length < 0}
                    >
                        Download Data
                    </button>
    )
}

export default CustomDownloader