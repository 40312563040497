import React from "react";
import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../css/signup.css";
import "../../css/style.css";
import "react-phone-number-input/style.css";
import axios from "axios";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";

const ChangeProfilePassword = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("Inv-X Current User"));
    const navigate = useNavigate();

    const [oldPassword, setOldPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({});
    const [collapseSidebar, setCollapseSidebar] = useState(
        JSON.parse(localStorage.getItem("CollapseSidebar"))
    );
    const logoUrl = localStorage.getItem('logoUrl');
    const [collapseDropdown, setCollapseDropdown] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1024px)").matches
    );



    useEffect(() => {
        window
            .matchMedia("(max-width: 1024px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);

    const getCollapseSidebar = (value) => {
        setCollapseSidebar(value);
    };

    const getCollapseDropdown = (value) => {
        setCollapseDropdown(value);
    };

    const [inventoryRole, setInventoryRole] = useState([]);
    const location = useLocation();
    const userid = location?.state?.responseData?.userid;
    // const [platformRole, setPlatformRole] = useState(null);
    const [organizationType, setOrganizationType] = useState([]);
    const [industry, setIndustry] = useState(null);
    const [errors, setErrors] = useState({});
    const [checkFlag, setCheckFlag] = useState(false);
    const [passwordValid, setPasswordValid] = useState(true);
    const dispatch = useDispatch();
    console.log(inventoryRole);

    const handlePasswordChange = async (e) => {
        setIsLoading(true)
        e.preventDefault();

        // Check if any field is empty
        if (!oldPassword || !password || !confirmPassword) {
            setIsLoading(false)
            toast.error("All fields are required");
            return;  // Return early without making the API request
        }

        // Validate password strength using validatePassword function
        if (!validatePassword(password)) {
            setIsLoading(false)
            toast.error("Password should contain at least one uppercase letter and one number");
            return;  // Return early without making the API request
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setIsLoading(false)
            toast.error("Confirm password does not match new password");
            return;  // Return early without making the API request
        }

        try {
            const response = await axios.post('/change_password', {
                user_id: currentUser.id,
                old_password: oldPassword,
                new_password: password
            });

            if (response.status === 200) {
                setIsLoading(false)
                toast.success("Password updated successfully");
                navigate("/");  // or use history.push('/') if you're using react-router-dom
            }
        } catch (error) {
            setIsLoading(false)
            const errorMessage = error.response && error.response.data.error ? error.response.data.error : "An error occurred";
            toast.error(errorMessage);
        }
    }

    const validatePassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        return hasUpperCase && hasNumber;
    };







    return (
        <div
        >
            {!currentUser ? (
                <Navigate to="/admin/login" replace={true} />
            ) : (
                <>
                    {matches && (
                        <Header getCollapseSidebar={getCollapseSidebar} getCollapseDropdown={getCollapseDropdown} logOut={props.logOut} />
                    )}
                    <div className="admin_panel">
                        <div className="d-lg-flex">
                            <div
                                className={`${collapseSidebar ? "pinched_sidebar" : "open_sidebar"
                                    }`}
                            >
                                <Sidebar
                                    collapsed={collapseSidebar}
                                    collapsedDropdown={collapseDropdown}
                                />
                            </div>
                            <div
                                className={`inventory_list_content ${collapseSidebar ? "open_content" : "pinched_content"
                                    }`}
                            >
                                {!matches && (
                                    <Header
                                        getCollapseSidebar={getCollapseSidebar}
                                        getCollapseDropdown={getCollapseDropdown}
                                        logOut={props.logOut}
                                    />
                                )}
                                <div className="content_panel container-fluid row_gap">
                                    <Row>
                                        <Col xs={12} md={5}>
                                            <div className="form-group d-flex flex-column" style={{ marginTop: "0.5em" }}>
                                                <h4 className="pageName_heading">
                                                {logoUrl && <img src={logoUrl} alt="Company Logo" style={{ width: '40px', height: '40px', marginRight: '5px', verticalAlign: 'middle' }} />}
                                                    Change Password
                                                </h4>

                                                <label htmlFor="oldPassword" className="signUpLabels">
                                                    Old Password <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="oldPassword"
                                                    className="form-control"
                                                    id="oldPassword"
                                                    placeholder="Enter Old Password"
                                                    onChange={e => setOldPassword(e.target.value)}
                                                />

                                                <label htmlFor="password" className="signUpLabels" style={{ marginTop: "0.5em" }}>
                                                    New Password <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    className="form-control"
                                                    id="password"
                                                    placeholder="Enter New Password"
                                                    onChange={e => setPassword(e.target.value)}
                                                />

                                                <label htmlFor="confirmPassword" className="signUpLabels" style={{ marginTop: "0.5em" }}>
                                                    Confirm New Password <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    className="form-control"
                                                    id="confirmPassword"
                                                    placeholder="Confirm New Password"
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                />

                                                <button
                                                    className="btn btn-primary custom_btn m-auto-sm"
                                                    style={{ marginTop: "0.5em", width: 'fit-content' }}
                                                    onClick={(e) => {
                                                        handlePasswordChange(e);
                                                    }}>
                                                    {isLoading ? (<div className="d-flex justify-content-center">
                                                        <br />
                                                        <div className="spinner-border text-info" role="status" style={{ height: '20px', width: '20px' }}></div>
                                                    </div>) : (' Change Password')
                                                    }

                                                </button>
                                            </div>


                                            <br />

                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </div >
    );
};

export default ChangeProfilePassword;
